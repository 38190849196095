import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import './seller.scss';
import { faBoxArchive, faCartFlatbed } from '@fortawesome/free-solid-svg-icons';
import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import LoaderData from '../../../utils/loader';
import { errorAlert } from '../../../utils/alert';
import SellerPendingProductDetails from './pending-products';
import { clearSellerProductsListReq } from '../../../store/powerMart/actions';
// import SellerOrderStatus from './my-orders/order-status';
import OrderVerticalStepper from './my-orders/order-stepper';
import Tooltip from '../../../utils/Tooltip/Tooltip';
import { SellerProductDetails } from './add-products/products';

export const SellerDashboardComponent: React.FC = () => {
  const dispatch = useDispatch();
  //   const tokenData = getTokenFromSessionStorage();
  //   const userInitTp = getUserProfileSessionStorage();
  const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('my-orders');
  const [loaderUser, setLoaderUser] = React.useState(false);
  const listUser = useSelector((state: RootState) => state.powerMart.getUserList);

  const handleOrders = () => {
    setLoaderUser(false);
    setActiveTab('my-orders');
    setUserTp('');
    // dispatch(getUserListReq({
    //   userType: userTp,
    //   requestType: APIHeader.REQ_ALL_USER_BY_STATUS,
    //   status: 'PADM',
    //   // limit: ApiLIMIT.LIMIT,
    //   token: tokenData,
    //   // searchData: '',
    // }));
  };
  const handleProductListing = () => {
    dispatch(clearSellerProductsListReq());
    setActiveTab('prod-listing');
    // dispatch(getUserListReq({
    //   userType: userTp,
    //   requestType: APIHeader.REQ_ALL_USER_BY_STATUS,
    //   status: 'APPR',
    //   // limit: ApiLIMIT.LIMIT,
    //   token: tokenData,
    //   // searchData: '',
    // }));
  };
  const handlePendProduct = () => {
    dispatch(clearSellerProductsListReq());
    setActiveTab('pend-product');
  };

  React.useEffect(() => {
    setLoaderUser(false);
    if (activeTab === 'my-orders' && userTp !== '') {
    //   dispatch(getUserListReq({
    //     userType: userTp,
    //     requestType: APIHeader.REQ_ALL_USER_BY_STATUS,
    //     status: 'PADM',
    //     // limit: ApiLIMIT.LIMIT,
    //     token: tokenData,
    //     // searchData: '',
    //   }));
    }
  }, [userTp]);

  React.useEffect(() => {
    if (listUser.isError && listUser.message !== '') {
      setLoaderUser(false);
      setAlertMsg(listUser.message);
      setErrorShowAlert(true);
    }
  }, [listUser.isError, listUser.message]);

  React.useEffect(() => {
    if (!listUser.isError && listUser.message === 'success') {
      setLoaderUser(false);
    //   if (activeTab === 'pending') {
    //     setPendingUserData(listUser);
    //   } else if (activeTab === 'approved') {
    //     setApprovedUserData(listUser);
    //   } else if (activeTab === 'revoked') {
    //     setRevokedUserData(listUser);
    //   }
      // dispatch(clearUserListReq());
    }
  }, [listUser.isError, listUser.message, listUser.userData]);

  return (
    <div className="label-margin-to-nav">
      { loaderUser ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-1 pt-3">
        <span className="powermart-main-label">
          Quick View - Order Status
        </span>
      </div>
      <Row>
        <Col xs="12" className="pt-4">
          <Nav tabs className="seller-profile-tab">
            <div className="seller-row-profile-tab">
              <div className="seller-column-profile-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="seller-profile-nav-tabs" active={activeTab === 'my-orders'} onClick={handleOrders}>
                    <FontAwesomeIcon icon={faCartFlatbed} className="seller-tab-profile-icon mr" />
                    <span>My orders</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="seller-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="seller-profile-nav-tabs" active={activeTab === 'pend-product'} onClick={handlePendProduct}>
                    <FontAwesomeIcon icon={faDropbox} className="seller-tab-profile-icon mr" />
                    <span className="seller-tab-text">Pending Products</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="seller-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="seller-profile-nav-tabs" active={activeTab === 'prod-listing'} onClick={handleProductListing}>
                    <FontAwesomeIcon icon={faBoxArchive} className="seller-tab-profile-icon mr" />
                    <span className="seller-tab-text">Product Listing</span>
                  </NavLink>
                </NavItem>
              </div>
            </div>
            <div className="seller-row-profile-tab-mobile">
              <div className="seller-column-profile-tab-left">
                <NavItem className="cursor-pointer seller-profile-tab">
                  <Tooltip
                    content="My Orders"
                    position="bottom"
                    customClassName="myOrderId"
                  >
                    <NavLink id="myOrderId" className="seller-profile-nav-tabs" active={activeTab === 'my-orders'} onClick={handleOrders}>
                      <FontAwesomeIcon icon={faCartFlatbed} className="seller-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
              <div className="seller-column-profile-tab-right">
                <NavItem className="profile-pointer-user-sadmin seller-profile-tab">
                  <Tooltip
                    content="Pending Products"
                    position="bottom"
                    customClassName="pendProductId"
                  >
                    <NavLink id="pendProductId" className="seller-profile-nav-tabs" active={activeTab === 'pend-product'} onClick={handlePendProduct}>
                      <FontAwesomeIcon icon={faDropbox} className="seller-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
              <div className="seller-column-profile-tab-right">
                <NavItem className="profile-pointer-user-sadmin seller-profile-tab">
                  <Tooltip
                    content="Product Listing"
                    position="bottom"
                    customClassName="prodListing"
                  >
                    <NavLink id="prodListing" className="seller-profile-nav-tabs" active={activeTab === 'prod-listing'} onClick={handleProductListing}>
                      <FontAwesomeIcon icon={faBoxArchive} className="seller-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            {activeTab === 'my-orders' && (
            <TabPane tabId="my-orders">
              <OrderVerticalStepper />
              {/* <SellerOrderStatus orderSts="my-orders" /> */}
            </TabPane>
            )}
            {activeTab === 'prod-listing' && (
            <TabPane tabId="prod-listing">
              <SellerProductDetails orderSts="prod-listing" />
            </TabPane>
            )}
            {activeTab === 'pend-product' && (
            <TabPane tabId="pend-product">
              <SellerPendingProductDetails orderSts="pend-product" />
            </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default SellerDashboardComponent;
