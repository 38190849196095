import { faCopyright } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import {
  Row,
  Col,
} from 'reactstrap';
import './powerMart.scss';

export const WDSFooter: React.FC = () => (
  <div className="footer-wds">
    <Row>
      <Col xs="12">
        <div className="align-center my-1">
          <span className="copyright">
            copyright {' '}<FontAwesomeIcon icon={faCopyright} />{' '}
            <a
              href="https://wdsinfotech.com/"
              className="text-copyright-mail"
              rel="noreferrer"
              target="_blank"
              style={{
                marginLeft: '2px', marginRight: '2px', fontSize: '15px', textDecoration: 'none',
              }}
            >
              wdsinfotech
            </a>
            {' '} 2020
          </span>
        </div>
      </Col>
    </Row>
  </div>
);

export default WDSFooter;
