import { GetListProdImgResponse, GetListProdImgResults } from './getList-prodimage.service.types';

export const GetListProdImgTransform: (response: GetListProdImgResponse) => GetListProdImgResults = (createProdImgDBResults) => {
  const { data } = createProdImgDBResults;
  const result: GetListProdImgResults = {
    data: [],
  };
  result.data = data;
  return result;
};
