import { getCommonApiToken } from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { GetAllSellerProductOptions, GetAllSellerProductResponse } from './get-all-seller-product.service.types';

const { POWERMART_API_ROOT } = getConfig();

export const GetAllSellerProductRequest = ({
  requestType, userType, prodTp, token, evalKey, limit, evalTp, searchBy, isSearch, isFilter, filterBy, isAccsProdF,
}: GetAllSellerProductOptions): Promise<GetAllSellerProductResponse> => {
  if (isFilter === 'YES') {
    return getCommonApiToken(`${POWERMART_API_ROOT}?userTp=${userType}&prodTp=${prodTp}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
  } if (isSearch === 'YES') {
    return getCommonApiToken(`${POWERMART_API_ROOT}?userTp=${userType}&isSearch=${isSearch}&searchBy=${searchBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
  } if (isAccsProdF === 'YES') {
    return getCommonApiToken(`${POWERMART_API_ROOT}?userTp=${userType}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isAccsProdF=${isAccsProdF}`, requestType, token);
  }
  return getCommonApiToken(`${POWERMART_API_ROOT}?userTp=${userType}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
};
