import React, { MutableRefObject, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import './powerMart.scss';
// import { WDSFooter } from './footer';
import Navbar from '../../components/public/navbar/powerMart.navbar';
import { HomeComponentList } from '../../components/public/home/home-component';
import { WDSFooter } from './footer';

export const PublicPowerMartComponent: React.FC = () => {
  const location = useLocation();
  const aboutDiv = useRef() as MutableRefObject<HTMLDivElement>;
  React.useLayoutEffect(() => {
    if (location.hash === '#about') {
      aboutDiv.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [location]);

  return (
    <div>
      <Helmet>
        <title>PowerMart</title>
        <meta name="description" content="Powermart-Integrated Power Solutions" />
        <meta name="ACCUCHEK" content="ACCUCHEK LT 1 PH" />
        <meta name="35 KV" content="35 KV High Voltage Spark Discharge Test Setup" />
        <meta name="Smart Meter" content="1PH AND 3PH FULLY AUTOMATIC SMART METER" />
      </Helmet>
      <div>
        <Navbar />
        <HomeComponentList />
      </div>
      <WDSFooter />
    </div>
  );
};

export default PublicPowerMartComponent;
