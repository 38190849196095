import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import './nav.scss';
import {
  faBars, faClose, faHeadset,
  faUserPlus,
} from '@fortawesome/free-solid-svg-icons';
import {
  Nav,
} from 'reactstrap';
import { faCircleUser } from '@fortawesome/free-regular-svg-icons';

export const SellerNavbar: React.FC = () => {
  const history = useNavigate();
  // const location = useLocation();
  const [click, setClick] = React.useState(false);
  const [changeIcon, setChangeIcon] = React.useState(false);
  // const path = location.pathname.split('/');

  const handleClick = () => {
    setChangeIcon(!changeIcon);
    setClick(!click);
  };

  return (
    <div className="navbar-container">
      <Nav className="navbar">
        <div className="navbar-left">
          <NavLink to="/" className="nav-logo">
            <div className="pt-2 ml-2">
              <img
                style={{
                  height: '40px', width: 'auto',
                }}
                src="/powerMart.png"
                alt="powerMart"
                className="logo-bg"
              />
            </div>
          </NavLink>
          <ul className={click ? 'nav-menu active' : 'nav-menu'}>
            <div className="mobile-view-nav pt-3">
              <div className="pt-2 mx-1">
                <NavLink
                  className="nav-links mx-1"
                  to="/seller-login/seller#logn"
                  title="User Login"
                  onClick={() => { history('/seller-login/seller#logn'); window.scrollTo(0, 0); }}
                >
                  <FontAwesomeIcon icon={faCircleUser} className="nav-mobile-icon mx-2" />
                  <span className="nav-text-login mx-2">Login</span>
                </NavLink>
              </div>
              <div className="pt-3 mx-1">
                <NavLink
                  className="nav-links mx-1"
                  to="/seller-login/seller#signUp"
                  title="Sign up"
                >
                  <FontAwesomeIcon icon={faUserPlus} className="nav-mobile-icon mx-2" />
                  <span className="nav-text-login mx-2">Sign up</span>
                </NavLink>
              </div>
              <div className="pt-3 mx-1">
                <NavLink
                  className="nav-links mx-1"
                  to="/power-support"
                  title="Support"
                >
                  <FontAwesomeIcon icon={faHeadset} className="nav-mobile-icon mx-2" />
                  <span className="nav-text-login mx-2">Support</span>
                </NavLink>
              </div>
            </div>
          </ul>
        </div>
        <div className="navbar-right mx-4">
          <NavLink
            className="nav-links mx-1"
            to="/seller-login/seller#logn"
            id="login"
            title="User Login"
          >
            <FontAwesomeIcon icon={faCircleUser} className="nav-icon-link mx-1" />
            <span className="nav-text-login">Login</span>
          </NavLink>
          <span className="mx-2 pipe-nav-lite">|</span>
          <NavLink
            className="nav-links mx-1"
            to="/seller-login/seller#signUp"
            id="custcare"
            title="Customer care"
            onClick={() => { history('/seller-login/seller#signUp'); window.scrollTo(0, 0); }}
          >
            <FontAwesomeIcon icon={faUserPlus} className="nav-icon-link mx-1" />
            <span className="nav-text-login">Sign up</span>
          </NavLink>
          <span className="mx-2 pipe-nav-lite">|</span>
          <NavLink
            className="nav-links mx-1"
            to="/power-support"
            id="custcare"
            title="Customer care"
            onClick={() => { history('/power-support'); window.scrollTo(0, 0); }}
          >
            <FontAwesomeIcon icon={faHeadset} className="nav-icon-link mx-1" />
            <span className="nav-text-login">Support</span>
          </NavLink>
        </div>
      </Nav>
      <NavLink
        className="nav-icon mr-1"
        onClick={() => { handleClick(); }}
        to="#"
      >
        {changeIcon ? <FontAwesomeIcon icon={faClose} className="nav-close-btn" /> : <FontAwesomeIcon icon={faBars} className="nav-open-btn" />}
      </NavLink>
    </div>
  );
};

export default SellerNavbar;
