import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { faCartArrowDown, faCheckToSlot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import '../cart.scss';
import { faOpencart } from '@fortawesome/free-brands-svg-icons';
import { APIHeader } from '../../../../../utils/constants';
import LoaderData from '../../../../../utils/loader';
import { errorAlert } from '../../../../../utils/alert';
import { getDiscomUtilSessionStorage, getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../../utils/service/localstorage-service';
import { clearKartListReq, getKartCountReq, getKartListReq } from '../../../../../store/powerMart/actions';
import { KartsResult } from '../../../../../services/products/list-kart/get-all-kart.types';
import CartPrivateList from './cart-list';
import CartReviewPrivateList from './kart-review';
import { KartCheckOutPrivateComponent } from './kart-checkout';
import Tooltip from '../../../../../utils/Tooltip/Tooltip';

export const KartComponent: React.FC = () => {
  const dispatch = useDispatch();
  const discomUtil = getDiscomUtilSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [kartCount, setKartCount] = React.useState(0);
  const [reviewCount, setReviewCount] = React.useState(0);
  const [pendCount, setPendCount] = React.useState(0);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('kart');
  const [discomUtility, setDiscomUtility] = React.useState('');
  const [loaderProduct, setLoaderProduct] = React.useState(false);
  const kartCountProducts = useSelector((state: RootState) => state.powerMart.getKartCount);
  const listKartProducts = useSelector((state: RootState) => state.powerMart.getKartList);
  const [productsData, setProductsData] = React.useState<KartsResult>({
    fCnt: 0,
    addr: '',
    mSts: '',
    ordId: '',
    sumCost: '',
    sumGst: '',
    sumTotal: '',
    prodTxnData: [],
  });

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
      setUserId(userInitTp && JSON.parse(userInitTp).profileData.UserCd);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData, activeTab]);

  const handleKart = () => {
    setLoaderProduct(true);
    dispatch(clearKartListReq());
    setProductsData({
      ...productsData,
      fCnt: 0,
      addr: '',
      mSts: '',
      ordId: '',
      sumCost: '',
      sumGst: '',
      sumTotal: '',
      prodTxnData: [],
    });
    dispatch(getKartListReq({
      userType: userTp,
      token: tokenData,
      requestType: APIHeader.REQ_GET_ALL_PROD_TXN_IN_CART,
      status: 'KART',
      discTp: discomUtility,
      // discomId: discomUtility,
    }));
    setActiveTab('kart');
  };

  const handleInReview = () => {
    setLoaderProduct(true);
    dispatch(clearKartListReq());
    setProductsData({
      ...productsData,
      fCnt: 0,
      addr: '',
      mSts: '',
      ordId: '',
      sumCost: '',
      sumGst: '',
      sumTotal: '',
      prodTxnData: [],
    });
    dispatch(getKartListReq({
      userType: userTp,
      token: tokenData,
      requestType: APIHeader.REQ_GET_ALL_PROD_TXN_IN_CART,
      status: 'RBUY',
      discTp: discomUtility,
      // discomId: discomUtility,
    }));
    setActiveTab('inReview');
  };

  const handleOrdrAppr = () => {
    setLoaderProduct(true);
    dispatch(clearKartListReq());
    setProductsData({
      ...productsData,
      fCnt: 0,
      addr: '',
      mSts: '',
      ordId: '',
      sumCost: '',
      sumGst: '',
      sumTotal: '',
      prodTxnData: [],
    });
    dispatch(getKartListReq({
      userType: userTp,
      token: tokenData,
      requestType: APIHeader.REQ_GET_ALL_PROD_TXN_IN_CART,
      status: 'PEND',
      discTp: discomUtility,
      // discomId: discomUtility,
    }));
    setActiveTab('ordAppr');
  };

  React.useEffect(() => {
    if (activeTab === 'kart' && userTp !== '') {
      setLoaderProduct(true);
      dispatch(getKartListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_IN_CART,
        status: 'KART',
        discTp: discomUtility,
        // discomId: discomUtility,
      }));
    }
  }, [userTp, tokenData, activeTab, discomUtility, activeTab]);

  React.useEffect(() => {
    if (discomUtil) {
      setDiscomUtility(discomUtil);
    }
  }, [discomUtil]);

  React.useEffect(() => {
    if (listKartProducts.isError && listKartProducts.message !== '') {
      setLoaderProduct(false);
      setAlertMsg(listKartProducts.message);
      setErrorShowAlert(true);
    }
  }, [listKartProducts.isError, listKartProducts.message, activeTab]);

  React.useEffect(() => {
    if (!listKartProducts.isError && listKartProducts.message === 'executed') {
      setLoaderProduct(false);
      setProductsData(listKartProducts.data);
    }
  }, [listKartProducts.isError, listKartProducts.message, listKartProducts.data.mSts, discomUtility, activeTab]);

  React.useEffect(() => {
    if (userTp !== '' && userId !== '' && discomUtility !== '') {
      dispatch(getKartCountReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_KART_COUNT,
        UserTp: userTp,
        UserCd: userId,
        status: 'KART',
        discTp: discomUtility,
        // discomId: discomUtility,
      }));
    }
  }, [userTp, userId, discomUtility, activeTab]);

  React.useEffect(() => {
    if (userTp !== '' && userId !== '' && discomUtility !== '') {
      dispatch(getKartCountReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_KART_COUNT,
        UserTp: userTp,
        UserCd: userId,
        status: 'RBUY',
        discTp: discomUtility,
        // discomId: discomUtility,
      }));
    }
  }, [userTp, userId, discomUtility, activeTab]);

  React.useEffect(() => {
    if (userTp !== '' && userId !== '' && discomUtility !== '') {
      dispatch(getKartCountReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_KART_COUNT,
        UserTp: userTp,
        UserCd: userId,
        status: 'PEND',
        discTp: discomUtility,
        // discomId: discomUtility,
      }));
    }
  }, [userTp, userId, discomUtility, activeTab]);

  React.useEffect(() => {
    if (!kartCountProducts.isError && kartCountProducts.message === 'executed') {
      const kartCnt = kartCountProducts.data;
      if (kartCnt.mSts === 'KART') {
        setKartCount(kartCnt.itmCnt);
      }
      if (kartCnt.mSts === 'RBUY') {
        setReviewCount(kartCnt.itmCnt);
      }
      if (kartCnt.mSts === 'PEND') {
        setPendCount(kartCnt.itmCnt);
      }
    }
  }, [kartCountProducts.isError, kartCountProducts.message, kartCount, reviewCount, pendCount, kartCountProducts.data, discomUtility, activeTab]);

  return (
    <div className="label-margin-to-nav">
      { loaderProduct ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-1 pt-2">
        <span className="powermart-main-label">
          Quick View - My Kart
        </span>
      </div>
      <Row>
        <Col xs="12" className="pt-4">
          <Nav tabs className="metadata-sadmin-profile-tab">
            <div className="metadata-sadmin-row-profile-tab">
              <div className="metadata-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'kart'} onClick={handleKart}>
                    <FontAwesomeIcon icon={faCartArrowDown} className="metadata-sadmin-tab-profile-icon" />
                    <span>All Orders({kartCount})</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="metadata-sadmin-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'ordAppr'} onClick={handleOrdrAppr}>
                    <FontAwesomeIcon icon={faCheckToSlot} className="metadata-sadmin-tab-profile-icon" />
                    <span className="metadata-sadmin-tab-text">Approved Orders({pendCount})</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="metadata-sadmin-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'inReview'} onClick={handleInReview}>
                    <FontAwesomeIcon icon={faOpencart} className="metadata-sadmin-tab-profile-icon" />
                    <span className="metadata-sadmin-tab-text">In Review({reviewCount})</span>
                  </NavLink>
                </NavItem>
              </div>
            </div>
            <div className="metadata-sadmin-row-profile-tab-mobile">
              <div className="metadata-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer metadata-sadmin-profile-tab">
                  <Tooltip
                    content="In Kart"
                    position="bottom"
                    customClassName="inKart"
                  >
                    <NavLink id="inKart" className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'kart'} onClick={handleKart}>
                      <FontAwesomeIcon icon={faCartArrowDown} className="metadata-sadmin-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
              <div className="metadata-sadmin-column-profile-tab-right">
                <NavItem className="profile-pointer-metadata-sadmin metadata-sadmin-profile-tab">
                  <Tooltip
                    content="Approved Orders"
                    position="bottom"
                    customClassName="ordApprID"
                  >
                    <NavLink id="ordApprID" className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'ordAppr'} onClick={handleOrdrAppr}>
                      <FontAwesomeIcon icon={faCheckToSlot} className="metadata-sadmin-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
              <div className="metadata-sadmin-column-profile-tab-right">
                <NavItem className="profile-pointer-metadata-sadmin metadata-sadmin-profile-tab">
                  <Tooltip
                    content="In Review"
                    position="bottom"
                    customClassName="reviewId"
                  >
                    <NavLink id="reviewId" className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'inReview'} onClick={handleInReview}>
                      <FontAwesomeIcon icon={faOpencart} className="metadata-sadmin-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            {activeTab === 'kart' && (
            <TabPane tabId="kart">
              <CartPrivateList productsData={productsData} setProductsData={setProductsData} setActiveTab={setActiveTab} />
            </TabPane>
            )}
            {activeTab === 'inReview' && (
            <TabPane tabId="inReview">
              <CartReviewPrivateList productsData={productsData} />
            </TabPane>
            )}
            {activeTab === 'ordAppr' && (
            <TabPane tabId="ordAppr">
              <KartCheckOutPrivateComponent productsData={productsData} />
            </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default KartComponent;
