import React from 'react';
import { useDispatch } from 'react-redux';
// import { RootState } from 'typesafe-actions';
import { faBox } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import './metadata.scss';
// import { APIHeader } from '../../../../utils/constants';
// import LoaderData from '../../../../utils/loader';
// import { errorAlert } from '../../../../utils/alert';
// import { getProductsListReq } from '../../../../store/powerMart/actions';
// import { ProductsResult } from '../../../../services/products/list-products/get-all-product.types';
import { faDropbox } from '@fortawesome/free-brands-svg-icons';
import { ProductDetails } from './products/products';
import DivisionData from './divisions/division-meta';
import { getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import { clearProductsListReq } from '../../../../store/powerMart/actions';
import { PendingProductDetails } from './products/pending-products';
import Tooltip from '../../../../utils/Tooltip/Tooltip';

export const MetaDataComponent: React.FC = () => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  // const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  // const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('product');
  // const [loaderProduct, setLoaderProduct] = React.useState(false);
  // const listProducts = useSelector((state: RootState) => state.powerMart.getProductList);
  // const [productsData, setProductsData] = React.useState<ProductsResult>({
  //   tCnt: 0,
  //   fCnt: 0,
  //   prodData: [],
  //   lastEvalKey: {
  //     ProdTp: '',
  //     ProdId: '',
  //   },
  // });

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  const handleProducts = () => {
    // setLoaderProduct(true);
    dispatch(clearProductsListReq());
    setActiveTab('product');
    // dispatch(getProductsListReq({
    //   requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
    //   isPublic: true,
    //   limit: 2,
    //   evalKey: '',
    //   evalTp: '',
    //   isFilter: 'NO',
    //   isSearch: 'NO',
    // }));
  };

  // const handleDivision = () => {
  //   setActiveTab('division');
  // };

  const handlePendProduct = () => {
    dispatch(clearProductsListReq());
    setActiveTab('pend-product');
  };

  // React.useEffect(() => {
  //   setLoaderProduct(true);
  //   if (activeTab === 'product' && userTp !== '') {
  //     dispatch(getProductsListReq({
  //       userType: userTp,
  //       requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
  //       isPublic: true,
  //       limit: 2,
  //       evalKey: '',
  //       evalTp: '',
  //       isFilter: 'NO',
  //       isSearch: 'NO',
  //     }));
  //   }
  // }, [userTp]);

  // React.useEffect(() => {
  //   if (listProducts.isError && listProducts.message !== '') {
  //     setLoaderProduct(false);
  //     setAlertMsg(listProducts.message);
  //     setErrorShowAlert(true);
  //   }
  // }, [listProducts.isError, listProducts.message]);

  // React.useEffect(() => {
  //   if (!listProducts.isError && listProducts.message === 'executed') {
  //     setLoaderProduct(false);
  //     if (activeTab === 'product') {
  //       setProductsData(listProducts.data);
  //     }
  //   }
  // }, [listProducts.isError, listProducts.message, listProducts.data]);

  return (
    <div className="label-margin-to-nav">
      {/* { loaderProduct ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null} */}
      <div className="mb-1 pt-5">
        <span className="powermart-main-label">
          Quick View - MetaData
        </span>
      </div>
      <Row>
        <Col xs="12" className="pt-3">
          <Nav tabs className="metadata-sadmin-profile-tab">
            <div className="metadata-sadmin-row-profile-tab">
              <div className="metadata-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'product'} onClick={handleProducts}>
                    <FontAwesomeIcon icon={faBox} className="metadata-sadmin-tab-profile-icon" />
                    <span>Products</span>
                  </NavLink>
                </NavItem>
              </div>
              {/* <div className="metadata-sadmin-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'division'} onClick={handleDivision}>
                    <FontAwesomeIcon icon={faChargingStation} className="metadata-sadmin-tab-profile-icon" />
                    <span className="metadata-sadmin-tab-text">Divisions</span>
                  </NavLink>
                </NavItem>
              </div> */}
              <div className="seller-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="seller-profile-nav-tabs" active={activeTab === 'pend-product'} onClick={handlePendProduct}>
                    <FontAwesomeIcon icon={faDropbox} className="seller-tab-profile-icon mr" />
                    <span className="seller-tab-text">Pending Products</span>
                  </NavLink>
                </NavItem>
              </div>
            </div>
            <div className="metadata-sadmin-row-profile-tab-mobile">
              <div className="metadata-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer metadata-sadmin-profile-tab">
                  <Tooltip
                    content="Products"
                    position="bottom"
                    customClassName="pendingId"
                  >
                    <NavLink id="pendingId" className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'product'} onClick={handleProducts}>
                      <FontAwesomeIcon icon={faBox} className="metadata-sadmin-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
              {/* <div className="metadata-sadmin-column-profile-tab-right">
                <NavItem className="profile-pointer-metadata-sadmin metadata-sadmin-profile-tab">
                  <NavLink id="divisionId" className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'division'} onClick={handleDivision}>
                    <FontAwesomeIcon icon={faChargingStation} className="metadata-sadmin-tab-profile-icon" />
                  </NavLink>
                  <UncontrolledTooltip delay={0} target="divisionId">
                    Divisions
                  </UncontrolledTooltip>
                </NavItem>
              </div> */}
              <div className="seller-column-profile-tab-right">
                <NavItem className="profile-pointer-user-sadmin seller-profile-tab">
                  <Tooltip
                    content="Pending Products"
                    position="bottom"
                    customClassName="pendProductId"
                  >
                    <NavLink id="pendProductId" className="seller-profile-nav-tabs" active={activeTab === 'pend-product'} onClick={handlePendProduct}>
                      <FontAwesomeIcon icon={faDropbox} className="seller-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            {activeTab === 'product' && (
            <TabPane tabId="product">
              <ProductDetails statusDet="product" />
            </TabPane>
            )}
            {activeTab === 'division' && (
            <TabPane tabId="division">
              <DivisionData />
            </TabPane>
            )}
            {activeTab === 'pend-product' && (
            <TabPane tabId="pend-product">
              <PendingProductDetails statusDet="pend-product" />
            </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default MetaDataComponent;
