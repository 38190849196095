/* eslint-disable no-console */
import AWS from 'aws-sdk';
import moment from 'moment-timezone';
import { getConfig } from '../config/config';
import { CreateProdImgTransportType } from '../../services/products/upload-prod-Image/create-prodimage.types';
import { GetAWSFileUrlTransportType } from '../../services/get-aws-files/get-aws-file.types';
import { GetListProdImgTransportType } from '../../services/products/get-list-product-images/getList-prodimage.types';
import { DeleteProdImgTransportType } from '../../services/products/delete-prod-image/delete-prodimage.types';
import { DeleteAWSFolderTransportType } from '../../services/products/delete-product/del-folder-aws-s3/delete-folder.types';

const {
  USERNAME, PASSWORD, AWS_ACCESS_KEY, AWS_SECRET, AWS_REGION,
} = getConfig();

const s3 = new AWS.S3({
  accessKeyId: AWS_ACCESS_KEY,
  secretAccessKey: AWS_SECRET,
  region: AWS_REGION,
});

export const getAWSS3FileDetailUrl = async (bucket: string, folderName: string, fileName: string): Promise<GetAWSFileUrlTransportType> => {
  try {
    const s3Key = `${folderName}/${fileName}`;
    const params = { Bucket: bucket, Prefix: s3Key };

    // Check if the file exists
    const data = await s3.listObjectsV2(params).promise();
    const fileExists = data.Contents && data.Contents.length > 0;

    if (!fileExists) {
      console.log('File not found:', fileName);
      return { data: '' }; // Or return { data: '' };
    }

    // File exists, generate signed URL
    const signedUrl = await s3.getSignedUrlPromise('getObject', {
      Bucket: bucket,
      Key: s3Key,
      ResponseContentDisposition: 'inline',
      ResponseContentType: 'application/pdf',
    });
    return { data: signedUrl };
  } catch (error) {
    console.error('Error checking file existence or getting signed URL:', error);
    return { data: 'ERROR' };
  }
};

export const checkIfFolderExists = async (bucket: string, folderPath: string): Promise<boolean> => {
  const params = {
    Bucket: bucket,
    Prefix: folderPath,
    Delimiter: '/',
  };

  try {
    const response = await s3.listObjectsV2(params).promise();
    return response.Contents !== undefined && response.Contents.length > 0;
  } catch (error) {
    return false;
  }
};

export const createFolder = async (bucket: string, folderPath: string) => {
  try {
    await s3.putObject({ Bucket: bucket, Key: `${folderPath}/` }).promise();
  } catch (error) {
    // console.error('Error creating folder:', error);
  }
};

const MAX_RETRIES = 3;
const RETRY_DELAY_MS = 1000; // 1 second

const retryWithDelay = async <T>(func: () => Promise<T>, retries: number): Promise<T> => {
  try {
    return await func();
  } catch (error) {
    if (retries > 0) {
      // console.log(`Retrying in ${RETRY_DELAY_MS / 1000} seconds...`);
      await new Promise<void>((resolve) => {
        setTimeout(() => {
          resolve();
        }, RETRY_DELAY_MS);
      });
      return retryWithDelay(func, retries - 1);
    }
    throw error;
  }
};

export const createFolderWithRetries = async (bucket: string, folderPath: string): Promise<void> => {
  await retryWithDelay(async () => {
    await createFolder(bucket, folderPath);
  }, MAX_RETRIES);
};

export const deleteFolderFromS3 = async (
  bucketName: string,
  folderName: string,
): Promise<DeleteAWSFolderTransportType> => {
  try {
    const listParams = {
      Bucket: bucketName,
      Prefix: `${folderName}/`,
    };

    const listedObjects = await s3.listObjectsV2(listParams).promise();

    if (!listedObjects.Contents || listedObjects.Contents.length === 0) {
      return { data: 'deleted' };
    }

    const objectsToDelete = listedObjects.Contents
      .map(({ Key }) => Key)
      .filter((Key): Key is string => Key !== undefined);

    if (objectsToDelete.length === 0) {
      return { data: 'deleted' };
    }

    const deleteParams = {
      Bucket: bucketName,
      Delete: {
        Objects: objectsToDelete.map((Key) => ({ Key })),
      },
    };

    await s3.deleteObjects(deleteParams).promise();

    return { data: 'deleted' };
  } catch (error) {
    console.error('Error deleting files:', error);
    throw error;
  }
};

export const deleteFilesFromS3 = async (
  bucketName: string,
  folderName: string,
  delFiles: string[],
): Promise<DeleteProdImgTransportType> => {
  try {
    const delPromises = delFiles.map(async (fileKey) => {
      const s3Key = `${folderName}/${fileKey}`;

      await s3.deleteObject({
        Bucket: bucketName,
        Key: s3Key,
      }).promise();
    });

    await Promise.all(delPromises);
    return { data: 'deleted' };
  } catch (error) {
    console.error('Error deleting files:', error);
    throw error;
  }
};

const getLastIndexNumber = async (bucketName: string, folderName: string): Promise<number> => {
  const params = {
    Bucket: bucketName,
    Prefix: `${folderName}/`, // Include the folder prefix
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const fileKeys = data.Contents?.map((item) => item.Key) || [];
    const lastIndex = fileKeys.reduce((maxIndex, fileKey) => {
      if (fileKey) {
        const match = fileKey.match(/_(\d+)\.\w+$/); // Match the index number in the file name
        if (match) {
          const index = parseInt(match[1], 10);
          return index > maxIndex ? index : maxIndex;
        }
      }
      return maxIndex;
    }, 0);
    return lastIndex;
  } catch (error) {
    console.error('Error getting last index number:', error);
    throw error;
  }
};

export const uploadS3File = async (
  bucketName: string,
  fileNmeInit: string,
  folderName: string,
  formData: FormData,
  isEdit: boolean,
): Promise<CreateProdImgTransportType> => {
  try {
    if (isEdit === true) {
      try {
        const lastIndex = await getLastIndexNumber(bucketName, folderName);
        const uploadPromises = Array.from(formData.entries()).map(async ([key, file], index) => {
          if (file instanceof File) {
            const indxNum = lastIndex + index + 1;
            const parts = key.split('.');
            const extension = parts[parts.length - 1];
            const filename = `${fileNmeInit}_${indxNum}.${extension}`;
            const s3Key = `${folderName}/${filename}`;

            const res = await s3.upload({
              Bucket: bucketName,
              Key: s3Key,
              Body: file,
              ACL: 'public-read',
            }).promise();

            return { data: res.Key };
          }
          throw new Error('Invalid file type');
        });

        const uploadResults = await Promise.all(uploadPromises);
        if (uploadResults.length > 0) {
          return uploadResults[0];
        }
        return { data: 'none' };
      } catch (error) {
        console.error('Error uploading files:', error);
        throw error;
      }
    }
    const uploadPromises = Array.from(formData.entries()).map(async ([key, file], index) => {
      if (file instanceof File) {
        const indxNum = index + 1;
        const parts = key.split('.');
        const extension = parts[parts.length - 1];
        const filename = `${fileNmeInit}_${indxNum}.${extension}`;
        const s3Key = `${folderName}/${filename}`;

        const res = await s3.upload({
          Bucket: bucketName,
          Key: s3Key,
          Body: file,
          ACL: 'public-read',
        }).promise();

        return { data: res.Key };
      }
      throw new Error('Invalid file type');
    });
    const uploadResults = await Promise.all(uploadPromises);
    if (uploadResults.length > 0) {
      return uploadResults[0];
    }
    return { data: 'none' };
  } catch (error) {
    console.error('Error uploading files:', error);
    throw error;
  }
};

export const getListS3File = async (
  bucketName: string,
  folderName: string,
): Promise<GetListProdImgTransportType> => {
  const params = {
    Bucket: bucketName,
    Prefix: folderName,
  };

  try {
    const data = await s3.listObjectsV2(params).promise();
    const fileKeys = data.Contents?.map((item) => {
      const fileName = item.Key?.split('/').pop() || '';
      const url = s3.getSignedUrl('getObject', {
        Bucket: bucketName,
        Key: item.Key,
        Expires: 60 * 60,
        ...(fileName.endsWith('.pdf')
          ? {
            ResponseContentDisposition: 'inline',
            ResponseContentType: 'application/pdf',
          }
          : {}),
      });
      return { url, nme: fileName };
    }) || [];
    return { data: fileKeys };
  } catch (error) {
    console.error('Error loading files: ', error);
    return { data: [] };
  }
};

export const getToken = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};
const requestOptions = (method: string, token: string, requestType: string, body?: BodyInit) => ({
  method,
  headers: {
    'token-data': token,
    'Request-Type': requestType,
  },
  body: JSON.stringify(body),
});

export const getJSON = async (url: RequestInfo, token: string, requestType: string) => {
  const response = await fetch(url, requestOptions('GET', token, requestType));
  const data = await response.json();
  return data;
};
export const postJSON = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('POST', token, requestType, body));
  const data = await response.json();
  return data;
};
export const putJSON = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('PUT', token, requestType, body));
  const data = await response.json();
  return data;
};
export const putNoneBodyJSON = async (url: RequestInfo, token: string, requestType: string) => {
  const response = await fetch(url, requestOptions('PUT', token, requestType));
  const data = await response.json();
  return data;
};

export const deleteJSON = async (url: RequestInfo, requestType: string, token: any) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'DELETE', headers });
  const data = await response.json();
  return data;
};
export const getLogin = async (url: RequestInfo, requestType: string, userName: string, passKey: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${userName}:${passKey}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};

export const getPublicJSON = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  };
  const response = await fetch(url, { headers });
  const data = await response.json();
  return data;
};

const requestOptionsPrivateFiles = (method: string, requestType: string, requestFile: string, requestData: string, token: string, formdata: FormData) => ({
  method,
  headers: {
    'token-data': token,
    'Request-Type': requestType,
    'Request-Data': requestData,
    'Request-File': requestFile,
    // 'Content-Type': formdata.type,
  },
  body: formdata,
});

const requestOptionsPublicFile = (method: string, requestType: string, requestFile: string, requestData: string, formdata: any) => ({
  method,
  headers: {
    'Request-Type': requestType,
    'Request-Data': requestData,
    'Request-File': requestFile,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  },
  body: formdata,
});

const requestPublicData = (method: string, requestType: string, bodyData: any) => ({
  method,
  headers: {
    'Request-Type': requestType,
    Authorization: `Basic ${btoa(`${USERNAME}:${PASSWORD}`)}`,
  },
  body: JSON.stringify(bodyData),
});

export const postPublicFileData = async (url: RequestInfo, requestType: string, fileUpload:string, body: BodyInit) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(body));
  formData.append('files', fileUpload);
  const response = await fetch(url, requestOptionsPublicFile('POST', requestType, '', '', formData));
  const data = await response.json();
  return data;
};

export const postPublicFormData = async (url: RequestInfo, requestType: string, requestFile: string, requestData: string, body: BodyInit) => {
  const formData = new FormData();
  formData.append('data', JSON.stringify(body));
  const response = await fetch(url, requestOptionsPublicFile('POST', requestType, requestFile, requestData, formData));
  const data = await response.json();
  return data;
};

export const postPublicData = async (url: RequestInfo, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestPublicData('POST', requestType, body));
  const data = await response.json();
  return data;
};

export const putPublicData = async (url: RequestInfo, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestPublicData('PUT', requestType, body));
  const data = await response.json();
  return data;
};

export const putCommonApi = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
  };
  const response = await fetch(url, { method: 'PUT', headers });
  const data = await response.json();
  return data;
};

export const getCommonApi = async (url: RequestInfo, requestType: string) => {
  const headers = {
    'Request-Type': requestType,
  };
  const response = await fetch(url, { method: 'GET', headers });
  const data = await response.json();
  return data;
};

export const getCommonApiToken = async (url: RequestInfo, requestType: string, token: any) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'GET', headers });
  const data = await response.json();
  return data;
};

export const patchApiData = async (url: RequestInfo, requestType: string, token: any) => {
  const headers = {
    'Request-Type': requestType,
    'token-data': token,
  };
  const response = await fetch(url, { method: 'PATCH', headers });
  const data = await response.json();
  return data;
};

export const patchApiBodyData = async (url: RequestInfo, token: string, requestType: string, body: BodyInit) => {
  const response = await fetch(url, requestOptions('PATCH', token, requestType, body));
  const data = await response.json();
  return data;
};

export const putPrivateFileData = async (url: RequestInfo, token: string, requestType: string, requestFile: string, requestData: string, fileData: FormData) => {
  const response = await fetch(url, requestOptionsPrivateFiles('PUT', requestType, requestFile, requestData, token, fileData));
  const data = await response.json();
  return data;
};

export const postCertFileData = async (url: RequestInfo, token: string, requestType: string, requestData: string, fileUpload:string, body: BodyInit) => {
  const formData = new FormData();
  formData.append('certData', JSON.stringify(body));
  formData.append('certFiles', fileUpload);
  const response = await fetch(url, requestOptionsPrivateFiles('PUT', requestType, '', requestData, token, formData));
  const data = await response.json();
  return data;
};

export const formatDate = (postDate: any) => {
  const now = moment();
  const date = moment.tz(postDate, 'Asia/Kolkata').add(5, 'hours').add(30, 'minutes');
  if (now.isSame(date, 'day')) {
    return `Today ${date.format('hh:mm A')}`;
  }
  if (now.year() === date.year()) {
    return date.format('DD MMM hh:mm A');
  }
  return date.format('DD MMM YYYY hh:mm A');
};
