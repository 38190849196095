import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Card, Button, Input,
  ButtonDropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Offcanvas,
  OffcanvasBody,
  OffcanvasHeader,
  CardBody,
  CardHeader,
} from 'reactstrap';
import {
  faCube,
  faFilePdf,
  faSearch,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductsResult } from '../../../services/products/list-products/get-all-product.types';
import { clearProdDescReq, getProductsListReq, prodDescReq } from '../../../store/powerMart/actions';
import { successAlert, errorAlert } from '../../../utils/alert';
import { APIHeader, ApiLIMIT } from '../../../utils/constants';
import LoaderData from '../../../utils/loader';
// import ShowMoreText from '../../../utils/showmore-text';
import ImageGallery from './thumbnail-image';
import { ProductDescResult } from '../../../services/products/prod-desc/product-desc.types';
import ShowMoreText from '../../../utils/showmore-text';

export const FullviewPowermartProducts: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [loaderProducts, setLoaderProducts] = React.useState(false);
  const [loaderProdDesc, setLoaderProdDesc] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [showCat, setShowCat] = React.useState(false);
  const [loadingMore, setLoadingMore] = React.useState(false);
  const [selectedCategory, setSelectedCategory] = React.useState('ALL');
  const [isFilterQuery, setIsFilterQuery] = React.useState('');
  const [isSearchQuery, setIsSearchQuery] = React.useState('');
  const [productsData, setProductsData] = React.useState<ProductsResult>({
    tCnt: 0,
    fCnt: 0,
    prodData: [],
    lastEvalKey: {
      ProdTp: '',
      ProdId: '',
    },
  });
  //   const [productTechSpec, setProductTechSpec] = React.useState<ProductDescResult>({
  //     data: {
  //       prodDesc: [],
  //       techSpec: [],
  //     },
  //     isError: false,
  //     message: '',
  //   });
  const [productDesc, setProductDesc] = React.useState<ProductDescResult>({
    data: {
      prodDesc: [],
      techSpec: [],
    },
    isError: false,
    message: '',
  });
  const [prodDescTp, setProdDescTp] = React.useState('');
  const listProducts = useSelector((state: RootState) => state.powerMart.getProductList);
  const prodDescRes = useSelector((state: RootState) => state.powerMart.prodDesc);
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [prodDetailsOpen, setProdDetailsOpen] = React.useState<Record<string, boolean>>({});
  // const [selectedProdIndex, setSelectedProdIndex] = React.useState<number | null>(null);
  // const [showProdDesc, setShowProdDesc] = React.useState(Array(productsData.prodData.length).fill(false));

  React.useEffect(() => {
    if (evalKeyText === '' && evalTpText === '') {
      setLoaderProducts(true);
      setProductsData({
        tCnt: 0,
        fCnt: 0,
        prodData: [],
        lastEvalKey: {
          ProdTp: '',
          ProdId: '',
        },
      });
      dispatch(getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: 10, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
      }));
    }
  }, [evalKeyText, evalTpText]);

  const fetchMoreData = () => {
    setLoaderProducts(true);
    if (isFilterQuery !== '') {
      if (selectedCategory === 'ALL') {
        dispatch(getProductsListReq({
          requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
          isPublic: true,
          limit: 10, // ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
        }));
      } else {
        dispatch(
          getProductsListReq({
            requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
            isPublic: true,
            limit: ApiLIMIT.LIMIT,
            evalKey: evalKeyText,
            evalTp: evalTpText,
            isFilter: 'YES',
            filterBy: selectedCategory,
            isSearch: 'NO',
          }),
        );
      }
    } else if (isSearchQuery !== '') {
      dispatch(
        getProductsListReq({
          requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
          isPublic: true,
          limit: ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          searchBy: searchText,
          isSearch: 'YES',
        }),
      );
    } else {
      dispatch(
        getProductsListReq({
          requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
          isPublic: true,
          limit: ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
        }),
      );
    }
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };

  const handleSearchProjects = () => {
    setLoaderProducts(true);
    setIsFilterQuery('');
    setIsSearchQuery(searchText);
    dispatch(
      getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
      }),
    );
  };

  React.useEffect(() => {
    if (listProducts.isError && listProducts.message !== '') {
      setLoaderProducts(false);
      setAlertMsg(listProducts.message);
      setErrorShowAlert(true);
    }
  }, [listProducts.isError, listProducts.message]);

  React.useEffect(() => {
    if (!listProducts.isError && listProducts.message === 'executed') {
      setLoaderProducts(false);
      if (searchText !== '') {
        setProductsData(listProducts.data);
        setLoadingMore(false);
      } else if (listProducts.data.lastEvalKey !== null) {
        const newData = listProducts.data.prodData.filter((item) => !productsData.prodData.some((existingItem) => existingItem.ProdId === item.ProdId));
        setProductsData((prevData) => ({
          ...prevData,
          tCnt: listProducts.data.tCnt,
          fCnt: listProducts.data.fCnt,
          prodData: [...prevData.prodData, ...newData],
          lastEvalKey: listProducts.data.lastEvalKey,
        }));
        setEvalKeyText(listProducts.data.lastEvalKey.ProdId);
        setEvalTpText(listProducts.data.lastEvalKey.ProdTp);
        setLoadingMore(true);
      } else {
        setProductsData((prevData) => ({
          ...prevData,
          tCnt: listProducts.data.tCnt,
          fCnt: listProducts.data.fCnt,
          prodData: [...prevData.prodData, ...listProducts.data.prodData],
        }));
        setLoadingMore(false);
      }
    }
  }, [listProducts.isError, listProducts.message, listProducts.data.prodData, listProducts.data.lastEvalKey, evalKeyText]);

  const handleShowCat = () => setShowCat((prevState) => !prevState);

  const handleSelectCategory = (text: string, catValue: string) => {
    setLoaderProducts(true);
    setSelectedCategory(text);
    setIsFilterQuery(catValue);
    setIsSearchQuery('');
    setSearchText('');
    setProductsData({
      ...productsData,
      tCnt: 0,
      fCnt: 0,
      prodData: [],
      lastEvalKey: {
        ProdTp: '',
        ProdId: '',
      },
    });
    if (catValue === 'ALL') {
      dispatch(getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: 10, // ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
      }));
    } else {
      dispatch(
        getProductsListReq({
          requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
          isPublic: true,
          limit: ApiLIMIT.LIMIT,
          evalKey: '',
          evalTp: '',
          isFilter: 'YES',
          filterBy: catValue,
          isSearch: 'NO',
        }),
      );
    }
  };

  const toggleProdDetails = (target: string, prodTp: string, prodId: string) => {
    setProdDescTp('PROD_DESC');
    setLoaderProdDesc(true);
    dispatch(prodDescReq({
      requestType: APIHeader.REQ_GET_PRODUCT_DESC,
      inSts: 'PROD_DESC',
      ProdTp: prodTp,
      ProdId: prodId,
    }));
    setProdDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  //   React.useEffect(() => {
  //     if (prodDescRes.isError && prodDescRes.message !== '') {
  //       setLoaderProdDesc(false);
  //       setAlertMsg(prodDescRes.message);
  //       setErrorShowAlert(true);
  //     }
  //   }, [prodDescRes.isError, prodDescRes.message]);

  React.useEffect(() => {
    if (!prodDescRes.isError && prodDescRes.message === 'executed') {
      setLoaderProdDesc(false);
      if (prodDescTp === 'PROD_DESC') {
        setProductDesc(prodDescRes);
      }
      //   else if (prodDescTp === 'TECH_SPEC') {
      //     setProductTechSpec(prodDescRes);
      //     setShowProdDesc((prevState) => {
      //       const updatedState = [...prevState];
      //       updatedState[selectedProdIndex] = true;
      //       return updatedState;
      //     });
      //   }
      dispatch(clearProdDescReq());
    }
  }, [prodDescRes.isError, prodDescRes.message, prodDescRes.data, prodDescTp, clearProdDescReq]);

  const toggleDescProdDetails = (target: string) => {
    setProdDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  //   const handleHideClick = (index: number) => {
  //     setShowProdDesc((prevState) => {
  //       const updatedState = [...prevState];
  //       updatedState[index] = false;
  //       return updatedState;
  //     });
  //     setSelectedProdIndex(null);
  //   };

  const handleLogin = () => {
    history('/login/puser#logn');
    window.scrollTo(0, 0);
  };

  return (
    <div className="margin-top-up">
      { loaderProdDesc ? <LoaderData /> : null}
      { loaderProducts ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="align-center">
        <Col xs="12" lg="12">
          <Card className="card-filter-power">
            <Row className="mx-1">
              <Col lg="5" xs="12">
                <div className="input-wrapper">
                  <Input
                    type="text"
                    placeholder="Search by Product Name"
                    onChange={handleSearchChange}
                  />
                  <Button type="button" onClick={handleSearchProjects}>
                    <FontAwesomeIcon icon={faSearch} />
                  </Button>
                </div>
              </Col>
              <Col lg="7" xs="12">
                <div className="align-fullview-tab pt-1">
                  <ButtonDropdown
                    isOpen={showCat}
                    toggle={() => handleShowCat()}
                    className="mx-1 margin-top-dropdwn"
                  >
                    <DropdownToggle caret className="button-open">
                      <span className="mx-2">{selectedCategory}</span>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem onClick={() => handleSelectCategory('ALL', 'ALL')} className="my-2">
                        ALL
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectCategory('Distribution', 'DIST')} className="my-2">
                        Distribution
                      </DropdownItem>
                      <DropdownItem onClick={() => handleSelectCategory('Transmission', 'TRANS')} className="my-2">
                        Transmission
                      </DropdownItem>
                      {/* <DropdownItem className="my-2">
                        Thermal
                      </DropdownItem> */}
                    </DropdownMenu>
                  </ButtonDropdown>
                </div>
              </Col>
              {/* <Col lg="2" xs="6">
                Budget
              </Col> */}
            </Row>
          </Card>
        </Col>
      </Row>
      <div className="margin-l-r-mobile">
        {/* <span className="text-lite-grey mx-2">Count: {projectsData && projectsData.projData.length} / {projectsData.tCnt}</span> */}
        <InfiniteScroll
          dataLength={productsData.prodData.length}
          next={fetchMoreData}
          hasMore={loadingMore}
          loader={loaderProducts}
        >
          <Row className="margin-top-des pt-3 mx-2">
            {productsData && productsData.prodData.length > 0
              ? productsData.prodData.map((prodDet, index) => (
                <Col xs="12" lg="4" key={`pending-${index.toString()}`} className="my-2">
                  <Card className="card-borderless card-min-ht-fullview">
                    <CardHeader className="card-min-ht-fullview-header form-card-header">
                      <div className="align-center">
                        <span className="meta-waranty-lite">Warranty {prodDet.wrnty} months</span>
                      </div>
                      <ImageGallery imageGal={prodDet.prodUrl} isDesc={false} />
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col xs="12">
                          <div className="align-center my-2">
                            <span className="product-name-header-fullview"><ShowMoreText text={prodDet.nme} wordLimit={4} /></span>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="align-center my-1 pt-3">
                            <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                          </div>
                          <div className="align-center">
                            <Button className="button-icon padding-zero" onClick={() => toggleProdDetails(`prodDetails-${index}`, prodDet.ProdTp, prodDet.ProdId)}>PRODUCT DETAILS</Button>
                            <Button id={`userMaual-${index}`} className="button-icon" onClick={handleLogin}>
                              <FontAwesomeIcon icon={faFilePdf} className="padding-zero button-icon-small-pdf" />
                            </Button>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="align-center pt-4">
                            <Button className="powermart-button-solid mx-1" onClick={() => handleLogin()}>
                              Buy
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Offcanvas
                    direction="end"
                    isOpen={prodDetailsOpen[`prodDetails-${index}`]}
                    toggle={() => toggleDescProdDetails(`prodDetails-${index}`)}
                  >
                    <OffcanvasHeader toggle={() => toggleDescProdDetails(`prodDetails-${index}`)} className="margin-0 mx-1" />
                    <div className="align-start border-bottom-box">
                      <span className="product-name-details-header my-1">{prodDet.nme}</span>
                    </div>
                    <OffcanvasBody>
                      <Row>
                        <Col xs="12">
                          <div className="pt-2">
                            <div className="align-center">
                              <span className="meta-waranty-lite">Warranty {prodDet.wrnty} months</span>
                            </div>
                          </div>
                          <ImageGallery imageGal={prodDet.prodUrl} isDesc />
                          {/* <div className="align-center">
                      <CardImg alt="logo" className="product-image border-image1" src="" />
                    </div> */}
                        </Col>
                        <Col xs="12">
                          <div className="my-2 meta-margin-mobile">
                            <div className="pt-4">
                              <div className="align-center">
                                <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                              </div>
                              <div className="align-center meta-price-lite">
                                (<span className="meta-price-lite">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.costVal))}</span>
                                <span className="mx-2">+</span>
                                <span className="meta-price-lite">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.gstVal))}</span>
                                )
                              </div>
                            </div>
                          </div>
                          <div className="pt-4">
                            <div className="align-center">
                              <Button className="powermart-button-solid mx-1" onClick={() => handleLogin()}>
                                Buy
                              </Button>
                            </div>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="my-2 meta-margin-mobile">
                            <div className="align-start pt-3">
                              <span className="product-name-header">Technical Specification</span>
                            </div>
                            <div className="pt-2">
                              {productDesc.data.techSpec.map((techSpecData, tSepcIndx) => (
                                <div key={`prodDesc-${tSepcIndx.toString()}`} className="my-1 align-start">
                                  <div className="mr">
                                    <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                  </div>
                                  <div>
                                    <span className="prod-tech-spec-text">{techSpecData}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="my-2 meta-margin-mobile">
                            <div className="align-start pt-3">
                              <span className="product-name-header">Product Description</span>
                            </div>
                            <div className="pt-2">
                              {productDesc.data.prodDesc.map((descData, descIndx) => (
                                <div key={`prodDesc-${descIndx.toString()}`} className="my-3 align-start">
                                  <div className="mr">
                                    <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                  </div>
                                  <div>
                                    <span className="prod-tech-spec-text">{ descData }</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </OffcanvasBody>
                  </Offcanvas>
                </Col>
              ))
              : (
                !loadingMore ? (
                  <div className="pt-4 align-center">
                    <span className="form-collapse-card-header">No Product Found</span>
                  </div>
                ) : null
              )}
          </Row>
        </InfiniteScroll>
      </div>
    </div>
  );
};
export default FullviewPowermartProducts;
