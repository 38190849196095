import { ListDivisionResponse, ListDivisionResults } from './list-division.service.types';

export const ListDivisionTransform: (response: ListDivisionResponse) => ListDivisionResults = (listDivisionResults) => {
  const { data, is_error, message } = listDivisionResults;
  const result: ListDivisionResults = {
    tCnt: 0,
    fCnt: 0,
    divData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.divData = data.divData;
    result.message = 'executed';
  }
  return result;
};
