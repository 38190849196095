import { RegisterResponse, RegisterResults } from './register.service.types';

export const RegisterTransform: (response: RegisterResponse) => RegisterResults = (registerResults) => {
  const { data, is_error, message } = registerResults;
  const result: RegisterResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.data = data;
  }
  return result;
};
