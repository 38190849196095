import { GetAllMyOrdersResponse, GetAllMyOrdersResults } from './get-all-myorders.service.types';

export const GetAllMyOrdersTransform: (response: GetAllMyOrdersResponse) => GetAllMyOrdersResults = (getAllMyOrdersResults) => {
  const { data, is_error, message } = getAllMyOrdersResults;
  const result: GetAllMyOrdersResults = {
    data: [{
      addr: '',
      fCnt: 0,
      ordId: '',
      ordDt: '',
      prodTxnData: [{
        ActId: '',
        ActTp: '',
        gst: '',
        prodCnt: '',
        prodCost: '',
        prodInfo: {
          cost: '',
          gst: '',
          imgUrl: '',
          nme: '',
        },
        mSts: '',
        sSts: '',
        tCost: '',
        rsn: '',
        shipDt: '',
        courNme: '',
        trckNo: '',
        trckUrl: '',
      }],
      sumCost: '',
      sumGst: '',
      sumTotal: '',
    }],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
