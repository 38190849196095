import { getPublicJSON, getCommonApiToken } from '../../../utils';
import { getConfig } from '../../../utils/config/config';
import { GetAllProductOptions, GetAllProductResponse } from './get-all-product.service.types';

const { POWERMART_API_ROOT } = getConfig();

export const GetAllProductRequest = ({
  requestType, userType, prodTp, isPublic, token, evalKey, limit, evalTp, searchBy, isSearch, isFilter, filterBy, isSAdminF,
}: GetAllProductOptions): Promise<GetAllProductResponse> => {
  if (!isPublic) {
    if (isFilter === 'YES') {
      return getCommonApiToken(`${POWERMART_API_ROOT}?userTp=${userType}&prodTp=${prodTp}&isFilter=${isFilter}&filterBy=${filterBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
    } if (isSearch === 'YES') {
      return getCommonApiToken(`${POWERMART_API_ROOT}?userTp=${userType}&prodTp=${prodTp}&isSearch=${isSearch}&searchBy=${searchBy}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
    }
    return getCommonApiToken(`${POWERMART_API_ROOT}?userTp=${userType}&prodTp=${prodTp}&evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType, token);
  }
  if (isFilter === 'YES') {
    return getPublicJSON(`${POWERMART_API_ROOT}?evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isFilter=${isFilter}&filterBy=${filterBy}`, requestType);
  } if (isSearch === 'YES') {
    return getPublicJSON(`${POWERMART_API_ROOT}?evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isSearch=${isSearch}&searchBy=${searchBy}`, requestType);
  } if (isSAdminF === 'YES') {
    return getPublicJSON(`${POWERMART_API_ROOT}?evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}&isSAdminF=${isSAdminF}`, requestType);
  }
  return getPublicJSON(`${POWERMART_API_ROOT}?evalKey=${evalKey}&evalTp=${evalTp}&limit=${limit}`, requestType);
};
