import React from 'react';
import { Row, Col } from 'reactstrap';
import { MyAvatar } from './avatar';
import './profile.scss';
import { PasskeyUpdate } from '../passkey-reset/passkey.component';
import { getUserProfileSessionStorage } from '../../../utils/service/localstorage-service';
import { MYOEM } from './oem';

export const MyProfileComponent: React.FC = () => {
  const userInitTp = getUserProfileSessionStorage();
  const [userTp, setUserTp] = React.useState('');

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [
    userInitTp && JSON.parse(userInitTp).profileData.UserTp,
  ]);

  return (
    <div className="pt-5">
      <div className="mb pt-4 my-3">
        <span className="powermart-main-label">
          Quick View - My Profile
        </span>
      </div>
      <Row className="mx-2">
        <Col lg="6">
          <MyAvatar />
        </Col>
        <Col lg="6">
          <PasskeyUpdate userType={userTp} />
        </Col>
        {userTp === 'SELLER'
          ? (
            <Col lg="12">
              <MYOEM />
            </Col>
          ) : null }
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default MyProfileComponent;
