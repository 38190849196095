import React, { useState } from 'react';
import {
  Button,
  CardImg, Col, Row,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { ImgGalProps } from './home.type';

export const ImageGallery: React.FC<ImgGalProps> = ({ imageGal, isDesc }) => {
  const [selectedImage, setSelectedImage] = useState(imageGal[0]);

  const handleThumbnailClick = (imageUrl: string) => {
    setSelectedImage(imageUrl);
  };

  React.useEffect(() => {
    if (imageGal.length > 0) {
      setSelectedImage(imageGal[0]);
    }
  }, [imageGal]);

  const handleThumbnailScroll = (direction: 'left' | 'right') => {
    const currentIndex = imageGal.findIndex((image) => image === selectedImage);
    if (direction === 'left') {
      const nextIndex = currentIndex > 0 ? currentIndex - 1 : imageGal.length - 1;
      setSelectedImage(imageGal[nextIndex]);
    } else if (direction === 'right') {
      const nextIndex = currentIndex < imageGal.length - 1 ? currentIndex + 1 : 0;
      setSelectedImage(imageGal[nextIndex]);
    }
  };

  return (
    <div>
      <div className="align-center mx-1">
        <CardImg top src={selectedImage} alt="Main Image" className={isDesc ? 'desc-product-image' : 'product-image'} />
      </div>
      {imageGal && imageGal.length > 1
        ? (
          <Row className="pt-3">
            <Col lg="1" xs="1">
              <div className="align-center pt-2 ml">
                <Button className="button-icon cursor-pointer" onClick={() => handleThumbnailScroll('left')}>
                  <FontAwesomeIcon icon={faAngleLeft} className="text-lite-grey" />
                </Button>
              </div>
            </Col>
            <Col lg="10" xs="10">
              <div style={{ display: 'flex', overflowX: 'auto' }}>
                {imageGal.map((image, index) => (
                  <div key={`img-${index.toString()}`} style={{ marginRight: '10px' }}>
                    <CardImg
                      className="thumbnail"
                      src={image}
                      alt={`Thumbnail ${index + 1}`}
                      onClick={() => handleThumbnailClick(image)}
                      style={{
                        cursor: 'pointer', width: '40px', height: '40px', border: selectedImage === image ? '2px solid #05cbddff' : 'none',
                      }}
                    />
                  </div>
                ))}
              </div>
            </Col>
            <Col lg="1" xs="1">
              <div className="align-center pt-2">
                <Button className="button-icon" onClick={() => handleThumbnailScroll('right')}>
                  <FontAwesomeIcon icon={faAngleRight} className="text-lite-grey" />
                </Button>
              </div>
            </Col>
          </Row>
        ) : null }
    </div>
  );
};

export default ImageGallery;

// import React, { useState } from 'react';
// import {
//   Card, CardImg, CardBody, Row, Col, CarouselControl, Carousel,
// } from 'reactstrap';
// import { ImgGalProps } from './metadata.type';

// export const ImageGallery: React.FC<ImgGalProps> = ({ imageGal }) => {
//   const [selectedImage, setSelectedImage] = useState(imageGal[0]);
//   const [activeIndex, setActiveIndex] = useState(0);
//   const [animating, setAnimating] = useState(false);

//   const handleThumbnailClick = (imageUrl : string) => {
//     setSelectedImage(imageUrl);
//     setAnimating(true);
//   };

//   const next = () => {
//     if (animating) return;
//     const nextIndex = activeIndex === imageGal.length - 1 ? 0 : activeIndex + 1;
//     setActiveIndex(nextIndex);
//   };

//   const previous = () => {
//     if (animating) return;
//     const nextIndex = activeIndex === 0 ? imageGal.length - 1 : activeIndex - 1;
//     setActiveIndex(nextIndex);
//   };

//   //   const slides = imageGal.map((prodDet, index) => (
//   //     <CarouselItem key={`imgC-${index.toString()}`}>
//   //       <Card>
//   //         <CardImg top width="100%" src={selectedImage} alt="Main Image" />
//   //         <CardBody>
//   //           {/* Additional details or description can go here */}
//   //         </CardBody>
//   //       </Card>
//   //     </CarouselItem>
//   //   ));

//   // const imageIndxGal = imageGal[activeIndex].images;

//   return (
//     <div>
//       <Card>
//         <CardImg top width="100%" src={selectedImage} alt="Main Image" />
//         <CardBody>
//           {/* Additional details or description can go here */}
//         </CardBody>
//       </Card>
//       <Row className="mt-3">
//         <div>
//           <Carousel activeIndex={activeIndex} next={next} previous={previous}>
//             <Row className="mt-3">
//               {imageGal.map((image, index) => (
//                 <Col key={`img-${index.toString()}`} xs={6} md={3} lg={2}>
//                   <CardImg
//                     className="thumbnail"
//                     src={image}
//                     alt={`Thumbnail ${index + 1}`}
//                     onClick={() => handleThumbnailClick(image)}
//                   />
//                 </Col>
//               ))}
//             </Row>
//             <CarouselControl direction="prev" onClickHandler={previous} />
//             <CarouselControl direction="next" onClickHandler={next} />
//           </Carousel>
//         </div>
//         {/* {imageGal.map((image, index) => (
//           <Col key={`img-${index.toString()}`} xs={6} md={3} lg={2}>
//             <CardImg
//               className="thumbnail"
//               src={image}
//               alt={`Thumbnail ${index + 1}`}
//               onClick={() => handleThumbnailClick(image)}
//             />
//           </Col>
//         ))} */}
//       </Row>
//     </div>
//   );
// };

// export default ImageGallery;
