import React from 'react';
import {
  Row, Col, Card, CardBody, CardImg,
  Button,
  Input,
  Spinner,
} from 'reactstrap';
import Select from 'react-select';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { faCircleCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import { APIHeader } from '../../../../utils/constants';
import { LoaderData } from '../../../../utils/loader';
import { getConfig } from '../../../../utils/config/config';
import { changeDateFormat, errorAlert, successAlert } from '../../../../utils/alert';
import { OrdersResult } from '../../../../services/products/power-orders/get-all-orders.types';
import {
  clearGetListProdImg,
  clearOrdersListReq, clearPutPendOrdrAdmReq, clearUploadProdImg, getListProdImg, getOrdersListReq, putPendOrdrAdmReq,
  uploadProdImg,
} from '../../../../store/powerMart/actions';
import { StatusComponentProps } from '../../super-admin/dashboard/dash.type';
import { PendOrdrStsEditData } from '../../../../services/products/update-pend-ordr/update-pend-order.types';
import { FileDetails } from '../../../../services/products/get-list-product-images/getList-prodimage.types';
import { ProdImgFileInput } from '../../../../services/products/upload-prod-Image/create-prodimage.types';
import Modal from '../../../../utils/Modal/Modal';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const SellerOrderDetails: React.FC<StatusComponentProps> = ({ orderSts, orderNme }) => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const [loaderOrder, setLoaderOrder] = React.useState(false);
  const [userTp, setUserTp] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const pendingOrdersResponse = useSelector((state: RootState) => state.powerMart.getOrdersList);
  const [modalConfirmPackage, setModalConfirmPackage] = React.useState<Record<string, boolean>>({});
  const [modalConfirmAck, setModalConfirmAck] = React.useState<Record<string, boolean>>({});
  const [modalConfirmReject, setmMdalConfirmReject] = React.useState<Record<string, boolean>>({});
  const [whichIndexReject, setWhichIndexReject] = React.useState('');
  const [modalConfirmUpdate, setModalConfirmUpdate] = React.useState<Record<string, boolean>>({});
  // const [isAckConfirmed, setIsAckConfirmed] = React.useState<Record<string, boolean>>({});
  const updatePendOrderStsResponse = useSelector((state: RootState) => state.powerMart.updatePendOrdrByAdm);
  // const [whichIndexApprove, setWhichIndexApprove] = React.useState('');
  const [whichIndexUpdate, setWhichIndexUpdate] = React.useState('');
  const [shipmentCount, setShipmentCount] = React.useState(0);
  // const [radioSelected, setRadioSelected] = React.useState('RBUY');
  const [eachProductCount, setEachProductCount] = React.useState(0);
  const [warrantyFileName, setWarrantyFileName] = React.useState('');
  const [orderDetId, setOrderDetId] = React.useState('');
  const [activId, setActivId] = React.useState('');
  const [uploadedFileName, setUploadedFileName] = React.useState('');
  const [loaderUploadWarrantyBills, setLoaderUploadWarrantyBills] = React.useState(false);
  const getProdImageAwsResponse = useSelector((state: RootState) => state.powerMart.getListProductsImageAWS);
  const uploadroductAwsResponse = useSelector((state: RootState) => state.powerMart.uploadProductsAWS);
  const [warrantyFile, setWarrantyFile] = React.useState<ProdImgFileInput>({
    fileData: new FormData(),
  });
  const [shipmentFiles, setShipmentFiles] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);
  const [orderStatusInput, setOrderStatusInput] = React.useState<PendOrdrStsEditData>({
    ActTp: '',
    ActId: '',
    mSts: 'RBUY',
    sSts: '',
    rsn: '',
    ordId: '',
    shipDet: [],
  });
  const [pendingOrderData, setPendingOrdersData] = React.useState<OrdersResult[]>([{
    fCnt: 0,
    ordId: '',
    usrOrg: '',
    usrInfo: {
      addr: '',
      div: '',
      eId: '',
      nme: '',
      ph: '',
      st: '',
      subDiv: '',
    },
    mSts: '',
    txnId: '',
    txnDt: '',
    ordDt: '',
    sumCost: '',
    sumGst: '',
    sumTotal: '',
    prodTxnData: [],
  }]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  //   const handleFilterData = (category: string) => {
  //     setRadioSelected(category);
  //     setLoaderOrder(true);
  //     dispatch(getOrdersListReq({
  //       userType: userTp,
  //       token: tokenData,
  //       requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
  //       status: category,
  //     }));
  //   };

  React.useEffect(() => {
    if (userTp !== '') {
      setLoaderOrder(true);
      dispatch(getOrdersListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
        status: orderSts,
      }));
    }
  }, [userTp, tokenData, orderSts]);

  React.useEffect(() => {
    if (pendingOrdersResponse.isError && pendingOrdersResponse.message !== '') {
      setLoaderOrder(false);
      setAlertMsg(pendingOrdersResponse.message);
      setErrorShowAlert(true);
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message, orderSts]);

  React.useEffect(() => {
    if (!pendingOrdersResponse.isError && pendingOrdersResponse.message === 'executed') {
      setLoaderOrder(false);
      setPendingOrdersData(pendingOrdersResponse.data);
      dispatch(clearPutPendOrdrAdmReq());
      dispatch(clearOrdersListReq());
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message, pendingOrdersResponse.data, pendingOrderData, orderSts]);

  const handleApproveStock = (target: string) => {
    // setAckShipInput({accTp: string, accId: string
    //   ...ackShipInput, ActTp: accTp, ActId: accId, txnSts: 'ACK',
    // });
    setModalConfirmAck((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleApprovePackage = (target: string, orderId: string) => {
    dispatch(getListProdImg({
      requestType: '',
      bucketNme: 'powermart',
      folderName: `p-orders/${orderId}`,
    }));
    setModalConfirmPackage((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleUpdateStock = (target: string, prodCnt: number) => {
    setEachProductCount(prodCnt);
    setModalConfirmUpdate((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setOrderStatusInput((prevState) => ({
      ...prevState,
      shipDet: [
        {
          nCnt: 0,
          shipDt: '',
        },
      ],
    }));
    setShipmentCount(0);
  };

  const handleRejectStock = (target: string, accTp: string, accId: string) => {
    setWhichIndexReject(target);
    setOrderStatusInput({
      ...orderStatusInput, ActTp: accTp, ActId: accId, sSts: 'REJCT',
    });
    setmMdalConfirmReject((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleConfirmAck = (target: string, status: string, orderId: string, activityType: string, activityId: string) => {
    // setWhichIndexApprove(target);
    if (status === 'approve') {
      const inputSubmitBody = {
        ...orderStatusInput,
        sSts: 'SAPPR',
        ordId: orderId,
        ActTp: activityType,
        ActId: activityId,
      };
      // setIsAckConfirmed((prevState) => ({
      //   ...prevState,
      //   [target]: true,
      // }));
      dispatch(putPendOrdrAdmReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_PND_ORDR_BY_ADM,
        inputBody: inputSubmitBody,
        token: tokenData,
      }));
    }
    // else {
    //   setIsAckConfirmed((prevState) => ({
    //     ...prevState,
    //     [target]: false,
    //   }));
    // }
    setModalConfirmAck((prevState) => ({
      ...prevState,
      [target]: false,
    }));
  };

  const handleConfirmPackage = (target: string, status: string, orderId: string, activityType: string, activityId: string) => {
    if (status === 'approve') {
      const inputSubmitBody = {
        ...orderStatusInput,
        sSts: 'ISSHIP',
        ordId: orderId,
        ActTp: activityType,
        ActId: activityId,
      };
      setModalConfirmPackage((prevState) => ({
        ...prevState,
        [target]: false,
      }));
      setLoaderOrder(true);
      dispatch(putPendOrdrAdmReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_PND_ORDR_BY_ADM,
        inputBody: inputSubmitBody,
        token: tokenData,
      }));
    } else {
      setModalConfirmPackage((prevState) => ({
        ...prevState,
        [target]: false,
      }));
    }
  };

  // React.useEffect(() => {
  //   if (isAckConfirmed[whichIndexApprove]) {
  //     setLoaderOrder(true);
  //     // Promise.resolve(
  //     //   dispatch(putAckShipReq({
  //     //     userType: userTp,
  //     //     requestType: APIHeader.REQ_UPDATE_ACK_SHIP_BY_ADM,
  //     //     inputBody: ackShipInput,
  //     //     token: tokenData,
  //     //   })),
  //     // );
  //   }
  // }, [isAckConfirmed[whichIndexApprove]]);

  const handleRejectReasonChange = (e: any) => {
    setOrderStatusInput({
      ...orderStatusInput, rsn: e.target.value,
    });
  };

  const handleSubmitReject = (activityType: string, activityId: string, orderId: string) => {
    setLoaderOrder(true);
    const inputSubmitBody = {
      ...orderStatusInput,
      sSts: 'REJCT',
      ordId: orderId,
      ActTp: activityType,
      ActId: activityId,
    };
    dispatch(putPendOrdrAdmReq({
      userType: userTp,
      requestType: APIHeader.REQ_UPDATE_PND_ORDR_BY_ADM,
      inputBody: inputSubmitBody,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (updatePendOrderStsResponse.error && updatePendOrderStsResponse.message !== '') {
      setLoaderOrder(false);
      setAlertMsg(updatePendOrderStsResponse.message);
      setErrorShowAlert(true);
      dispatch(clearPutPendOrdrAdmReq());
    }
  }, [updatePendOrderStsResponse.error, updatePendOrderStsResponse.message]);

  React.useEffect(() => {
    if (!updatePendOrderStsResponse.error && updatePendOrderStsResponse.message !== '') {
      setLoaderOrder(false);
      setAlertMsg(updatePendOrderStsResponse.message);
      setSuccessShowAlert(true);
      setmMdalConfirmReject((prevState) => ({
        ...prevState,
        [whichIndexReject]: false,
      }));
      setModalConfirmUpdate((prevState) => ({
        ...prevState,
        [whichIndexUpdate]: false,
      }));
      Promise.resolve(
        dispatch(getOrdersListReq({
          userType: userTp,
          token: tokenData,
          requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
          status: orderSts,
        })),
      ).then(() => {
        dispatch(clearPutPendOrdrAdmReq());
      });
    }
  }, [updatePendOrderStsResponse.error, updatePendOrderStsResponse.message]);

  const handleShipmentCountChange = (e: any) => {
    const newShipmentCount = e.value;
    setShipmentCount(newShipmentCount);
    setOrderStatusInput((prevState) => ({
      ...prevState,
      shipDet: Array.from({ length: newShipmentCount }, (_, i) => ({
        nCnt: prevState.shipDet[i]?.nCnt || 0,
        shipDt: prevState.shipDet[i]?.shipDt || '',
      })),
    }));
  };

  const maxShipments = Math.min(eachProductCount, 5);
  const shipmentOptions = Array.from({ length: maxShipments }, (_, i) => ({
    value: i + 1,
    label: `${i + 1}`,
  }));

  const handleQuantityChange = (index: number, value: any) => {
    const newQuantity = parseInt(value, 10);
    const totalQuantity = orderStatusInput.shipDet.reduce((acc, item, idx) => acc + (idx === index ? newQuantity : item.nCnt), 0);

    if (totalQuantity <= eachProductCount) {
      const newShipDet = [...orderStatusInput.shipDet];
      newShipDet[index].nCnt = newQuantity;

      setOrderStatusInput((prevState) => ({
        ...prevState,
        shipDet: newShipDet,
      }));
    }
  };

  const handleDateChange = (index: number, value: any) => {
    const newShipDet = [...orderStatusInput.shipDet];
    newShipDet[index].shipDt = value;

    setOrderStatusInput((prevState) => ({
      ...prevState,
      shipDet: newShipDet,
    }));
  };

  const isSubmitDisabled = orderStatusInput.shipDet.some(
    (shipment) => shipment.nCnt === 0 || shipment.shipDt === '',
  ) || orderStatusInput.shipDet.reduce((acc, item) => acc + item.nCnt, 0) !== eachProductCount;

  const handleClearChange = () => {
    setOrderStatusInput((prevState) => ({
      ...prevState,
      shipDet: [
        {
          nCnt: 0,
          shipDt: '',
        },
      ],
    }));
    setShipmentCount(0);
  };

  const handleUpdateSubmit = (target: string, orderId: string, activityType: string, activityId: string) => {
    setWhichIndexUpdate(target);
    setLoaderOrder(true);
    const inputSubmitBody = {
      ...orderStatusInput,
      sSts: 'SUPDT',
      ordId: orderId,
      ActTp: activityType,
      ActId: activityId,
    };
    dispatch(putPendOrdrAdmReq({
      userType: userTp,
      requestType: APIHeader.REQ_UPDATE_PND_ORDR_BY_ADM,
      inputBody: inputSubmitBody,
      token: tokenData,
    }));
  };

  const handleCheckWarranty = (warrantyBill: FileDetails[], orderId: string, activityId: string) => {
    if (!warrantyBill || warrantyBill.length === 0) return true;
    const isAnyOtherStatus = warrantyBill.some((invoic) => invoic.nme === `warranty-${orderId}_${activityId}_1.pdf`);
    return isAnyOtherStatus;
  };

  const handleUploadWarranty = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const file = files[0];
    const maxSizeInBytes = 2 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      setAlertMsg('File size exceeds the limit of 2 MB.');
      setErrorShowAlert(true);
      return;
    }
    const formData = new FormData();
    formData.append(file.name, file);
    setWarrantyFileName(file.name);
    setWarrantyFile({ ...warrantyFile, fileData: formData });
    dispatch(clearUploadProdImg());
  };

  const handleSubmitWarranty = (orderId: String, activityId: string) => {
    setLoaderUploadWarrantyBills(true);
    dispatch(clearGetListProdImg());
    setUploadedFileName(`warranty-${orderId}_${activityId}`);
    setOrderDetId(`${orderId}`);
    setActivId(`${activityId}`);
    dispatch(uploadProdImg({
      bucketNme: AWS_PRODUCT_BUCKET,
      folderName: `p-orders/${orderId}`,
      fileNmeInit: `warranty-${orderId}_${activityId}`,
      formData: warrantyFile.fileData,
      requestType: '',
      isEdit: false,
    }));
  };

  React.useEffect(() => {
    const pattern = new RegExp(`^p-orders/${orderDetId}/${uploadedFileName}_.+\\.pdf$`);
    if (pattern.test(uploadroductAwsResponse.fileName) && userTp !== '') {
      setLoaderUploadWarrantyBills(false);
      setWarrantyFile({ ...warrantyFile, fileData: new FormData() });
      setWarrantyFileName('');
      dispatch(getListProdImg({
        requestType: '',
        bucketNme: 'powermart',
        folderName: `p-orders/${orderDetId}`,
      }));
      dispatch(clearUploadProdImg());
    }
  }, [uploadroductAwsResponse.fileName, userTp]);

  React.useEffect(() => {
    if (getProdImageAwsResponse.data.length !== 0) {
      setShipmentFiles(getProdImageAwsResponse.data);
      handleCheckWarranty(shipmentFiles, orderDetId, activId);
    }
  }, [getProdImageAwsResponse.data]);

  return (
    <div>
      {loaderOrder ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row>
        <Col xs="12">
          <Row className="mx-2">
            <Col lg="6" xs="6">
              <div className="pt-3 align-start">
                <span className="meta-waranty-lite">{orderNme}</span>
              </div>
            </Col>
            <Col lg="6" xs="6">
              <div className="pt-3 align-end">
                <span className="meta-waranty-lite">Order Count: {pendingOrderData.length}</span>
              </div>
            </Col>
            <hr />
          </Row>
        </Col>
        <Col xs="12">
          <Row className="align-center">
            {pendingOrderData && pendingOrderData.length > 0
              ? pendingOrderData.map((orderDet, index) => (
                <Col lg="12" xs="12" key={`order-txn-${index.toString()}`} className="my-1">
                  {orderDet.ordId !== ''
                    ? (
                      <Card className="dash-card">
                        <CardBody>
                          <div className="my-2 ml">
                            <span className="order-id-text">{orderDet.ordId}<span className="mx-3 super-dashboard-header">|</span>{orderDet.ordDt}</span>
                          </div>
                          <span className="super-dashboard-sub-name ml mx-2">Total Products: {orderDet.fCnt}</span>
                          <Row className="mx-1 pt-2">
                            {orderDet && orderDet.prodTxnData.length > 0
                              ? orderDet.prodTxnData.map((allOrders, orderIndex) => (
                                <Col xs="12" lg="3" key={`allOrder-txn-${orderIndex.toString()}`} className="dash-card-header mb-2">
                                  <Row>
                                    <Col lg="12">
                                      <div className="mx-2 min-text-card-padding">
                                        <span className="text-bold-grey">{allOrders.prodInfo.nme}</span>
                                      </div>
                                      {/* <div className="align-end mx-2">
                                        {allOrders.sSts === ''
                                          ? <span className="text-lite-grey">In-Progress</span>
                                          : allOrders.sSts === 'SAPPR' || allOrders.sSts === 'SUPDT'
                                            ? (
                                              <span className="correct-pass-key">Approved
                                                {allOrders.sSts === 'SUPDT' ? <span className="wrong-pass-key">(M)</span> : null }
                                              </span>
                                            )
                                            : allOrders.sSts === 'REJCT'
                                              ? <span className="wrong-pass-key">Rejected</span>
                                              : null}
                                      </div> */}
                                    </Col>
                                    <Col lg="12">
                                      <div className="my-2">
                                        <CardImg src={allOrders.prodInfo.imgUrl} alt="Prod Image" className="seller-product-image" />
                                      </div>
                                      <div className="my-1 align-start ml">
                                        {allOrders.shipDt !== ''
                                          ? <span className="text-lite-grey">Est Shipment: {changeDateFormat(allOrders.shipDt)}</span> : <span className="text-lite-grey">Est Shipment: NA</span>}
                                      </div>
                                    </Col>
                                    <Col lg="6" xs="6">
                                      <div className="align-start mx-2 pt-2">
                                        <span className="text-lite-grey">Quantity : </span>
                                        <span className="text-lite-grey">{allOrders.prodCnt}</span>
                                      </div>
                                    </Col>
                                    <Col lg="6" xs="6">
                                      {orderSts === 'RBUY'
                                        ? (
                                          <div className="align-end my-2 mx-1">
                                            <Button disabled={allOrders.sSts === 'REJCT' || allOrders.sSts === 'SAPPR' || allOrders.sSts === 'SUPDT'} className="powermart-button-link" onClick={() => handleUpdateStock(`orderUpdate-${index}-${orderIndex}`, allOrders.prodCnt)}>
                                              Update Shipment
                                            </Button>
                                          </div>
                                        ) : null }
                                      <Modal
                                        show={modalConfirmUpdate[`orderUpdate-${index}-${orderIndex}`] || false}
                                        targetId={`orderUpdate-${index}-${orderIndex}`}
                                        disCancel={false}
                                        onClose={() => handleUpdateStock(`orderUpdate-${index}-${orderIndex}`, allOrders.prodCnt)}
                                        title={(
                                          <div className="align-start">
                                            <span className="text-lite-grey">{allOrders.prodInfo.nme} ({allOrders.prodCnt})</span>
                                          </div>
)}
                                        size="MD"
                                      >
                                        <div className="align-start mx-2">
                                          <span className="mx-2 input-checkbox-text-grey">Break Shipments
                                            <span className="text-lite-grey mx-2 my-1">- Update this form only if the shipment is not fullfilled in single go</span>
                                          </span>
                                        </div>
                                        <div className="my-3">
                                          {allOrders.prodCnt === 1 ? (
                                            <div className="text-center-aligned mx-2">
                                              <span className="mandate-star">
                                                Since only a single product needs to be shipped, please either APPROVE or REJECT the order.
                                              </span>
                                            </div>
                                          ) : (
                                            <div className="mx-3">
                                              <div className="mx-1 my-4">
                                                <h6 className="text-lite-grey mx-2 my-1">
                                                  Select number of shipments (max {maxShipments}):
                                                  <span className="mandate-star">*</span>
                                                </h6>
                                                <Select
                                                  id="shipmentCount"
                                                  options={shipmentOptions}
                                                  onChange={handleShipmentCountChange}
                                                  styles={{
                                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                                    menu: (base) => ({
                                                      ...base,
                                                      position: 'relative',
                                                    }),
                                                    control: (base) => ({
                                                      ...base,
                                                      '&:hover': { borderColor: '#05cbddff' },
                                                      border: '1px solid #BFBFBF',
                                                      minHeight: '45px',
                                                      borderRadius: '5px',
                                                      padding: 1,
                                                      textAlign: 'left',
                                                      fontSize: '16px',
                                                      fontWeight: 400,
                                                      color: '#383838',
                                                      boxShadow: 'none',
                                                      backgroundColor: '#fff',
                                                      '&:focus': {
                                                        borderColor: '#E1EDF8',
                                                        boxShadow: 'none',
                                                        color: '#575656',
                                                        background: '#fff',
                                                      },
                                                    }),
                                                    option: (provided, state) => ({
                                                      ...provided,
                                                      color: state.isSelected ? '#383838' : '#212121',
                                                      padding: 15,
                                                      textAlign: 'left',
                                                      background: state.isSelected ? '#FAFCFB' : '#fff',
                                                      '&:hover': {
                                                        background: '#FAFCFB',
                                                      },
                                                    }),
                                                  }}
                                                />
                                              </div>
                                              {shipmentCount > 0 && (
                                                <div className="mx-2 my-2">
                                                  <div className="align-end">
                                                    <Button className="powermart-button-link" onClick={() => handleClearChange()}>
                                                      Clear all
                                                    </Button>
                                                  </div>
                                                  {Array.from({ length: shipmentCount }, (_, i) => (
                                                    <div key={i} className="powermart-main-label">
                                                      <span className="mandate-star">Shipment: {i + 1}</span>
                                                      <Row>
                                                        <Col lg="5" xs="10">
                                                          <h6 className="text-lite-grey mx-2 my-1">
                                                            Quantity<span className="mandate-star">*</span>
                                                          </h6>
                                                          <Input
                                                            placeholder="Quantity"
                                                            type="number"
                                                            required
                                                            min="0"
                                                            name="cnt"
                                                            value={orderStatusInput.shipDet[i]?.nCnt || ''}
                                                            onChange={(e) => handleQuantityChange(i, e.target.value)}
                                                            className="powermart-input"
                                                          />
                                                        </Col>
                                                        <Col lg="7" xs="10">
                                                          <h6 className="text-lite-grey mx-2 my-1">
                                                            Estimated Ship Date<span className="mandate-star">*</span>
                                                          </h6>
                                                          <Input
                                                            type="date"
                                                            min={new Date().toISOString().split('T')[0]}
                                                            value={orderStatusInput.shipDet[i]?.shipDt || ''}
                                                            onChange={(e) => handleDateChange(i, e.target.value)}
                                                            className="powermart-input"
                                                          />
                                                        </Col>
                                                      </Row>
                                                    </div>
                                                  ))}
                                                </div>
                                              )}
                                              <Button onClick={() => handleUpdateSubmit(`orderUpdate-${index}-${orderIndex}`, orderDet.ordId, allOrders.ActTp, allOrders.ActId)} disabled={isSubmitDisabled || orderStatusInput.shipDet.length === 0} className="powermart-button-fullwidth my-3">
                                                SUBMIT
                                              </Button>
                                            </div>
                                          )}
                                        </div>
                                      </Modal>
                                    </Col>
                                    <Col xs="6" lg="6">
                                      {orderSts === 'RBUY'
                                        ? (
                                          <div className="align-start my-1 pt-2 mx-2">
                                            <Button disabled={allOrders.sSts === 'REJCT' || allOrders.sSts === 'SAPPR'} className="powermart-button-red" onClick={() => handleRejectStock(`orderReject-${index}-${orderIndex}`, allOrders.ActTp, allOrders.ActId)}>
                                              REJECT
                                            </Button>
                                          </div>
                                        ) : null }
                                      <Modal
                                        show={modalConfirmReject[`orderReject-${index}-${orderIndex}`] || false}
                                        disCancel={false}
                                        targetId={`orderReject-${index}-${orderIndex}`}
                                        onClose={() => handleRejectStock(`orderReject-${index}-${orderIndex}`, allOrders.ActTp, allOrders.ActId)}
                                        title={(
                                          <div className="align-start">
                                            <span className="text-lite-grey">{allOrders.prodInfo.nme} ({allOrders.prodCnt})</span>
                                          </div>
                                        )}
                                        size="MD"
                                      >
                                        <div className="text-center-aligned mx-2">
                                          <span className="mx-2 input-checkbox-text-grey">You are about to reject this order. Please provide a valid reason for the rejection. Once rejected, this action cannot be reverted. Please verify your decision before proceeding.</span>
                                        </div>
                                        <div className="pt-4 mx-2">
                                          <Input
                                            type="textarea"
                                            placeholder="Provide Rejection Reason"
                                            className="powermart-input-desc"
                                            onChange={(e) => handleRejectReasonChange(e)}
                                          />
                                          <div className="align-end pt-3 mb-3">
                                            <Button disabled={orderStatusInput.rsn === ''} className="powermart-button-red ml-1" onClick={() => handleSubmitReject(allOrders.ActTp, allOrders.ActId, orderDet.ordId)}>
                                              SUBMIT
                                            </Button>
                                          </div>
                                        </div>
                                      </Modal>
                                    </Col>
                                    {orderSts === 'RBUY'
                                      ? (
                                        <Col xs="6" lg="6">

                                          <div className="align-end my-1 pt-2 mx-2">
                                            <Button disabled={allOrders.sSts === 'REJCT' || allOrders.sSts === 'SAPPR' || allOrders.sSts === 'SUPDT'} className="powermart-button-solid" onClick={() => handleApproveStock(`orderAck-${index}-${orderIndex}`)}>
                                              APPROVE
                                            </Button>
                                          </div>

                                          <Modal
                                            show={modalConfirmAck[`orderAck-${index}-${orderIndex}`] || false}
                                            disCancel
                                            targetId={`orderAck-${index}-${orderIndex}`}
                                            onClose={() => handleConfirmAck(`orderAck-${index}-${orderIndex}`, 'cancel', orderDet.ordId, allOrders.ActTp, allOrders.ActId)}
                                            title={(
                                              <div className="align-start my-1">
                                                <span className="text-lite-grey">{allOrders.prodInfo.nme} ({allOrders.prodCnt})</span>
                                              </div>
                                          )}
                                            size="MD"
                                          >
                                            <div className="text-center-aligned">
                                              <span className="mx-2 input-checkbox-text-grey">You confirm that the product is available in stock, matching the requested quantity by the buyer, and all items will be shipped together.</span>
                                            </div>
                                            <div className="align-center my-3 pt-3">
                                              <Button className="powermart-button-red mx-2" onClick={() => handleConfirmAck(`orderAck-${index}-${orderIndex}`, 'cancel', orderDet.ordId, allOrders.ActTp, allOrders.ActId)}>
                                                CANCEL
                                              </Button>
                                              <Button className="powermart-button-solid mx-2" onClick={() => handleConfirmAck(`orderAck-${index}-${orderIndex}`, 'approve', orderDet.ordId, allOrders.ActTp, allOrders.ActId)}>
                                                APPROVE
                                              </Button>
                                            </div>
                                          </Modal>
                                        </Col>
                                      ) : null }
                                    {orderSts === 'TACK' && allOrders.shpRdy === ''
                                      ? (
                                        <Col xs="12">
                                          <div className="align-center my-1 pt-2 mx-2">
                                            <Button className="powermart-button-fullwidth" onClick={() => handleApprovePackage(`confirmPackage-${index}-${orderIndex}`, orderDet.ordId)}>
                                              MARK PACKAGE READY
                                            </Button>

                                          </div>
                                          <Modal
                                            show={modalConfirmPackage[`confirmPackage-${index}-${orderIndex}`] || false}
                                            disCancel
                                            targetId={`confirmPackage-${orderIndex}`}
                                            onClose={() => handleConfirmPackage(`confirmPackage-${index}-${orderIndex}`, 'cancel', orderDet.ordId, allOrders.ActTp, allOrders.ActId)}
                                            title={(
                                              <div className="align-start my-2">
                                                <span className="text-lite-grey">{allOrders.prodInfo.nme} ({allOrders.prodCnt})</span>
                                              </div>
)}
                                            size="MD"
                                          >
                                            <Row className="my-1 align-center mx-2">
                                              <Col xs="12">
                                                {!handleCheckWarranty(shipmentFiles, orderDet.ordId, allOrders.ActId)
                                                  ? (
                                                    <div className="text-center-aligned mx-2 mb-4 pt-2">
                                                      <span className="mx-2 input-checkbox-text-grey">Upload Warranty Card before Approving</span>
                                                    </div>
                                                  ) : (
                                                    <div className="text-center-aligned mx-2 mb-4 pt-2">
                                                      <span className="mx-2 input-checkbox-text-grey">By confirming, you acknowledge that the product is ready for shipment and Warranty Card is Uploaded. <br />Upon confirmation, the Powermart team will proceed with initiating the shipment process.</span>
                                                    </div>
                                                  ) }
                                              </Col>
                                              <Col xs="9" lg="10">
                                                <div className="upload-box">
                                                  <Input
                                                    type="file"
                                                    accept=".pdf"
                                                    name="fileData"
                                                    disabled={handleCheckWarranty(shipmentFiles, orderDet.ordId, allOrders.ActId)}
                                                    className="upload-box-input"
                                                    onChange={(e) => handleUploadWarranty(e)}
                                                  />
                                                  {warrantyFileName === ''
                                                    ? <span className="register-file-link">UPLOAD WARRANTY</span>
                                                    : <span className="register-file-link-lite-active">WARRANTY UPLOADED</span>}
                                                </div>
                                              </Col>
                                              <Col xs="3" lg="2">
                                                <div className="align-end">
                                                  {handleCheckWarranty(shipmentFiles, orderDet.ordId, allOrders.ActId)
                                                    ? (
                                                      <Button disabled className="button-icon padding-zero">
                                                        VERIFIED <FontAwesomeIcon icon={faCircleCheck} className="correct-pass-key" />
                                                      </Button>
                                                    )
                                                    : (
                                                      <Button disabled={warrantyFileName === ''} className="button-icon padding-zero" onClick={() => handleSubmitWarranty(orderDet.ordId, allOrders.ActId)}>
                                                        SUBMIT
                                                        {loaderUploadWarrantyBills ? <><Spinner type="grow" className="spin-grow-loader ml" /><Spinner type="grow" className="spin-grow-loader" /><Spinner type="grow" className="spin-grow-loader" /></> : null}
                                                      </Button>
                                                    ) }
                                                </div>
                                              </Col>
                                              {!handleCheckWarranty(shipmentFiles, orderDet.ordId, allOrders.ActId)
                                                ? (
                                                  <div className="pt-3">
                                                    <span className="text-lite-grey">Combine all the warranty details into a single PDF, ensuring each product has its own separate warranty card</span>
                                                  </div>
                                                ) : null}
                                            </Row>
                                            <div className="align-center my-3 pt-3">
                                              <Button className="powermart-button-red mx-2" onClick={() => handleConfirmPackage(`confirmPackage-${index}-${orderIndex}`, 'cancel', orderDet.ordId, allOrders.ActTp, allOrders.ActId)}>
                                                CANCEL
                                              </Button>
                                              <Button disabled={!handleCheckWarranty(shipmentFiles, orderDet.ordId, allOrders.ActId)} className="powermart-button-solid mx-2" onClick={() => handleConfirmPackage(`confirmPackage-${orderIndex}`, 'approve', orderDet.ordId, allOrders.ActTp, allOrders.ActId)}>
                                                APPROVE
                                              </Button>
                                            </div>
                                          </Modal>
                                        </Col>
                                      ) : null }
                                    {orderSts === 'TACK' && allOrders.shpRdy === 'YES'
                                      ? (
                                        <Col xs="12">
                                          <div className="align-center my-1 pt-2 mx-2">
                                            <Button disabled className="powermart-button-fullwidth">
                                              PACKAGE PACKED
                                            </Button>
                                          </div>
                                        </Col>
                                      ) : null}
                                  </Row>
                                  {/* <hr className="hr-horz" /> */}
                                </Col>
                              ))
                              : (
                                <div className="pt-4 align-center">
                                  <span className="form-collapse-card-header">No Orders avilable</span>
                                </div>
                              )}
                          </Row>
                        </CardBody>
                      </Card>
                    ) : (
                      <div className="pt-4 align-center">
                        <span className="form-collapse-card-header">No Orders Found</span>
                      </div>
                    ) }
                </Col>
              ))
              : (
                <div className="pt-4 align-center">
                  <span className="form-collapse-card-header">No Orders Found</span>
                </div>
              )}
          </Row>
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default SellerOrderDetails;
