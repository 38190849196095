import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Button, Container,
  CardImg,
  Card,
} from 'reactstrap';
import './home.scss';
import {
  faAngleLeft,
  faAngleRight,
  faCaretLeft, faCaretRight,
  faFilePdf,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductsResult } from '../../../services/products/list-products/get-all-product.types';
import { clearProductsListReq, getProductsListReq } from '../../../store/powerMart/actions';
import { APIHeader } from '../../../utils/constants';
import ShowMoreText from '../../../utils/showmore-text';
import { ProductDataProps } from './home.type';

const AllProductsGallery: React.FC<ProductDataProps> = ({ prodSts, isFirstAPICall, setIsFirstAPICall }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [loadingMore, setLoadingMore] = useState(false);
  const [evalKeyText, setEvalKeyText] = useState('');
  const [evalTpText, setEvalTpText] = useState('');
  const [currentImageIndexes, setCurrentImageIndexes] = useState<number[]>([]);
  const [productsData, setProductsData] = useState<ProductsResult>({
    tCnt: 0,
    fCnt: 0,
    prodData: [],
    lastEvalKey: {
      ProdTp: '',
      ProdId: '',
    },
  });
  const itemsPerPage = isMobile ? 1 : 2;
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const listProducts = useSelector((state: RootState) => state.powerMart.getProductList);
  const totalPages = Math.ceil(productsData.tCnt / itemsPerPage);
  const startIndex = currentPage * itemsPerPage;
  const slicedData = productsData.prodData.slice(startIndex, startIndex + itemsPerPage);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const numberOfCardsToShow = isMobile ? 1 : 2;

  useEffect(() => {
    if (prodSts !== '' && isFirstAPICall) {
      setLoadingMore(true);
      setProductsData({
        tCnt: 0,
        fCnt: 0,
        prodData: [],
        lastEvalKey: {
          ProdTp: '',
          ProdId: '',
        },
      });
      dispatch(getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: numberOfCardsToShow,
        evalKey: '',
        evalTp: '',
        isFilter: 'YES',
        filterBy: prodSts,
        isSearch: 'NO',
      }));
    }
  }, [evalKeyText, evalTpText, prodSts, isFirstAPICall]);

  const fetchMoreData = () => {
    setLoadingMore(true);
    dispatch(
      getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: numberOfCardsToShow,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'YES',
        filterBy: prodSts,
        isSearch: 'NO',
      }),
    );
  };

  const handlePrevClick = (): void => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 0));
  };

  const handleNextClick = () => {
    if (currentPage + 1 < totalPages) {
      setLoadingMore(true);
      fetchMoreData();
      setCurrentPage((prevPage) => prevPage + 1);
    }
    setCurrentImageIndexes([]);
  };

  const nextImage = (cardIndex: number) => {
    if (productsData.prodData[cardIndex]) {
      setCurrentImageIndexes((prevIndexes) => {
        const newIndexes = [...prevIndexes];
        newIndexes[cardIndex] = (newIndexes[cardIndex] + 1) % productsData.prodData[cardIndex].prodUrl.length;
        return newIndexes;
      });
    }
  };

  const prevImage = (cardIndex: number) => {
    if (productsData.prodData[cardIndex]) {
      setCurrentImageIndexes((prevIndexes) => {
        const newIndexes = [...prevIndexes];
        newIndexes[cardIndex] = (newIndexes[cardIndex] - 1 + productsData.prodData[cardIndex].prodUrl.length) % productsData.prodData[cardIndex].prodUrl.length;
        return newIndexes;
      });
    }
  };

  React.useEffect(() => {
    if (!listProducts.isError && listProducts.message === 'executed') {
      setLoadingMore(false);
      if (listProducts.data.lastEvalKey !== null) {
        const newData = listProducts.data.prodData.filter((item) => !productsData.prodData.some((existingItem) => existingItem.ProdId === item.ProdId));
        setProductsData((prevData) => ({
          ...prevData,
          tCnt: listProducts.data.tCnt,
          fCnt: listProducts.data.fCnt,
          prodData: [...prevData.prodData, ...newData],
          lastEvalKey: listProducts.data.lastEvalKey,
        }));
        setEvalKeyText(listProducts.data.lastEvalKey.ProdId);
        setEvalTpText(listProducts.data.lastEvalKey.ProdTp);
        setCurrentImageIndexes((prevIndexes) => [...prevIndexes, ...newData.map(() => 0)]);
      } else {
        setProductsData((prevData) => ({
          ...prevData,
          tCnt: listProducts.data.tCnt,
          fCnt: listProducts.data.fCnt,
          prodData: [...prevData.prodData, ...listProducts.data.prodData],
        }));
        setCurrentImageIndexes((prevIndexes) => [...prevIndexes, ...listProducts.data.prodData.map(() => 0)]);
      }
      dispatch(clearProductsListReq());
      setIsFirstAPICall(false);
    }
  }, [listProducts.isError, listProducts.message, listProducts.data.prodData, listProducts.data.lastEvalKey, evalKeyText]);

  const handleLogin = () => {
    history('/login/puser#logn');
    window.scrollTo(0, 0);
  };

  return (
    <Container className="card-scroller">
      {loadingMore && (
        <div className="loader-container-none">
          <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
          <span className="mx-2">Please wait....</span>
        </div>
      )}
      <Row>
        <Col className="navigation-button" xs="1" lg="1">
          <Button onClick={handlePrevClick} disabled={currentPage === 0}>
            <FontAwesomeIcon icon={faCaretLeft} className="arrow-icon" />
          </Button>
        </Col>
        <Col className="card-container">
          <Row>
            {slicedData.length > 0 && !loadingMore
              ? slicedData.map((prodDet, index) => (
                <Col key={`${prodSts}-${index.toString()}`} lg="6">
                  <Card className="card-lite card-lite-home-height">
                    <div className="card-image-container">
                      {prodDet.prodUrl.length > 1 && (
                        <Button className="image-navigation-button left" onClick={() => prevImage(index)}>
                          <FontAwesomeIcon icon={faAngleLeft} className="round-small-icon" />
                        </Button>
                      )}
                      <CardImg
                        src={prodDet.prodUrl[currentImageIndexes[index]]}
                        alt={`Card-${prodSts}-${index}`}
                        onError={(e: any) => {
                          e.target.onerror = null;
                          e.target.src = '/images/default-image.jpg';
                        }}
                      />
                      {prodDet.prodUrl.length > 1 && (
                        <Button className="image-navigation-button right" onClick={() => nextImage(index)}>
                          <FontAwesomeIcon icon={faAngleRight} className="round-small-icon" />
                        </Button>
                      )}
                    </div>
                    <div className="align-center pt-1">
                      <span className="meta-waranty-lite">{prodDet.wrnty} months warranty</span>
                      <Button id={`userMaual-${prodSts}-${index}`} className="button-icon" onClick={handleLogin}>
                        <FontAwesomeIcon icon={faFilePdf} className="mx-1 button-icon-small-pdf" />
                      </Button>
                    </div>
                    <div className="card-text mx-2">{prodDet.nme}</div>
                    <div className="my-2 pt-2">
                      <div className="text-center-aligned">
                        <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                      </div>
                      <div className="text-center-aligned">
                        <h6 className="text-lite-grey">(inclusive of taxes)</h6>
                      </div>
                    </div>
                    <div className="align-center">
                      <Button className="powermart-button-solid mx-1" onClick={() => handleLogin()}>
                        Buy
                      </Button>
                    </div>
                    <div className="ml align-start mr my-3">
                      <span className="prod-tech-spec-text">
                        <ShowMoreText text={prodDet.techSpec.join(', ')} wordLimit={30} />
                      </span>
                    </div>
                  </Card>
                </Col>
              ))
              : (
                <div className="pt-4 align-center">
                  <span className="form-collapse-card-header">No Projects Found</span>
                  {!loadingMore && (
                    <div className="loader-container">
                      <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
                      <span className="mx-2">Please wait....</span>
                    </div>
                  )}
                </div>
              )}
          </Row>
        </Col>
        <Col className="navigation-button" xs="1" lg="1">
          <Button onClick={handleNextClick} disabled={currentPage + 1 >= totalPages}>
            <FontAwesomeIcon icon={faCaretRight} className="arrow-icon" />
          </Button>
        </Col>
      </Row>
    </Container>
  );
};

export default AllProductsGallery;
