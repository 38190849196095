import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import {
  Button,
  CardImg,
  Col,
  Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import {
  faArrowUpRightFromSquare, faEnvelope, faHeadset, faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { LoaderData } from '../../../../utils/loader';
import {
  myOrdersReq,
} from '../../../../store/powerMart/actions';
import { changeDateFormat, errorAlert } from '../../../../utils/alert';
import { APIHeader } from '../../../../utils/constants';
import { getDiscomUtilSessionStorage, getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import './orders.scss';
import { MyOrdersResult } from '../../../../services/products/list-my-orders/get-all-myorders.types';
import HorizontalStepper from '../../../../utils/horz-stepper/stepper-horz';
import Modal from '../../../../utils/Modal/Modal';
import Tooltip from '../../../../utils/Tooltip/Tooltip';
import Accordion from '../../../../utils/Accordion/Accordion';

export type HorzStep = {
  label: string;
  prodSts: string;
};

export const MyOrdersComponent: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const discomUtil = getDiscomUtilSessionStorage();
  const [discomUtility, setDiscomUtil] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [loaderOrders, setLoaderOrders] = React.useState(false);
  const [helpOrders, setHelpOrders] = React.useState(false);
  // const [myOrderData, setMyOrderData] = React.useState<string>('myOrder-0');
  const listMyOrder = useSelector((state: RootState) => state.powerMart.getMyOrdersList);
  const [openModalSeller, setOpenModalSeller] = React.useState<Record<string, boolean>>({});
  const [myOrders, setMyOrders] = React.useState<MyOrdersResult[]>([
    {
      addr: '',
      fCnt: 0,
      ordId: '',
      prodTxnData: [{
        ActId: '',
        ActTp: '',
        gst: '',
        prodCnt: '',
        prodCost: '',
        prodInfo: {
          cost: '',
          gst: '',
          imgUrl: '',
          nme: '',
        },
        mSts: '',
        sSts: '',
        tCost: '',
        rsn: '',
        shipDt: '',
        courNme: '',
        trckNo: '',
        trckUrl: '',
      }],
      sumCost: '',
      sumGst: '',
      sumTotal: '',
      ordDt: '',
    },
  ]);

  const horzSteps: HorzStep[] = [
    { label: 'PAYMENT COMPLETED', prodSts: '' },
    { label: 'ORDER CONFIRMED', prodSts: '' },
    { label: 'IN TRANSIT', prodSts: '' },
    { label: 'TO DELIVER', prodSts: '' },
  ];

  React.useEffect(() => {
    if (discomUtil) {
      setDiscomUtil(discomUtil);
    }
  }, [discomUtil]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userInitTp]);

  React.useEffect(() => {
    if (userTp !== '' && discomUtil !== '') {
      setLoaderOrders(true);
      dispatch(myOrdersReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_USER_ORDERS,
        ordSts: 'CURRENT',
        discomId: discomUtility,
      }));
    }
  }, [userTp, discomUtil]);

  React.useEffect(() => {
    if (listMyOrder.isError && listMyOrder.message !== '') {
      setLoaderOrders(false);
      setAlertMsg(listMyOrder.message);
      setErrorShowAlert(true);
    }
  }, [listMyOrder.isError, listMyOrder.message]);

  React.useEffect(() => {
    if (!listMyOrder.isError && listMyOrder.message === 'executed') {
      setMyOrders(listMyOrder.data);
      setLoaderOrders(false);
    }
  }, [listMyOrder.isError, listMyOrder.message, discomUtil, listMyOrder.data]);

  // const handleAccordionToggle = (myData: string) => {
  //   setMyOrderData(myData);
  // };

  const handleShowOrders = () => {
    history('/puser/order-history');
  };

  const sellerToggleModal = (target: string) => {
    setOpenModalSeller((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  return (
    <div className="pt-5">
      { loaderOrders ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="pt-5 align-center">
        <span className="my-order-label">
          MY ACTIVE ORDERS
        </span>
      </div>
      <Row className="align-center">
        <Col lg="10" xs="12">
          <div className="align-end order-margin-right order-margin-top">
            <Button className="powermart-button-link" onClick={handleShowOrders}>order histroy</Button>
            <span className="mx-3 text-lite-grey">|</span>
            <Button onClick={() => setHelpOrders(true)} className="powermart-button-link"> <FontAwesomeIcon icon={faHeadset} className="nav-mobile-icon1 mx-1" />help</Button>
          </div>
          <Modal
            show={helpOrders || false}
            onClose={() => setHelpOrders(false)}
            disCancel={false}
            targetId="orderHelp"
            title="Need Help ?"
            size="XL"
          >
            <div className="my-4 mx-3">
              <div className="ml">
                <span className="about-sub-title">Email Support</span><br />
                <span className="about-text">For detailed inquiries or support requests, you can contact us via email. Our team is ready to assist you with any questions or issues you may have.</span>
                <div className="pt-2 ml">
                  <FontAwesomeIcon icon={faEnvelope} className="text-lite-grey mx-2" />
                  <span className="text-lite-grey">support@powermart.com</span>
                </div>
              </div>
              <div className="pt-3 ml">
                <span className="about-sub-title">Phone Support</span><br />
                <span className="about-text">If you prefer to speak with a support representative directly, give us a call. Our phone support is available during business hours to provide you with immediate assistance.</span>
                <div className="pt-3 ml">
                  <FontAwesomeIcon icon={faWhatsapp} className="correct-pass-key mx-2" />
                  <span className="text-lite-grey">+91 8618679984</span>
                </div>
              </div>
            </div>
          </Modal>
        </Col>
        {myOrders && myOrders.length > 0
          ? myOrders.map((orderDet, index) => (
            <Col lg="10" xs="12" key={`order-det-${index.toString()}`}>
              <Accordion
                title={`Order details# ${orderDet.ordId}`}
                defaultOpen={index === 0}
                targetId={`myOrder-${index}`}
              >
                <Row className="align-center">
                  <Col lg="12">
                    <Row>
                      <Col xs="12">
                        <div className="align-start">
                          <span className="text-lite-grey">Order Date: </span>
                          <span className="text-bold-grey mx-1">{changeDateFormat(orderDet.ordDt)}</span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {/* <Col xs="12">
                        <div className="my-2">
                          {orderDet.mSts === 'PCOMP' ? <span className="correct-pass-key">Transaction validation is currently in progress, and you can view all updates here.</span> : null}
                          {orderDet.mSts === 'TACK' ? <span className="correct-pass-key">The order has been confirmed, and we are now in the process of initiating the shipment.</span> : null}
                          {orderDet.mSts === 'SHPP' ? <span className="correct-pass-key">Your product has been shipped. Please find the shipment details below for your reference.</span> : null}
                        </div>
                      </Col> */}
                  <Col lg="12" className="pt-2">
                    <div className="my-order-items-bg">
                      <Row className="pt-2">
                        {orderDet.prodTxnData && orderDet.prodTxnData.length > 0
                          ? orderDet.prodTxnData.map((itmDet, itmIndex) => (
                            <Col xs="12" key={`item-det-${itmIndex.toString()}`} className="mb">
                              <Row className="mr-1">
                                <Col xs="12" lg="12">
                                  <div className="align-end">
                                    <div className="ml">
                                      {/* {itmDet.mSts === 'SHPC'
                                        ? (
                                          <span className="powermart-button-link">Delivered
                                            <span className="text-lite-grey">(view warranty card in order history )</span>
                                          </span>
                                        )
                                        : (
                                          <span className="text-lite-grey">Est Date:
                                            <span className="text-bold-grey mx-1">{itmDet.shipDt !== '' ? changeDateFormat(itmDet.shipDt) : 'NA'}</span>
                                          </span>
                                        )} */}
                                      <span className="text-lite-grey">{itmDet.mSts === 'SHPC' ? 'Recieved:' : 'ETA:'}
                                        <span className="text-bold-grey mx-1">{itmDet.shipDt !== '' ? changeDateFormat(itmDet.shipDt) : 'NA'}</span>
                                      </span>
                                      {itmDet.trckNo !== '' ? <span className="mx-3 text-lite-grey">|</span> : null}
                                      {itmDet.trckNo !== ''
                                        ? (
                                          <Tooltip
                                            content="Courier Details"
                                            position="bottom"
                                            customClassName={`orderData-${itmIndex}`}
                                          >

                                            <Button className="powermart-button-link" id={`orderData-${itmIndex}`} onClick={() => sellerToggleModal(`ordDet-${itmIndex}`)}>
                                              <FontAwesomeIcon icon={faTruckFast} className="mx-1" />
                                            </Button>
                                          </Tooltip>
                                        )
                                        : null}
                                    </div>
                                    {/* {itmDet.trckNo !== ''
                                          ? (
                                            <UncontrolledTooltip delay={0} target={`orderData-${itmIndex}`}>
                                              Courier Details
                                            </UncontrolledTooltip>
                                          )
                                          : null} */}
                                    <Modal
                                      show={openModalSeller[`ordDet-${itmIndex}`] || false}
                                      onClose={() => sellerToggleModal(`ordDet-${itmIndex}`)}
                                      disCancel={false}
                                      targetId={`ordDet-${itmIndex}`}
                                      title={(
                                        <div className="align-start my-3">
                                          <CardImg src={itmDet.prodInfo.imgUrl} alt="Seller Image" className="buyer-ship-image mx-2" />
                                          <span className="dash-seller-chat-icon pt-3">{itmDet.prodInfo.nme}</span>
                                        </div>
                                      )}
                                      size="MD"
                                    >
                                      <div className="my-order-items-bg">
                                        <Row className="align-center my-2">
                                          <Col xs="12" lg="12">
                                            <div className="pt-1 align-start ">
                                              <h6 className="text-lite-grey mx-2 my-1">Courier Name:
                                                <span className="form-card-header ml">{itmDet.courNme}</span>
                                              </h6>
                                            </div>
                                          </Col>
                                          <Col xs="12" lg="12">
                                            <div className="pt-2 align-start ">
                                              <h6 className="text-lite-grey mx-2 my-1">Tracking Number:
                                                <span className="form-card-header ml">{itmDet.trckNo}</span>
                                              </h6>
                                            </div>
                                          </Col>
                                          {itmDet.trckUrl !== ''
                                            ? (
                                              <Col xs="12">
                                                <div className="pt-2 align-start ">
                                                  <h6 className="text-lite-grey mx-2 my-1">Tracking Url:
                                                    <Button
                                                      className="button-icon mb"
                                                      href={itmDet.trckUrl}
                                                      target="_blank"
                                                    >
                                                      {itmDet.trckUrl}<FontAwesomeIcon icon={faArrowUpRightFromSquare} className="ml order-link-size" />
                                                    </Button>
                                                  </h6>
                                                </div>
                                              </Col>
                                            ) : null }
                                        </Row>
                                        <div className="pt-2" />
                                      </div>
                                    </Modal>
                                  </div>
                                  <div className="stepper-parent-container">
                                    {itmDet.mSts === 'PCOMP'
                                      ? <HorizontalStepper horzSteps={horzSteps} indexStep={0} status={itmDet.mSts} />
                                      : itmDet.mSts === 'TACK'
                                        ? <HorizontalStepper horzSteps={horzSteps} indexStep={1} status={itmDet.mSts} />
                                        : itmDet.mSts === 'SHPP'
                                          ? <HorizontalStepper horzSteps={horzSteps} indexStep={2} status={itmDet.mSts} />
                                          : itmDet.mSts === 'SHPC'
                                            ? <HorizontalStepper horzSteps={horzSteps} indexStep={3} status={itmDet.mSts} />
                                            : null }
                                  </div>
                                  <div className="align-start ml pt-3">
                                    <span className="product-name-header-link">{itmDet.prodInfo.nme}</span>
                                  </div>
                                </Col>
                                {/* <Col xs="2" lg="2">
                                      <div className="align-end">
                                        <Button className="powermart-button-link"> <FontAwesomeIcon icon={faHeadset} className="nav-mobile-icon1 mx-1" />help</Button>
                                      </div>
                                    </Col> */}
                              </Row>
                              <Row className="mr-1">
                                <Col lg="3" xs="4">
                                  <CardImg alt="prodLogo" className="order-prod-image order-margin-top" src={itmDet.prodInfo.imgUrl} />
                                </Col>
                                <Col lg="3" xs="3">
                                  <div className="align-center my-4">
                                    <span className="order-cost-text">{itmDet.prodCnt} x</span>
                                  </div>
                                </Col>
                                <Col lg="6" xs="5">
                                  <div className="align-end my-4">
                                    <span className="order-cost-text">
                                      {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(itmDet.prodCost))}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-center">
                                <Col xs="11">
                                  <hr className="hr-horz" />
                                </Col>
                              </Row>
                            </Col>
                          )) : (
                            <div className="pt-4 align-center">
                              <span className="form-collapse-card-header">No Product Found in Item List</span>
                            </div>
                          )}
                      </Row>
                      <Row className="mr-1">
                        <Col xs="12" lg="12">
                          <div className="align-end pt-1">
                            <span className="text-bold-grey mr-2">Product Total</span>
                            <span className="order-cost-text">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumCost))}</span>
                          </div>
                          <div className="align-end pt-3">
                            <span className="text-bold-grey mr-2">Total Tax</span>
                            <span className="order-cost-text">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumGst))}</span>
                          </div>
                        </Col>
                        <Col lg="12">
                          <hr className="hr-horz ml-3 my-3" />
                        </Col>
                        <Col xs="12" lg="12">
                          <div className="align-end">
                            <span className="text-bold-grey mr-2">Sum Total</span>
                            <span className="notification-text">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumTotal))}</span>
                          </div>
                        </Col>
                      </Row>
                      <div className="pt-4" />
                    </div>
                  </Col>
                </Row>
              </Accordion>
            </Col>
          )) : (
            <div className="pt-4 align-center">
              <span className="form-collapse-card-header">No Orders Found. Continue Shopping</span>
            </div>
          )}
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default MyOrdersComponent;
