import React, { useState } from 'react';
import { Button } from 'reactstrap';

const ShowMoreText: React.FC<{ text: string, wordLimit: number }> = ({ text, wordLimit }) => {
  const [showMore, setShowMore] = useState(false);
  const words = text.split(' ');

  const toggleShowMore = () => setShowMore(!showMore);

  if (words.length <= wordLimit) {
    return <span>{text}</span>;
  }

  return (
    <span>
      {showMore ? text : `${words.slice(0, wordLimit).join(' ')} ...`}
      <Button onClick={toggleShowMore} className="button-icon-text">
        {showMore ? 'show less' : 'show more'}
      </Button>
    </span>
  );
};

export default ShowMoreText;
