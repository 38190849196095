import { ProductDescResponse, ProductDescResults } from './product-desc.service.types';

export const ProductDescTransform: (response: ProductDescResponse) => ProductDescResults = (getAllProductResults) => {
  const { data, is_error, message } = getAllProductResults;
  const result: ProductDescResults = {
    data: {
      prodDesc: [],
      techSpec: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? [];
    result.message = 'executed';
  }
  return result;
};
