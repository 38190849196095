import { PostSpareResponse, PostSpareResults } from './post-spare.service.types';

export const PostSpareTransform: (response: PostSpareResponse) => PostSpareResults = (createPostResults) => {
  const { data, is_error, message } = createPostResults;
  const result: PostSpareResults = {
    message: '',
    error: false,
    data: '',
    prodId: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
    result.prodId = data.id;
  }
  return result;
};
