import { ListStateResponse, ListStateResults } from './list-state.service.types';

export const ListStateTransform: (response: ListStateResponse) => ListStateResults = (listStateResults) => {
  const { data, is_error, message } = listStateResults;
  const result: ListStateResults = {
    tCnt: 0,
    fCnt: 0,
    stData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.stData = data.stData;
    result.message = 'executed';
  }
  return result;
};
