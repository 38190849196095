import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Input, InputGroup, Button, Form, Label, Modal, ModalBody,
  CardBody,
  Card,
  CardHeader,
} from 'reactstrap';
import '../login.scss';
import React from 'react';
import { faBolt, faCircleXmark, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  APIHeader,
  ListCPOptions,
} from '../../../../utils/constants';
import { LoaderData } from '../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../utils/alert';
import {
  clearRegisterPostReq,
  sellerRegisterReq,
} from '../../../../store/powerMart/actions';
// import { CorpResult } from '../../../../services/meta-data/list-corp/list-corp.types';
import { OEMDATA, SellerRegisterInput } from '../../../../services/register-seller/seller-register.types';

export const RegisterSellerUser: React.FC = () => {
  const dispatch = useDispatch();
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  // const [loaderCorp, setLoaderCorp] = React.useState(false);
  const [invalidField, setInvalidField] = React.useState(false);
  const [registerLoader, setRegisterLoader] = React.useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [modalConfirm, setModalConfirm] = React.useState(false);
  const [isShowOrgDet, setIsShowOrgDet] = React.useState(false);
  const [oEMData, setOEMData] = React.useState('');
  const [oEMInitOptions, setOEMOInitptions] = React.useState<OEMDATA[]>([]);
  // const corpResData = useSelector((state: RootState) => state.powerMart.getCorpMetaList);
  // const [corpListData, setCorpListData] = React.useState<CorpResult[]>([]);
  const registerSellerResponse = useSelector((state: RootState) => state.powerMart.registerSeller);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const phoneRegex = /^\d{10}$/;
  const [sellerRegisterData, setSellerRegisterData] = React.useState<SellerRegisterInput>({
    nme: '',
    ph: '',
    eId: '',
    // corpId: 'TMPCORP',
    desg: '',
    usrTp: 'SELLER',
    org: '',
    cntNme: '',
    cntEmail: '',
    cntPh1: '',
    cntPh2: '',
    cntDesg: '',
    oem: [],
    isAgree: false,
  });

  const handleTermUse = () => {
    setModalConfirm(!modalConfirm);
  };

  React.useEffect(() => {
    if (registerSellerResponse.error && registerSellerResponse.message !== '') {
      dispatch(clearRegisterPostReq());
      setRegisterLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(registerSellerResponse.message);
    }
  }, [registerSellerResponse.message, registerSellerResponse.error]);

  React.useEffect(() => {
    if (!registerSellerResponse.error && registerSellerResponse.data !== '') {
      dispatch(clearRegisterPostReq());
      setRegisterLoader(false);
      setInvalidField(false);
      setIsValidEmail(false);
      setIsValidPhone(false);
      setSellerRegisterData({
        nme: '',
        ph: '',
        eId: '',
        // corpId: '',
        desg: '',
        usrTp: 'SELLER',
        org: '',
        cntNme: '',
        cntEmail: '',
        cntPh1: '',
        cntPh2: '',
        cntDesg: '',
        oem: [],
        isAgree: false,
      });
      if (registerSellerResponse.data === 'Mail sent successfully') {
        setAlertMsg('Congratulations on completing your registration! Powermart will review your information and send you login details.');
      }
      setSuccessShowAlert(true);
    }
  }, [registerSellerResponse.data, registerSellerResponse.error]);

  // const handleSelectCorp = (e: any) => {
  //   setCorpData(e.value);
  //   setSellerRegisterData({ ...sellerRegisterData, corpId: e.value });
  // };

  const handleFieldChangeRegister = (e: any) => {
    const fieldName = e.target.name;
    setSellerRegisterData({ ...sellerRegisterData, [fieldName]: e.target.value });
  };

  const handleTermChange = (e: any) => {
    setSellerRegisterData({ ...sellerRegisterData, isAgree: e.target.checked });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const validEmail = emailRegex.test(sellerRegisterData.eId);
    const validPhone = phoneRegex.test(sellerRegisterData.ph);
    const validCntEmail = emailRegex.test(sellerRegisterData.cntEmail);
    const validPhone1 = phoneRegex.test(sellerRegisterData.cntPh1);
    const validPhone2 = phoneRegex.test(sellerRegisterData.cntPh2);
    if (validEmail && validPhone) {
      setRegisterLoader(true);
      Promise.resolve(
        dispatch(sellerRegisterReq({
          inputBody: sellerRegisterData,
          requestType: APIHeader.REQ_ADD_SELLER,
        })),
      );
    } else if (!validEmail || !validCntEmail) {
      setIsValidEmail(true);
    } else if (!validPhone || !validPhone1 || !validPhone2) {
      setIsValidPhone(true);
    }
  };

  const oemOptions = ListCPOptions.map((oem) => ({
    label: oem.label,
    value: oem.value,
  }));

  const filteredOEMOptions = oemOptions.filter((option) => option.label === oEMData);

  const handleSelectOEM = (e: any) => {
    const selectedValue = e.value;
    const selectedLabel = e.label;

    if (!oEMInitOptions.includes(selectedLabel)) {
      if (!sellerRegisterData.oem.some((oemDet) => oemDet === selectedValue)) {
        const updatedOEM = [
          ...sellerRegisterData.oem,
          selectedValue,
        ];
        setSellerRegisterData({ ...sellerRegisterData, oem: updatedOEM });

        const updatedLabel: OEMDATA[] = [
          ...oEMInitOptions,
          { label: selectedLabel, value: selectedValue },
        ];
        setOEMOInitptions(updatedLabel);
      }
      setOEMData(selectedLabel);
    }
  };

  const handleRemoveOEM = (oemDet: string) => {
    setOEMOInitptions((prevOptions) => prevOptions.filter((option) => option.value !== oemDet));

    // Find the corresponding item in discom based on dist value
    const oemToRemove = sellerRegisterData.oem.find(
      (oemData) => oemData === oemDet,
    );

    if (oemToRemove) {
      setSellerRegisterData((prevData) => ({
        ...prevData,
        oem: prevData.oem.filter(
          (oemDel) => oemDel !== oemToRemove,
        ),
      }));
    }
    setOEMData('');
  };

  // React.useEffect(() => {
  //   setLoaderCorp(true);
  //   dispatch(getCorpListReq({
  //     requestType: APIHeader.REQ_ALL_CORP_META,
  //   }));
  // }, []);

  // React.useEffect(() => {
  //   if (!corpResData.isError) {
  //     setLoaderCorp(false);
  //     setCorpListData(corpResData.corpData);
  //   }
  // }, [corpResData.corpData]);

  const handleShowOrgDet = () => {
    setIsShowOrgDet(!isShowOrgDet);
  };

  return (
    <div className="mx-2">
      {registerLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {invalidField ? (
        errorAlert(false, 'All Fields Are Mandatory !!!', invalidField, setInvalidField)
      ) : null}
      {isValidEmail ? (
        errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}

      <Form onSubmit={handleSubmit}>
        <div className="my-3">
          <Card className="discom-signup-buz">
            <CardHeader className="form-card-header">
              <span>Personal Details</span>
            </CardHeader>
            <CardBody>
              <Row className="pt-2 mx-1 my-1">
                <Col lg="12" className="pt-1">
                  <h6 className="text-lite-grey mx-2 my-1">Full Name<span className="mandate-star">*</span></h6>
                  <Input
                    placeholder="Enter your Name"
                    type="text"
                    required
                    name="nme"
                    value={sellerRegisterData.nme}
                    disabled={registerLoader}
                    onChange={handleFieldChangeRegister}
                    className="powermart-input"
                  />
                </Col>
                <Col lg="12" className="pt-3">
                  <h6 className="text-lite-grey mx-2 my-1">Phone<span className="mandate-star">*</span></h6>
                  <InputGroup>
                    <Input
                      placeholder="Enter your Phone Number"
                      type="number"
                      required
                      name="ph"
                      value={sellerRegisterData.ph}
                      disabled={registerLoader}
                      onChange={handleFieldChangeRegister}
                      className="powermart-input"
                    />
                  </InputGroup>
                  <h6 className="text-lite-grey mx-2">
                    10 digit phone number (exclude +91)
                  </h6>
                </Col>
                <Col lg="12" className="pt-3">
                  <h6 className="text-lite-grey mx-2 my-1">Email Id<span className="mandate-star">*</span></h6>
                  <Input
                    placeholder="Enter your Email Id"
                    type="text"
                    required
                    name="eId"
                    value={sellerRegisterData.eId}
                    disabled={registerLoader}
                    onChange={handleFieldChangeRegister}
                    className="powermart-input"
                  />
                  <h6 className="text-lite-grey mx-2">
                    All the communications will be sent to your email id
                  </h6>
                </Col>
                <Col lg="12" className="pt-3">
                  <h6 className="text-lite-grey mx-2 my-1">Designation<span className="mandate-star">*</span></h6>
                  <Input
                    placeholder="Enter your Designation"
                    type="text"
                    required
                    name="desg"
                    value={sellerRegisterData.desg}
                    disabled={registerLoader}
                    onChange={handleFieldChangeRegister}
                    className="powermart-input"
                  />
                </Col>
              </Row>
              <div className="align-end my-2">
                <Button className="button-icon" onClick={handleShowOrgDet}>
                  Add Your/Organization Details<FontAwesomeIcon icon={faPlusCircle} className="ml" />
                </Button>
              </div>
            </CardBody>
          </Card>
        </div>
        {isShowOrgDet
          ? (
            <div>
              <Card className="discom-signup-buz">
                <CardHeader className="form-card-header">
                  <span>Sellers Contact Info</span>
                </CardHeader>
                <CardBody>
                  <Row className="pt-2 mx-1">
                    <Col lg="12" className="pt-1">
                      <h6 className="text-lite-grey mx-2 my-1">Your Organization<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter your Organization Name"
                        type="text"
                        required
                        name="org"
                        value={sellerRegisterData.org}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="powermart-input"
                      />
                    </Col>
                    <Col lg="12" className="pt-3">
                      <h6 className="text-lite-grey mx-2 my-1">Contact Person Name<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Contact Person Name"
                        type="text"
                        required
                        name="cntNme"
                        value={sellerRegisterData.cntNme}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="powermart-input"
                      />
                    </Col>
                    <Col lg="12" className="pt-3">
                      <h6 className="text-lite-grey mx-2 my-1">Contact Email Id<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Contact Email Id"
                        type="text"
                        required
                        name="cntEmail"
                        value={sellerRegisterData.cntEmail}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="powermart-input"
                      />
                    </Col>
                    <Col lg="12" className="pt-3">
                      <h6 className="text-lite-grey mx-2 my-1">Contact Phone<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Contact Phone"
                        type="number"
                        required
                        name="cntPh1"
                        value={sellerRegisterData.cntPh1}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="powermart-input"
                      />
                      <h6 className="text-lite-grey mx-2">
                        10 digit phone number (exclude +91)
                      </h6>
                    </Col>
                    <Col lg="12" className="pt-3">
                      <h6 className="text-lite-grey mx-2 my-1">Phone Others</h6>
                      <Input
                        placeholder="Contact Phone"
                        type="number"
                        name="cntPh2"
                        value={sellerRegisterData.cntPh2}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="powermart-input"
                      />
                      <h6 className="text-lite-grey mx-2">
                        10 digit phone number (exclude +91)
                      </h6>
                    </Col>
                    <Col lg="12" className="pt-3">
                      <h6 className="text-lite-grey mx-2 my-1">Contact Designation<span className="mandate-star">*</span></h6>
                      <Input
                        placeholder="Enter your Designation"
                        type="text"
                        required
                        name="cntDesg"
                        value={sellerRegisterData.cntDesg}
                        disabled={registerLoader}
                        onChange={handleFieldChangeRegister}
                        className="powermart-input"
                      />
                    </Col>
                    <Col lg="12" className="pt-3">
                      <h6 className="text-lite-grey mx-2 my-1">OEM/CATEGORY<span className="mandate-star">*</span></h6>
                      <Select
                        value={oEMData === '' ? null : filteredOEMOptions}
                        options={oemOptions}
                        onChange={handleSelectOEM}
                        menuPortalTarget={document.body}
                        styles={{
                          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                          menu: (base) => ({ ...base }),
                          control: (base) => ({
                            ...base,
                            '&:hover': { borderColor: '#05cbddff' },
                            border: '1px solid #BFBFBF',
                            minHeight: '45px',
                            borderRadius: '5px',
                            padding: 1,
                            textAlign: 'left',
                            fontSize: '16px',
                            fontWeight: 400,
                            color: '#383838',
                            boxShadow: 'none',
                            backgroundColor: '#fff',
                            '&:focus': {
                              borderColor: '#E1EDF8',
                              boxShadow: 'none',
                              color: '#575656',
                              background: '#fff',
                            },
                          }),
                          option: (provided, state) => ({
                            ...provided,
                            color: state.isSelected ? '#383838' : '#212121',
                            padding: 15,
                            textAlign: 'left',
                            background: state.isSelected ? '#FAFCFB' : '#fff',
                            '&:hover': {
                              background: '#FAFCFB',
                            },
                          }),
                        }}
                        noOptionsMessage={() => 'No OEM/CATEGORY Found'}
                        placeholder="Select OEM/CATEGORY"
                      />
                    </Col>
                    {oEMInitOptions.length > 0
                      ? <h6 className="text-lite-grey mx-2 my-2">Selected OEM Type<span className="mandate-star">*</span></h6>
                      : null }
                    {oEMInitOptions && oEMInitOptions.length > 0
                      ? oEMInitOptions.map((oemDet, index) => (
                        <div key={`oem-${index.toString()}`} className="ml my-1 align-start">
                          <div className="mr">
                            <FontAwesomeIcon icon={faBolt} className="prod-tech-spec-icon" />
                          </div>
                          <div>
                            <span className="discom-liting-text">{oemDet.label}</span>
                            <Button className="button-icon padding-zero ml mb" onClick={() => handleRemoveOEM(oemDet.value)}>
                              <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                            </Button>
                          </div>
                        </div>
                      )) : null }
                  </Row>
                </CardBody>
              </Card>
            </div>
          ) : null }
        <Row className="pt-1">
          <Col lg="12" className="pt-1">
            <Label check>
              <Input
                type="checkbox"
                disabled={registerLoader}
                onChange={handleTermChange}
                className="powermart-check-box ml pt-1 cursor-pointer"
              />{' '}
              <span className="text-lite-grey">I have read the <Button className="powermart-button-link" onClick={handleTermUse}>terms &amp; use</Button> and agree.</span>
            </Label>
          </Col>
          <Col lg="12">
            <div className="align-center pt-4">
              <Button
                className="powermart-button-solid mx-1"
                disabled={!sellerRegisterData.isAgree || sellerRegisterData.org === '' || sellerRegisterData.cntEmail === '' || sellerRegisterData.cntNme === '' || sellerRegisterData.cntPh1 === ''}
              >
                Register
              </Button>
            </div>
          </Col>
        </Row>
      </Form>
      <Modal isOpen={modalConfirm} className="width-100" size="lg">
        <ModalBody>
          <div className="width-100">
            <span>Member Terms and Conditions</span>
          </div>
          <div className="pt-3">
            <span className="text-lite-grey">These Member Terms and Conditions (&quot;Terms&quot;) govern your participation as a member in our data registration program. By joining and participating in our program, you agree to abide by the following terms and conditions:</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">1. Accurate Data Collection:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">1.1. You are responsible for accurately and truthfully collecting and recording data as per the government-issued identification provided by the individuals you register.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">1.2. Any data you collect must be in compliance with local, state, and national laws and regulations.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">2. Non-Misuse of Data:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">2.1. You agree not to misuse or exploit any data collected during the registration process for personal gain, illegal activities, or unauthorized purposes.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">2.2. Data collected must be used solely for the purpose of registration and in accordance with our data handling policies.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">3. Identity Verification:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">3.1. You must not use fake or fraudulent identification for yourself during the registration process.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">3.2. Any misrepresentation of your identity is strictly prohibited.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">4. Data Integrity:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">4.1. Once data is recorded and submitted, you must not alter or manipulate it in any way, unless explicitly instructed to do so by authorized personnel.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">4.2. Any unauthorized modification of data is a breach of these terms.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">5. Confidentiality:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">5.1. You must maintain the confidentiality and security of all data collected and not disclose it to any unauthorized parties.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">5.2. Data confidentiality is critical, and violations of this policy may result in legal action.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">6. Compliance with Policies:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">6.1. You agree to comply with all policies, guidelines, and instructions provided to you by our organization.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">6.2. Failure to comply with policies may lead to termination of your membership.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">7. Termination:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">7.1. We reserve the right to terminate your membership if you violate these terms and conditions.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">8. Jurisdiction for Legal Matters:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">8.1. In the event of any legal disputes or concerns arising from your participation in this program, both parties agree that the exclusive jurisdiction for resolution of such matters shall be the courts located in Pune, Maharashtra India..</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">8.2. This provision applies to all legal matters and disputes, including but not limited to those related to the interpretation, enforcement, or breach of these terms and conditions.</span>
          </div>
          <div className="pt-3">
            <span className="text-lite-grey">By joining our data registration program, you acknowledge your understanding and acceptance of these terms and conditions. If you have any questions or concerns, please contact us before proceeding.</span>
          </div>
          <div className="pt-1">
            <span className="text-lite-grey">These terms and conditions are subject to change, and any updates will be communicated to you.</span>
          </div>
          <div className="align-center my-3">
            <Button className="powermart-button-solid mx-1" onClick={handleTermUse}>
              I Agree
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default RegisterSellerUser;
