import React from 'react';
import '../cart.scss';
import {
  Row, Col, Card, CardBody, CardImg,
  Input,
  Button,
  Spinner,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { KartReviewProps } from '../cart.type';
import { AddressPutDataInput } from '../../../../../services/all-users/update-address/update-address.types';
import { APIHeader } from '../../../../../utils/constants';
import { addressReq, clearAddressReq } from '../../../../../store/powerMart/actions';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../../utils/service/localstorage-service';
import { errorAlert } from '../../../../../utils/alert';
import { KartsResultData } from '../../../../../services/products/list-kart/get-all-kart.types';

export const CartReviewPrivateList: React.FC<KartReviewProps> = ({ productsData }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [loaderAddress, setLoaderAddress] = React.useState(false);
  const [confirmAddress, setConfirmAddress] = React.useState(false);
  const [showNewUpdateAddr, setShowNewUpdateAddr] = React.useState(false);
  const updateUserAddressRes = useSelector((state: RootState) => state.powerMart.updateAddress);
  const [updateAddress, setUpdateAddress] = React.useState<AddressPutDataInput>({
    addr: '',
  });

  React.useEffect(() => {
    if (productsData.addr) {
      setUpdateAddress({ ...updateAddress, addr: productsData.addr });
    }
  }, [productsData.addr]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [
    userInitTp && JSON.parse(userInitTp).profileData.UserTp,
  ]);

  const handleAddAddress = () => {
    setConfirmAddress(!confirmAddress);
  };

  const handleAddAddressChange = (e: any) => {
    setUpdateAddress({ ...updateAddress, addr: e.target.value });
  };

  const handleSubmitAddress = () => {
    setLoaderAddress(true);
    dispatch(addressReq({
      userType: userTp,
      requestType: APIHeader.REQ_UPDATE_USR_ADDR,
      inputBody: updateAddress,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (updateUserAddressRes.error && updateUserAddressRes.message !== '') {
      setLoaderAddress(false);
      setAlertMsg(updateUserAddressRes.message);
      setErrorShowAlert(true);
      dispatch(clearAddressReq());
      setConfirmAddress(false);
    }
  }, [updateUserAddressRes.error, updateUserAddressRes.message]);

  React.useEffect(() => {
    if (!updateUserAddressRes.error && updateUserAddressRes.message === 'executed') {
      setLoaderAddress(false);
      dispatch(clearAddressReq());
      setConfirmAddress(false);
      setShowNewUpdateAddr(true);
    }
  }, [updateUserAddressRes.error, updateUserAddressRes.message]);

  const handleDisable = (prodTxnData: KartsResultData[]) => {
    if (!prodTxnData || prodTxnData.length === 0) return true;
    const isAnyOtherStatus = prodTxnData.some((txn) => txn.sSts !== 'SUPDT' && txn.sSts !== 'SAPPR');
    return isAnyOtherStatus;
  };

  const checkMultiOrder = (prodTxnData: KartsResultData[]) => {
    if (!prodTxnData || prodTxnData.length === 0) return true;
    const isAnyOtherStatus = prodTxnData.some((txn) => txn.sSts === 'SUPDT');
    return isAnyOtherStatus;
  };

  const checkRejectOrder = (prodTxnData: KartsResultData[]) => {
    if (!prodTxnData || prodTxnData.length === 0) return true;
    const isAnyOtherStatus = prodTxnData.some((txn) => txn.sSts === 'REJCT');
    return isAnyOtherStatus;
  };

  return (
    <div>
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {productsData.prodTxnData.length > 0
        ? (
          <Row className="align-center">
            <Col lg="11" xs="11">
              <Row className="pt-2">
                <Col lg="4" xs="9">
                  <div className="pt-3 align-start">
                    <span className="meta-waranty-lite">In Review</span>
                  </div>
                </Col>
                <Col lg="4" xs="3">
                  <div className="pt-3">
                    <span className="text-bold-grey">{productsData.fCnt} items</span>
                  </div>
                </Col>
                <Col lg="4">
                  <div className="align-end pt-3">
                    <span className="meta-waranty-lite">
                      <span className="text-bold-grey">Need help? Call </span>
                      <FontAwesomeIcon icon={faWhatsapp} className="correct-pass-key mx-1" />
                      +91 8618679984
                    </span>
                  </div>
                </Col>
                <hr />
              </Row>
            </Col>
            <Col lg="11" xs="11">
              {handleDisable(productsData.prodTxnData) === false
                ? (
                  <span className="text-lite-grey">Products are currently under review.
                    {checkMultiOrder(productsData.prodTxnData) === true
                      ? <span className="text-lite-grey">(Some products will be shipped in multiple shipments (M))</span>
                      : null}
                  </span>
                )
                : (
                  <span className="correct-pass-key">Awaiting for Final Approval.
                    {checkMultiOrder(productsData.prodTxnData) === true
                      ? <span className="correct-pass-key mx-1">(Some products will be shipped in multiple shipments (M))</span>
                      : null}
                  </span>
                )}
              {checkRejectOrder(productsData.prodTxnData) === true
                ? <span className="wrong-pass-key mx-1"><br />Please note: Some products have been rejected, and valid reasons have been provided. The costs have been adjusted accordingly.</span>
                : null}
              <Row className="pt-1">
                <Col lg="8">
                  {productsData && productsData.prodTxnData.length > 0
                    ? productsData.prodTxnData.map((prodDet, index) => (
                      <div key={`prod-txn-${index.toString()}`} className="align-center my-2">
                        <Card className={prodDet.sSts === 'REJCT' ? 'card-lite-opaq' : 'card-lite'}>
                          {/* <div className="align-center">
                            {prodDet.subSts !== '' && prodDet.subSts === 'ONHLD'
                              ? <span className="wrong-pass-key">Request is On HOLD. Reason : {prodDet.rsn}</span>
                              : <span className="correct-pass-key">Product is currently under review. Once approved, they will appear in the Approved Orders tab.</span>}
                          </div> */}
                          <CardBody className="my-1">
                            <Row>
                              <Col xs="12">
                                <Row className="my-1">
                                  <Col lg="10" xs="9">
                                    <div className="align-start">
                                      <span className={prodDet.sSts === 'REJCT' ? 'text-lite-grey' : 'card-toggle-text'}>{prodDet.prodInfo.nme}</span>
                                    </div>
                                  </Col>
                                  <Col lg="2" xs="3">
                                    <div className="align-end mx-1">
                                      {prodDet.sSts === ''
                                        ? <span className="text-bold-grey">In-Progress</span>
                                        : prodDet.sSts === 'SAPPR' || prodDet.sSts === 'SUPDT'
                                          ? (
                                            <span className="correct-pass-key">Approved
                                              {prodDet.sSts === 'SUPDT' ? <span className="wrong-pass-key">(M)</span> : null }
                                            </span>
                                          )
                                          : prodDet.sSts === 'REJCT'
                                            ? <span className="wrong-pass-key">Rejected</span>
                                            : null}
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="12">
                                <Row className="pt-2">
                                  <Col lg="2" xs="3">
                                    <div className="align-center mx-1">
                                      <div className="image-container-prod-power">
                                        <CardImg src={prodDet.prodInfo.imgUrl} alt="Prod Image" className="dash-product-image-prod-power" />
                                        {prodDet.sSts !== '' && prodDet.sSts === 'REJCT' && (
                                        <div className="overlay-prod-power">
                                          <CardImg src="/images/stop.png" alt="Prod Image" className="image-reject-prod-power" />
                                        </div>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col lg="4" xs="5">
                                    <div className="align-start mx-1 pt-3">
                                      <span className="text-lite-grey">Each Price</span>
                                    </div>
                                    <div className="align-start mx-1 my-2">
                                      <span className={prodDet.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.prodInfo.cost))}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col lg="2" xs="4">
                                    <div className="align-start mx-2 pt-3">
                                      <span className="text-lite-grey">Quantity</span>
                                    </div>
                                    <div className="align-start mx-2">
                                      <Input
                                        placeholder="Quantity"
                                        type="number"
                                        min="0"
                                        disabled
                                        name="cnt"
                                        defaultValue={prodDet.prodCnt || ''}
                                        className={prodDet.sSts === 'REJCT' ? 'text-lite-grey' : 'powermart-input'}
                                      />
                                    </div>
                                  </Col>
                                  <Col lg="4" xs="12">
                                    <div className="align-end mx-1 pt-3">
                                      <span className="text-lite-grey">Total</span>
                                    </div>
                                    <div className="align-end mx-1 my-2">
                                      <span className={prodDet.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.prodCost))}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                          {prodDet.rsn !== '' ? <span className="wrong-pass-key ml-1 my-1">Reason: {prodDet.rsn}</span> : null }
                        </Card>
                      </div>
                    ))
                    : (
                      <div className="pt-4 align-center">
                        <span className="form-collapse-card-header">No Product Found in Cart</span>
                      </div>
                    )}
                </Col>
                <Col lg="4">
                  <div className="hr-vertical my-2">
                    <Row className="pt-2 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span>Shipping cost</span>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="align-end">
                          <span className="correct-pass-key">FREE</span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-3 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span>Products Cost</span>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="align-end">
                          <span>
                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumCost))}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-3 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span>GST</span>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="align-end">
                          <span>
                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumGst))}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-3 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span className="notification-text">Total Cost</span>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="align-end">
                          <span className="notification-text">
                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumTotal))}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-3 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span className="text-lite-grey">Shipping Address</span>
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="align-start">
                          <span className="text-lite-grey pt-1">
                            {showNewUpdateAddr ? updateAddress.addr
                              : productsData.addr}
                          </span>
                          <Button className="button-icon padding-zero ml-1 mb-2" onClick={handleAddAddress}>
                            <FontAwesomeIcon icon={faEdit} className="wrong-pass-key" />
                          </Button>
                        </div>
                      </Col>
                      {confirmAddress
                        ? (
                          <Col xs="12">
                            <Input
                              type="textarea"
                              placeholder="Add Address"
                              className="powermart-input-desc"
                              value={updateAddress.addr}
                              disabled={loaderAddress}
                              onChange={(e) => handleAddAddressChange(e)}
                            />
                            <div className="align-end pt-2">
                              <Button className="powermart-button-red" onClick={handleSubmitAddress} disabled={loaderAddress}>
                                ADD
                                {loaderAddress ? <><Spinner type="grow" className="spin-grow-loader-white ml" /><Spinner type="grow" className="spin-grow-loader-white" /><Spinner type="grow" className="spin-grow-loader-white" /></> : null}
                              </Button>
                            </div>
                          </Col>
                        )
                        : null}
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )
        : (
          <div className="pt-5 my-5 align-center">
            <span className="form-collapse-card-header">No Product found for Review.</span>
          </div>
        )}
      <div className="pt-5" />
    </div>
  );
};
export default CartReviewPrivateList;
