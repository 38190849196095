import React, { useState, useEffect, useRef } from 'react';
import { useSpring, animated } from 'react-spring';
import './scroll.scss';
import { Button, CardImg } from 'reactstrap';

interface Slide {
  image: string;
  headerText: string;
  mainText: string;
  subText: string;
}

interface ScrollerProps {
  slides: Slide[];
}

const Scroller: React.FC<ScrollerProps> = ({ slides }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isTransitioning, setIsTransitioning] = useState(false);
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const totalSlides = slides.length;

  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  useEffect(() => {
    resetTimeout();
    timeoutRef.current = setTimeout(() => {
      setIsTransitioning(true);
      setCurrentIndex((prevIndex) => prevIndex + 1);
    }, 4000);

    return () => {
      resetTimeout();
    };
  }, [currentIndex]);

  useEffect(() => {
    if (isTransitioning) {
      if (currentIndex === totalSlides + 1) {
        setTimeout(() => {
          setIsTransitioning(false);
          setCurrentIndex(1);
        }, 4000); // Adjust the duration to match the transition duration
      }
    }
  }, [currentIndex, isTransitioning, totalSlides]);

  const changeSlide = (index: number) => {
    setIsTransitioning(true);
    setCurrentIndex(index);
  };

  const slideProps = useSpring({
    transform: `translateX(${-currentIndex * 100}%)`,
    onRest: () => {
      if (currentIndex === totalSlides + 1) {
        setIsTransitioning(false);
        setCurrentIndex(1);
      }
    },
    immediate: !isTransitioning,
  });

  return (
    <div className="scroller">
      <div className="scroller-window">
        <animated.div className="scroller-slides" style={slideProps}>
          <div className="scroller-slide">
            <CardImg src={slides[totalSlides - 1].image} alt={`Slide ${totalSlides}`} className="scroller-slide-img" />
            <div className="scroller-text">
              <div>
                <span className="header-scroll-text">{slides[totalSlides - 1].headerText}</span>
              </div>
              <div className="pt-3">
                <span className="main-scroll-text">{slides[totalSlides - 1].mainText}</span>
              </div>
              <div className="pt-3">
                <span className="sub-scroll-text">{slides[totalSlides - 1].subText}</span>
              </div>
            </div>
          </div>
          {slides.map((slide, index) => (
            <div className="scroller-slide" key={`${index.toString()}`}>
              <CardImg src={slide.image} alt={`Slide ${index}`} className="scroller-slide-img" />
              <div className="scroller-text">
                <div>
                  <span className="header-scroll-text">{slide.headerText}</span>
                </div>
                <div className="pt-3">
                  <span className="main-scroll-text">{slide.mainText}</span>
                </div>
                <div className="pt-3">
                  <span className="sub-scroll-text">{slide.subText}</span>
                </div>
              </div>
            </div>
          ))}
          <div className="scroller-slide">
            <CardImg src={slides[0].image} alt="Slide 0" className="scroller-slide-img" />
            <div className="scroller-text">
              <div>
                <span className="header-scroll-text">{slides[0].headerText}</span>
              </div>
              <div className="pt-3">
                <span className="main-scroll-text">{slides[0].mainText}</span>
              </div>
              <div className="pt-3">
                <span className="sub-scroll-text">{slides[0].subText}</span>
              </div>
            </div>
          </div>
        </animated.div>
      </div>
      <div className="scroller-dots">
        {slides.map((_, index) => (
          <Button
            key={`${index.toString()}`}
            className={`scroller-dot ${index === (currentIndex - 1) % totalSlides ? 'active padding-zero' : 'padding-zero'}`}
            onClick={() => changeSlide(index + 1)}
          />
        ))}
      </div>
    </div>
  );
};

export default Scroller;
