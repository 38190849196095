import { AddressPutRequest } from './all-users/update-address/update-address.service';
import {
  AddressPutService, AddressPutOptions, AddressPutResponse, AddressPutResults,
} from './all-users/update-address/update-address.service.types';
import { AddressPutTransform } from './all-users/update-address/update-address.transform';
import { UserStatusPutRequest } from './all-users/update-status/update-status.service';
import {
  UserStatusPutService, UserStatusPutOptions, UserStatusPutResponse, UserStatusPutResults,
} from './all-users/update-status/update-status.service.types';
import { UserStatusPutTransform } from './all-users/update-status/update-status.transform';
import { ListUserRequest } from './all-users/user-list/list-user.service';
import {
  ListUserService, ListUserOptions, ListUserResponse, ListUserResults,
} from './all-users/user-list/list-user.service.types';
import { ListUserTransform } from './all-users/user-list/list-user.transform';
import { ContactUsPostRequest } from './contact-us/contact-us.service';
import {
  ContactUsService, ContactUsOptions, ContactUsResponse, ContactUsResults,
} from './contact-us/contact-us.service.types';
import { ContactUsTransform } from './contact-us/contact-us.transform';
import { ForgotPasswordRequest } from './forgot-password/forgot-password.service';
import {
  ForgotPasswordService, ForgotPasswordOptions, ForgotPasswordResponse, ForgotPasswordResults,
} from './forgot-password/forgot-password.service.types';
import { ForgotPasswordTransform } from './forgot-password/forgot-password.transform';
import { GenTokenRequest } from './gen-token/gen-token.service';
import {
  GenTokenService, GenTokenOptions, GenTokenResponse, GenTokenResults,
} from './gen-token/gen-token.service.types';
import { GenTokenTransform } from './gen-token/gen-token.transform';
import { GetAWSFileUrlRequest } from './get-aws-files/get-aws-file.service';
import {
  GetAWSFileUrlService, GetAWSFileUrlOptions, GetAWSFileUrlResponse, GetAWSFileUrlResults,
} from './get-aws-files/get-aws-file.service.types';
import { GetAWSFileUrlTransform } from './get-aws-files/get-aws-file.transform';
import { GetOrderTxnByIdRequest } from './get-order-txn-by-id/get-order-txn-by-id.service';
import {
  GetOrderTxnByIdService, GetOrderTxnByIdOptions, GetOrderTxnByIdResponse, GetOrderTxnByIdResults,
} from './get-order-txn-by-id/get-order-txn-by-id.service.types';
import { GetOrderTxnByIdTransform } from './get-order-txn-by-id/get-order-txn-by-id.transform';
import { ProfileRequest } from './get-profile/get-profile.service';
import {
  ProfileService, ProfileOptions, ProfileResponse, ProfileResults,
} from './get-profile/get-profile.service.types';
import { ProfileTransform } from './get-profile/get-profile.transform';
import { PostDivisionRequest } from './meta-data/add-division/post-division.service';
import {
  PostDivisionService, PostDivisionOptions, PostDivisionResponse, PostDivisionResults,
} from './meta-data/add-division/post-division.service.types';
import { PostDivisionTransform } from './meta-data/add-division/post-division.transform';
import { ListCorpRequest } from './meta-data/list-corp/list-corp.service';
import {
  ListCorpService, ListCorpOptions, ListCorpResponse, ListCorpResults,
} from './meta-data/list-corp/list-corp.service.types';
import { ListCorpTransform } from './meta-data/list-corp/list-corp.transform';
import { ListDivisionRequest } from './meta-data/list-division/list-division.service';
import {
  ListDivisionService, ListDivisionOptions, ListDivisionResponse, ListDivisionResults,
} from './meta-data/list-division/list-division.service.types';
import { ListDivisionTransform } from './meta-data/list-division/list-division.transform';
import { ListStateRequest } from './meta-data/list-state/list-state.service';
import {
  ListStateService, ListStateOptions, ListStateResponse, ListStateResults,
} from './meta-data/list-state/list-state.service.types';
import { ListStateTransform } from './meta-data/list-state/list-state.transform';
import { PostAddKartRequest } from './products/add-kart/post-addkart.service';
import {
  PostAddKartService, PostAddKartOptions, PostAddKartResponse, PostAddKartResults,
} from './products/add-kart/post-addkart.service.types';
import { PostAddKartTransform } from './products/add-kart/post-addkart.transform';
import { PostProductRequest } from './products/add-products/post-product.service';
import {
  PostProductService, PostProductOptions, PostProductResponse, PostProductResults,
} from './products/add-products/post-product.service.types';
import { PostProductTransform } from './products/add-products/post-product.transform';
import { PostSpareRequest } from './products/add-spares/post-spare.service';
import {
  PostSpareService, PostSpareOptions, PostSpareResponse, PostSpareResults,
} from './products/add-spares/post-spare.service.types';
import { PostSpareTransform } from './products/add-spares/post-spare.transform';
import { DELKartProdRequest } from './products/del-kart/del-kart-prod.service';
import {
  DELKartProdService, DELKartProdOptions, DELKartProdResponse, DELKartProdResults,
} from './products/del-kart/del-kart-prod.service.types';
import { DELKartProdTransform } from './products/del-kart/del-kart-prod.transform';
import { DeleteProdImgRequest } from './products/delete-prod-image/delete-prodimage.service';
import {
  DeleteProdImgService, DeleteProdImgOptions, DeleteProdImgResponse, DeleteProdImgResults,
} from './products/delete-prod-image/delete-prodimage.service.types';
import { DeleteProdImgTransform } from './products/delete-prod-image/delete-prodimage.transform';
import { DELProdRequest } from './products/delete-product/del-api-data/del-prod.service';
import {
  DELProdService, DELProdOptions, DELProdResponse, DELProdResults,
} from './products/delete-product/del-api-data/del-prod.service.types';
import { DELProdTransform } from './products/delete-product/del-api-data/del-prod.transform';
import { DeleteAWSFolderRequest } from './products/delete-product/del-folder-aws-s3/delete-folder.service';
import {
  DeleteAWSFolderService, DeleteAWSFolderOptions, DeleteAWSFolderResponse, DeleteAWSFolderResults,
} from './products/delete-product/del-folder-aws-s3/delete-folder.service.types';
import { DeleteAWSFolderTransform } from './products/delete-product/del-folder-aws-s3/delete-folder.transform';
import { GetKartCountRequest } from './products/get-kart-count/get-kart-count.service';
import {
  GetKartCountService, GetKartCountOptions, GetKartCountResponse, GetKartCountResults,
} from './products/get-kart-count/get-kart-count.service.types';
import { GetKartCountTransform } from './products/get-kart-count/get-kart-count.transform';
import { GetListProdImgRequest } from './products/get-list-product-images/getList-prodimage.service';
import {
  GetListProdImgService, GetListProdImgOptions, GetListProdImgResponse, GetListProdImgResults,
} from './products/get-list-product-images/getList-prodimage.service.types';
import { GetListProdImgTransform } from './products/get-list-product-images/getList-prodimage.transform';
import { GetAllKartRequest } from './products/list-kart/get-all-kart.service';
import {
  GetAllKartService, GetAllKartOptions, GetAllKartResponse, GetAllKartResults,
} from './products/list-kart/get-all-kart.service.types';
import { GetAllKartTransform } from './products/list-kart/get-all-kart.transform';
import { GetAllMyOrdersRequest } from './products/list-my-orders/get-all-myorders.service';
import {
  GetAllMyOrdersService, GetAllMyOrdersOptions, GetAllMyOrdersResponse, GetAllMyOrdersResults,
} from './products/list-my-orders/get-all-myorders.service.types';
import { GetAllMyOrdersTransform } from './products/list-my-orders/get-all-myorders.transform';
import { GetAllProductRequest } from './products/list-products/get-all-product.service';
import {
  GetAllProductService, GetAllProductOptions, GetAllProductResponse, GetAllProductResults,
} from './products/list-products/get-all-product.service.types';
import { GetAllProductTransform } from './products/list-products/get-all-product.transform';
import { GetAllSellerProductRequest } from './products/list-seller-product/get-all-seller-product.service';
import {
  GetAllSellerProductService, GetAllSellerProductOptions, GetAllSellerProductResponse, GetAllSellerProductResults,
} from './products/list-seller-product/get-all-seller-product.service.types';
import { GetAllSellerProductTransform } from './products/list-seller-product/get-all-seller-product.transform';
import { GetAllOrdersRequest } from './products/power-orders/get-all-orders.service';
import {
  GetAllOrdersService, GetAllOrdersOptions, GetAllOrdersResponse, GetAllOrdersResults,
} from './products/power-orders/get-all-orders.service.types';
import { GetAllOrdersTransform } from './products/power-orders/get-all-orders.transform';
import { ProductDescRequest } from './products/prod-desc/product-desc.service';
import {
  ProductDescService, ProductDescOptions, ProductDescResponse, ProductDescResults,
} from './products/prod-desc/product-desc.service.types';
import { ProductDescTransform } from './products/prod-desc/product-desc.transform';
import { AckShipPutRequest } from './products/update-ack-ship/update-ack-ship.service';
import {
  AckShipPutService, AckShipPutOptions, AckShipPutResponse, AckShipPutResults,
} from './products/update-ack-ship/update-ack-ship.service.types';
import { AckShipPutTransform } from './products/update-ack-ship/update-ack-ship.transform';
import { KartCountPutRequest } from './products/update-kart-count/update-kart-count.service';
import {
  KartCountPutService, KartCountPutOptions, KartCountPutResponse, KartCountPutResults,
} from './products/update-kart-count/update-kart-count.service.types';
import { KartCountPutTransform } from './products/update-kart-count/update-kart-count.transform';
import { KartStatusPutRequest } from './products/update-kart-status/update-kart-status.service';
import {
  KartStatusPutService, KartStatusPutOptions, KartStatusPutResponse, KartStatusPutResults,
} from './products/update-kart-status/update-kart-status.service.types';
import { KartStatusPutTransform } from './products/update-kart-status/update-kart-status.transform';
import { KartTxnIdPutRequest } from './products/update-kart-txn-id/update-kart-txnid.service';
import {
  KartTxnIdPutService, KartTxnIdPutOptions, KartTxnIdPutResponse, KartTxnIdPutResults,
} from './products/update-kart-txn-id/update-kart-txnid.service.types';
import { KartTxnIdPutTransform } from './products/update-kart-txn-id/update-kart-txnid.transform';
import { PendOrdrStsPutRequest } from './products/update-pend-ordr/update-pend-order.service';
import {
  PendOrdrStsPutService, PendOrdrStsPutOptions, PendOrdrStsPutResponse, PendOrdrStsPutResults,
} from './products/update-pend-ordr/update-pend-order.service.types';
import { PendOrdrStsPutTransform } from './products/update-pend-ordr/update-pend-order.transform';
import { ProductPutRequest } from './products/update-products/update-product.service';
import {
  ProductPutService, ProductPutOptions, ProductPutResponse, ProductPutResults,
} from './products/update-products/update-product.service.types';
import { ProductPutTransform } from './products/update-products/update-product.transform';
import { CreateProdImgRequest } from './products/upload-prod-Image/create-prodimage.service';
import {
  CreateProdImgService, CreateProdImgOptions, CreateProdImgResponse, CreateProdImgResults,
} from './products/upload-prod-Image/create-prodimage.service.types';
import { CreateProdImgTransform } from './products/upload-prod-Image/create-prodimage.transform';
import { SellerRegisterPostRequest } from './register-seller/seller-register.service';
import {
  SellerRegisterService, SellerRegisterOptions, SellerRegisterResponse, SellerRegisterResults,
} from './register-seller/seller-register.service.types';
import { SellerRegisterTransform } from './register-seller/seller-register.transform';
import { RegisterPostRequest } from './register/register.service';
import {
  RegisterService, RegisterOptions, RegisterResponse, RegisterResults,
} from './register/register.service.types';
import { RegisterTransform } from './register/register.transform';
import { createService } from './service';
import { OEMPutRequest } from './update-oem-profile/update-oem.service';
import {
  OEMPutService, OEMPutOptions, OEMPutResponse, OEMPutResults,
} from './update-oem-profile/update-oem.service.types';
import { OEMPutTransform } from './update-oem-profile/update-oem.transform';
import { PassKeyPutRequest } from './update-passkey/update-passkey.service';
import {
  PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults, PassKeyPutService,
} from './update-passkey/update-passkey.service.types';
import { PassKeyPutTransform } from './update-passkey/update-passkey.transform';

const response = 'Data has been loaded successfully!';

export function loadData(): Promise<string> {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve(response);
      if (response === undefined) {
        reject();
      }
    }, 500);
  });
}
export const contactUsService: ContactUsService = createService<ContactUsOptions, ContactUsResponse, ContactUsResults>(ContactUsPostRequest, ContactUsTransform);
export const registerService: RegisterService = createService<RegisterOptions, RegisterResponse, RegisterResults>(RegisterPostRequest, RegisterTransform);
export const genTokenService: GenTokenService = createService<GenTokenOptions, GenTokenResponse, GenTokenResults>(GenTokenRequest, GenTokenTransform);
export const profileService: ProfileService = createService<ProfileOptions, ProfileResponse, ProfileResults>(ProfileRequest, ProfileTransform);
export const forgotPasswordService: ForgotPasswordService = createService<ForgotPasswordOptions, ForgotPasswordResponse, ForgotPasswordResults>(ForgotPasswordRequest, ForgotPasswordTransform);
export const createDivService: PostDivisionService = createService<PostDivisionOptions, PostDivisionResponse, PostDivisionResults>(PostDivisionRequest, PostDivisionTransform);
export const divListService: ListDivisionService = createService<ListDivisionOptions, ListDivisionResponse, ListDivisionResults>(ListDivisionRequest, ListDivisionTransform);
export const stateListService: ListStateService = createService<ListStateOptions, ListStateResponse, ListStateResults>(ListStateRequest, ListStateTransform);
export const passKeyService: PassKeyPutService = createService<PassKeyPutOptions, PassKeyPutResponse, PassKeyPutResults>(PassKeyPutRequest, PassKeyPutTransform);
export const userStatusService: UserStatusPutService = createService<UserStatusPutOptions, UserStatusPutResponse, UserStatusPutResults>(UserStatusPutRequest, UserStatusPutTransform);
export const userListService: ListUserService = createService<ListUserOptions, ListUserResponse, ListUserResults>(ListUserRequest, ListUserTransform);
export const createProductService: PostProductService = createService<PostProductOptions, PostProductResponse, PostProductResults>(PostProductRequest, PostProductTransform);
export const productListService: GetAllProductService = createService<GetAllProductOptions, GetAllProductResponse, GetAllProductResults>(GetAllProductRequest, GetAllProductTransform);
export const prodImgService: CreateProdImgService = createService<CreateProdImgOptions, CreateProdImgResponse, CreateProdImgResults>(CreateProdImgRequest, CreateProdImgTransform);
export const createAddKartService: PostAddKartService = createService<PostAddKartOptions, PostAddKartResponse, PostAddKartResults>(PostAddKartRequest, PostAddKartTransform);
export const kartListService: GetAllKartService = createService<GetAllKartOptions, GetAllKartResponse, GetAllKartResults>(GetAllKartRequest, GetAllKartTransform);
export const getKartCountService: GetKartCountService = createService<GetKartCountOptions, GetKartCountResponse, GetKartCountResults>(GetKartCountRequest, GetKartCountTransform);
export const updateKartCountService: KartCountPutService = createService<KartCountPutOptions, KartCountPutResponse, KartCountPutResults>(KartCountPutRequest, KartCountPutTransform);
export const delKartProdService: DELKartProdService = createService<DELKartProdOptions, DELKartProdResponse, DELKartProdResults>(DELKartProdRequest, DELKartProdTransform);
export const updateKartStatusService: KartStatusPutService = createService<KartStatusPutOptions, KartStatusPutResponse, KartStatusPutResults>(KartStatusPutRequest, KartStatusPutTransform);
export const updateKartTxnIdService: KartTxnIdPutService = createService<KartTxnIdPutOptions, KartTxnIdPutResponse, KartTxnIdPutResults>(KartTxnIdPutRequest, KartTxnIdPutTransform);
export const prodDescService: ProductDescService = createService<ProductDescOptions, ProductDescResponse, ProductDescResults>(ProductDescRequest, ProductDescTransform);
export const myOrderListService: GetAllMyOrdersService = createService<GetAllMyOrdersOptions, GetAllMyOrdersResponse, GetAllMyOrdersResults>(GetAllMyOrdersRequest, GetAllMyOrdersTransform);
export const createSpareService: PostSpareService = createService<PostSpareOptions, PostSpareResponse, PostSpareResults>(PostSpareRequest, PostSpareTransform);
export const putProductService: ProductPutService = createService<ProductPutOptions, ProductPutResponse, ProductPutResults>(ProductPutRequest, ProductPutTransform);
export const corpListService: ListCorpService = createService<ListCorpOptions, ListCorpResponse, ListCorpResults>(ListCorpRequest, ListCorpTransform);
export const sellerRegisterService: SellerRegisterService = createService<SellerRegisterOptions, SellerRegisterResponse, SellerRegisterResults>(SellerRegisterPostRequest, SellerRegisterTransform);
export const sellerProductListService: GetAllSellerProductService = createService<GetAllSellerProductOptions, GetAllSellerProductResponse, GetAllSellerProductResults>(GetAllSellerProductRequest, GetAllSellerProductTransform);
export const ordersListService: GetAllOrdersService = createService<GetAllOrdersOptions, GetAllOrdersResponse, GetAllOrdersResults>(GetAllOrdersRequest, GetAllOrdersTransform);
export const addressService: AddressPutService = createService<AddressPutOptions, AddressPutResponse, AddressPutResults>(AddressPutRequest, AddressPutTransform);
export const getAWSFileUrlService: GetAWSFileUrlService = createService<GetAWSFileUrlOptions, GetAWSFileUrlResponse, GetAWSFileUrlResults>(GetAWSFileUrlRequest, GetAWSFileUrlTransform);
export const putPendOrdrStsService: PendOrdrStsPutService = createService<PendOrdrStsPutOptions, PendOrdrStsPutResponse, PendOrdrStsPutResults>(PendOrdrStsPutRequest, PendOrdrStsPutTransform);
export const putAckShipService: AckShipPutService = createService<AckShipPutOptions, AckShipPutResponse, AckShipPutResults>(AckShipPutRequest, AckShipPutTransform);
export const ordersTxnByIdService: GetOrderTxnByIdService = createService<GetOrderTxnByIdOptions, GetOrderTxnByIdResponse, GetOrderTxnByIdResults>(GetOrderTxnByIdRequest, GetOrderTxnByIdTransform);
export const getListProdImgService: GetListProdImgService = createService<GetListProdImgOptions, GetListProdImgResponse, GetListProdImgResults>(GetListProdImgRequest, GetListProdImgTransform);
export const deleteProdImgService: DeleteProdImgService = createService<DeleteProdImgOptions, DeleteProdImgResponse, DeleteProdImgResults>(DeleteProdImgRequest, DeleteProdImgTransform);
export const oemUpdateService: OEMPutService = createService<OEMPutOptions, OEMPutResponse, OEMPutResults>(OEMPutRequest, OEMPutTransform);
export const delProdService: DELProdService = createService<DELProdOptions, DELProdResponse, DELProdResults>(DELProdRequest, DELProdTransform);
export const deleteAWSFolderService: DeleteAWSFolderService = createService<DeleteAWSFolderOptions, DeleteAWSFolderResponse, DeleteAWSFolderResults>(DeleteAWSFolderRequest, DeleteAWSFolderTransform);
