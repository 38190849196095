import { AddressPutResponse, AddressPutResults } from './update-address.service.types';

export const AddressPutTransform: (response: AddressPutResponse) => AddressPutResults = (passKeyResults) => {
  const { is_error, message } = passKeyResults;
  const result :AddressPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
  }
  return result;
};
