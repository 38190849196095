import { CreateProdImgResponse, CreateProdImgResults } from './create-prodimage.service.types';

export const CreateProdImgTransform: (response: CreateProdImgResponse) => CreateProdImgResults = (createProdImgDBResults) => {
  const { data } = createProdImgDBResults;
  const result: CreateProdImgResults = {
    fileName: '',
  };
  result.fileName = data;
  return result;
};
