import { ActionType, getType, Reducer } from 'typesafe-actions';
import {
  clearAddressReq,
  clearContactUsPostReq, clearCorpListReq, clearCreateAddKartReq, clearCreateDivisionReq, clearCreateProductReq, clearCreateSpareReq, clearDelKartProdReq, clearDivisionListReq, clearGetAWSFileUrlReq, clearGetListProdImg, clearKartCountPUTReq, clearKartCountReq, clearKartListReq, clearKartStatusPUTReq, clearKartTxnIdPUTReq, clearLoginTokenReq, clearMyOrdersReq, clearOrdersListReq, clearOrderTxnByIdReq, clearPassKeyReq, clearUploadProdImg, clearProdDescReq, clearProductsListReq, clearProfileReq, clearPutAckShipReq, clearPutPendOrdrAdmReq, clearPutProductReq, clearRegisterPostReq, clearSellerProductsListReq, clearSellerRegisterPostReq, clearStateListReq, clearUserListReq, clearUserStatusReq, updateAddressReq, updateContactUsPostReq, updateCorpListReq, updateCreateAddKartReq, updateCreateDivisionReq, updateCreateProductReq, updateCreateSpareReq, updateData, updateDelKartProdReq, updateDivisionListReq, updateForgotPassword, updateGetAWSFileUrlReq, updateKartCountPUTReq, updateKartCountReq, updateKartListReq, updateKartStatusPUTReq, updateKartTxnIdPUTReq, updateMyOrdersReq, updateOrdersListReq, updateOrderTxnByIdReq, updatePassKeyReq, updateProdDescReq, updateProductsListReq, updateProfile, updatePutAckShipReq, updatePutPendOrdrAdmReq, updatePutProductReq, updateRegisterPostReq, updateSellerProductsListReq, updateSellerRegisterPostReq, updateStateListReq, updateToken, updateUploadProdImg, updateUserListReq, updateUserStatusReq,
  updateDeleteProdImg,
  clearDeleteProdImg,
  updateOEMReq,
  clearOEMReq,
  updateDelProdReq,
  clearDelProdReq,
  updateDeleteAWSFolder,
  clearDeleteAWSFolder,
} from './actions';
import * as actions from './actions';
import { PowerMartAppState } from './action.types';

export const initialState: PowerMartAppState = {
  data: {
    show: '',
  },
  contactUs: {
    message: '',
    errorMessage: '',
    error: false,
  },
  registerUser: {
    message: '',
    data: '',
    error: false,
  },
  genToken: {
    token: '',
    message: '',
    isError: false,
  },
  profileData: {
    isError: false,
    message: '',
    profileData: {
      nme: '',
      ph: '',
      st: '',
      UserCd: '',
      UserTp: '',
      isPKReset: false,
      lgnSts: '',
      eId: '',
      avatar: '',
      corpData: {
        nme: '',
        logo: '',
      },
      oem: [],
      discom: [{
        discomTp: '',
        discomUtil: '',
        st: '',
      }],
      div: '',
      subDiv: '',
    },
  },
  forgotPassKeyData: {
    forgotPasswordData: '',
    message: '',
    isError: false,
  },
  getDivMetaList: {
    tCnt: 0,
    fCnt: 0,
    divData: [],
    isError: false,
    message: '',
  },
  getStateMetaList: {
    tCnt: 0,
    fCnt: 0,
    stData: [],
    isError: false,
    message: '',
  },
  updatePassKey: {
    message: '',
    error: false,
  },
  getUserList: {
    tCnt: 0,
    fCnt: 0,
    userData: [],
    isError: false,
    message: '',
  },
  updateUserStatus: {
    message: '',
    error: false,
    data: '',
  },
  createDivisions: {
    message: '',
    error: false,
    data: '',
  },
  createNewProducts: {
    message: '',
    error: false,
    data: '',
    prodId: '',
  },
  getProductList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      prodData: [],
      lastEvalKey: {
        ProdTp: '',
        ProdId: '',
      },
    },
    isError: false,
    message: '',
  },
  createNewKart: {
    message: '',
    error: false,
    data: '',
    prodId: '',
  },
  getKartList: {
    data: {
      fCnt: 0,
      addr: '',
      ordId: '',
      mSts: '',
      sumCost: '',
      sumGst: '',
      sumTotal: '',
      prodTxnData: [],
    },
    isError: false,
    message: '',
  },
  uploadProductsAWS: {
    fileName: '',
  },
  getKartCount: {
    message: '',
    isError: false,
    data: {
      itmCnt: 0,
      mSts: '',
    },
  },
  updateKartCount: {
    message: '',
    error: false,
  },
  delKartProd: {
    message: '',
    error: false,
  },
  updateKartStatus: {
    message: '',
    error: false,
  },
  updateKartTxnId: {
    message: '',
    error: false,
  },
  prodDesc: {
    message: '',
    isError: false,
    data: {
      prodDesc: [],
      techSpec: [],
    },
  },
  getMyOrdersList: {
    data: [{
      addr: '',
      fCnt: 0,
      ordId: '',
      ordDt: '',
      prodTxnData: [{
        ActId: '',
        ActTp: '',
        gst: '',
        prodCnt: '',
        prodCost: '',
        prodInfo: {
          cost: '',
          gst: '',
          imgUrl: '',
          nme: '',
        },
        mSts: '',
        sSts: '',
        tCost: '',
        rsn: '',
        shipDt: '',
        courNme: '',
        trckNo: '',
        trckUrl: '',
      }],
      sumCost: '',
      sumGst: '',
      sumTotal: '',
    }],
    isError: false,
    message: '',
  },
  createNewSpare: {
    message: '',
    error: false,
    data: '',
    prodId: '',
  },
  updateProduct: {
    message: '',
    error: false,
  },
  getCorpMetaList: {
    tCnt: 0,
    fCnt: 0,
    corpData: [],
    isError: false,
    message: '',
  },
  registerSeller: {
    message: '',
    data: '',
    error: false,
  },
  getSellerProductList: {
    data: {
      tCnt: 0,
      fCnt: 0,
      prodData: [],
      lastEvalKey: {
        ProdTp: '',
        ProdId: '',
      },
    },
    isError: false,
    message: '',
  },
  getOrdersList: {
    data: [{
      fCnt: 0,
      ordId: '',
      usrOrg: '',
      usrInfo: {
        addr: '',
        div: '',
        eId: '',
        nme: '',
        ph: '',
        st: '',
        subDiv: '',
      },
      txnId: '',
      txnDt: '',
      ordDt: '',
      mSts: '',
      sumCost: '',
      sumGst: '',
      sumTotal: '',
      prodTxnData: [],
    }],
    isError: false,
    message: '',
  },
  updateAddress: {
    message: '',
    error: false,
  },
  getAWSFileUrl: {
    fileUrl: '',
  },
  updatePendOrdrByAdm: {
    message: '',
    error: false,
  },
  updateAckShip: {
    message: '',
    error: false,
  },
  getOrderTxnById: {
    data: {
      fCnt: 0,
      usrOrg: '',
      usrInfo: {
        addr: '',
        div: '',
        eId: '',
        nme: '',
        ph: '',
        st: '',
        subDiv: '',
      },
      subSts: '',
      rsn: '',
      mSts: '',
      txnId: '',
      txnDt: '',
      ordDt: '',
      poUrl: '',
      sumCost: '',
      sumGst: '',
      sumTotal: '',
      prodTxnData: [],
      ordId: '',
    },
    isError: false,
    message: '',
  },
  getListProductsImageAWS: {
    data: [],
  },
  deleteProductsAWS: {
    fileName: '',
  },
  updateOEM: {
    message: '',
    error: false,
  },
  delProd: {
    message: '',
    error: false,
  },
  deleteAWSFolder: {
    folderName: '',
  },
};
export type AllAction = ActionType<typeof actions>;
const powerMartReducer: Reducer<PowerMartAppState, AllAction> = (state: PowerMartAppState = initialState, action: AllAction): PowerMartAppState => {
  switch (action.type) {
    case getType(updateData):
      return {
        ...state,
        data: {
          show: action.payload,
        },
      };
    case getType(updateContactUsPostReq):
      return {
        ...state,
        contactUs: {
          message: action.payload.message,
          errorMessage: action.payload.errorMessage,
          error: action.payload.error,
        },
      };
    case getType(clearContactUsPostReq):
      return {
        ...state,
        contactUs: initialState.contactUs,
      };
    case getType(updateRegisterPostReq):
      return {
        ...state,
        registerUser: {
          message: action.payload.message,
          data: action.payload.data,
          error: action.payload.error,
        },
      };
    case getType(clearRegisterPostReq):
      return {
        ...state,
        registerUser: initialState.registerUser,
      };
    case getType(updateToken):
      return {
        ...state,
        genToken: {
          token: action.payload.token,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearLoginTokenReq):
      return {
        ...state,
        genToken: initialState.genToken,
      };
    case getType(updateProfile):
      return {
        ...state,
        profileData: {
          isError: action.payload.isError,
          message: action.payload.message,
          profileData: {
            nme: action.payload.profileData.nme,
            ph: action.payload.profileData.ph,
            eId: action.payload.profileData.eId,
            st: action.payload.profileData.st,
            UserCd: action.payload.profileData.UserCd,
            UserTp: action.payload.profileData.UserTp,
            div: action.payload.profileData.div,
            subDiv: action.payload.profileData.subDiv,
            isPKReset: action.payload.profileData.isPKReset,
            lgnSts: action.payload.profileData.lgnSts,
            discom: action.payload.profileData.discom,
            corpData: action.payload.profileData.corpData,
            oem: action.payload.profileData.oem,
            avatar: action.payload.profileData.avatar,
          },
        },
      };
    case getType(clearProfileReq):
      return {
        ...state,
        profileData: initialState.profileData,
      };
    case getType(updateForgotPassword):
      return {
        ...state,
        forgotPassKeyData: {
          forgotPasswordData: action.payload.forgotPasswordData,
          message: action.payload.message,
          isError: action.payload.isError,
        },
      };
    case getType(updateDivisionListReq):
      return {
        ...state,
        getDivMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          divData: action.payload.divData,
          message: action.payload.message,
        },
      };
    case getType(clearDivisionListReq):
      return {
        ...state,
        getDivMetaList: initialState.getDivMetaList,
      };
    case getType(updateStateListReq):
      return {
        ...state,
        getStateMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          stData: action.payload.stData,
          message: action.payload.message,
        },
      };
    case getType(clearStateListReq):
      return {
        ...state,
        getStateMetaList: initialState.getStateMetaList,
      };
    case getType(updatePassKeyReq):
      return {
        ...state,
        updatePassKey: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPassKeyReq):
      return {
        ...state,
        updatePassKey: initialState.updatePassKey,
      };
    case getType(updateUserListReq):
      return {
        ...state,
        getUserList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          userData: action.payload.userData,
          message: action.payload.message,
        },
      };
    case getType(clearUserListReq):
      return {
        ...state,
        getUserList: initialState.getUserList,
      };
    case getType(updateUserStatusReq):
      return {
        ...state,
        updateUserStatus: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearUserStatusReq):
      return {
        ...state,
        updateUserStatus: initialState.updateUserStatus,
      };
    case getType(updateCreateDivisionReq):
      return {
        ...state,
        createDivisions: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
        },
      };
    case getType(clearCreateDivisionReq):
      return {
        ...state,
        createDivisions: initialState.createDivisions,
      };
    case getType(updateCreateProductReq):
      return {
        ...state,
        createNewProducts: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
          prodId: action.payload.prodId,
        },
      };
    case getType(clearCreateProductReq):
      return {
        ...state,
        createNewProducts: initialState.createNewProducts,
      };
    case getType(updateProductsListReq):
      return {
        ...state,
        getProductList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearProductsListReq):
      return {
        ...state,
        getProductList: initialState.getProductList,
      };
    case getType(updateUploadProdImg):
      return {
        ...state,
        uploadProductsAWS: {
          fileName: action.payload.fileName,
        },
      };
    case getType(clearUploadProdImg):
      return {
        ...state,
        uploadProductsAWS: initialState.uploadProductsAWS,
      };
    case getType(updateCreateAddKartReq):
      return {
        ...state,
        createNewKart: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
          prodId: action.payload.prodId,
        },
      };
    case getType(clearCreateAddKartReq):
      return {
        ...state,
        createNewKart: initialState.createNewKart,
      };
    case getType(updateKartListReq):
      return {
        ...state,
        getKartList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearKartListReq):
      return {
        ...state,
        getKartList: initialState.getKartList,
      };
    case getType(updateKartCountReq):
      return {
        ...state,
        getKartCount: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearKartCountReq):
      return {
        ...state,
        getKartCount: initialState.getKartCount,
      };
    case getType(updateKartCountPUTReq):
      return {
        ...state,
        updateKartCount: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearKartCountPUTReq):
      return {
        ...state,
        updateKartCount: initialState.updateKartCount,
      };
    case getType(updateDelKartProdReq):
      return {
        ...state,
        delKartProd: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearDelKartProdReq):
      return {
        ...state,
        delKartProd: initialState.delKartProd,
      };
    case getType(updateKartStatusPUTReq):
      return {
        ...state,
        updateKartStatus: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearKartStatusPUTReq):
      return {
        ...state,
        updateKartStatus: initialState.updateKartStatus,
      };
    case getType(updateKartTxnIdPUTReq):
      return {
        ...state,
        updateKartTxnId: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearKartTxnIdPUTReq):
      return {
        ...state,
        updateKartTxnId: initialState.updateKartTxnId,
      };
    case getType(updateProdDescReq):
      return {
        ...state,
        prodDesc: {
          data: action.payload.data,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearProdDescReq):
      return {
        ...state,
        prodDesc: initialState.prodDesc,
      };
    case getType(updateMyOrdersReq):
      return {
        ...state,
        getMyOrdersList: {
          data: action.payload.data,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearMyOrdersReq):
      return {
        ...state,
        getMyOrdersList: initialState.getMyOrdersList,
      };
    case getType(updateCreateSpareReq):
      return {
        ...state,
        createNewSpare: {
          message: action.payload.message,
          error: action.payload.error,
          data: action.payload.data,
          prodId: action.payload.prodId,
        },
      };
    case getType(clearCreateSpareReq):
      return {
        ...state,
        createNewSpare: initialState.createNewSpare,
      };
    case getType(updatePutProductReq):
      return {
        ...state,
        updateProduct: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPutProductReq):
      return {
        ...state,
        updateProduct: initialState.updateProduct,
      };
    case getType(updateCorpListReq):
      return {
        ...state,
        getCorpMetaList: {
          isError: action.payload.isError,
          tCnt: action.payload.tCnt,
          fCnt: action.payload.fCnt,
          corpData: action.payload.corpData,
          message: action.payload.message,
        },
      };
    case getType(clearCorpListReq):
      return {
        ...state,
        getCorpMetaList: initialState.getCorpMetaList,
      };
    case getType(updateSellerRegisterPostReq):
      return {
        ...state,
        registerSeller: {
          message: action.payload.message,
          data: action.payload.data,
          error: action.payload.error,
        },
      };
    case getType(clearSellerRegisterPostReq):
      return {
        ...state,
        registerSeller: initialState.registerSeller,
      };
    case getType(updateSellerProductsListReq):
      return {
        ...state,
        getSellerProductList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearSellerProductsListReq):
      return {
        ...state,
        getSellerProductList: initialState.getSellerProductList,
      };
    case getType(updateOrdersListReq):
      return {
        ...state,
        getOrdersList: {
          isError: action.payload.isError,
          data: action.payload.data,
          message: action.payload.message,
        },
      };
    case getType(clearOrdersListReq):
      return {
        ...state,
        getOrdersList: initialState.getOrdersList,
      };
    case getType(updateAddressReq):
      return {
        ...state,
        updateAddress: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearAddressReq):
      return {
        ...state,
        updateAddress: initialState.updateAddress,
      };
    case getType(updateGetAWSFileUrlReq):
      return {
        ...state,
        getAWSFileUrl: {
          fileUrl: action.payload.fileUrl,
        },
      };
    case getType(clearGetAWSFileUrlReq):
      return {
        ...state,
        getAWSFileUrl: initialState.getAWSFileUrl,
      };
    case getType(updatePutPendOrdrAdmReq):
      return {
        ...state,
        updatePendOrdrByAdm: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPutPendOrdrAdmReq):
      return {
        ...state,
        updatePendOrdrByAdm: initialState.updatePendOrdrByAdm,
      };
    case getType(updatePutAckShipReq):
      return {
        ...state,
        updateAckShip: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearPutAckShipReq):
      return {
        ...state,
        updateAckShip: initialState.updateAckShip,
      };
    case getType(updateOrderTxnByIdReq):
      return {
        ...state,
        getOrderTxnById: {
          data: action.payload.data,
          isError: action.payload.isError,
          message: action.payload.message,
        },
      };
    case getType(clearOrderTxnByIdReq):
      return {
        ...state,
        getOrderTxnById: initialState.getOrderTxnById,
      };
    case getType(actions.updateGetListProdImg):
      return {
        ...state,
        getListProductsImageAWS: {
          data: action.payload.data,
        },
      };
    case getType(clearGetListProdImg):
      return {
        ...state,
        getListProductsImageAWS: initialState.getListProductsImageAWS,
      };
    case getType(updateDeleteProdImg):
      return {
        ...state,
        deleteProductsAWS: {
          fileName: action.payload.fileName,
        },
      };
    case getType(clearDeleteProdImg):
      return {
        ...state,
        deleteProductsAWS: initialState.deleteProductsAWS,
      };
    case getType(updateOEMReq):
      return {
        ...state,
        updateOEM: {
          message: action.payload.message,
          error: action.payload.error,
        },
      };
    case getType(clearOEMReq):
      return {
        ...state,
        updateOEM: initialState.updateOEM,
      };
    case getType(updateDelProdReq):
      return {
        ...state,
        delProd: {
          error: action.payload.error,
          message: action.payload.message,
        },
      };
    case getType(clearDelProdReq):
      return {
        ...state,
        delProd: initialState.delProd,
      };
    case getType(updateDeleteAWSFolder):
      return {
        ...state,
        deleteAWSFolder: {
          folderName: action.payload.folderName,
        },
      };
    case getType(clearDeleteAWSFolder):
      return {
        ...state,
        deleteAWSFolder: initialState.deleteAWSFolder,
      };
    default:
      return state;
  }
};
export default powerMartReducer;
