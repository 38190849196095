import React from 'react';
import { faUserPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { PUserLogin } from './login';
import RegisterUser from './signup';

export const LoginSignupComponent: React.FC = () => {
  const [activeTab, setActiveTab] = React.useState('login');

  return (
    <div className="label-margin-to-nav login-bg">
      <Row className="align-center">
        <Col xs="11" lg="5" className="pt-3">
          <Card className="card-lite min-login-height1 login-card-layout">
            <Nav tabs className="metadata-sadmin-profile-tab align-center my-3">
              <div className="metadata-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'login'} onClick={() => setActiveTab('login')}>
                    <FontAwesomeIcon icon={faUserCircle} className="metadata-sadmin-tab-profile-icon" />
                    <span>Login</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="metadata-sadmin-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="metadata-sadmin-profile-nav-tabs" active={activeTab === 'signup'} onClick={() => setActiveTab('signup')}>
                    <FontAwesomeIcon icon={faUserPlus} className="metadata-sadmin-tab-profile-icon" />
                    <span className="metadata-sadmin-tab-text">Signup</span>
                  </NavLink>
                </NavItem>
              </div>
            </Nav>
            <TabContent activeTab={activeTab}>
              {activeTab === 'login' && (
              <TabPane tabId="login">
                <PUserLogin />
              </TabPane>
              )}
              {activeTab === 'signup' && (
              <TabPane tabId="signup">
                <RegisterUser />
              </TabPane>
              )}
            </TabContent>
          </Card>
        </Col>
      </Row>
    </div>
  );
};

export default LoginSignupComponent;
