import React, { ReactElement } from 'react';
import { Route, Routes } from 'react-router-dom';
import LoginPowerMartComponent from '../../scenes/public/login.component';
import SuperAdminComponent from '../../scenes/exclusive/Super-Admin/super-admin.component';
import { CXUnauthorized } from '../../scenes/public/unauthorized/unauthorized.component';
import PUserComponent from '../../scenes/exclusive/Power-User/puser.component';
import { SellerLoginPowerMartComponent } from '../../scenes/public/seler-login.component';
import { SellerComponent } from '../../scenes/exclusive/Sellers/seller.component';
import { SupportComponent } from '../../scenes/public/support.component';
import PublicPowerMartComponent from '../../scenes/public/powerMart.component';
import { AllPowermartProductComponent } from '../../scenes/public/all-powermart.components';

const AppBase: React.FC = (): ReactElement => (
  <Routes>
    <Route path="/unathorized" element={<CXUnauthorized />} />
    <Route path="/" element={<PublicPowerMartComponent />} />
    <Route path="/all-products" element={<AllPowermartProductComponent />} />
    <Route path="/power-support" element={<SupportComponent />} />
    <Route path="/login/*" element={<LoginPowerMartComponent />} />
    <Route path="/super-admin/*" element={<SuperAdminComponent />} />
    <Route path="/puser/*" element={<PUserComponent />} />
    <Route path="/seller-login/*" element={<SellerLoginPowerMartComponent />} />
    <Route path="/seller/*" element={<SellerComponent />} />
  </Routes>
);

export default AppBase;
