import { PostDivisionResponse, PostDivisionResults } from './post-division.service.types';

export const PostDivisionTransform: (response: PostDivisionResponse) => PostDivisionResults = (createPostResults) => {
  const { data, is_error, message } = createPostResults;
  const result: PostDivisionResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
