import { GetAllKartResponse, GetAllKartResults } from './get-all-kart.service.types';

export const GetAllKartTransform: (response: GetAllKartResponse) => GetAllKartResults = (getAllKartResults) => {
  const { data, is_error, message } = getAllKartResults;
  const result: GetAllKartResults = {
    data: {
      fCnt: 0,
      addr: '',
      mSts: '',
      ordId: '',
      sumCost: '',
      sumGst: '',
      sumTotal: '',
      prodTxnData: [],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
