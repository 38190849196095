import React from 'react';
import '../cart.scss';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Card, CardBody, CardImg,
  Button,
  Input,
  Spinner,
  InputGroup,
  // InputGroupText,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import {
  faEdit,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { getConfig } from '../../../../../utils/config/config';
import { LoaderData } from '../../../../../utils/loader';
import {
  addressReq,
  clearAddressReq,
  // clearDelKartProdReq,
  clearKartCountPUTReq, clearKartCountReq, clearKartStatusPUTReq, clearUploadProdImg, getKartCountReq, getKartListReq, kartCountPUTReq,
  kartStatusPUTReq,
  uploadProdImg,
} from '../../../../../store/powerMart/actions';
// import { errorAlert } from '../../../../utils/alert';
import { APIHeader } from '../../../../../utils/constants';
import {
  getDiscomUtilSessionStorage,
  getTokenFromSessionStorage, getUserProfileSessionStorage,
} from '../../../../../utils/service/localstorage-service';
import { ProdImgFileInput } from '../../../../../services/products/upload-prod-Image/create-prodimage.types';
import { ProductProps } from '../cart.type';
import { errorAlert } from '../../../../../utils/alert';
import { AddressPutDataInput } from '../../../../../services/all-users/update-address/update-address.types';
// import Tooltip from '../../../../../utils/Tooltip/Tooltip';
// import Modal from '../../../../../utils/Modal/Modal';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const CartPrivateList: React.FC<ProductProps> = ({ productsData, setProductsData, setActiveTab }) => {
  const dispatch = useDispatch();
  // const history = useNavigate();
  const discomUtil = getDiscomUtilSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [discomUtility, setDiscomUtil] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [loaderProduct, setLoaderProduct] = React.useState(false);
  const [loaderAddress, setLoaderAddress] = React.useState(false);
  const [confirmAddress, setConfirmAddress] = React.useState(false);
  // const [modalDelProd, setModalDelProd] = React.useState<Record<string, boolean>>({});
  // const addKartResponse = useSelector((state: RootState) => state.powerMart.createNewKart);
  const listKartProducts = useSelector((state: RootState) => state.powerMart.getKartList);
  const updateKartRes = useSelector((state: RootState) => state.powerMart.updateKartCount);
  // const delKartProdRes = useSelector((state: RootState) => state.powerMart.delKartProd);
  const updateKartStatusRes = useSelector((state: RootState) => state.powerMart.updateKartStatus);
  const updateUserAddressRes = useSelector((state: RootState) => state.powerMart.updateAddress);
  const uploadroductAwsResponse = useSelector((state: RootState) => state.powerMart.uploadProductsAWS);
  const [poFileName, setPOFileName] = React.useState('');
  const [purchaseOrderFile, setPurchaseOrderFile] = React.useState<ProdImgFileInput>({
    fileData: new FormData(),
  });

  const [updateAddress, setUpdateAddress] = React.useState<AddressPutDataInput>({
    addr: '',
  });

  React.useEffect(() => {
    if (discomUtil) {
      setDiscomUtil(discomUtil);
    }
  }, [discomUtil]);

  React.useEffect(() => {
    if (productsData.addr) {
      setUpdateAddress({ ...updateAddress, addr: productsData.addr });
    }
  }, [productsData.addr]);

  React.useEffect(() => {
    if (listKartProducts.isError && listKartProducts.message !== '') {
      setLoaderProduct(false);
      setAlertMsg(listKartProducts.message);
      setErrorShowAlert(true);
    }
  }, [listKartProducts.isError, listKartProducts.message]);

  React.useEffect(() => {
    if (!listKartProducts.isError && listKartProducts.message === 'executed') {
      setLoaderProduct(false);
      setProductsData(listKartProducts.data);
    }
  }, [listKartProducts.isError, listKartProducts.message, listKartProducts.data]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
      setUserId(userInitTp && JSON.parse(userInitTp).profileData.UserCd);
    }
  }, [
    userInitTp && JSON.parse(userInitTp).profileData.UserTp,
  ]);

  const handleChangeKartCount = (e: React.ChangeEvent<HTMLInputElement>, index: number, actType: string, activityId: string) => {
    const { value } = e.target;
    const cntValue = value === '' ? 0 : parseInt(value);
    if (cntValue > 0) {
      setProductsData((prevState) => {
        const updatedProdTxnData = [...prevState.prodTxnData];
        updatedProdTxnData[index].prodCnt = cntValue;
        return { ...prevState, prodTxnData: updatedProdTxnData };
      });
      dispatch(kartCountPUTReq({
        requestType: APIHeader.REQ_UPDATE_KART_PROD_CNT,
        userType: userTp,
        token: tokenData,
        cnt: cntValue.toString(),
        ActTp: actType,
        ActId: activityId,
      }));
    } else {
      setProductsData((prevState) => {
        const updatedProdTxnData = [...prevState.prodTxnData];
        updatedProdTxnData[index].prodCnt = 0;
        return { ...prevState, prodTxnData: updatedProdTxnData };
      });
    }
  };

  // const handleUpdateKartCount = (actType: string, activityId: string, cntValue: number) => {
  //   if (cntValue > 0) {
  //     setLoaderProduct(true);
  //     dispatch(kartCountPUTReq({
  //       requestType: APIHeader.REQ_UPDATE_KART_PROD_CNT,
  //       userType: userTp,
  //       token: tokenData,
  //       cnt: cntValue.toString(),
  //       ActTp: actType,
  //       ActId: activityId,
  //     }));
  //   } else {
  //     setAlertMsg('Quantity should not be 0');
  //     setErrorShowAlert(true);
  //   }
  // };

  // const handleUpdateKartCount = (activityType:string, activityCode:string, index: number) => {
  //   Promise.resolve(
  //     dispatch(kartCountPUTReq({
  //       requestType: APIHeader.REQ_UPDATE_KART_PROD_CNT,
  //       userType: userTp,
  //       token: tokenData,
  //       cnt: productsData.prodTxnData.toString(),
  //       ActTp: activityType,
  //       ActId: activityCode,
  //     })),
  //   );
  // };

  React.useEffect(() => {
    if (updateKartRes.error && updateKartRes.message !== '') {
      setLoaderProduct(false);
      dispatch(clearKartCountPUTReq());
    }
  }, [updateKartRes.error, updateKartRes.message]);

  React.useEffect(() => {
    if (!updateKartRes.error && updateKartRes.message !== '') {
      setLoaderProduct(false);
      dispatch(clearKartCountPUTReq());
      dispatch(getKartListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_IN_CART,
        status: productsData.mSts,
        discTp: discomUtility,
        // discomId: discomUtility,
      }));
    }
  }, [updateKartRes.error, updateKartRes.message]);

  // const handlePreDelKartProd = (target: string) => {
  //   setModalDelProd((prevState) => ({
  //     ...prevState,
  //     [target]: !prevState[target],
  //   }));
  // };

  // const handlePostDelKartProd = (status: string, target: string, activityType:string, activityId:string) => {
  //   if (status === 'approve') {
  //     setLoaderProduct(true);
  //     dispatch(clearKartCountReq());
  //     Promise.resolve(
  //       dispatch(delKartProdReq({
  //         requestType: APIHeader.REQ_DELETE_KART_PROD,
  //         userType: userTp,
  //         token: tokenData,
  //         ActTp: activityType,
  //         ActId: activityId,
  //       })),
  //     );
  //   } else if (status === 'cancel') {
  //     setModalDelProd((prevState) => ({
  //       ...prevState,
  //       [target]: false,
  //     }));
  //   }
  //   setModalDelProd((prevState) => ({
  //     ...prevState,
  //     [target]: false,
  //   }));
  // };

  // React.useEffect(() => {
  //   if (delKartProdRes.error && delKartProdRes.message !== '') {
  //     setLoaderProduct(false);
  //     dispatch(clearDelKartProdReq());
  //   }
  // }, [delKartProdRes.error, delKartProdRes.message]);

  // React.useEffect(() => {
  //   if (!delKartProdRes.error && delKartProdRes.message === 'executed') {
  //     setLoaderProduct(false);
  //     dispatch(clearDelKartProdReq());
  //     dispatch(getKartListReq({
  //       userType: userTp,
  //       token: tokenData,
  //       requestType: APIHeader.REQ_GET_ALL_PROD_TXN_IN_CART,
  //       status: productsData.mSts,
  //       discTp: discomUtility,
  //       // discomId: discomUtility,
  //     }));
  //     dispatch(getKartCountReq({
  //       userType: userTp,
  //       token: tokenData,
  //       requestType: APIHeader.REQ_GET_KART_COUNT,
  //       UserTp: userTp,
  //       UserCd: userId,
  //       status: productsData.mSts,
  //       discTp: discomUtility,
  //       // discomId: discomUtility,
  //     }));
  //   }
  // }, [delKartProdRes.error, delKartProdRes.message]);

  React.useEffect(() => {
    setPurchaseOrderFile(purchaseOrderFile);
  }, [purchaseOrderFile.fileData]);

  const handlePOFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const file = files[0];
    const maxSizeInBytes = 2 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      setAlertMsg('File size exceeds the limit of 2 MB.');
      setErrorShowAlert(true);
      return;
    }
    const formData = new FormData();
    formData.append(file.name, file);
    setPOFileName(file.name);
    setPurchaseOrderFile({ ...purchaseOrderFile, fileData: formData });
    dispatch(clearUploadProdImg());
  };

  const handleCheckoutSubmit = (orderId: string) => {
    setLoaderProduct(true);
    dispatch(clearKartCountReq());
    Promise.resolve(
      dispatch(kartStatusPUTReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_KART_MAIN_STATUS,
        mSts: 'RBUY',
        ordId: orderId,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (updateKartStatusRes.error && updateKartStatusRes.message !== '') {
      setLoaderProduct(false);
      setAlertMsg('There is already a product in the REVIEW stage. Once the prior order concludes, you can proceed with this transaction.');
      setErrorShowAlert(true);
      dispatch(clearKartStatusPUTReq());
    }
  }, [updateKartStatusRes.error, updateKartStatusRes.message]);

  React.useEffect(() => {
    if (!updateKartStatusRes.error && updateKartStatusRes.message === 'executed') {
      dispatch(clearKartStatusPUTReq());
      dispatch(uploadProdImg({
        bucketNme: AWS_PRODUCT_BUCKET,
        folderName: `p-orders/${productsData.ordId}`,
        fileNmeInit: `po-${productsData.ordId}`,
        formData: purchaseOrderFile.fileData,
        requestType: '',
        isEdit: false,
      }));
    }
  }, [updateKartStatusRes.error, updateKartStatusRes.message, purchaseOrderFile.fileData]);
  // productsData.ActTp, productsData.ActId,

  React.useEffect(() => {
    const pattern = new RegExp(`^p-orders/${productsData.ordId}/po-${productsData.ordId}_.+\\.pdf$`);
    if (pattern.test(uploadroductAwsResponse.fileName) && userTp !== '') {
      setLoaderProduct(false);
      dispatch(getKartCountReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_KART_COUNT,
        UserTp: userTp,
        UserCd: userId,
        status: productsData.mSts,
        discTp: discomUtility,
        // discomId: discomUtility,
      }));
      dispatch(clearUploadProdImg());
      setActiveTab('inReview');
      // dispatch(getKartListReq({
      //   userType: userTp,
      //   token: tokenData,
      //   requestType: APIHeader.REQ_GET_ALL_PROD_TXN_IN_CART,
      //   status: productsData.mSts,
      //   discTp: discomUtility,
      //   // discomId: discomUtility,
      // }));
    }
  }, [uploadroductAwsResponse.fileName, userTp, productsData.mSts]);

  // const handleEditAddress = (userAddress: string) => {
  //   setUpdateAddress({ ...updateAddress, addr: userAddress });
  //   setConfirmAddress(true);
  // };

  const handleAddAddress = () => {
    setConfirmAddress(!confirmAddress);
  };

  const handleAddAddressChange = (e: any) => {
    setUpdateAddress({ ...updateAddress, addr: e.target.value });
  };

  const handleSubmitAddress = () => {
    setLoaderAddress(true);
    // setProductsData({ ...productsData, addr: updateAddress.addr });
    dispatch(addressReq({
      userType: userTp,
      requestType: APIHeader.REQ_UPDATE_USR_ADDR,
      inputBody: updateAddress,
      token: tokenData,
    }));
  };

  React.useEffect(() => {
    if (updateUserAddressRes.error && updateUserAddressRes.message !== '') {
      setLoaderAddress(false);
      setAlertMsg(updateUserAddressRes.message);
      setErrorShowAlert(true);
      dispatch(clearAddressReq());
      setConfirmAddress(false);
    }
  }, [updateUserAddressRes.error, updateUserAddressRes.message]);

  React.useEffect(() => {
    if (!updateUserAddressRes.error && updateUserAddressRes.message === 'executed') {
      dispatch(getKartListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_IN_CART,
        status: 'KART',
        discTp: discomUtility,
        // discomId: discomUtility,
      }));
      setLoaderAddress(false);
      dispatch(clearAddressReq());
      setConfirmAddress(false);
    }
  }, [updateUserAddressRes.error, updateUserAddressRes.message]);

  return (
    <div>
      { loaderProduct ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="align-center">
        <Col lg="11">
          <Row className="pt-2">
            <Col lg="4" xs="9">
              <div className="pt-3 align-start">
                <span className="meta-waranty-lite">Continue adding Spare Parts</span>
              </div>
            </Col>
            <Col lg="4" xs="3">
              <div className="pt-3">
                <span className="text-bold-grey">{productsData.fCnt} items</span>
              </div>
            </Col>
            <Col lg="4">
              <div className="align-end pt-3">
                <span className="meta-waranty-lite">
                  <span className="text-bold-grey">Need help? Call </span>
                  <FontAwesomeIcon icon={faWhatsapp} className="correct-pass-key mx-1" />
                  +91 8618679984
                </span>
              </div>
            </Col>
            <hr />
          </Row>
        </Col>
      </Row>
      {productsData.prodTxnData.length > 0
        ? (
          <Row className="align-center">
            {/* <Col lg="11">
              <span className="correct-pass-key ml-1">UNDER REVIEW</span>
            </Col> */}
            <Col lg="11">
              <Row className="mx-1">
                <span className="wrong-pass-key mb-1">Once all listed spare parts are approved, you will be able to upload Purchase Order</span>
                <Col lg="8" className="pt-2">
                  {productsData && productsData.prodTxnData.length > 0
                    ? productsData.prodTxnData.map((prodDet, index) => (
                      <Col xs="12" key={`prod-txn-${index.toString()}`} className="mb-2">
                        <Card className="card-lite">
                          <CardBody>
                            <Row>
                              <Col xs="12">
                                {prodDet.prodCnt > 0 && prodDet.tCost === '0.00' && productsData.mSts === 'INACT'
                                  ? <div className="mr align-end"><span className="text-pre-lite-grey">Pricing and stock under review</span></div>
                                  : null }
                                <Row>
                                  <Col lg="10" xs="9">
                                    <div className="ml align-start">
                                      <span className="card-toggle-text">{prodDet.prodInfo.nme}</span>
                                    </div>
                                  </Col>
                                  {/* <Col lg="2" xs="3">
                                    <div className="align-end mr">
                                      <Button disabled={prodDet.prodCnt > 0 && prodDet.tCost === '0.00' && productsData.mSts === 'INACT'} className="button-icon padding-zero" onClick={() => handlePreDelKartProd(`delInfo-${index}`)}>
                                        <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                      </Button>
                                    </div>
                                    <Modal
                                      show={modalDelProd[`delInfo-${index}`] || false}
                                      disCancel={false}
                                      targetId={`delInfo-${index}`}
                                      onClose={() => handlePostDelKartProd('cancel', `delInfo-${index}`, prodDet.ActTp, prodDet.ActId)}
                                      title={<span className="mx-2 alert-error">You are about to remove</span>}
                                      size="MD"
                                    >
                                      <div className="align-center">
                                        <span className="mx-2 notification-text">{prodDet.prodInfo.nme}</span>
                                      </div>
                                      <div className="my-4">
                                        <Row className="align-center">
                                          <Col lg="3" xs="3">
                                            <CardImg src={prodDet.prodInfo.imgUrl} alt="Prod Image" className="mx-1" />
                                          </Col>
                                        </Row>
                                      </div>
                                      <div className="align-center my-3 mx-2">
                                        <Button className="powermart-button-fullwidth mx-1" onClick={() => handlePostDelKartProd('cancel', `delInfo-${index}`, prodDet.ActTp, prodDet.ActId)}>
                                          CANCEL
                                        </Button>
                                        <Button className="powermart-button-fullwidth-red mx-1" onClick={() => handlePostDelKartProd('approve', `delInfo-${index}`, prodDet.ActTp, prodDet.ActId)}>
                                          REMOVE
                                        </Button>
                                      </div>
                                    </Modal>
                                    {/* <Modal isOpen={modalDelProd[`delInfo-${index}`] || false} size="lg">
                                      <ModalBody>
                                        <div className="my-2 align-center">
                                          <span className="mx-2 notification-text">You are about to remove</span>
                                        </div>
                                        <div className="my-2 align-center">
                                          <span className="card-toggle-text">{prodDet.prodInfo.nme}</span>
                                        </div>
                                        <div className="my-4">
                                          <Row className="align-center">
                                            <Col lg="3" xs="3">
                                              <CardImg src={prodDet.prodInfo.imgUrl} alt="Prod Image" className="mx-1" />
                                            </Col>
                                          </Row>
                                        </div>
                                        <div className="align-center my-3 mx-2">
                                          <Button className="powermart-button-fullwidth mx-1" onClick={() => handlePostDelKartProd('cancel', `delInfo-${index}`, prodDet.ActTp, prodDet.ActId)}>
                                            CANCEL
                                          </Button>
                                          <Button className="powermart-button-fullwidth-red mx-1" onClick={() => handlePostDelKartProd('approve', `delInfo-${index}`, prodDet.ActTp, prodDet.ActId)}>
                                            REMOVE
                                          </Button>
                                        </div>
                                      </ModalBody>
                                    </Modal>
                                  </Col> */}
                                </Row>
                              </Col>
                              <Col xs="12">
                                <Row className="pt-2">
                                  <Col lg="2" xs="3">
                                    <div className="align-center mx-1">
                                      <CardImg src={prodDet.prodInfo.imgUrl} alt="Prod Image" className="avatar-cards" />
                                    </div>
                                  </Col>
                                  <Col lg="3" xs="5">
                                    <div className="align-center mx-1 pt-2">
                                      <span className="text-lite-grey">Each Price</span>
                                    </div>
                                    <div className="align-center mx-1 my-1">
                                      <span className="">
                                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.prodInfo.cost))}
                                      </span>
                                    </div>
                                  </Col>
                                  <Col lg="3" xs="4">
                                    <div className="align-start mx-2 pt-2">
                                      <span className="text-lite-grey">Quantity</span>
                                    </div>
                                    <div className="align-center mx-2">
                                      <InputGroup>
                                        <Input
                                          placeholder="Quantity"
                                          type="number"
                                          disabled
                                          min="0"
                                          name="cnt"
                                          value={prodDet.prodCnt || ''}
                                          onChange={(e) => handleChangeKartCount(e, index, prodDet.ActTp, prodDet.ActId)}
                                          className="powermart-input"
                                        />
                                        {/* <InputGroupText className="input-search-layer">
                                          <Tooltip
                                            content="Click to Change Count"
                                            position="bottom"
                                            customClassName={`prodCount-${index}`}
                                          >
                                            <Button id={`prodCount-${index}`} className="button-icon align-center" onClick={() => handleUpdateKartCount(prodDet.ActTp, prodDet.ActId, prodDet.prodCnt)}>
                                              <FontAwesomeIcon icon={faCheckCircle} className="card-borderless" />
                                            </Button>
                                          </Tooltip>
                                        </InputGroupText> */}
                                      </InputGroup>
                                    </div>
                                  </Col>
                                  <Col lg="4" xs="12">
                                    <div className="align-end mx-1 pt-2">
                                      <span className="text-lite-grey">Total</span>
                                    </div>
                                    <div className="align-end mx-1 my-1">
                                      <span>
                                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.prodCost))}
                                      </span>
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    ))
                    : (
                      <div className="pt-4 align-center">
                        <span className="form-collapse-card-header">No Product Found in Cart</span>
                      </div>
                    )}
                </Col>
                <Col lg="4">
                  <div className="hr-vertical">
                    <Row className="pt-1 ml mobile-marin-top">
                      <Col xs="12">
                        <div className="align-center">
                          <Button className="powermart-button-link">
                            Generate Purchase Order
                          </Button>
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="align-center my-1">
                          <span className="text-lite-grey ">OR</span>
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="upload-box">
                          <Input
                            type="file"
                            accept=".pdf"
                            name="fileData"
                            className="upload-box-input"
                            onChange={(e) => handlePOFile(e)}
                          />
                          {poFileName === ''
                            ? <span className="register-file-link">UPLOAD PURCHASE ORDER</span>
                            : <span className="register-file-link-active">PURCHASE ORDER UPLOADED</span>}
                        </div>
                        <div className="ml">
                          {poFileName !== ''
                            ? (
                              <span className="wrong-pass-key">File uploaded: <span className="tex-red-italic-lite mx-1">{poFileName}</span>
                              </span>
                            ) : null}
                        </div>
                      </Col>
                      <hr className="my-3" />
                    </Row>
                    <Row className="pt-1 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span>Shipping cost</span>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="align-end">
                          <span className="correct-pass-key">FREE</span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-3 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span>Products Cost</span>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="align-end">
                          <span>
                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumCost))}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-3 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span>GST</span>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="align-end">
                          <span>
                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumGst))}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-3 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span className="notification-text">Total Cost</span>
                        </div>
                      </Col>
                      <Col xs="6">
                        <div className="align-end">
                          <span className="notification-text">
                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumTotal))}
                          </span>
                        </div>
                      </Col>
                    </Row>
                    <Row className="pt-3 ml">
                      <Col xs="6">
                        <div className="align-start">
                          <span className="text-lite-grey">Shipping Address</span>
                        </div>
                      </Col>
                      {productsData.addr === ''
                        ? (
                          <Col xs="12" className="pt-2">
                            <Input
                              type="textarea"
                              placeholder="Add shipping address"
                              className="powermart-input-desc"
                              value={updateAddress.addr}
                              disabled={loaderAddress}
                              onChange={(e) => handleAddAddressChange(e)}
                            />
                            <div className="align-end pt-2">
                              <Button className="powermart-button-red" onClick={handleSubmitAddress} disabled={loaderAddress}>
                                ADD
                                {loaderAddress ? <><Spinner type="grow" className="spin-grow-loader-white ml" /><Spinner type="grow" className="spin-grow-loader-white" /><Spinner type="grow" className="spin-grow-loader-white" /></> : null}
                              </Button>
                            </div>
                          </Col>
                          // <Col xs="6">
                          //   <div className="align-end">
                          //     <Button className="powermart-button-link" onClick={handleAddAddress}>
                          //       ADD <FontAwesomeIcon icon={faLocationDot} className="nav-mobile-icon1 mx-1" />
                          //     </Button>
                          //   </div>
                          // </Col>
                        )
                        : (
                          <Col xs="12" className="pt-2">
                            <div className="align-start">
                              <span className="text-lite-grey pt-1">
                                {productsData.addr}
                              </span>
                              <Button className="button-icon padding-zero ml-1 mb-2" onClick={handleAddAddress}>
                                <FontAwesomeIcon icon={faEdit} className="wrong-pass-key" />
                              </Button>
                            </div>
                          </Col>
                        )}
                      {confirmAddress
                        ? (
                          <Col xs="12">
                            <Input
                              type="textarea"
                              placeholder="Add Address"
                              className="powermart-input-desc"
                              value={updateAddress.addr}
                              disabled={loaderAddress}
                              onChange={(e) => handleAddAddressChange(e)}
                            />
                            <div className="align-end pt-2">
                              <Button className="powermart-button-red" onClick={handleSubmitAddress} disabled={loaderAddress}>
                                ADD
                                {loaderAddress ? <><Spinner type="grow" className="spin-grow-loader-white ml" /><Spinner type="grow" className="spin-grow-loader-white" /><Spinner type="grow" className="spin-grow-loader-white" /></> : null}
                              </Button>
                            </div>
                          </Col>
                        )
                        : null}
                    </Row>
                    <Row className="pt-5 ml">
                      <Col xs="12">
                        <Button
                          disabled={(productsData && productsData.prodTxnData.length === 0) || productsData.sumTotal === '0.00' || Array.from(purchaseOrderFile.fileData.keys()).length === 0 || productsData.addr === ''}
                          className="powermart-button-fullwidth mx-1"
                          onClick={() => handleCheckoutSubmit(productsData.ordId)}
                        >
                          <div className="align-center">
                            PURCHASE REVIEW
                            {/* {loaderAddKart && index === loaderIndex ? <Spinner className="alert-loader mx-2" /> : null} */}
                          </div>
                        </Button>
                        <div className="align-center pt-1">
                          <span className="text-lite-grey">Purchase Order and Shipping Address are mandatory !!</span>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        )
        : (
          <div className="pt-5 my-5 align-center">
            <span className="form-collapse-card-header">No Product found in Cart.</span>
          </div>
        )}
      <div className="pt-5" />
    </div>
  );
};
export default CartPrivateList;
