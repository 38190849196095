import { KartTxnIdPutResponse, KartTxnIdPutResults } from './update-kart-txnid.service.types';

export const KartTxnIdPutTransform: (response: KartTxnIdPutResponse) => KartTxnIdPutResults = (passKeyResults) => {
  const { is_error, message } = passKeyResults;
  const result :KartTxnIdPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = 'executed';
  }
  return result;
};
