export enum ApiLIMIT {
  LIMIT = 10,
}

export enum UserType {
  SBDUSR = 'Sub Division User',
  SBDADM = 'Sub Division Admin',
  SALES = 'Sales Team',
  SUPRT = 'Support Team',
  SADM = 'Super Admin',
  APIU = 'API User',
}
export enum APIHeader {
  REQ_ADD_USER = 'add_user',
  REQ_ADD_SELLER = 'add_seller',
  USER_LOGIN = 'user_login',
  REQ_UPDATE_USER_STATUS = 'update_user_status',
  REQ_ALL_USER_BY_STATUS = 'get_all_user_by_status',
  REQ_PUB_FORGOT_PASSWORD = 'pub_forgot_password',
  REQ_UPDATE_PASSWORD = 'update_password',
  REQ_USER_PROFILE_INFO = 'user_profile_info',
  REQ_ALL_ST_META = 'get_all_state_meta',
  REQ_ALL_CORP_META = 'get_all_corp_meta',
  REQ_ADD_DIV_META = 'add_div_meta',
  REQ_ALL_DIV_META = 'get_all_div_meta',
  REQ_ADD_SUBDIV_META = 'add_subdiv_meta',
  REQ_ALL_SUBDIV_META = 'get_all_subdist_meta',
  REQ_ADD_PRODUCTS = 'add_products',
  REQ_ADD_SPARES = 'add_spares',
  REQ_UPDATE_PRODUCTS = 'update_products',
  REQ_GET_ALL_PRODUCTS_PUB = 'get_all_products_pub',
  REQ_GET_ALL_PRODUCTS_SELLER = 'get_all_products_seller',
  REQ_GET_ALL_PRODUCTS_PRV = 'get_all_products_prv',
  REQ_GET_PRODUCT_DESC = 'get_product_desc',
  REQ_UPLOAD_FILES = 'upload_files',
  REQ_ADD_PROD_TXN_BY_USER = 'add_prod_txn_by_user',
  REQ_GET_ALL_PROD_TXN_IN_CART = 'get_all_prod_txn_in_cart',
  REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR = 'get_all_prod_txn_for_sadm_selr',
  REQ_GET_KART_COUNT = 'get_kart_count',
  REQ_UPDATE_KART_PROD_CNT = 'update_kart_cnt',
  REQ_DELETE_KART_PROD = 'delete_kart_prod',
  REQ_UPDATE_KART_MAIN_STATUS = 'update_kart_main_status',
  REQ_UPDATE_KART_TXN_ID = 'update_kart_txn_id',
  REQ_GET_ALL_USER_ORDERS = 'get_all_user_orders',
  REQ_UPDATE_USR_ADDR = 'update_usr_addr',
  REQ_UPDATE_PND_ORDR_BY_ADM = 'update_pend_ordr_by_adm',
  REQ_UPDATE_ACK_SHIP_BY_ADM = 'update_ack_ship_by_adm',
  REQ_GET_ORDER_TXN_BY_ACTID = 'get_all_order_txn_by_actid',
  REQ_UPDATE_OEM = 'update_oem',
  REQ_DELETE_PRODUCTS = 'delete_products'
}

export const formatNumberToMillion = (numVal: number) => {
  const suffixes = ['', 'k', 'm', 'b', 't'];
  const orderOfMagnitude = Math.floor(Math.log10(Math.abs(numVal)) / 3);

  if (orderOfMagnitude < suffixes.length) {
    const roundedNumber = (numVal / 10 ** (orderOfMagnitude * 3)).toFixed(2);
    return roundedNumber + suffixes[orderOfMagnitude];
  }
  return numVal.toString();
};

export const ProductCatList = [
  { label: 'Distribution', value: 'DIST' },
  { label: 'Transmission', value: 'TRANS' },
  { label: 'Thermal', value: 'THRML' },
  { label: 'Hydro', value: 'HYDRO' },
  { label: 'Renewable Energy', value: 'RNWE' },
];

export const ListStateOptions = [
  { label: 'Andaman & Nicobar', value: 'AN' },
  { label: 'Andhra Pradesh', value: 'AP' },
  { label: 'Arunachal Pradesh', value: 'AR' },
  { label: 'Assam', value: 'AS' },
  { label: 'Bihar', value: 'BR' },
  { label: 'Chhattisgarh', value: 'CJ' },
  { label: 'Delhi', value: 'DL' },
  { label: 'Goa', value: 'GA' },
  { label: 'Gujarat', value: 'GJ' },
  { label: 'Haryana', value: 'HR' },
  { label: 'Himachal Pradesh', value: 'HP' },
  { label: 'Jammu & Kashmir', value: 'JK' },
  { label: 'Jharkhand', value: 'JH' },
  { label: 'Karnataka', value: 'KA' },
  { label: 'Kerala', value: 'KL' },
  { label: 'Ladakh', value: 'LA' },
  { label: 'Lakshwadeep', value: 'LD' },
  { label: 'Madhya Pradesh', value: 'MP' },
  { label: 'Maharashtra', value: 'MH' },
  { label: 'Manipur', value: 'MN' },
  { label: 'Meghalaya', value: 'ML' },
  { label: 'Mizoram', value: 'MZ' },
  { label: 'Nagaland', value: 'NL' },
  { label: 'Puducherry', value: 'PY' },
  { label: 'Punjab', value: 'PB' },
  { label: 'Rajasthan', value: 'RJ' },
  { label: 'Sikkim', value: 'SK' },
  { label: 'Tamil Nadu', value: 'TN' },
  { label: 'Telangana', value: 'TS' },
  { label: 'Tripura', value: 'TR' },
  { label: 'Uttar Pradesh', value: 'UP' },
  { label: 'Uttarakhand', value: 'UK' },
  { label: 'West Bengal', value: 'WB' },
];

export const DiscomDistributionOptions = [
  { label: 'Electricity Department, Government Of Andaman & Nicobar Islands(A&NPD)', value: 'ANPD', st: 'AN' },
  { label: 'Central Power Distribution Corporation Limited(APCPDCL)', value: 'APCPDCL', st: 'AP' },
  { label: 'Eastern Power Distribution Company Of AP Limited(APEPDCL)', value: 'APEPDCL', st: 'AP' },
  { label: 'Southern Power Distribution Company Of AP Limited(APSPDCL)', value: 'APSPDCL', st: 'AP' },
  { label: 'Department of Power, Arunachal Pradesh(ARUNAPD)', value: 'ARUNAPD', st: 'AR' },
  { label: 'Assam Power Distribution Company Limited(APDCL)', value: 'APDCL', st: 'AS' },
  { label: 'North Bihar Power Distribution Company Limited(NBPDCL)', value: 'NBPDCL', st: 'BR' },
  { label: 'South Bihar Power Distribution Company Limited(SBPDCL)', value: 'SBPDCL', st: 'BR' },
  { label: 'Chattisgarh State Power Distribution Company Limited(CSPDCL)', value: 'CSPDCL', st: 'CJ' },
  { label: 'New Delhi Municipal Corporation(NDMC)', value: 'NDMC', st: 'DL' },
  { label: 'Electricity Department of Goa(GOAPD)', value: 'GOAPD', st: 'GA' },
  { label: 'Dakshin Gujarat Vij Company Limited (DGVCL)', value: 'DGVCL', st: 'GJ' },
  { label: 'Madhya Gujarat Vij Company Limited (MGVCL)', value: 'MGVCL', st: 'GJ' },
  { label: 'Paschim Gujarat Vij Company Limited (PGVCL)', value: 'PGVCL', st: 'GJ' },
  { label: 'Uttar Gujarat Vij Company Limited (UGVCL)', value: 'UGVCL', st: 'GJ' },
  { label: 'Dakshin Haryana Bijli Vitran Nigam Limited (DHBVNL)', value: 'DHBVNL', st: 'HR' },
  { label: 'Uttar Haryana Bijli Vitran Nigam Limited (UHBVNL)', value: 'UHBVNL', st: 'HR' },
  { label: 'HP State Electricity Board (HPSEBL)', value: 'HPSEBL', st: 'HP' },
  { label: 'Jammu Power Distrbution Corporation Limited (JPDCL)', value: 'JPDCL', st: 'JK' },
  { label: 'Kashmir Power Distribution Corporation Limited (KPDCL)', value: 'KPDCL', st: 'JK' },
  { label: 'Jharkhand Bijli Vitran Nigam Limited (JBVNL)', value: 'JBVNL', st: 'JH' },
  { label: 'Bangalore Electricity Supply Company Limited (BESCOM)', value: 'BESCOM', st: 'KA' },
  { label: 'Chamundeshwari Electricity Supply Corporation Limited (CHESCOM)', value: 'CHESCOM', st: 'KA' },
  { label: 'Gulbarga Electricity Supply Company Limited (GESCOM)', value: 'GESCOM', st: 'KA' },
  { label: 'Hubli Electricity Supply Company Limited (HESCOM)', value: 'HESCOM', st: 'KA' },
  { label: 'Mangalore Electricity Supply Company Limited (MESCOM)', value: 'MESCOM', st: 'KA' },
  { label: 'Hukkeri Rural Electric Co-operative Society (HRECS)', value: 'HRECS', st: 'KA' },
  { label: 'Kerala State Electricity Board (KSEBL)', value: 'KSEBL', st: 'KL' },
  { label: 'TCED-Thrissur Corporation Electricity Department (TCED)', value: 'TCED', st: 'KL' },
  { label: 'Electricity Department Ladakh (LDKHED)', value: 'LDKHED', st: 'LA' },
  { label: 'Lakshwadeep Electricity Department (LAKSHED)', value: 'LAKSHED', st: 'LD' },
  { label: 'MP Madhya Kshetra Vidyut Vitran Corporation Limited (MPMVVCL)', value: 'MPMVVCL', st: 'MP' },
  { label: 'MP Paschim Kshetra Vidyut Vitaran Company Limited (MPPVVCL)', value: 'MPPVVCL', st: 'MP' },
  { label: 'MP Poorv Kshetra Vidyut Vitaran Company Limited (MPPVVCL)', value: 'MPPVVCL', st: 'MP' },
  { label: 'BEST Undertaking (BEST)', value: 'BEST', st: 'MH' },
  { label: 'Maharashtra State Electricity Distribution Corporation Limited (Mahavitaran)', value: 'MSEDCL', st: 'MH' },
  { label: 'Manipur State Power Distribution Corporation Limited (MSPDCL)', value: 'MSPDCL', st: 'MN' },
  { label: 'Meghalaya Power Distribution Corporation Limited (MEGPDCL)', value: 'MEGPDCL', st: 'ML' },
  { label: 'Mizoram Power & Electricity Department (MIZPD)', value: 'MIZPD', st: 'MZ' },
  { label: 'Nagaland Electricity Department Of the Govt. Of Nagaland (NAGPD)', value: 'NAGPD', st: 'NL' },
  { label: 'Puducherry Electricity Department of Government Of Puducherry (PED)', value: 'PED', st: 'PY' },
  { label: 'Punjab State Power Corporation Limited (PSPCL)', value: 'PSPCL', st: 'PB' },
  { label: 'Ajmer Vidyut Vitran Nigam Limited (AVVNL)', value: 'AVVNL', st: 'RJ' },
  { label: 'Jaipur Vidyut Vitran Nigam Limited (JVVNL)', value: 'JVVNL', st: 'RJ' },
  { label: 'Jodhpur Vidyut Vitran Nigam Limited (JDVVNL)', value: 'JDVVNL', st: 'RJ' },
  { label: 'Sikkim Energy & Power Department (SKMPD)', value: 'SKMPD', st: 'SK' },
  { label: 'Tamil Nadu Generation and Distribution Corporation Limited (TNGEDCL)', value: 'TNGEDCL', st: 'TN' },
  { label: 'Northern Power Distribution Company Of Telangana Limited (TSNPDCL)', value: 'TSNPDCL', st: 'TS' },
  { label: 'Southern Power Distribution Company Of Telangana State Limited (TSSPDCL)', value: 'TSSPDCL', st: 'TS' },
  { label: 'Tripura State Electricity Corporation Limited (TSECL)', value: 'TSECL', st: 'TR' },
  { label: 'Uttar Pradesh Dakshinanchal Vidyut Vitaran Nigam Limited (UPDVVNL)', value: 'DVVNL', st: 'UP' },
  { label: 'Uttar Pradesh Kanpur Electricity Supply Company (UPKESCO)', value: 'UPKESCO', st: 'UP' },
  { label: 'Uttar Pradesh Madhyanchal Vidyut Vitaran Nigam Limited (UPMVVNL)', value: 'UPMVVNL', st: 'UP' },
  { label: 'Uttar Pradesh Pashchimanchal Vidyut Vitaran Nigam Limited (UPPVVNL)', value: 'UPPVVNL', st: 'UP' },
  { label: 'Uttar Pradesh Poorvanchal  Vidyut Vitaran Nigam Limited (UPPVVNL)', value: 'UPPVVNL', st: 'UP' },
  { label: 'Uttarakhand Power Corporation Limited (UPCL)', value: 'UPCL', st: 'UK' },
  { label: 'West Bengal State Electricity Distribution Company Limited (WBSEDCL)', value: 'WBSEDCL', st: 'WB' },
];

export const DiscomTransmissionOptions = [
  { label: 'Transmission corporation of Andra Pradesh', value: 'APTRANSCO', st: 'AP' },
  { label: 'Assam Electricity Grid Corporation Limited', value: 'AEGCL', st: 'AS' },
  { label: 'Bihar State Power Transmission Company Limited', value: 'BSPTCL', st: 'BR' },
  { label: 'Chattisgarh State Power Transmission Company Limited', value: 'CSPTCL', st: 'CJ' },
  { label: 'Delhi Transco Limited', value: 'DTL', st: 'DL' },
  { label: 'Goa Electricity Department', value: 'GOAPD', st: 'GA' },
  { label: 'Gujarat Energy Transmission Corporation Limited', value: 'GATCO', st: 'GJ' },
  { label: 'Haryana Vidyut Prasaran Nigam Limited', value: 'HVPNL', st: 'HR' },
  { label: 'HP Power Transmission Corporation Limited', value: 'HPPTCL', st: 'HP' },
  { label: 'Jammu & Kashmir Power Transmission Corporation Limited', value: 'JKPTCL', st: 'JK' },
  { label: 'Jharkhand Urja Sancharan Nigam Limited', value: 'JUSNL', st: 'JH' },
  { label: 'Karnataka Power Transmission Corporation Limited', value: 'KPTCL', st: 'KA' },
  { label: 'Kerala State Electricity Board', value: 'KSEBL', st: 'KL' },
  { label: 'Lakshwadeep Electricity Department', value: 'LAKSHED', st: 'LD' },
  { label: 'Madhya Pradesh Power Transmission Corporation Limited', value: 'MPPTCL', st: 'MP' },
  { label: 'Maharastra State Electricity Transmission Company', value: 'MSETCL', st: 'MH' },
  { label: 'Manipur State Power Company Limited', value: 'MSPCL', st: 'MN' },
  { label: 'Meghalaya Energy Corporation Limited', value: 'MEGECL', st: 'ML' },
  { label: 'Mizoram Power & Electricity Department', value: 'MIZPD', st: 'MZ' },
  { label: 'Kohima Mariani Transmission Limited', value: 'KMTL', st: 'NL' },
  { label: 'Puducherry Power Corporation Limited', value: 'PPCL', st: 'PY' },
  { label: 'Punjab State Transmission Corporation Limited', value: 'PSPTCL', st: 'PB' },
  { label: 'Rajastan Rajya Vidyut Prasaran Nigam Limited', value: 'RRVPNL', st: 'RJ' },
  { label: 'Sikkim Teestavalley Power Transmission Limited', value: 'TPTL', st: 'SK' },
  { label: 'Tamil Nadu Transmission Corporation Limited', value: 'TNTCL', st: 'TN' },
  { label: 'Transmission corporation Of Telangana Limited', value: 'TSPTCL', st: 'TS' },
  { label: 'Tripura Power Transmission Limited', value: 'TPTL', st: 'TR' },
  { label: 'Uttarpradesh Power Transmission Corporation Limited', value: 'UPPTCL', st: 'UP' },
  { label: 'Power Transmission Corporation Of Uttarakhand Limited', value: 'PTCUL', st: 'UK' },
  { label: 'West Bengal State Electricity Transmission Company Limited', value: 'WBSETCL', st: 'WB' },
];

export const ListCPOptions = [
  { label: 'SIEMENS', value: 'SEM' },
  { label: 'ABB', value: 'ABB' },
  { label: 'SCHNEIDER', value: 'SCR' },
  { label: 'LUCY ELECTRIC', value: 'LEL' },
  { label: 'C&S ELECTRIC', value: 'CNS' },
  { label: 'MITSUBISHI ELECTRIC', value: 'MEL' },
  { label: 'HITACHI', value: 'HIT' },
  { label: 'GENERAL ELECTRIC', value: 'GEL' },
  { label: 'JINDAL RECTIFIERS', value: 'JIR' },
  { label: 'TESTING AND MEASURING  INSTRUMENTS', value: 'TNM' },
  { label: 'YOKOGAWA', value: 'YOK' },
  { label: 'POWER TRANSFORMERS', value: 'POT' },
  { label: 'CABLES', value: 'CAB' },
  { label: 'RENEWABLE ENERGY', value: 'REN' },
  { label: 'BOXES AND  PANELS', value: 'BAP' },
  { label: 'GOS,LA,DOLO,HGFUSE,LTP', value: 'GLD' },
  { label: 'INSULATORS', value: 'INS' },
  { label: 'LINE MATERIALS', value: 'LIM' },
  { label: 'POLES', value: 'POL' },
  { label: 'STRUCTURE MATERIALS', value: 'STM' },
  { label: 'CONTROL AND  RELAY PANELS', value: 'CRP' },
  { label: 'ON LOAD TAP CHANGERS', value: 'OLC' },
  { label: 'CIRCUIT BREAKERS', value: 'CIB' },
  { label: '11 KV MCVCB AND PCVCB UP TO 33 KV CLASS', value: 'KVM' },
  { label: 'MONOPOLES,TOWER PARTS,STATION STRUCTURE WITH BOLTS,NUTS AND WASHER', value: 'MBN' },
  { label: 'LIGHTNING ARRESTERS WITH MOUNTING STRUCTURES', value: 'LAM' },
  { label: 'ISOLATORS WITH MOUNTING STRUCTURES', value: 'IMS' },
  { label: 'INSTRUMENT TRANSFORMERS', value: 'INT' },
  { label: 'BATTERY SETS', value: 'BAS' },
  { label: 'BATTERY CHARGERS', value: 'BAC' },
  { label: 'ACSR CONDUCTORS', value: 'ACC' },
  { label: 'AAAC CONDUCTORS', value: 'AAA' },
  { label: 'GROUND WIRE', value: 'GRW' },
  { label: 'CAPACITOR BANK', value: 'CAP' },
  { label: 'TOWER ACCESSORIES', value: 'TOA' },
  { label: 'INSULATOR HARDWARE', value: 'INH' },
  { label: 'CONDUCTOR AND GROUND WIRE ACCESSORIES', value: 'CGA' },
  { label: 'C-TYPE WEDGE CONNECTORS', value: 'CWC' },
  { label: 'LTAC AND DCDB PANELS', value: 'LDP' },
  { label: 'NITROGEN INJECTION FIRE PROTECTION SYSTEM', value: 'NIF' },
  { label: 'WAVE TRAP AND LINE TRAP', value: 'WTL' },
  { label: 'PLCC EQUIPMENTS', value: 'PLC' },
  { label: 'POWER TRANSFORMER BUSHINGS', value: 'PTB' },
  { label: 'RTU & DCU PANELS', value: 'RDP' },
  { label: 'OPGW CABLE ACCESSORIES', value: 'OCA' },

];
