import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const PowerSupport: React.FC = () => (
  <div className="margin-support">
    <div className="text-center-aligned pt-2">
      <span className="text-header">Get the Help You Need</span>
    </div>
    <Row className="align-center pt-3">
      <Col xs="12" lg="9">
        <div className="pt-2 text-center-aligned">
          <span className="about-title">At PowerMart, we are committed to providing exceptional support to ensure your experience with us is seamless and enjoyable. Whether you have questions, need assistance, or encounter any issues, our support team is here to help.</span>
        </div>
      </Col>
      <Col xs="12" lg="5">
        <div className="pt-5 ml">
          <span className="about-sub-title-support">How to Reach Us</span><br />
          <span className="about-text">We offer multiple channels for you to reach our support team quickly and conveniently.</span>
        </div>
        <div className="pt-5 ml">
          <div className="pt-1 ml">
            <span className="about-sub-title-support">Email Support</span><br />
            <span className="about-text">For detailed inquiries or support requests, you can contact us via email. Our team is ready to assist you with any questions or issues you may have.</span>
            <div className="pt-2 ml">
              <FontAwesomeIcon icon={faEnvelope} className="text-lite-grey mx-2" />
              <span className="powermart-button-link">support@powermart.com</span>
            </div>
          </div>
          <div className="pt-5 ml">
            <span className="about-sub-title-support">Phone Support</span><br />
            <span className="about-text">If you prefer to speak with a support representative directly, give us a call. Our phone support is available during business hours to provide you with immediate assistance.</span>
            <div className="pt-3 ml">
              <FontAwesomeIcon icon={faWhatsapp} className="correct-pass-key mx-2" />
              <span className="powermart-button-link">+91 8618679984</span>
            </div>
          </div>
        </div>
      </Col>
      <Col xs="12" lg="6">
        <div className="pt-5 mx-3">
          <CardImg src="/images/contact-us.png" alt="support" />
        </div>
      </Col>
      <Col xs="12" lg="8">
        <div className="pt-5 text-center-aligned">
          <span className="about-text">We value your time and strive to resolve your queries promptly. Our knowledgeable and friendly support team is dedicated to providing you with the best possible service. Do not hesitate to reach out to us through any of the above methods for any support you may need.</span>
        </div>
      </Col>
    </Row>
    <div className="pt-5" />
  </div>
);

export default PowerSupport;
