import React from 'react';
import { useDispatch } from 'react-redux';
import {
  faBoxArchive, faGears, faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import './dashboard.scss';
import { clearProductsListReq } from '../../../../store/powerMart/actions';
import SearchOrders from './search-orders';
import AdminOrdersStepper from './orders/order-stepper';
import Tooltip from '../../../../utils/Tooltip/Tooltip';
import { PartsSaleAdmin } from './orders/parts-sale';

export const SuperAdminDashboard: React.FC = () => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = React.useState('pend-order');
  const [orderPartsCnt, setOrderPartsCnt] = React.useState(0);

  const handlePendOrder = () => {
    dispatch(clearProductsListReq());
    setActiveTab('pend-order');
  };

  // const handlePendPayment = () => {
  //   dispatch(clearProductsListReq());
  //   setActiveTab('pend-payment');
  // };

  // const handleTransitSts = () => {
  //   dispatch(clearProductsListReq());
  //   setActiveTab('transit-status');
  // };

  const handleSearchOrder = () => {
    dispatch(clearProductsListReq());
    setActiveTab('searchOrder-status');
  };

  const handlePartsOrder = () => {
    dispatch(clearProductsListReq());
    setActiveTab('parts-status');
  };

  return (
    <div className="label-margin-to-nav">
      <div className="mb-1 pt-3">
        <span className="powermart-main-label">
          Quick View - Dashboard
        </span>
      </div>
      <Row>
        <Col xs="12" className="pt-3">
          <Nav tabs className="dashboard-sadmin-profile-tab">
            <div className="dashboard-sadmin-row-profile-tab">
              <div className="dashboard-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="dashboard-sadmin-profile-nav-tabs" active={activeTab === 'pend-order'} onClick={handlePendOrder}>
                    <FontAwesomeIcon icon={faBoxArchive} className="dashboard-sadmin-tab-profile-icon" />
                    <span>Orders</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="seller-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="seller-profile-nav-tabs" active={activeTab === 'searchOrder-status'} onClick={handleSearchOrder}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} className="seller-tab-profile-icon mr" />
                    <span className="seller-tab-text">Search Order</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="seller-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="seller-profile-nav-tabs" active={activeTab === 'parts-status'} onClick={handlePartsOrder}>
                    <FontAwesomeIcon icon={faGears} className="seller-tab-profile-icon mr" />
                    <span className="seller-tab-text">Parts Sale ({orderPartsCnt})</span>
                  </NavLink>
                </NavItem>
              </div>
              {/* <div className="seller-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="seller-profile-nav-tabs" active={activeTab === 'transit-status'} onClick={handleTransitSts}>
                    <FontAwesomeIcon icon={faTruckFast} className="seller-tab-profile-icon mr" />
                    <span className="seller-tab-text">Transit Status</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="dashboard-sadmin-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="dashboard-sadmin-profile-nav-tabs" active={activeTab === 'pend-payment'} onClick={handlePendPayment}>
                    <FontAwesomeIcon icon={faMoneyBillTransfer} className="dashboard-sadmin-tab-profile-icon" />
                    <span className="dashboard-sadmin-tab-text">Payment Status</span>
                  </NavLink>
                </NavItem>
              </div> */}
            </div>
            <div className="dashboard-sadmin-row-profile-tab-mobile">
              <Row>
                <Col xs="12">
                  <div className="align-start">
                    <NavItem className="cursor-pointer dashboard-sadmin-profile-tab">
                      <Tooltip
                        content="Orders"
                        position="bottom"
                        customClassName="orderId"
                      >
                        <NavLink id="orderId" className="dashboard-sadmin-profile-nav-tabs" active={activeTab === 'pend-order'} onClick={handlePendOrder}>
                          <FontAwesomeIcon icon={faBoxArchive} className="dashboard-sadmin-tab-profile-icon" />
                        </NavLink>
                      </Tooltip>
                    </NavItem>
                    <NavItem className="profile-pointer-user-sadmin seller-profile-tab">
                      <Tooltip
                        content="Parts Order"
                        position="bottom"
                        customClassName="searchOrderId"
                      >
                        <NavLink id="partsId" className="seller-profile-nav-tabs" active={activeTab === 'parts-status'} onClick={handlePartsOrder}>
                          <FontAwesomeIcon icon={faGears} className="seller-tab-profile-icon" />
                          ({orderPartsCnt})
                        </NavLink>
                      </Tooltip>
                    </NavItem>
                    <NavItem className="profile-pointer-user-sadmin seller-profile-tab">
                      <Tooltip
                        content="Search Order"
                        position="bottom"
                        customClassName="searchOrderId"
                      >
                        <NavLink id="searchOrderId" className="seller-profile-nav-tabs" active={activeTab === 'searchOrder-status'} onClick={handleSearchOrder}>
                          <FontAwesomeIcon icon={faMagnifyingGlass} className="seller-tab-profile-icon" />
                        </NavLink>
                      </Tooltip>
                    </NavItem>
                  </div>
                </Col>
              </Row>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            {activeTab === 'pend-order' && (
            <TabPane tabId="pend-order">
              {/* <PendingOrdersAdmin orderSts="pend-order" /> */}
              <AdminOrdersStepper />
            </TabPane>
            )}
            {/* {activeTab === 'pend-payment' && (
            <TabPane tabId="pend-payment">
              <PendingPaymentAdmin orderSts="pend-payment" />
            </TabPane>
            )}
            {activeTab === 'transit-status' && (
            <TabPane tabId="transit-status">
              <InTransit orderSts="transit-status" />
            </TabPane>
            )} */}
            {activeTab === 'searchOrder-status' && (
            <TabPane tabId="searchOrder-status">
              <SearchOrders />
            </TabPane>
            )}
            {activeTab === 'parts-status' && (
            <TabPane tabId="parts-status">
              <PartsSaleAdmin setOrderPartsCnt={setOrderPartsCnt} orderSts="INACT" orderNme="User Requested for Parts" />
            </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default SuperAdminDashboard;
