import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Select from 'react-select';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Card, CardBody, CardImg, Button, Input, Form, InputGroup, InputGroupText, Offcanvas, OffcanvasHeader, OffcanvasBody,
  // Spinner,
  CardFooter,
  CardHeader,
  FormGroup,
  Label,
} from 'reactstrap';
import {
  faCirclePlus, faCircleXmark, faCube, faFilePdf, faMagnifyingGlass, faSortDown, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faImages, faTrashCan } from '@fortawesome/free-regular-svg-icons';
import { errorAlert, successAlert } from '../../../../../utils/alert';
import LoaderData from '../../../../../utils/loader';
import { ProdImgFileInput } from '../../../../../services/products/upload-prod-Image/create-prodimage.types';
import { PostProductInput } from '../../../../../services/products/add-products/post-product.types';
import {
  APIHeader, ApiLIMIT, ListCPOptions, ProductCatList,
} from '../../../../../utils/constants';
import {
  clearCreateProductReq,
  clearDelProdReq,
  clearDeleteAWSFolder,
  clearDeleteProdImg,
  clearGetListProdImg,
  clearProdDescReq, clearProductsListReq, clearPutProductReq, clearUploadProdImg, createProductReq, delProdReq, deleteAWSFolder, deleteProdImg, getCorpListReq, getListProdImg, getProductsListReq, prodDescReq, putProductReq, uploadProdImg,
} from '../../../../../store/powerMart/actions';
import { getConfig } from '../../../../../utils/config/config';
import ImageGallery from './thumbnail-image';
import { getUserProfileSessionStorage, getTokenFromSessionStorage } from '../../../../../utils/service/localstorage-service';
// import { ProductsResult } from '../../../../../services/products/list-products/get-all-product.types';
import { ProductDescResult } from '../../../../../services/products/prod-desc/product-desc.types';
import { ProductsResult } from '../../../../../services/products/list-products/get-all-product.types';
import { ProductsEditData } from '../../../../../services/products/update-products/update-product.types';
import { CorpResult } from '../../../../../services/meta-data/list-corp/list-corp.types';
import { FileDetails } from '../../../../../services/products/get-list-product-images/getList-prodimage.types';
import ShowMoreText from '../../../../../utils/showmore-text';
import { ProdStatusProps } from '../metadata.type';
import Tooltip from '../../../../../utils/Tooltip/Tooltip';
import Modal from '../../../../../utils/Modal/Modal';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const ProductDetails: React.FC<ProdStatusProps> = ({ statusDet }) => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const tokenData = getTokenFromSessionStorage();
  const [loaderAddProduct, setLoaderAddProduct] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showAddProdForm, setShowAddProdForm] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [loaderCorp, setLoaderCorp] = React.useState(false);
  // const [expandedIndices, setExpandedIndices] = React.useState<string[]>([]);
  // const [hideAfterIndex, setHideAfterIndex] = React.useState<number | null>(3);
  const [prodDetailsOpen, setProdDetailsOpen] = React.useState<Record<string, boolean>>({});
  const [techSpec, setTechSpec] = React.useState('');
  const [prodDesc, setProdDesc] = React.useState('');
  const [productDelType, setProductDelType] = React.useState('');
  const [productDelId, setProductDelId] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [loadingMore, setLoadingMore] = React.useState(false);
  // const [mediaFileNmeExt, setMediaFileNmeExt] = React.useState('');
  const [corpData, setCorpData] = React.useState('');
  const [corpListData, setCorpListData] = React.useState<CorpResult[]>([]);
  const corpResData = useSelector((state: RootState) => state.powerMart.getCorpMetaList);
  const addProductResponse = useSelector((state: RootState) => state.powerMart.createNewProducts);
  const updateProductResponse = useSelector((state: RootState) => state.powerMart.updateProduct);
  const uploadProductAwsResponse = useSelector((state: RootState) => state.powerMart.uploadProductsAWS);
  const getProdImageAwsResponse = useSelector((state: RootState) => state.powerMart.getListProductsImageAWS);
  const deleteImageAwsResponse = useSelector((state: RootState) => state.powerMart.deleteProductsAWS);
  const deleteAwsFolderResponse = useSelector((state: RootState) => state.powerMart.deleteAWSFolder);
  // const [filePreviews, setFilePreviews] = React.useState<string[]>([]);
  const [openModalProd, setOpenModalProd] = React.useState<Record<string, boolean>>({});
  const [prodImageFile, setProdImageDataFile] = React.useState<ProdImgFileInput>({
    fileData: new FormData(),
  });
  const [addProdImgList, setAddProdImgList] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);
  const [prodEditImgList, setEditProdImgList] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);
  const [selectedDelImages, setSelectedDelImages] = React.useState<string[]>([]);
  const [productInput, setProductInput] = React.useState<PostProductInput>({
    nme: '',
    cat: '',
    techSpec: [],
    prodDesc: [],
    wrnty: 0,
    keyW: '',
    modlN: '',
    tagIn: '',
    hsn: '',
    cost: '',
    gst: '',
    oem: '',
  });
  const [productUpdateInput, setProductUpdateInput] = React.useState<ProductsEditData>({
    ProdTp: '',
    ProdId: '',
    nme: '',
    techSpec: [],
    prodDesc: [],
    wrnty: 0,
    cost: '',
    gst: '',
    keyW: '',
    modlN: '',
    tagIn: '',
    hsn: '',
    oem: '',
    isAcs: 'True',
  });
  const [productsData, setProductsData] = React.useState<ProductsResult>({
    tCnt: 0,
    fCnt: 0,
    prodData: [],
    lastEvalKey: {
      ProdTp: '',
      ProdId: '',
    },
  });
  // const [loaderProdDesc, setLoaderProdDesc] = React.useState(false);
  // const [loaderDescIndex, setLoaderDescIndex] = React.useState<number | null>(null);
  const listProducts = useSelector((state: RootState) => state.powerMart.getProductList);
  const prodDescRes = useSelector((state: RootState) => state.powerMart.prodDesc);
  const [prodDescTp, setProdDescTp] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [loaderProduct, setLoaderProduct] = React.useState(false);
  const [radioSelected, setRadioSelected] = React.useState('');
  const [openModalProduct, setOpenModalProduct] = React.useState<Record<string, boolean>>({});
  const [modelProdIndex, setModalProdIndex] = React.useState('');
  // const [isLastObject, setIsLastObject] = React.useState(false);
  const [selectedProdIndex, setSelectedProdIndex] = React.useState<number | null>(null);
  const delProdRes = useSelector((state: RootState) => state.powerMart.delProd);
  const [productTechSpec, setProductTechSpec] = React.useState<ProductDescResult>({
    data: {
      prodDesc: [],
      techSpec: [],
    },
    isError: false,
    message: '',
  });
  const [productDesc, setProductDesc] = React.useState<ProductDescResult>({
    data: {
      prodDesc: [],
      techSpec: [],
    },
    isError: false,
    message: '',
  });
  const [modalDelProd, setModalDelProd] = React.useState<Record<string, boolean>>({});
  const [whichModelDel, setWhichModelDel] = React.useState('');
  // const [productsData, setProductsData] = React.useState<ProductsResult>({
  //   tCnt: 0,
  //   fCnt: 0,
  //   prodData: [],
  // });
  const [showProdDesc, setShowProdDesc] = React.useState(Array(productsData.prodData.length).fill(false));

  const handleHideClick = (index: number) => {
    setShowProdDesc((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = false;
      return updatedState;
    });
    setSelectedProdIndex(null);
  };

  const prodToggleModal = (target: string, rowData: ProductsEditData, productDescription: string[], technicSpec: string[]) => {
    setOpenModalProd((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setProductUpdateInput({
      ...productUpdateInput,
      ProdTp: rowData.ProdTp ? rowData.ProdTp : '',
      ProdId: rowData.ProdId ? rowData.ProdId : '',
      nme: rowData.nme ? rowData.nme : '',
      techSpec: technicSpec,
      prodDesc: productDescription,
      wrnty: rowData.wrnty ? rowData.wrnty : 0,
      keyW: rowData.keyW ? rowData.keyW : '',
      modlN: rowData.modlN ? rowData.modlN : '',
      tagIn: rowData.tagIn ? rowData.tagIn : '',
      hsn: rowData.hsn ? rowData.hsn : '',
      cost: rowData.cost ? rowData.cost : '',
      gst: rowData.gst ? rowData.gst : '',
      oem: rowData.oem ? rowData.oem : '',
    });
  };

  // const handleShowAllClick = (prodTp: string, prodId: string, index: number) => {
  //   setLoaderDescIndex(index);
  //   setLoaderProdDesc(true);
  //   setProdDescTp('TECH_SPEC');
  //   dispatch(prodDescReq({
  //     requestType: APIHeader.REQ_GET_PRODUCT_DESC,
  //     inSts: 'TECH_SPEC',
  //     ProdTp: prodTp,
  //     ProdId: prodId,
  //   }));
  //   setSelectedProdIndex(index);
  // };

  const toggleProdDetails = (target: string, prodTp: string, prodId: string) => {
    setProdDescTp('PROD_DESC');
    setLoaderAddProduct(true);
    dispatch(prodDescReq({
      requestType: APIHeader.REQ_GET_PRODUCT_DESC,
      inSts: 'PROD_DESC',
      ProdTp: prodTp,
      ProdId: prodId,
    }));
    setProdDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleSelectCorp = (e: any) => {
    setCorpData(e.value);
    setProductInput({ ...productInput, tagIn: e.value });
  };

  const corpOptions = corpListData && corpListData.map((corp) => ({
    label: corp.nme,
    value: corp.MetaId,
  }));

  const selectedCorpOptions = corpOptions && corpOptions.find(
    (option) => option.value === corpData,
  );

  const selectedUodatedCorpOptions = corpOptions && corpOptions.find(
    (option) => option.value === productUpdateInput.tagIn,
  );

  React.useEffect(() => {
    setLoaderCorp(true);
    dispatch(getCorpListReq({
      requestType: APIHeader.REQ_ALL_CORP_META,
    }));
  }, []);

  React.useEffect(() => {
    if (!corpResData.isError) {
      setLoaderCorp(false);
      setCorpListData(corpResData.corpData);
    }
  }, [corpResData.corpData]);

  React.useEffect(() => {
    if (prodDescRes.isError && prodDescRes.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(prodDescRes.message);
      setErrorShowAlert(true);
    }
  }, [prodDescRes.isError, prodDescRes.message]);

  React.useEffect(() => {
    if (!prodDescRes.isError && prodDescRes.message === 'executed') {
      setLoaderAddProduct(false);
      if (prodDescTp === 'PROD_DESC') {
        setProductDesc(prodDescRes);
      } else if (prodDescTp === 'TECH_SPEC' && selectedProdIndex !== null) {
        setProductTechSpec(prodDescRes);
        setShowProdDesc((prevState) => {
          const updatedState = [...prevState];
          updatedState[selectedProdIndex] = true;
          return updatedState;
        });
      }
      dispatch(clearProdDescReq());
    }
  }, [prodDescRes.isError, prodDescRes.message, prodDescRes.data, selectedProdIndex, prodDescTp, clearProdDescReq]);

  const toggleDescProdDetails = (target: string) => {
    setProdDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  // const toggleProdButton = () => {
  //   dispatch(uploadProdImg({
  //     bucketNme: AWS_PRODUCT_BUCKET,
  //     folderName: 'products/56787544444',
  //     formData: prodImageFile.fileData,
  //     requestType: '',
  //   }));
  // };

  React.useEffect(() => {
    setProdImageDataFile(prodImageFile);
  }, [prodImageFile.fileData]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  const handleProductSubmit = (e:any) => {
    e.preventDefault();
    setLoaderAddProduct(true);
    Promise.resolve(
      dispatch(createProductReq({
        userType: userTp,
        requestType: APIHeader.REQ_ADD_PRODUCTS,
        inputBody: productInput,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (addProductResponse.error && addProductResponse.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(addProductResponse.message);
      setErrorShowAlert(true);
    }
  }, [addProductResponse.error, addProductResponse.message]);

  const handleUploadEditProduct = (prodId: string) => {
    setLoaderAddProduct(true);
    Promise.resolve(
      dispatch(uploadProdImg({
        bucketNme: AWS_PRODUCT_BUCKET,
        folderName: `products/${prodId}`,
        fileNmeInit: prodId,
        formData: prodImageFile.fileData,
        requestType: '',
        isEdit: true,
      })),
    );
  };

  React.useEffect(() => {
    if (!addProductResponse.error && addProductResponse.message !== '') {
      if (addProductResponse.prodId !== '') {
        // const prodIdNme = `${addProductResponse.prodId}.${mediaFileNmeExt}`;
        dispatch(uploadProdImg({
          bucketNme: AWS_PRODUCT_BUCKET,
          folderName: `products/${addProductResponse.prodId}`,
          fileNmeInit: addProductResponse.prodId,
          formData: prodImageFile.fileData,
          requestType: '',
          isEdit: false,
        }));
      } else {
        setLoaderAddProduct(false);
        setAlertMsg('Product Image upload failed');
        setErrorShowAlert(true);
      }
    }
  }, [addProductResponse.error, addProductResponse.message, addProductResponse.prodId]);

  React.useEffect(() => {
    if (uploadProductAwsResponse.fileName !== '') {
      setLoaderAddProduct(false);
      setAlertMsg('File Uploaded Successfully');
      setSuccessShowAlert(true);
      dispatch(clearCreateProductReq());
      setShowAddProdForm(false);
      setOpenModalProduct((prevState) => ({
        ...prevState,
        [modelProdIndex]: false,
      }));
      dispatch(clearUploadProdImg());
    }
  }, [uploadProductAwsResponse.fileName]);

  const handleShowAddProduct = () => {
    setShowAddProdForm(!showAddProdForm);
    setProdImageDataFile({ ...prodImageFile, fileData: new FormData() });
    // setFilePreviews([]);
    setAddProdImgList([]);
    setProductInput({
      nme: '',
      cat: '',
      techSpec: [],
      prodDesc: [],
      wrnty: 0,
      keyW: '',
      modlN: '',
      tagIn: '',
      hsn: '',
      cost: '',
      gst: '',
      oem: '',
    });
  };

  const handleProductImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const maxSizeInBytes = 5 * 1024 * 1024;

    const formData = new FormData();
    // const previews: string[] = [];

    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setAlertMsg('File size exceeds the limit of 5 MB.');
        setErrorShowAlert(true);
        return;
      }
      formData.append(file.name, file);
      const objectUrl = URL.createObjectURL(file);
      setAddProdImgList((prevList) => [
        ...prevList,
        { url: objectUrl, nme: file.name },
      ]);
      // addProdImgList.push({ url: objectUrl, nme: file.name });
      // previews.push(objectUrl);
    });
    setProdImageDataFile({ ...prodImageFile, fileData: formData });
    // setFilePreviews(previews);
    // setAddProdImgList(prodEditImgList);
  };

  // Start Prod Category List

  const prodCatOptions = ProductCatList.map((prodCat) => ({
    label: prodCat.label,
    value: prodCat.value,
  }));

  const handleSelectProdCatData = (e: any) => {
    if (e) {
      setProductInput({ ...productInput, cat: e.value });
    } else {
      setProductInput({ ...productInput, cat: '' });
    }
  };

  const selectedProdCatOptions = prodCatOptions.find(
    (option) => option.value === productInput.cat,
  );
    // End Prod Category List

  const handleFieldChange = (e:any) => {
    const fieldName = e.target.name;
    setProductInput({ ...productInput, [fieldName]: e.target.value });
  };

  const handleNumberFieldChange = (e:any) => {
    const fieldName = e.target.name;
    if (e.target.value > 0) {
      setProductInput({ ...productInput, [fieldName]: e.target.value });
    } else {
      setProductInput({ ...productInput, [fieldName]: 0 });
    }
  };

  const handleFieldUpdateChange = (e:any) => {
    const fieldName = e.target.name;
    setProductUpdateInput({ ...productUpdateInput, [fieldName]: e.target.value });
  };

  const handleUpdateNumberFieldChange = (e:any) => {
    const fieldName = e.target.name;
    if (e.target.value > 0) {
      setProductUpdateInput({ ...productUpdateInput, [fieldName]: e.target.value });
    } else {
      setProductUpdateInput({ ...productUpdateInput, [fieldName]: 0 });
    }
  };

  const handleTechSpecChange = (event: any) => {
    setTechSpec(event.target.value);
  };

  const handleUpdateTechSpec = () => {
    if (techSpec !== '') {
      if (techSpec !== '') {
        const updatedInitTechSpec = { ...productUpdateInput };
        updatedInitTechSpec.techSpec.push(techSpec);
        setProductUpdateInput(updatedInitTechSpec);
        setTechSpec('');
      }
    }
  };

  const handleAddTechSpec = () => {
    if (techSpec !== '') {
      if (techSpec !== '') {
        const updatedInitTechSpec = { ...productInput };
        updatedInitTechSpec.techSpec.push(techSpec);
        setProductInput(updatedInitTechSpec);
        setTechSpec('');
      }
    }
  };

  const handleRemoveTechSpec = (specIndx: number) => {
    const updatedInitProdSpec = { ...productInput };
    updatedInitProdSpec.techSpec.splice(specIndx, 1);
    setProductInput(updatedInitProdSpec);
  };

  const handleUpdateRemoveTechSpec = (specIndx: number) => {
    const updatedInitProdSpec = { ...productUpdateInput };
    updatedInitProdSpec.techSpec.splice(specIndx, 1);
    setProductUpdateInput(updatedInitProdSpec);
  };

  const handleProdDescChange = (event: any) => {
    setProdDesc(event.target.value);
  };

  const handleAddProdDesc = () => {
    if (prodDesc !== '') {
      if (prodDesc !== '') {
        const updatedInitProdDesc = { ...productInput };
        updatedInitProdDesc.prodDesc.push(prodDesc);
        setProductInput(updatedInitProdDesc);
        setProdDesc('');
      }
    }
  };

  const handleUpdateProdDesc = () => {
    if (prodDesc !== '') {
      if (prodDesc !== '') {
        const updatedInitProdDesc = { ...productUpdateInput };
        updatedInitProdDesc.prodDesc.push(prodDesc);
        setProductUpdateInput(updatedInitProdDesc);
        setProdDesc('');
      }
    }
  };

  const handleRemoveProdDesc = (descIndx: number) => {
    const updatedInitProdSpec = { ...productInput };
    updatedInitProdSpec.prodDesc.splice(descIndx, 1);
    setProductInput(updatedInitProdSpec);
  };

  const handleUpdateRemoveProdDesc = (descIndx: number) => {
    const updatedInitProdSpec = { ...productUpdateInput };
    updatedInitProdSpec.prodDesc.splice(descIndx, 1);
    setProductUpdateInput(updatedInitProdSpec);
  };

  const handleRemoveImage = (indexToRemove: number) => {
    // const updatedPreviews = filePreviews.filter((_, index) => index !== indexToRemove);
    const updatedPreviews = addProdImgList.filter((_, index) => index !== indexToRemove);
    const updatedFiles = new FormData();

    Array.from(prodImageFile.fileData.entries()).forEach(([key, file], index) => {
      if (index !== indexToRemove) {
        updatedFiles.append(key, file);
      }
    });

    // setFilePreviews(updatedPreviews);
    setAddProdImgList(updatedPreviews);
    setProdImageDataFile({ ...prodImageFile, fileData: updatedFiles });
  };
  React.useEffect(() => {
    if (evalKeyText === '' && evalTpText === '' && statusDet === 'product') {
      setLoaderProduct(true);
      setProductsData((prevData) => ({
        ...prevData,
        tCnt: 0,
        fCnt: 0,
        prodData: [],
        lastEvalKey: {
          ProdId: '',
          ProdTp: '',
        },
      }));
      dispatch(getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
      }));
    }
  }, [evalKeyText, evalTpText, statusDet]);

  const fetchMoreData = () => {
    setLoaderProduct(true);
    dispatch(
      getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: ApiLIMIT.LIMIT,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        isSearch: 'NO',
      }),
    );
  };

  const fetchMoreFilterData = (filterData: string) => {
    setLoaderProduct(true);
    dispatch(
      getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: ApiLIMIT.LIMIT,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'YES',
        filterBy: filterData,
        isSearch: 'NO',
      }),
    );
  };

  const handleFilterData = async (e: any) => {
    const filterBy = e.target.name;
    setLoaderProduct(true);
    Promise.resolve(
      setProductsData((prevData) => ({
        ...prevData,
        tCnt: 0,
        fCnt: 0,
        prodData: [],
        lastEvalKey: {
          ProdId: '',
          ProdTp: '',
        },
      })),
    ).then(() => {
      dispatch(
        getProductsListReq({
          requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
          isPublic: true,
          limit: ApiLIMIT.LIMIT,
          evalKey: '',
          evalTp: '',
          isFilter: 'YES',
          filterBy,
          isSearch: 'NO',
        }),
      );
    }).then(() => {
      setRadioSelected(filterBy);
    });
  };

  const handleFilterSellerData = (metaId: string) => {
    setLoaderProduct(true);
    Promise.resolve(
      setProductsData((prevData) => ({
        ...prevData,
        tCnt: 0,
        fCnt: 0,
        prodData: [],
        lastEvalKey: {
          ProdId: '',
          ProdTp: '',
        },
      })),
    ).then(() => {
      dispatch(
        dispatch(
          getProductsListReq({
            requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
            isPublic: true,
            limit: ApiLIMIT.LIMIT,
            evalKey: '',
            evalTp: '',
            isFilter: 'YES',
            filterBy: metaId,
            isSearch: 'NO',
          }),
        ),
      );
    }).then(() => {
      setRadioSelected(metaId);
    });
  };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };

  const handleSearchProduct = () => {
    setProductsData((prevData) => ({
      ...prevData,
      tCnt: 0,
      fCnt: 0,
      prodData: [],
      lastEvalKey: {
        ProdId: '',
        ProdTp: '',
      },
    }));
    setLoaderProduct(true);
    dispatch(
      getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
      }),
    );
  };

  React.useEffect(() => {
    if (listProducts.isError && listProducts.message !== '') {
      setLoaderProduct(false);
      setAlertMsg(listProducts.message);
      setErrorShowAlert(true);
    }
  }, [listProducts.isError, listProducts.message]);

  React.useEffect(() => {
    if (!listProducts.isError && listProducts.message === 'executed') {
      setLoaderProduct(false);
      const newData = listProducts.data.prodData.filter((item) => !productsData.prodData.some((existingItem) => existingItem.ProdId === item.ProdId));

      if (listProducts.data.lastEvalKey !== null) {
        setProductsData((prevData) => ({
          ...prevData,
          tCnt: listProducts.data.tCnt,
          fCnt: listProducts.data.fCnt,
          prodData: [...prevData.prodData, ...newData],
          lastEvalKey: listProducts.data.lastEvalKey,
        }));
        setEvalKeyText(listProducts.data.lastEvalKey.ProdId);
        setEvalTpText(listProducts.data.lastEvalKey.ProdTp);
        dispatch(clearProductsListReq());
      } else {
        setProductsData((prevData) => ({
          ...prevData,
          tCnt: listProducts.data.tCnt,
          fCnt: listProducts.data.fCnt,
          prodData: [...prevData.prodData, ...newData],
        }));
        setLoadingMore(false);
        dispatch(clearProductsListReq());
      }
    }
  }, [listProducts.isError, listProducts.message, listProducts.data.lastEvalKey]);

  const handleClearAll = () => {
    setRadioSelected('');
    setSearchText('');
    setEvalKeyText('');
    setEvalTpText('');
    setProductsData((prevData) => ({
      ...prevData,
      tCnt: 0,
      fCnt: 0,
      prodData: [],
      lastEvalKey: {
        ProdId: '',
        ProdTp: '',
      },
    }));
    if (userTp !== '') {
      dispatch(getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
      }));
    }
  };

  const handleUpdateProductSubmit = () => {
    setLoaderAddProduct(true);
    Promise.resolve(
      dispatch(putProductReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_PRODUCTS,
        inputBody: productUpdateInput,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (updateProductResponse.error && updateProductResponse.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(updateProductResponse.message);
      setErrorShowAlert(true);
    }
  }, [updateProductResponse.error, updateProductResponse.message]);

  React.useEffect(() => {
    if (!updateProductResponse.error && updateProductResponse.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(updateProductResponse.message);
      dispatch(clearPutProductReq());
      setSuccessShowAlert(true);
      setOpenModalProd({});
      if (userTp !== '') {
        setProductsData((prevData) => ({
          ...prevData,
          tCnt: 0,
          fCnt: 0,
          prodData: [],
          lastEvalKey: {
            ProdId: '',
            ProdTp: '',
          },
        }));
        dispatch(getProductsListReq({
          requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
          isPublic: true,
          limit: ApiLIMIT.LIMIT,
          evalKey: '',
          evalTp: '',
          isFilter: 'NO',
          isSearch: 'NO',
        }));
      }
    }
  }, [updateProductResponse.error, updateProductResponse.message]);

  const productToggleModal = (target: string, productId:string) => {
    if (productId !== '') {
      dispatch(clearGetListProdImg());
      setEditProdImgList([{ url: '', nme: '' }]);
      setLoaderAddProduct(true);
      dispatch(getListProdImg({
        requestType: '',
        bucketNme: 'powermart',
        folderName: `products/${productId}`,
      }));
    }

    setOpenModalProduct((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setModalProdIndex(target);
  };

  React.useEffect(() => {
    if (getProdImageAwsResponse.data) {
      setLoaderAddProduct(false);
      setEditProdImgList(getProdImageAwsResponse.data);
    }
  }, [getProdImageAwsResponse.data]);

  React.useEffect(() => {
    if (deleteImageAwsResponse.fileName === 'deleted') {
      setLoaderAddProduct(false);
      setAlertMsg('Files Deleted Successfully');
      setSuccessShowAlert(true);
      dispatch(clearDeleteProdImg());
      setShowAddProdForm(false);
      setOpenModalProduct((prevState) => ({
        ...prevState,
        [modelProdIndex]: false,
      }));
      dispatch(clearUploadProdImg());
    }
  }, [deleteImageAwsResponse.fileName]);

  const isImage = (fileName: string) => /\.(jpg|jpeg|png|gif)$/i.test(fileName);

  const isPDF = (fileName: string) => /\.pdf$/i.test(fileName);

  const handleDelChangeImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (checked) {
      setSelectedDelImages((prevImages) => [...prevImages, name]);
    } else {
      setSelectedDelImages((prevImages) => prevImages.filter((imgName) => imgName !== name));
    }
  };

  const isProdEditImgListEmpty = (list: FileDetails[]) => (
    list.length === 0 || list.every((item) => item.url === '' && item.nme === '')
  );

  const handleDeleteProductImage = (prodId: string) => {
    setLoaderAddProduct(true);
    Promise.resolve(
      dispatch(deleteProdImg({
        bucketNme: AWS_PRODUCT_BUCKET,
        folderName: `products/${prodId}`,
        delFiles: selectedDelImages,
        requestType: '',
      })),
    );
  };

  const handleUserManualDownload = (downloadUrl: string, fileName: string) => {
    if (downloadUrl) {
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.download = `${fileName}.pdf`;
      anchor.target = '_blank';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  };

  const handleUpdateEditProd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const maxSizeInBytes = 5 * 1024 * 1024;
    const formData = new FormData();
    const newProdImgList: FileDetails[] = [];

    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setAlertMsg('File size exceeds the limit of 5 MB.');
        setErrorShowAlert(true);
        return;
      }
      formData.append(file.name, file);
      // formData.append('fileData', file); // Use the same name for all files
      const objectUrl = URL.createObjectURL(file);
      newProdImgList.push({ url: objectUrl, nme: file.name });
    });

    setEditProdImgList((prevList) => [...prevList, ...newProdImgList]);

    setProdImageDataFile({ ...prodImageFile, fileData: formData });
  };

  const oemOptions = ListCPOptions.map((oemCat) => ({
    label: oemCat.label,
    value: oemCat.value,
  }));

  const selectedOEMOptions = oemOptions.find(
    (option) => option.value === productInput.oem,
  );

  const handleSelectOEMData = (e: any) => {
    if (e) {
      setProductInput({ ...productInput, oem: e.value });
    } else {
      setProductInput({ ...productInput, oem: '' });
    }
  };

  const selectedUpdateOEMOptions = oemOptions.find(
    (option) => option.value === productUpdateInput.oem,
  );

  const handleSelectUpdateOEMData = (e: any) => {
    if (e) {
      setProductUpdateInput({ ...productUpdateInput, oem: e.value });
    } else {
      setProductUpdateInput({ ...productUpdateInput, oem: '' });
    }
  };

  const handleDelProd = (target: string) => {
    setWhichModelDel(target);
    setModalDelProd((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleSubmitDelProd = (status: string, target: string, prodType:string, prodId:string) => {
    if (status === 'approve') {
      setProductDelId(prodId);
      setProductDelType(prodType);
      setLoaderProduct(true);
      Promise.resolve(
        dispatch(deleteAWSFolder({
          bucketNme: AWS_PRODUCT_BUCKET,
          folderName: `products/${prodId}`,
          requestType: '',
        })),
      );
    } else if (status === 'cancel') {
      setModalDelProd((prevState) => ({
        ...prevState,
        [target]: false,
      }));
    }
    setModalDelProd((prevState) => ({
      ...prevState,
      [target]: false,
    }));
  };

  React.useEffect(() => {
    if (deleteAwsFolderResponse.folderName === 'deleted') {
      Promise.resolve(
        dispatch(delProdReq({
          requestType: APIHeader.REQ_DELETE_PRODUCTS,
          userType: userTp,
          token: tokenData,
          ProdTp: productDelType,
          ProdId: productDelId,
        })),
      );
    }
  }, [deleteAwsFolderResponse.folderName]);

  React.useEffect(() => {
    if (delProdRes.error && delProdRes.message !== '') {
      setLoaderProduct(false);
      dispatch(clearDelProdReq());
      dispatch(clearDeleteAWSFolder());
    }
  }, [delProdRes.error, delProdRes.message]);

  React.useEffect(() => {
    if (!delProdRes.error && delProdRes.message === 'executed') {
      dispatch(clearDelProdReq());
      dispatch(clearDeleteAWSFolder());
      setProductsData((prevData) => ({
        ...prevData,
        tCnt: 0,
        fCnt: 0,
        prodData: [],
        lastEvalKey: {
          ProdId: '',
          ProdTp: '',
        },
      }));
      dispatch(getProductsListReq({
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PUB,
        isPublic: true,
        limit: ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
      }));
      setRadioSelected('');
      setModalDelProd((prevState) => ({
        ...prevState,
        [whichModelDel]: false,
      }));
      setLoaderProduct(false);
    }
  }, [delProdRes.error, delProdRes.message]);

  return (
    <div>
      { loaderAddProduct ? <LoaderData /> : null}
      { loaderProduct ? <LoaderData /> : null}
      { loadingMore ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="mx-1 pt-3">
        <Col lg="4" xs="6">
          <div className="align-start">
            <span className="card-toggle-text">
              Product Details
            </span>
          </div>
        </Col>
        {/* <Button onClick={() => toggleProdButton()}>Uplodess</Button> */}
        <Col lg="8" xs="6">
          <div className="align-end">
            {!showAddProdForm
              ? (
                <Button className="powermart-button-solid mx-1" onClick={() => handleShowAddProduct()}>
                  Add Product
                </Button>
              )
              : (
                <Button className="powermart-button-solid mx-1" onClick={() => handleShowAddProduct()}>
                  Back
                </Button>
              )}
          </div>
        </Col>
      </Row>
      {/* <Row className="align-center mx-2">
        <Col lg="11" xs="12">
          <InputGroup className="pt-2">
            <Input
              type="text"
              className="powermart-input"
              placeholder="Search products...."
            />
          </InputGroup>
        </Col>
      </Row> */}
      {showAddProdForm
        ? (
          <Row className="mx-1 align-center">
            <Col lg="10" xs="12">
              <Card className="card-lite my-2">
                <Row className="mx-1">
                  <Col lg="5" xs="12">
                    <div className="align-center my-4">
                      {addProdImgList.length === 0
                        ? <CardImg className="img-fluid product-preview" src="/images/broken-image.png" alt="broken img" />
                        : (
                          <Row className="align-center">
                            <span className="text-lite-grey">{Array.from(prodImageFile.fileData.keys()).length} files in Queue</span>
                            {addProdImgList.map((preview, indexImg) => (
                              <Col lg="3" key={`prodImg-${indexImg.toString()}`} className="border-image mx-1">
                                <div className="align-end">
                                  <Button className="button-icon padding-zero" onClick={() => handleRemoveImage(indexImg)}>
                                    <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                                  </Button>
                                </div>
                                {isImage(preview.nme) ? (
                                  <div className="pt-1">
                                    <span className="text-lite-grey">{preview.nme}</span>
                                    <CardImg className="seller-image-dim" src={preview.url} alt={`ImgData ${indexImg + 1}`} />
                                  </div>
                                ) : isPDF(preview.nme) ? (
                                  <div>
                                    <span className="text-lite-grey">{preview.nme}</span>
                                    <object
                                      data={preview.url}
                                      type="application/pdf"
                                      width="100%"
                                      height="100%"
                                      aria-label={`PDFData ${indexImg + 1}`}
                                      className="pdf-preview-object"
                                    >
                                      <p>No Preview</p>
                                    </object>
                                  </div>
                                ) : (
                                  <p>Unsupported file type</p>
                                )}
                                {/* <CardImg className="img-fluid" src={preview} alt={`Preview ${index + 1}`} /> */}
                              </Col>
                            ))}
                          </Row>
                        )}
                    </div>
                    <div className="align-center">
                      <Input
                        type="file"
                        accept=".png, .jpg, .jpeg, .pdf"
                        name="fileData"
                        multiple
                        className="product-image-btn product-img"
                        onChange={(e) => handleProductImage(e)}
                      />
                    </div>
                  </Col>
                  <Col lg="7" xs="12">
                    <div className="align-center pt-2">
                      <span className="powermart-dark-icon">
                        Add New Product
                      </span>
                    </div>
                    <Form onSubmit={handleProductSubmit}>
                      <Row className="mx-1">
                        <Col lg="8" className="pt-4">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">Product Name<span className="mandate-star">*</span></h6>
                          </div>
                          <Input
                            placeholder="Enter product name"
                            type="text"
                            required
                            name="nme"
                            value={productInput.nme}
                            onChange={handleFieldChange}
                            className="powermart-input"
                          />
                        </Col>
                        <Col lg="4" className="pt-4">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">HSN Code<span className="mandate-star">*</span></h6>
                          </div>
                          <Input
                            placeholder="Enter HSN Code"
                            type="text"
                            required
                            name="hsn"
                            value={productInput.hsn}
                            onChange={handleFieldChange}
                            className="powermart-input"
                          />
                        </Col>
                        <Col lg="6" className="pt-4">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">Define Model<span className="mandate-star">*</span></h6>
                          </div>
                          <Input
                            placeholder="Enter Model"
                            type="text"
                            required
                            name="modlN"
                            value={productInput.modlN}
                            onChange={handleFieldChange}
                            className="powermart-input"
                          />
                        </Col>
                        <Col lg="6" className="pt-4">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">OEM/Category<span className="mandate-star">*</span></h6>
                          </div>
                          <Select
                            value={productInput.oem === '' ? null : selectedOEMOptions}
                            options={oemOptions}
                            onChange={handleSelectOEMData}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({
                                ...base,
                              }),
                              control: (base) => ({
                                ...base,
                                '&:hover': { borderColor: '#05cbddff' },
                                border: '1px solid #BFBFBF',
                                minHeight: '45px',
                                borderRadius: '5px',
                                padding: 1,
                                textAlign: 'left',
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#383838',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided, state) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                            noOptionsMessage={() => 'No OEM Found'}
                            placeholder="Select OEM"
                          />
                        </Col>
                        <Col lg="12" className="pt-4">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">Tag Product<span className="mandate-star">*</span></h6>
                          </div>
                          <Select
                            value={corpData === '' ? null : selectedCorpOptions}
                            options={corpOptions}
                            onChange={handleSelectCorp}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({
                                ...base,
                                // position: 'relative',
                              }),
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#05cbddff' },
                                border: '1px solid #BFBFBF',
                                minHeight: '45px',
                                borderRadius: '5px',
                                padding: 1,
                                textAlign: 'left',
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#383838',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                            isLoading={loaderCorp}
                            noOptionsMessage={() => 'No Organization Found'}
                            placeholder="Select Organization"
                          />
                        </Col>
                        <Col lg="12" className="pt-4">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">Category<span className="mandate-star">*</span></h6>
                          </div>
                          <Select
                            value={productInput.cat === '' ? null : selectedProdCatOptions}
                            options={prodCatOptions}
                            onChange={handleSelectProdCatData}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({
                                ...base,
                              // position: 'relative',
                              }),
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#05cbddff' },
                                border: '1px solid #BFBFBF',
                                minHeight: '45px',
                                borderRadius: '5px',
                                padding: 1,
                                textAlign: 'left',
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#383838',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                            placeholder="Select Product Category"
                          />
                        </Col>
                        <Col lg="5" className="pt-3">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">Warranty(months)<span className="mandate-star">*</span></h6>
                          </div>
                          <Input
                            placeholder="Warranty in month"
                            type="number"
                            required
                            name="wrnty"
                            value={productInput.wrnty || ''}
                            onChange={handleNumberFieldChange}
                            className="powermart-input"
                          />
                        </Col>
                        <Col lg="5" xs="7" className="pt-3">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">Cost<span className="mandate-star">*</span></h6>
                          </div>
                          <Input
                            type="number"
                            required
                            name="cost"
                            value={productInput.cost || ''}
                            onChange={handleNumberFieldChange}
                            className="powermart-input"
                          />
                        </Col>
                        <Col lg="2" xs="5" className="pt-3">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">GST<span className="mandate-star">*</span></h6>
                          </div>
                          <Input
                            type="number"
                            required
                            name="gst"
                            value={productInput.gst || ''}
                            onChange={handleNumberFieldChange}
                            className="powermart-input"
                          />
                        </Col>
                        <Col lg="12" className="pt-3">
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">Keyword<span className="mandate-star">*</span></h6>
                          </div>
                          <Input
                            placeholder="Enter Keyword Model"
                            type="text"
                            required
                            name="keyW"
                            value={productInput.keyW}
                            onChange={handleFieldChange}
                            className="powermart-input"
                          />
                          <div className="align-start">
                            <h6 className="text-lite-grey mx-2 my-1">Supply keywords separated by commas. This will aid buyers in quick searches.</h6>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="text-right-login-campus-register mx-1 pt-4">
                            <div className="align-start">
                              <h6 className="text-lite-grey mx-2 my-1">Tech Specifications</h6>
                            </div>
                            <InputGroup>
                              <Input
                                type="text"
                                placeholder="Provide Tech Specifications"
                                className="powermart-input"
                                value={techSpec}
                                onChange={(e) => handleTechSpecChange(e)}
                              />
                              <InputGroupText className="powermart-input mx-1 cursor-pointer" onClick={() => handleAddTechSpec()}>
                                <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                              </InputGroupText>
                            </InputGroup>
                            {productInput.techSpec.length > 0
                              ? (
                                <Card className="my-3 meta-card">
                                  <span className="my-1 text-lite-grey ml-1">Tech Specification Details</span>
                                  {productInput.techSpec.map((techSpecData, techSpecIndx) => (
                                    <div key={`techSpec-${techSpecIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                      <Row className="mx-1 pt-2">
                                        <Col lg="11" xs="10">
                                          <div className="pt-1">
                                            <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                            <span className="ml-1">{ techSpecData }</span>
                                          </div>
                                        </Col>
                                        <Col lg="1" xs="2">
                                          <div className="align-end">
                                            <Button className="button-icon" onClick={() => handleRemoveTechSpec(techSpecIndx)}>
                                              <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </Card>
                              ) : null }
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="text-right-login-campus-register mx-1 pt-4">
                            <div className="align-start">
                              <h6 className="text-lite-grey mx-2 my-1">Product Description</h6>
                            </div>
                            <InputGroup>
                              <Input
                                type="textarea"
                                placeholder="Provide Product Description"
                                className="powermart-input-desc"
                                value={prodDesc}
                                onChange={(e) => handleProdDescChange(e)}
                              />
                              <InputGroupText className="powermart-input mx-1 cursor-pointer my-4" onClick={() => handleAddProdDesc()}>
                                <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                              </InputGroupText>
                            </InputGroup>
                            {productInput.prodDesc.length > 0
                              ? (
                                <Card className="my-3 meta-card">
                                  <span className="my-1 text-lite-grey ml-1">Product Description Details</span>
                                  {productInput.prodDesc.map((descData, descIndx) => (
                                    <div key={`prodDesc-${descIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                      <Row className="mx-1 pt-2">
                                        <Col lg="11" xs="10">
                                          <div className="pt-1">
                                            <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                            <span className="ml-1">{ descData }</span>
                                          </div>
                                        </Col>
                                        <Col lg="1" xs="2">
                                          <div className="align-end">
                                            <Button className="button-icon" onClick={() => handleRemoveProdDesc(descIndx)}>
                                              <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                    </div>
                                  ))}
                                </Card>
                              ) : null }
                          </div>
                        </Col>
                      </Row>
                      <div className="align-center pt-4 mx-2">
                        <Button
                          className="powermart-button-fullwidth mx-1"
                          disabled={Array.from(prodImageFile.fileData.keys()).length === 0}
                        >
                          SAVE
                        </Button>
                      </div>
                    </Form>
                  </Col>
                </Row>
                <div className="pt-5" />
              </Card>
            </Col>
          </Row>
        )
        : (
          <Row>
            <Col lg={{ size: 3, order: 1 }} xs={{ order: 1 }}>
              <div className="pt-3 ml-1">
                <Card className="key-highlights-cards">
                  <CardHeader className="input-search-layer">
                    <div className="my-2">
                      <InputGroup>
                        <Input
                          type="text"
                          className="input-search-button"
                          placeholder="Search..."
                          value={searchText}
                          onChange={handleSearchChange}
                        />
                        <InputGroupText className="input-search-layer">
                          <Button className="button-icon align-center" onClick={handleSearchProduct}>
                            <FontAwesomeIcon icon={faMagnifyingGlass} className="round-small-icon" />
                          </Button>
                        </InputGroupText>
                      </InputGroup>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="pt-1">
                      <span className="listing-price-text">Categories</span>
                      <div className="pt-2">
                        <FormGroup>
                          <Label check className="cat-check-text">
                            <Input
                              type="radio"
                              role="switch"
                              name="DIST"
                              onChange={handleFilterData}
                              checked={radioSelected === 'DIST'}
                              className="powermart-check-box mr-1 cursor-pointer"
                            />
                            Distribution
                          </Label>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup>
                          <Label check className="cat-check-text">
                            <Input
                              type="radio"
                              role="switch"
                              name="TRANS"
                              onChange={handleFilterData}
                              checked={radioSelected === 'TRANS'}
                              className="powermart-check-box mr-1 cursor-pointer"
                            />
                            Transmission
                          </Label>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup>
                          <Label check className="cat-check-text">
                            <Input
                              type="radio"
                              role="switch"
                              name="THRML"
                              disabled
                              onChange={handleFilterData}
                              checked={radioSelected === 'THRML'}
                              className="powermart-check-box mr-1 cursor-pointer"
                            />
                            Thermal Generation
                          </Label>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup>
                          <Label check className="cat-check-text">
                            <Input
                              type="radio"
                              role="switch"
                              name="HYDRO"
                              disabled
                              onChange={handleFilterData}
                              checked={radioSelected === 'HYDRO'}
                              className="powermart-check-box mr-1 cursor-pointer"
                            />
                            Hydro
                          </Label>
                        </FormGroup>
                      </div>
                      <div>
                        <FormGroup>
                          <Label check className="cat-check-text">
                            <Input
                              type="radio"
                              role="switch"
                              name="RNWE"
                              disabled
                              onChange={handleFilterData}
                              checked={radioSelected === 'RNWE'}
                              className="powermart-check-box mr-1 cursor-pointer"
                            />
                            Renewable Energy
                          </Label>
                        </FormGroup>
                      </div>
                      <div className="pt-1" />
                    </div>
                    <div className="pt-2">
                      <span className="listing-price-text">Sellers</span>
                      <div className="pt-1">
                        {corpListData && corpListData.length > 0
                          ? corpListData.map((corpDet, corpIndex) => (
                            <div className="pt-1" key={`corpDet-${corpIndex.toString()}`}>
                              <FormGroup>
                                <Label check className="seller-filter-text">
                                  <Input
                                    type="radio"
                                    role="switch"
                                    name={corpDet.MetaId}
                                    onChange={() => handleFilterSellerData(corpDet.MetaId)}
                                    checked={radioSelected === corpDet.MetaId}
                                    className="powermart-check-box mr-1 cursor-pointer"
                                  />
                                  {corpDet.nme}
                                </Label>
                              </FormGroup>
                            </div>
                          )) : <div className="pt-4 align-center"><span className="form-collapse-card-header">No Sellers Found</span></div>}
                      </div>
                    </div>
                  </CardBody>
                  <CardFooter className="border-up-listing">
                    <div className="my-2 align-end">
                      <Button className="powermart-button-link" onClick={handleClearAll}>
                        clear all
                      </Button>
                    </div>
                  </CardFooter>
                </Card>
              </div>
            </Col>
            <Col lg={{ size: 9, order: 2 }} xs={{ order: 2 }}>
              <span className="text-lite-grey mx-2">Count: {productsData && productsData.prodData.length} / {productsData.tCnt}</span>
              <Row className="mx-1">
                {productsData && productsData.prodData.length > 0
                  ? productsData.prodData.map((prodDet, index) => (
                    <Col lg={{ size: 12, order: 1 }} xs={{ order: 1 }} key={`pending-${index.toString()}`}>
                      <Card className="card-borderless my-2">
                        <CardBody>
                          <Row>
                            <Col lg="4" xs="12">
                              <div className="mx-1 my-2 pt-2">
                                <ImageGallery imageGal={prodDet.prodUrl} isDesc={false} />
                              </div>
                              {/* <div className="my-2 mx-2">ImageGallery
                            <CardImg alt="logo" className="product-image" src={prodDet.prodUrl} />
                          </div> */}
                            </Col>
                            <Col lg="5" xs="12">
                              <div className="meta-margin-mobile pt-3">
                                <div className="align-start">
                                  <span className="product-name-header">{prodDet.nme}
                                    <span className="meta-price-lite ml">({prodDet.ProdTp})</span>
                                  </span>
                                </div>
                                <div className="my-2 meta-margin-mobile">
                                  <div className="pt-2" key={`prodDet-${index.toString()}`}>
                                    {showProdDesc[index] && (
                                      <div>
                                        {productTechSpec.data.techSpec.map((prodSpecData, pSepcIndx) => (
                                          <div key={`pSpec-${pSepcIndx.toString()}`} className="my-2 align-start">
                                            <div className="mr">
                                              <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                            </div>
                                            <div>
                                              <span className="prod-tech-spec-text"><ShowMoreText text={prodSpecData} wordLimit={10} /></span>
                                            </div>
                                          </div>
                                        ))}
                                        <div className="align-end">
                                          <Button className="powermart-button-link" onClick={() => handleHideClick(index)}>
                                            hide...
                                          </Button>
                                        </div>
                                      </div>
                                    )}
                                    {!showProdDesc[index] && (
                                      <div>
                                        {prodDet.techSpec.map((techSpecData, tSepcIndx) => (
                                          <div key={`tSpec-${tSepcIndx.toString()}`} className="my-2 align-start">
                                            <div className="mr">
                                              <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                            </div>
                                            <div>
                                              <span className="prod-tech-spec-text"><ShowMoreText text={techSpecData} wordLimit={10} /></span>
                                            </div>
                                          </div>
                                        ))}
                                        {/* <div className="align-end">
                                          <Button className="powermart-button-link" onClick={() => handleShowAllClick(prodDet.ProdTp, prodDet.ProdId, index)}>
                                            show all {loaderProdDesc && index === loaderDescIndex ? <><Spinner type="grow" className="spin-grow-loader" /><Spinner type="grow" className="spin-grow-loader" /><Spinner type="grow" className="spin-grow-loader" /></> : null}
                                          </Button>
                                        </div> */}
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg="3" xs="12">
                              <div className="align-end">
                                <Tooltip
                                  content="Image Upload/Delete"
                                  position="left"
                                  customClassName={`imageEdit-${index}`}
                                >
                                  <Button id={`imageEdit-${index}`} className="button-icon" onClick={() => productToggleModal(`productDet-${index}`, prodDet.ProdId)}>
                                    <FontAwesomeIcon icon={faImages} />
                                  </Button>
                                </Tooltip>
                                <Modal
                                  show={openModalProduct[`productDet-${index}`] || false}
                                  onClose={() => productToggleModal(`productDet-${index}`, '')}
                                  disCancel={false}
                                  targetId={`productDet-${index}`}
                                  title={prodDet.nme}
                                  size="XL"
                                >
                                  <Row className="pt-1">
                                    {isProdEditImgListEmpty(prodEditImgList)
                                      ? <div className="pt-2 align-center"><span className="form-collapse-card-header">No Product Image Found</span></div>
                                      : (prodEditImgList.map((imgDet, imgIndex) => (
                                        <Col lg="4" key={`imgList-${imgIndex.toString()}`} className="border-image">
                                          <div className="align-end">
                                            <Input
                                              type="checkbox"
                                              name={imgDet.nme}
                                              onChange={handleDelChangeImages}
                                              className="powermart-check-box cursor-pointer"
                                            />
                                          </div>
                                          {isImage(imgDet.nme) ? (
                                            <div>
                                              <div className="align-center pt-1">
                                                <span className="text-lite-grey">{imgDet.nme}</span>
                                              </div>
                                              <div className="align-center pt-1">
                                                <CardImg className="seller-image-dim" src={imgDet.url} alt={`ImgData ${imgIndex + 1}`} />
                                              </div>
                                            </div>
                                          ) : isPDF(imgDet.nme) ? (
                                            <div>
                                              <div className="align-center pt-1">
                                                <span className="text-lite-grey">{imgDet.nme}</span>
                                              </div>
                                              <div className="align-center pt-1">
                                                <object
                                                  id={imgDet.nme}
                                                  data={imgDet.url}
                                                  type="application/pdf"
                                                  className="seller-image-dim"
                                            // width="auto"
                                            // height="seller-image-dim"
                                                  style={{ display: 'block' }}
                                                >
                                                  <p>No Preview Available</p>
                                                  <Tooltip
                                                    content="Download PDF"
                                                    position="bottom"
                                                    customClassName={`userEditMaual-${imgIndex}`}
                                                  >
                                                    <Button id={`userEditMaual-${imgIndex}`} className="button-icon" onClick={() => handleUserManualDownload(imgDet.url, imgDet.nme.split('.')[0])}>
                                                      <FontAwesomeIcon icon={faFilePdf} className="button-icon-small-pdf" />
                                                    </Button>
                                                  </Tooltip>
                                                </object>
                                              </div>
                                            </div>
                                          ) : (
                                            <p>Unsupported file type</p>
                                          )}
                                        </Col>
                                      )))}
                                  </Row>
                                  {/* <hr className="my-3 hr-horz" /> */}
                                  <Row className="my-3 pt-3 align-center">
                                    <Col xs="12" lg="11">
                                      <div className="upload-box">
                                        <Input
                                          type="file"
                                          accept=".png, .jpg, .jpeg, .pdf"
                                          name="fileData"
                                          multiple
                                          className="upload-box-input"
                                          onChange={(e) => handleUpdateEditProd(e)}
                                        />
                                        <span className="register-file-link">Upload New Files</span>
                                      </div>
                                    </Col>
                                    <Col xs="12" lg="11">
                                      <div className="align-center pt-4">
                                        <Button disabled={selectedDelImages.length === 0} className="powermart-button-red mx-1" onClick={() => handleDeleteProductImage(prodDet.ProdId)}>
                                          DELETE
                                        </Button>
                                        <Button disabled={Array.from(prodImageFile.fileData.keys()).length === 0} className="powermart-button-solid mx-1" onClick={() => handleUploadEditProduct(prodDet.ProdId)}>
                                          UPLOAD
                                        </Button>
                                      </div>
                                    </Col>
                                  </Row>
                                  <div className="pt-4" />
                                </Modal>

                              </div>
                              <div className="meta-margin-mobile">
                                <div className="pt-2">
                                  <div className="align-center">
                                    <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                                  </div>
                                  <div className="align-center pt-2">
                                    <span className="meta-price-lite">Cost: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.costVal))}</span>
                                  </div>
                                  <div className="align-center">
                                    <span className="meta-price-lite">GST: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.gstVal))}</span>
                                  </div>
                                </div>
                                <div className="pt-4">
                                  <div className="align-center">
                                    <span className="meta-waranty-lite">warranty {prodDet.wrnty} months</span>
                                  </div>
                                </div>
                                <div className="pt-4 align-center mx-2">
                                  <Button className="powermart-button-fullwidth-red mx-1" onClick={() => toggleProdDetails(`prodDetails-${index}`, prodDet.ProdTp, prodDet.ProdId)}>PRODUCT DETAILS</Button>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                        <div className="align-start ml">
                          <Button className="button-icon padding-zero" onClick={() => handleDelProd(`delInfo-${index}`)}>
                            <FontAwesomeIcon icon={faTrashCan} className="super-metadata-name" />
                          </Button>
                          <Modal
                            show={modalDelProd[`delInfo-${index}`] || false}
                            onClose={() => (false)}
                            disCancel
                            targetId="orderHelp"
                            title="You are about to remove"
                            size="MD"
                          >
                            <div className="my-2 align-center">
                              <span className="mx-2 notification-text" />
                            </div>
                            <div className="my-2 align-center">
                              <span className="card-toggle-text">{prodDet.nme}</span>
                            </div>
                            <div className="my-4">
                              <Row className="align-center">
                                <Col lg="3" xs="3">
                                  <CardImg src={prodDet.prodUrl[0]} alt="Prod Image" className="mx-1" />
                                </Col>
                              </Row>
                            </div>
                            <div className="align-center my-3 mx-2">
                              <Button className="powermart-button-fullwidth mx-1" onClick={() => handleSubmitDelProd('cancel', `delInfo-${index}`, prodDet.ProdTp, prodDet.ProdId)}>
                                CANCEL
                              </Button>
                              <Button className="powermart-button-fullwidth-red mx-1" onClick={() => handleSubmitDelProd('approve', `delInfo-${index}`, prodDet.ProdTp, prodDet.ProdId)}>
                                DELETE
                              </Button>
                            </div>
                          </Modal>
                        </div>
                      </Card>
                      <Offcanvas
                        direction="end"
                        isOpen={prodDetailsOpen[`prodDetails-${index}`]}
                        toggle={() => toggleDescProdDetails(`prodDetails-${index}`)}
                      >
                        <OffcanvasHeader toggle={() => toggleDescProdDetails(`prodDetails-${index}`)} className="margin-0 mx-1" />
                        <div className="align-start border-bottom-box">
                          <span className="product-name-details-header my-1">{prodDet.nme}
                            <Button className="button-icon padding-zero ml-1 mb-2" onClick={() => prodToggleModal(`prodDet-${index}`, prodDet, productDesc.data.prodDesc, productDesc.data.techSpec)}>
                              <FontAwesomeIcon icon={faEdit} className="wrong-pass-key" />
                            </Button>
                          </span>
                          <Modal
                            show={openModalProd[`prodDet-${index}`] || false}
                            onClose={() => prodToggleModal(`prodDet-${index}`, prodDet, productDesc.data.prodDesc, productDesc.data.techSpec)}
                            disCancel={false}
                            targetId="orderHelp"
                            title={prodDet.nme}
                            size="XL"
                          >
                            <Row className="mx-2">
                              <Col lg="8" className="pt-1">
                                <div className="align-start pt-2">
                                  <h6 className="text-lite-grey mx-2 my-1">Product Name<span className="mandate-star">*</span></h6>
                                </div>
                                <Input
                                  placeholder="Enter product name"
                                  type="text"
                                  required
                                  name="nme"
                                  value={productUpdateInput.nme}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="4" className="pt-1">
                                <div className="align-start pt-2">
                                  <h6 className="text-lite-grey mx-2 my-1">HSN Code<span className="mandate-star">*</span></h6>
                                </div>
                                <Input
                                  placeholder="Enter HSN Code"
                                  type="text"
                                  required
                                  name="hsn"
                                  value={productUpdateInput.hsn}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="5" xs="5" className="pt-3">
                                <div className="align-start">
                                  <h6 className="text-lite-grey mx-2 my-1">Warranty(months)<span className="mandate-star">*</span></h6>
                                </div>
                                <Input
                                  placeholder="Warranty in month"
                                  type="number"
                                  required
                                  name="wrnty"
                                  value={productUpdateInput.wrnty || ''}
                                  onChange={handleUpdateNumberFieldChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="4" xs="5" className="pt-3">
                                <div className="align-start">
                                  <h6 className="text-lite-grey mx-2 my-1">Cost<span className="mandate-star">*</span></h6>
                                </div>
                                <Input
                                  type="number"
                                  required
                                  name="cost"
                                  value={productUpdateInput.cost || ''}
                                  onChange={handleUpdateNumberFieldChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="3" xs="3" className="pt-3">
                                <div className="align-start">
                                  <h6 className="text-lite-grey mx-2 my-1">GST<span className="mandate-star">*</span></h6>
                                </div>
                                <Input
                                  type="number"
                                  required
                                  name="gst"
                                  value={productUpdateInput.gst || ''}
                                  onChange={handleUpdateNumberFieldChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="6" className="pt-3">
                                <div className="align-start">
                                  <h6 className="text-lite-grey mx-2 my-1">Model<span className="mandate-star">*</span></h6>
                                </div>
                                <Input
                                  placeholder="Enter Model"
                                  type="text"
                                  required
                                  name="modlN"
                                  value={productUpdateInput.modlN}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="6" className="pt-3">
                                <div className="align-start">
                                  <h6 className="text-lite-grey mx-2 my-1">OEM/Category<span className="mandate-star">*</span></h6>
                                </div>
                                <Select
                                  value={productUpdateInput.oem === '' ? null : selectedUpdateOEMOptions}
                                  options={oemOptions}
                                  onChange={handleSelectUpdateOEMData}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (base) => ({
                                      ...base,
                                    }),
                                    control: (base) => ({
                                      ...base,
                                      '&:hover': { borderColor: '#05cbddff' },
                                      border: '1px solid #BFBFBF',
                                      minHeight: '45px',
                                      borderRadius: '5px',
                                      padding: 1,
                                      textAlign: 'left',
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      color: '#383838',
                                      boxShadow: 'none',
                                      backgroundColor: '#fff',
                                      '&:focus': {
                                        borderColor: '#E1EDF8',
                                        boxShadow: 'none',
                                        color: '#575656',
                                        background: '#fff',
                                      },
                                    }),
                                    option: (provided, state) => ({
                                      ...provided,
                                      color: state.isSelected ? '#383838' : '#212121',
                                      padding: 15,
                                      textAlign: 'left',
                                      background: state.isSelected ? '#FAFCFB' : '#fff',
                                      '&:hover': {
                                        background: '#FAFCFB',
                                      },
                                    }),
                                  }}
                                  noOptionsMessage={() => 'No OEM Found'}
                                  placeholder="Select OEM"
                                />
                              </Col>
                              <Col lg="12" className="pt-3">
                                <div className="align-start">
                                  <h6 className="text-lite-grey mx-2 my-1">Keyword<span className="mandate-star">*</span></h6>
                                </div>
                                <Input
                                  placeholder="Enter Keyword"
                                  type="text"
                                  required
                                  name="keyW"
                                  value={productUpdateInput.keyW}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="12" className="pt-4">
                                <div className="align-start">
                                  <h6 className="text-lite-grey mx-2 my-1">Tag Product<span className="mandate-star">*</span></h6>
                                </div>
                                <Select
                                  value={selectedUodatedCorpOptions}
                                  options={corpOptions}
                                  onChange={handleSelectCorp}
                                  menuPortalTarget={document.body}
                                  styles={{
                                    menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                    menu: (base) => ({
                                      ...base,
                                    // position: 'relative',
                                    }),
                                    control: (base: any) => ({
                                      ...base,
                                      '&:hover': { borderColor: '#05cbddff' },
                                      border: '1px solid #BFBFBF',
                                      minHeight: '45px',
                                      borderRadius: '5px',
                                      padding: 1,
                                      textAlign: 'left',
                                      fontSize: '16px',
                                      fontWeight: 400,
                                      color: '#383838',
                                      boxShadow: 'none',
                                      backgroundColor: '#fff',
                                      '&:focus': {
                                        borderColor: '#E1EDF8',
                                        boxShadow: 'none',
                                        color: '#575656',
                                        background: '#fff',
                                      },
                                    }),
                                    option: (provided: any, state: { isSelected: any; }) => ({
                                      ...provided,
                                      color: state.isSelected ? '#383838' : '#212121',
                                      padding: 15,
                                      textAlign: 'left',
                                      background: state.isSelected ? '#FAFCFB' : '#fff',
                                      '&:hover': {
                                        background: '#FAFCFB',
                                      },
                                    }),
                                  }}
                                  isLoading={loaderCorp}
                                  noOptionsMessage={() => 'No Organization Found'}
                                  placeholder="Select Organization"
                                />
                              </Col>
                              <Col xs="12">
                                <div className="text-right-login-campus-register mx-1 pt-4">
                                  <div className="align-start">
                                    <h6 className="text-lite-grey mx-2 my-1">Tech Specifications</h6>
                                  </div>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      placeholder="Provide Tech Specifications"
                                      className="powermart-input"
                                      value={techSpec}
                                      onChange={(e) => handleTechSpecChange(e)}
                                    />
                                    <InputGroupText className="powermart-input mx-1 cursor-pointer" onClick={() => handleUpdateTechSpec()}>
                                      <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                                    </InputGroupText>
                                  </InputGroup>
                                  {productUpdateInput.techSpec.length > 0
                                    ? (
                                      <Card className="my-3 meta-card">
                                        <span className="my-1 text-lite-grey ml-1">Tech Specification Details</span>
                                        {productUpdateInput.techSpec.map((techSpecData, techSpecIndx) => (
                                          <div key={`techSpec-${techSpecIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                            <Row className="mx-1 pt-2">
                                              <Col lg="11" xs="10">
                                                <div className="pt-1">
                                                  <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                                  <span className="ml-1">{ techSpecData }</span>
                                                </div>
                                              </Col>
                                              <Col lg="1" xs="2">
                                                <div className="align-end">
                                                  <Button className="button-icon" onClick={() => handleUpdateRemoveTechSpec(techSpecIndx)}>
                                                    <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        ))}
                                      </Card>
                                    ) : null }
                                </div>
                              </Col>
                              <Col xs="12">
                                <div className="text-right-login-campus-register mx-1 pt-4">
                                  <div className="align-start">
                                    <h6 className="text-lite-grey mx-2 my-1">Product Description</h6>
                                  </div>
                                  <InputGroup>
                                    <Input
                                      type="textarea"
                                      placeholder="Provide Product Description"
                                      className="powermart-input-desc"
                                      value={prodDesc}
                                      onChange={(e) => handleProdDescChange(e)}
                                    />
                                    <InputGroupText className="powermart-input mx-1 cursor-pointer my-4" onClick={() => handleUpdateProdDesc()}>
                                      <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                                    </InputGroupText>
                                  </InputGroup>
                                  {productUpdateInput.prodDesc.length > 0
                                    ? (
                                      <Card className="my-3 meta-card">
                                        <span className="my-1 text-lite-grey ml-1">Product Description Details</span>
                                        {productUpdateInput.prodDesc.map((descData, descIndx) => (
                                          <div key={`prodDesc-${descIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                            <Row className="mx-1 pt-2">
                                              <Col lg="11" xs="10">
                                                <div className="pt-1">
                                                  <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                                  <span className="ml-1">{ descData }</span>
                                                </div>
                                              </Col>
                                              <Col lg="1" xs="2">
                                                <div className="align-end">
                                                  <Button className="button-icon" onClick={() => handleUpdateRemoveProdDesc(descIndx)}>
                                                    <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        ))}
                                      </Card>
                                    ) : null }
                                </div>
                              </Col>
                            </Row>
                            <div className="align-center pt-4 mx-2">
                              <Button
                                className="powermart-button-fullwidth mx-1"
                                onClick={handleUpdateProductSubmit}
                              >
                                UPDATE
                              </Button>
                            </div>
                            <div className="pt-4" />
                          </Modal>
                        </div>
                        <OffcanvasBody>
                          <Row>
                            <Col xs="12">
                              <div className="pt-2">
                                <div className="align-center">
                                  <span className="meta-waranty-lite">Warranty {prodDet.wrnty} months</span>
                                </div>
                              </div>
                              <ImageGallery imageGal={prodDet.prodUrl} isDesc />
                              {/* <div className="align-center">
                            <CardImg alt="logo" className="product-image" src={prodDet.prodUrl} />
                          </div> */}
                            </Col>
                            <Col xs="12">
                              <div className="my-2 meta-margin-mobile">
                                <div className="pt-4">
                                  <div className="align-center">
                                    <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                                  </div>
                                  <div className="align-center meta-price-lite">
                                    ( <span className="meta-price-lite">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.costVal))}</span>
                                    <span className="mx-2">+</span>
                                    <span className="meta-price-lite">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.gstVal))}</span>
                                    )
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col xs="12">
                              <div className="my-2 meta-margin-mobile">
                                <div className="align-start pt-3">
                                  <span className="product-name-header">Technical Specification</span>
                                </div>
                                <div className="pt-2">
                                  {productDesc.data.techSpec.map((techSpecData, tSepcIndx) => (
                                    <div key={`prodDesc-${tSepcIndx.toString()}`} className="my-1 align-start">
                                      <div className="mr">
                                        <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                      </div>
                                      <div>
                                        <span className="prod-tech-spec-text">{techSpecData}</span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Col>
                            <Col xs="12">
                              <div className="my-2 meta-margin-mobile">
                                <div className="align-start pt-3">
                                  <span className="product-name-header">Product Description</span>
                                </div>
                                <div className="pt-2">
                                  {productDesc.data.prodDesc.map((descData, descIndx) => (
                                    <div key={`prodDesc-${descIndx.toString()}`} className="my-3 align-start">
                                      <div className="mr">
                                        <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                      </div>
                                      <div>
                                        <span className="prod-tech-spec-text">{ descData }</span>
                                      </div>
                                    </div>
                                  ))}
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </OffcanvasBody>
                      </Offcanvas>
                    </Col>
                  )) : <div className="pt-4 align-center"><span className="form-collapse-card-header">No Product Found</span></div>}
              </Row>
              {radioSelected === ''
                ? (
                  <div>
                    {productsData.prodData.length === productsData.tCnt
                      ? (
                        <div>
                          <div className="align-center">
                            <span className="text-bold-grey pt-3">No More Records Avilable</span>
                          </div>
                        </div>
                      )
                      : (
                        <div className="align-center">
                          <Button disabled={productsData.prodData.length === productsData.tCnt} className="link-text-italic button-icon" onClick={fetchMoreData}>
                            Load More <FontAwesomeIcon icon={faSortDown} />
                          </Button>
                        </div>
                      )}
                  </div>
                ) : null}
              {radioSelected !== ''
                ? (
                  <div>
                    {productsData.prodData.length === productsData.tCnt
                      ? (
                        <div>
                          <div className="align-center">
                            <span className="text-bold-grey pt-3">No More Records Avilable</span>
                          </div>
                        </div>
                      )
                      : (
                        <div className="align-center">
                          <Button disabled={productsData.prodData.length === productsData.tCnt} className="link-text-italic button-icon" onClick={() => fetchMoreFilterData(radioSelected)}>
                            Load More <FontAwesomeIcon icon={faSortDown} />
                          </Button>
                        </div>
                      )}
                  </div>
                ) : null}
            </Col>
          </Row>
        ) }
    </div>
  );
};
export default ProductDetails;
