import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
// import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import {
  Routes, Route,
  NavLink,
  // useLocation,
} from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import { useNavigate } from 'react-router';
import {
  faBoxOpen,
  faCartShopping,
  faHome,
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './puser.scss';
import {
  Row, Col, Button,
  Badge,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  Nav,
} from 'reactstrap';
// import { faOpencart } from '@fortawesome/free-brands-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { CXUnauthorized } from '../../public/unauthorized/unauthorized.component';
import ModalPasskeyUpdate from '../../../components/exclusive/passkey-reset/modal-reset-passkey.component';
import { PUserDashboard } from '../../../components/exclusive/puser/dashboard/puser-dashboard.component';
import { APIHeader, DiscomDistributionOptions, DiscomTransmissionOptions } from '../../../utils/constants';
import {
  getTokenFromSessionStorage, getUserProfileSessionStorage, clearKeysFromSessionStorage, setDiscomSessionStorage,
  setDiscomUtilSessionStorage,
} from '../../../utils/service/localstorage-service';
import MyOrdersComponent from '../../../components/exclusive/puser/my-orders/my-order.component';
import { DiscomUserType } from '../../../services/register/register.types';
import { clearKartCountReq, getKartCountReq } from '../../../store/powerMart/actions';
import { AddPrivSpareDetails } from '../../../components/exclusive/puser/products/add-spare';
import KartComponent from '../../../components/exclusive/puser/cart/kart-comp/kart.component';
import OrderHistoryComponent from '../../../components/exclusive/puser/my-orders/order-history';
import PreProdKartComponent from '../../../components/exclusive/puser/cart/pre-product-cart/preprod-kart.component';
import { GetCountDet } from '../../../services/products/get-kart-count/get-kart-count.types';
import { MyProfileComponent } from '../../../components/exclusive/my-profile/profile';
import Popover from '../../../utils/Popover/Popover';
import Tooltip from '../../../utils/Tooltip/Tooltip';
import Modal from '../../../utils/Modal/Modal';

export const ExclusiveRoutes: React.FC = () => (
  <Routes>
    <Route path="dashboard" element={<PUserDashboard />} />
    <Route path="my-kart" element={<KartComponent />} />
    <Route path="my-orders" element={<MyOrdersComponent />} />
    <Route path="order-history" element={<OrderHistoryComponent />} />
    {/* <Route path="my-checkout" element={<CheckOutPrivateComponent />} /> */}
    <Route path="add-my-products" element={<AddPrivSpareDetails />} />
    <Route path="cust-prod-cart" element={<PreProdKartComponent />} />
    <Route path="my-profile" element={<MyProfileComponent />} />
  </Routes>
);

export const PUserComponent: React.FC = () => {
  const history = useNavigate();
  const dispatch = useDispatch();
  // const productStatus = getProdStsSessionStorage();
  // const location = useLocation();
  // const path = location.pathname.split('/');
  // const productStatus = getProdStsSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const userProfile = getUserProfileSessionStorage();
  const [emailId, setEmailId] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [userTp, setUserTp] = useState('');
  const [userId, setUserId] = useState('');
  // const [userSt, setUserSt] = useState('');
  const [isClickClose, setIsClickClose] = useState(false);
  const [filePreview, setFilePreview] = useState('');
  const [showFooter, setShowFooter] = useState(false);
  // const [prodStsText, setProdStsText] = React.useState('');
  const [orderTp, setOrderTp] = React.useState('');
  const [kartCount, setKartCount] = React.useState<GetCountDet>({
    itmCnt: 0,
    mSts: '',
  });
  const [preProdCount, setPreProdCount] = React.useState<GetCountDet>({
    itmCnt: 0,
    mSts: '',
  });
  // const [pendCount, setPendCount] = React.useState(0);
  const [resetPassKey, setResetPassKey] = useState(true);
  const [isResetPassKey, setIsResetPasskey] = useState(false);
  const [openUserNotification, setOpenUserNotification] = useState(false);
  const passKeyModal = () => setResetPassKey(!resetPassKey);
  const userInfoRef = React.useRef<HTMLImageElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const [discomData, setDiscomData] = useState<DiscomUserType[]>([]);
  const [selectDiscom, setSelectDiscom] = useState('');
  const kartCountProducts = useSelector((state: RootState) => state.powerMart.getKartCount);

  // React.useEffect(() => {
  //   if (tokenData !== '') {
  //     const decodedToken: any = jwt_decode(tokenData);
  //     if (decodedToken !== undefined || decodedToken !== null) {
  //       if (decodedToken.exp * 1000 < new Date().getTime()) {
  //         history('/login/puser#logn');
  //       }
  //     }
  //   }
  // }, [tokenData, history]);

  React.useEffect(() => {
    let shouldRedirect = false;
    if (tokenData !== '') {
      const decodedToken: any = jwt_decode(tokenData);
      if (decodedToken !== undefined || decodedToken !== null) {
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          shouldRedirect = true;
          history('/login/puser#logn');
        }
      }
    }
    if (shouldRedirect) {
      window.location.reload();
    }
  }, [tokenData, history]);

  const handleDiscomData = (discomUtil: string, discomTp: string) => {
    // setKartCount(0);
    // setPendCount(0);
    Promise.resolve(
      dispatch(clearKartCountReq()),
    ).then(() => {
      setSelectDiscom(discomUtil);
    }).then(() => {
      setDiscomSessionStorage(discomTp);
    }).then(() => {
      setDiscomUtilSessionStorage(discomUtil);
    })
      .then(() => {
        setOpenUserNotification(false);
      })
      .then(() => {
        history('dashboard');
      });
  };

  const handleScroll = () => {
    if (window.scrollY > 10) {
      setShowFooter(true);
    } else {
      setShowFooter(false);
    }
  };
  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  React.useEffect(() => {
    if (selectDiscom === '' && discomData && discomData.length > 0) {
      setSelectDiscom(discomData[0].discomUtil);
      setDiscomSessionStorage(discomData[0].discomTp);
      setDiscomUtilSessionStorage(discomData[0].discomUtil);
    }
  }, [selectDiscom, discomData]);

  // React.useEffect(() => {
  //   if (productStatus) {
  //     setProdStsText(productStatus);
  //   }
  // }, []);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.isPKReset) {
      setIsResetPasskey(userProfile && JSON.parse(userProfile).profileData.isPKReset);
    }
  }, [isResetPassKey, userProfile && JSON.parse(userProfile).profileData.isPKReset]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData) {
      setUserTp(userProfile && JSON.parse(userProfile).profileData.UserTp);
      setUserId(userProfile && JSON.parse(userProfile).profileData.UserCd);
      // setUserSt(userProfile && JSON.parse(userProfile).profileData.st);
      setDiscomData(userProfile && JSON.parse(userProfile).profileData.discom);
    }
  }, [userTp, userId, userProfile && JSON.parse(userProfile).profileData.UserCd, userProfile && JSON.parse(userProfile).profileData.st]);

  React.useEffect(() => {
    if (userTp !== '' && userId !== '' && selectDiscom !== '') {
      // setProdStatusSessionStorage('KART');
      dispatch(getKartCountReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_KART_COUNT,
        UserTp: userTp,
        UserCd: userId,
        status: 'KART',
        discTp: selectDiscom,
        // discomId: selectDiscom,
      }));
    }
  }, [userTp, userId, selectDiscom]);

  React.useEffect(() => {
    if (userTp !== '' && userId !== '' && selectDiscom !== '') {
      dispatch(getKartCountReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_KART_COUNT,
        UserTp: userTp,
        UserCd: userId,
        status: 'INACT',
        discTp: selectDiscom,
        // discomId: selectDiscom,
      }));
    }
  }, [userTp, userId, selectDiscom]);

  React.useEffect(() => {
    if (!kartCountProducts.isError && kartCountProducts.message === 'executed') {
      const kartCnt = kartCountProducts.data;
      if (kartCnt.mSts === 'KART') {
        setOrderTp('KART');
        setKartCount(kartCnt);
      }
      if (kartCnt.mSts === 'INACT') {
        setPreProdCount(kartCnt);
      }
    }
  }, [kartCountProducts.isError, kartCountProducts.message, kartCountProducts.data.mSts]);

  // React.useEffect(() => {
  //   if (!kartCountProducts.isError && kartCountProducts.message === 'executed') {
  //     const kartCnt = kartCountProducts.data;
  //     if (kartCnt === 0 && productStatus === 'KART') {
  //       dispatch(getKartCountReq({
  //         userType: userTp,
  //         token: tokenData,
  //         requestType: APIHeader.REQ_GET_KART_COUNT,
  //         UserTp: userTp,
  //         UserCd: userId,
  //         status: 'PEND',
  //         discomId: selectDiscom,
  //       }));
  //       setProdStatusSessionStorage('PEND');
  //     }
  //     if (kartCnt === 0 && productStatus === 'PEND') {
  //       setProdStatusSessionStorage('KART');
  //       setOrderTp('KART');
  //       setKartCount(kartCnt);
  //     }
  //     if (kartCnt > 0 && productStatus === 'KART') {
  //       setOrderTp('KART');
  //       setKartCount(kartCnt);
  //     }
  //     if (kartCnt > 0 && productStatus === 'PEND') {
  //       setOrderTp('PEND');
  //       setPendCount(kartCnt);
  //     }
  //   }
  // }, [kartCountProducts.isError, kartCountProducts.message, productStatus]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.eId === '') {
      setEmailId('');
    } else {
      setEmailId(userProfile && JSON.parse(userProfile).profileData.eId);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.eId]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.ph === '') {
      setPhone('');
    } else {
      setPhone(userProfile && JSON.parse(userProfile).profileData.ph);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.ph]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.nme === '') {
      setName('');
    } else {
      setName(userProfile && JSON.parse(userProfile).profileData.nme);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.nme]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.avatar) {
      setFilePreview(userProfile && JSON.parse(userProfile).profileData.avatar);
    }
  }, [
    userProfile && JSON.parse(userProfile).profileData.avatar,
  ]);

  const handleUserNotification = () => {
    setOpenUserNotification(!openUserNotification);
  };

  const handleMyOrder = () => {
    setOpenUserNotification(false);
    setIsClickClose(true);
    history('my-orders');
  };

  const handlePreProdKart = () => {
    history('cust-prod-cart');
  };

  const handleKartPendingData = (penKartOrder: string) => {
    // if (penKartOrder === 'PEND') {
    //   history('my-checkout');
    // }
    if (penKartOrder === 'KART') {
      history('my-kart');
    }
  };

  const truncateText = (text: string, maxLetters: number) => {
    if (text.length > maxLetters) {
      const truncatedText = text.slice(0, maxLetters);
      return `${truncatedText}...`;
    }
    return text;
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        userInfoRef.current
        && !userInfoRef.current.contains(event.target as Node)
        && popoverRef.current
        && !popoverRef.current.contains(event.target as Node)
      ) {
        setOpenUserNotification(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {tokenData !== '' ? (
        <div>
          {userProfile && JSON.parse(userProfile).profileData.isPKReset ? (
            <Modal
              show={resetPassKey || false}
              onClose={passKeyModal}
              disCancel={false}
              targetId="passwordResetAdmin"
              title="Update Password"
              size="MD"
            >
              <ModalPasskeyUpdate setIsResetPasskey={setIsResetPasskey} userType="SBDUSR" />
            </Modal>
          )
            : null}
          <Row className="navbar-puser">
            <Col lg="8" xs="11">
              <div className="mx-1 my-2 align-start">
                <div className="bg-icon-nav-dash">
                  <NavLink to="dashboard">
                    <img
                      className="logo-bg icon-powermart-logo cursor-pointer"
                      src="/powerMart_white.png"
                      alt="powerMart"
                      id="powerImage"
                    />
                  </NavLink>
                </div>
                <div className="pt-3 ml-1 icon-mobile-view">
                  <span className="text-lite-white">{ DiscomDistributionOptions.find((state) => state.value === selectDiscom)?.label || ''}</span>
                  <span className="text-lite-white">{ DiscomTransmissionOptions.find((state) => state.value === selectDiscom)?.label || ''}</span>
                </div>
                {/* <span className="vert-line-dash">|</span>
                <NavLink
                  className="navbar-puser-icon"
                  onClick={() => { handleClick(); }}
                  to="#"
                >
                  {click ? (
                    <FontAwesomeIcon
                      icon={faListUl}
                      className={`navbar-puser-open-btn ${!click ? 'fade-out' : 'fade-in'}`}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={`navbar-puser-close-btn ${!click ? 'fade-in' : 'fade-out'}`}
                    />
                  )}
                </NavLink> */}
              </div>
            </Col>
            <Col lg="4" xs="1">
              <div className="align-end pt-2">
                <div className="cart-margin icon-mobile-view align-end">
                  {orderTp === 'KART' && (
                  <div className="mb-2">
                    <div className="align-center">
                      <Tooltip
                        content="My Enquiry"
                        position="bottom"
                        customClassName="enqID"
                      >
                        <Button id="enqID" className="button-icon" onClick={handlePreProdKart}>
                          <span className="cart-text align-center"><FontAwesomeIcon icon={faBoxOpen} className="cart-menu-icon" />
                            <Badge className="kart-icon">
                              {preProdCount.itmCnt}
                            </Badge>
                          </span>
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  )}
                  {orderTp === 'KART' && (
                  <div className="mb-2">
                    <div className="align-center">
                      <Tooltip
                        content="Cart Items"
                        position="bottom"
                        customClassName="cartID"
                      >
                        <Button id="cartID" className="button-icon" onClick={() => handleKartPendingData('KART')}>
                          <span className="cart-text align-center"><FontAwesomeIcon icon={faCartShopping} className="cart-menu-icon" />
                            <Badge className="kart-icon">
                              {kartCount.itmCnt}
                            </Badge>
                          </span>
                        </Button>
                      </Tooltip>
                    </div>
                  </div>
                  )}

                  {/* {orderTp === 'PEND' && (
                  <div>
                    <div className="align-center">
                      <Button className="button-icon" onClick={() => handleKartPendingData('PEND')}>
                        <span className="cart-text align-center"><FontAwesomeIcon icon={faOpencart} className="cart-menu-icon mx-1" />
                          <Badge className="kart-icon mx-1">
                            {pendCount}
                          </Badge>
                        </span>
                      </Button>
                    </div>
                    <div className="align-center">
                      <span className="cart-text align-center cart-text-display">Pending Orders</span>
                    </div>
                  </div>
                  )} */}
                </div>
                <div className="menu-image-avatar">
                  <div className="align-center padding-zero">
                    <Popover
                      header={(
                        <div className="align-center mx-2">
                          <span className="puser-user-name-text">{name}</span>
                        </div>
)}
                      content={(
                        <div ref={popoverRef}>
                          <div className="align-center pt-2">
                            <span className="text-lite-grey">{emailId}</span>
                          </div>
                          <div className="align-center">
                            <span className="text-lite-grey">+91 {phone}</span>
                          </div>
                          <div className="align-center my-4">
                            <UncontrolledDropdown
                              direction="down"
                            >
                              <DropdownToggle
                                caret
                                className="discom-user-button"
                              >
                                <span>{ truncateText(DiscomDistributionOptions.find((state) => state.value === selectDiscom)?.label || '', 25)}</span>
                                <span>{ truncateText(DiscomTransmissionOptions.find((state) => state.value === selectDiscom)?.label || '', 25)}</span>
                              </DropdownToggle>
                              <DropdownMenu>
                                {discomData.map((discom, index) => (
                                  <DropdownItem key={`discom-${index.toString()}`} onClick={() => handleDiscomData(discom.discomUtil, discom.discomTp)}>
                                    <span className="puser-user-name-text">{ DiscomDistributionOptions.find((state) => state.value === discom.discomUtil)?.label || ''}</span>
                                    <span className="puser-user-name-text">{ DiscomTransmissionOptions.find((state) => state.value === discom.discomUtil)?.label || ''}</span>
                                  </DropdownItem>
                                ))}
                              </DropdownMenu>
                            </UncontrolledDropdown>
                          </div>
                          <hr className="text-lite-grey" />
                          <div className="align-center pt-2">
                            <NavLink
                              className="nav-links mx-1"
                              to="my-orders"
                              title="My Active Orders"
                              onClick={handleMyOrder}
                            >
                              <FontAwesomeIcon icon={faBoxOpen} className="nav-text-login-icon mx-1" />
                              <span className="mx-2 text-link-user">My Orders</span>
                            </NavLink>
                          </div>
                          <hr className="text-lite-grey" />
                          <div className="align-center margin-zero">
                            <Button className="button-icon" onClick={() => { history('my-profile'); setIsClickClose(true); }}>
                              <FontAwesomeIcon className="seller-grey-icon" icon={faUserCircle} />
                              <span className="text-link-user mx-2">My Profile</span>
                            </Button>
                          </div>
                          <hr className="text-lite-grey" />
                          <div className="align-center my-4">
                            <NavLink to="/login/puser#logn" onClick={() => { clearKeysFromSessionStorage(); window.location.href = '/login/puser#logn'; }} className="menu-link-notification">
                              <FontAwesomeIcon className="puser-grey-icon" icon={faRightFromBracket} />
                              <span className="text-link-user mx-2">Logout</span>
                            </NavLink>
                          </div>
                        </div>
                        )}
                      position="bottom"
                      customClassName="custom-popover"
                      isClickClose={isClickClose}
                      setIsClickClose={setIsClickClose}
                    >
                      <Nav className="button-icon padding-zero" onClick={handleUserNotification}>
                        <img
                          alt="..."
                          className="powermart-user-icon rounded-image cursor-pointer padding-zero"
                          src={filePreview !== '' ? filePreview : '/images/register-login.png'}
                          id="userInfo"
                          ref={userInfoRef}
                        />
                      </Nav>
                    </Popover>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="route-margin">
              <ExclusiveRoutes />
            </div>
          </Row>
          <footer className={!showFooter ? 'footer-visible' : ''}>
            <Row className="align-center pt-2">
              <Col xs="4">
                <div className="pt-1">
                  <div>
                    <div className="align-center">
                      <Button className="button-icon" onClick={() => { history('dashboard'); }}>
                        <span className="cart-text align-center"><FontAwesomeIcon icon={faHome} className="cart-menu-icon-mobile" />
                        </span>
                      </Button>
                    </div>
                    <div className="align-center">
                      <span className="cart-text-mobile">Home</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs="4">
                <div className="pt-1">
                  <div>
                    <div className="align-center">
                      <Button className="button-icon" onClick={handlePreProdKart}>
                        <span className="cart-text align-center"><FontAwesomeIcon icon={faBoxOpen} className="cart-menu-icon-mobile" />
                          <Badge className="kart-icon mx-1">
                            {preProdCount.itmCnt}
                          </Badge>
                        </span>
                      </Button>
                    </div>
                    <div className="align-center">
                      <span className="cart-text-mobile">My Enquiry</span>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs="4">
                <div className="pt-1">
                  {orderTp === 'KART' && (
                  <div>
                    <div className="align-center">
                      <Button className="button-icon" onClick={() => handleKartPendingData('KART')}>
                        <span className="cart-text align-center"><FontAwesomeIcon icon={faCartShopping} className="cart-menu-icon-mobile" />
                          <Badge className="kart-icon mx-1">
                            {kartCount.itmCnt}
                          </Badge>
                        </span>
                      </Button>
                    </div>
                    <div className="align-center">
                      <span className="cart-text-mobile">Cart Items</span>
                    </div>
                  </div>
                  )}
                </div>
              </Col>
            </Row>
          </footer>
        </div>
      )
        : (
          <CXUnauthorized />
        )}
    </div>
  );
};

export default PUserComponent;
