import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import Select from 'react-select';
import {
  Row, Col, Card, CardImg, Button, Input, Form,
  InputGroup,
  InputGroupText,
  Label,
} from 'reactstrap';
import {
  faCirclePlus,
  faCircleXmark,
  faCube,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { errorAlert, successAlert } from '../../../../utils/alert';
import LoaderData from '../../../../utils/loader';
import { APIHeader, ProductCatList } from '../../../../utils/constants';
import {
  createSpareReq, uploadProdImg,
} from '../../../../store/powerMart/actions';
import { getConfig } from '../../../../utils/config/config';
import {
  getUserProfileSessionStorage, getTokenFromSessionStorage, getDiscomUtilSessionStorage, getDiscomSessionStorage,
} from '../../../../utils/service/localstorage-service';
import { ProdImgFileInput } from '../../../../services/products/upload-prod-Image/create-prodimage.types';
import { PostSpareInput } from '../../../../services/products/add-spares/post-spare.types';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const AddPrivSpareDetails: React.FC = () => {
  const dispatch = useDispatch();
  const discomUtil = getDiscomUtilSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const discomType = getDiscomSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const tokenData = getTokenFromSessionStorage();
  const [loaderAddSpare, setLoaderAddSpare] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [techSpec, setTechSpec] = React.useState('');
  const [prodDesc, setProdDesc] = React.useState('');
  const [discomTp, setDiscomTp] = React.useState('');
  const [discomIdent, setDiscomIdent] = React.useState('');
  const addSpareResponse = useSelector((state: RootState) => state.powerMart.createNewSpare);
  const uploadroductAwsResponse = useSelector((state: RootState) => state.powerMart.uploadProductsAWS);
  const [filePreviews, setFilePreviews] = React.useState<string[]>([]);
  // const [fileName, setFileName] = React.useState<string>('No file chosen');
  const fileInputRef = React.useRef<HTMLInputElement>(null);
  const [prodImageFile, setProdImageDataFile] = React.useState<ProdImgFileInput>({
    fileData: new FormData(),
  });
  const [spareInput, setSpareInput] = React.useState<PostSpareInput>({
    nme: '',
    cat: '',
    wrnty: '0',
    cost: '0',
    gst: '0',
    techSpec: [],
    prodDesc: [],
    discomId: '',
    keyW: '',
    cntItm: 0,
    modlN: '',
    tagIn: 'SPARE-1714651611',
    hsn: '',
  });

  React.useEffect(() => {
    if (discomType) {
      setDiscomTp(discomType);
    }
  }, [discomType]);

  React.useEffect(() => {
    if (discomUtil) {
      setDiscomIdent(discomUtil);
    }
  }, [discomUtil]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  const handleSpareSubmit = (e:any) => {
    e.preventDefault();
    setLoaderAddSpare(true);
    const updatedKartInput = {
      ...spareInput,
      discomId: discomIdent,
      cat: discomTp,
    };
    Promise.resolve(updatedKartInput).then((resolvedInput) => {
      dispatch(createSpareReq({
        userType: userTp,
        requestType: APIHeader.REQ_ADD_SPARES,
        inputBody: resolvedInput,
        token: tokenData,
      }));
    });
  };

  React.useEffect(() => {
    if (addSpareResponse.error && addSpareResponse.message !== '') {
      setLoaderAddSpare(false);
      setAlertMsg(addSpareResponse.message);
      setErrorShowAlert(true);
    }
  }, [addSpareResponse.error, addSpareResponse.message]);

  React.useEffect(() => {
    if (!addSpareResponse.error && addSpareResponse.message !== '') {
      if (addSpareResponse.prodId !== '') {
        // const prodIdNme = `${addSpareResponse.prodId}.${mediaFileNmeExt}`;
        dispatch(uploadProdImg({
          bucketNme: AWS_PRODUCT_BUCKET,
          folderName: `products/${addSpareResponse.prodId}`,
          fileNmeInit: addSpareResponse.prodId,
          formData: prodImageFile.fileData,
          requestType: '',
          isEdit: false,
        }));
      } else {
        setLoaderAddSpare(false);
        setAlertMsg('Spare Image upload failed');
        setErrorShowAlert(true);
      }
    }
  }, [addSpareResponse.error, addSpareResponse.message, addSpareResponse.prodId]);

  React.useEffect(() => {
    if (uploadroductAwsResponse.fileName !== '') {
      setLoaderAddSpare(false);
      setAlertMsg(addSpareResponse.message);
      setSuccessShowAlert(true);
    }
  }, [uploadroductAwsResponse.fileName]);

  const handleSpareImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;

    const formData = new FormData();
    const previews: string[] = [];

    Array.from(files).forEach((file) => {
      formData.append(file.name, file);
      const objectUrl = URL.createObjectURL(file);
      previews.push(objectUrl);
    });
    setProdImageDataFile({ ...prodImageFile, fileData: formData });
    setFilePreviews(previews);
  };

  const handleFieldChange = (e:any) => {
    const fieldName = e.target.name;
    setSpareInput({ ...spareInput, [fieldName]: e.target.value });
  };

  const handleCountChange = (e:any) => {
    const { value } = e.target;
    const cntValue = value === '' ? 0 : parseInt(value);
    if (cntValue > 0) {
      setSpareInput({ ...spareInput, cntItm: Number(cntValue) });
    } else {
      setSpareInput({ ...spareInput, cntItm: Number(0) });
    }
  };

  const handleRemoveImage = (indexToRemove: number) => {
    const updatedPreviews = filePreviews.filter((_, index) => index !== indexToRemove);
    const updatedFiles = new FormData();

    Array.from(prodImageFile.fileData.entries()).forEach(([key, file], index) => {
      if (index !== indexToRemove) {
        updatedFiles.append(key, file);
      }
    });
    setFilePreviews(updatedPreviews);
    setProdImageDataFile({ ...prodImageFile, fileData: updatedFiles });
  };

  // React.useEffect(() => {
  //   console.log('INDEXER', Array.from(prodImageFile.fileData.keys()).length);
  //   // setProdImageDataFile(prodImageFile);
  //   if (Array.from(prodImageFile.fileData.keys()).length > 0) {
  //     setFileName(`${Array.from(prodImageFile.fileData.keys()).length} image selected`);
  //   } else {
  //     setFileName('No Files Choosen');
  //   }
  // }, [Array.from(prodImageFile.fileData.keys()).length]);

  const handleTechSpecChange = (event: any) => {
    setTechSpec(event.target.value);
  };

  const handleAddTechSpec = () => {
    if (techSpec !== '') {
      if (techSpec !== '') {
        const updatedInitTechSpec = { ...spareInput };
        updatedInitTechSpec.techSpec.push(techSpec);
        setSpareInput(updatedInitTechSpec);
        setTechSpec('');
      }
    }
  };

  const handleRemoveTechSpec = (specIndx: number) => {
    const updatedInitProdSpec = { ...spareInput };
    updatedInitProdSpec.techSpec.splice(specIndx, 1);
    setSpareInput(updatedInitProdSpec);
  };

  const handleProdDescChange = (event: any) => {
    setProdDesc(event.target.value);
  };

  const handleAddProdDesc = () => {
    if (prodDesc !== '') {
      if (prodDesc !== '') {
        const updatedInitProdDesc = { ...spareInput };
        updatedInitProdDesc.prodDesc.push(prodDesc);
        setSpareInput(updatedInitProdDesc);
        setProdDesc('');
      }
    }
  };

  const handleRemoveProdDesc = (descIndx: number) => {
    const updatedInitProdSpec = { ...spareInput };
    updatedInitProdSpec.prodDesc.splice(descIndx, 1);
    setSpareInput(updatedInitProdSpec);
  };

  // Start Prod Category List

  const prodCatOptions = ProductCatList.map((prodCat) => ({
    label: prodCat.label,
    value: prodCat.value,
  }));

  // const handleSelectProdCatData = (e: any) => {
  //   if (e) {
  //     setSpareInput({ ...spareInput, cat: e.value });
  //   } else {
  //     setSpareInput({ ...spareInput, cat: '' });
  //   }
  // };

  const selectedProdCatOptions = prodCatOptions.find(
    (option) => option.value === discomTp,
  );
    // End Prod Category List

  return (
    <div className="pt-5 mt-2">
      { loaderAddSpare ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="mx-1">
        <Col lg="6" xs="12">
          <div className="align-center pt-5 mt-1">
            <span className="card-toggle-text">
              Upload most detailed Images. You can add multiple images
            </span>
            <span className="mandate-star">*</span>
          </div>
          <div className="powermart-main-label">
            <div className="align-center pt-5">
              {filePreviews.length === 0
                ? <CardImg style={{ height: '100px', width: '100px' }} src="/images/broken-image.png" alt="broken img" />
                : (
                  <Row className="align-center">
                    <span className="text-lite-grey">{Array.from(prodImageFile.fileData.keys()).length} files in Queue</span>
                    {filePreviews.map((preview, index) => (
                      <Col lg="3" key={`prodImg-${index.toString()}`} className="border-image mx-2">
                        <div className="align-end">
                          <Button className="button-icon padding-zero" onClick={() => handleRemoveImage(index)}>
                            <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                          </Button>
                        </div>
                        <CardImg className="img-fluid" src={preview} alt={`Preview ${index + 1}`} />
                      </Col>
                    ))}
                  </Row>
                )}
            </div>
            <div className="align-center pt-3">
              <div className="custom-file-input">
                <Input
                  type="file"
                  multiple
                  accept=".png, .jpg, .jpeg"
                  name="fileData"
                  onChange={(e) => handleSpareImage(e)}
                />
                <Label className="file-label" onClick={() => fileInputRef.current?.click()}>
                  Select Files
                  {/* ({fileName}) */}
                </Label>
              </div>
              {/* <Input
                type="file"
                accept=".png, .jpg, .jpeg"
                name="fileData"
                multiple
                placeholder="1111"
                className="product-image-btn product-img"
                onChange={(e) => handleSpareImage(e)}
              /> */}
            </div>
          </div>
        </Col>
        <Col lg="6" xs="12">
          <div className="pt-5 mt-2">
            <Card className="card-lite">
              <Row className="mx-1">
                <Col xs="12">
                  <div className="align-center pt-2">
                    <span className="powermart-dark-icon">
                      Spare Part Details
                    </span>
                  </div>
                </Col>
                <Col xs="12">
                  <Form onSubmit={handleSpareSubmit}>
                    <Row className="mx-1">
                      <Col lg="12" className="pt-3">
                        <h6 className="text-lite-grey mx-2 my-1">Spare Part Name<span className="mandate-star">*</span></h6>
                        <Input
                          placeholder="Enter product name"
                          type="text"
                          required
                          name="nme"
                          value={spareInput.nme}
                          onChange={handleFieldChange}
                          className="powermart-input"
                        />
                      </Col>
                      <Col lg="12" className="pt-3">
                        <h6 className="text-lite-grey mx-2 my-1">If Model Known</h6>
                        <Input
                          placeholder="Enter model number"
                          type="text"
                          name="modlN"
                          value={spareInput.modlN}
                          onChange={handleFieldChange}
                          className="powermart-input"
                        />
                      </Col>
                      <Col lg="8" className="pt-4">
                        <h6 className="text-lite-grey mx-2 my-1">Category<span className="mandate-star">*</span></h6>
                        <Select
                          value={spareInput.cat === discomTp ? null : selectedProdCatOptions}
                          options={prodCatOptions}
                          isDisabled
                          // onChange={handleSelectProdCatData}
                          menuPortalTarget={document.body}
                          styles={{
                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                            menu: (base) => ({
                              ...base,
                              // position: 'relative',
                            }),
                            control: (base: any) => ({
                              ...base,
                              '&:hover': { borderColor: '#05cbddff' },
                              border: '1px solid #BFBFBF',
                              minHeight: '45px',
                              borderRadius: '5px',
                              padding: 1,
                              textAlign: 'left',
                              fontSize: '16px',
                              fontWeight: 400,
                              color: '#383838',
                              boxShadow: 'none',
                              backgroundColor: '#fff',
                              '&:focus': {
                                borderColor: '#E1EDF8',
                                boxShadow: 'none',
                                color: '#575656',
                                background: '#fff',
                              },
                            }),
                            option: (provided: any, state: { isSelected: any; }) => ({
                              ...provided,
                              color: state.isSelected ? '#383838' : '#212121',
                              padding: 15,
                              textAlign: 'left',
                              background: state.isSelected ? '#FAFCFB' : '#fff',
                              '&:hover': {
                                background: '#FAFCFB',
                              },
                            }),
                          }}
                          placeholder="Select Spare Category"
                        />
                      </Col>
                      <Col lg="4" className="pt-4">
                        <h6 className="text-lite-grey mx-2 my-1">Item Count<span className="mandate-star">*</span></h6>
                        <Input
                          placeholder="Item Count"
                          type="number"
                          required
                          min="0"
                          name="cntItm"
                          value={spareInput.cntItm || ''}
                          onChange={handleCountChange}
                          className="powermart-input"
                        />
                      </Col>
                      <Col xs="12">
                        <div className="text-right-login-campus-register mx-1 pt-4">
                          <h6 className="text-lite-grey mx-2 my-1">Tech Specifications</h6>
                          <InputGroup>
                            <Input
                              type="text"
                              placeholder="Provide Tech Specifications"
                              className="powermart-input"
                              value={techSpec}
                              onChange={(e) => handleTechSpecChange(e)}
                            />
                            <InputGroupText className="powermart-input mx-1 cursor-pointer" onClick={() => handleAddTechSpec()}>
                              <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                            </InputGroupText>
                          </InputGroup>
                          <span className="text-pre-lite-grey ml">Press the add button to enter each detail</span>
                          {spareInput.techSpec.length > 0
                            ? (
                              <Card className="my-3 meta-card">
                                <span className="my-1 text-lite-grey ml-1">Tech Specification Details</span>
                                {spareInput.techSpec.map((techSpecData, techSpecIndx) => (
                                  <div key={`techSpec-${techSpecIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                    <Row className="mx-1 pt-2">
                                      <Col lg="11" xs="10">
                                        <div className="pt-1">
                                          <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                          <span className="ml-1">{ techSpecData }</span>
                                        </div>
                                      </Col>
                                      <Col lg="1" xs="2">
                                        <div className="align-end">
                                          <Button className="button-icon" onClick={() => handleRemoveTechSpec(techSpecIndx)}>
                                            <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </Card>
                            ) : null }
                        </div>
                      </Col>
                      <Col xs="12">
                        <div className="text-right-login-campus-register mx-1 pt-4">
                          <h6 className="text-lite-grey mx-2 my-1">Description</h6>
                          <InputGroup>
                            <Input
                              type="textarea"
                              placeholder="Provide Description"
                              className="powermart-input-desc"
                              value={prodDesc}
                              onChange={(e) => handleProdDescChange(e)}
                            />
                            <InputGroupText className="powermart-input mx-1 cursor-pointer my-4" onClick={() => handleAddProdDesc()}>
                              <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                            </InputGroupText>
                          </InputGroup>
                          <span className="text-pre-lite-grey ml">Press the add button to enter each detail</span>
                          {spareInput.prodDesc.length > 0
                            ? (
                              <Card className="my-3 meta-card">
                                <span className="my-1 text-lite-grey ml-1">Description</span>
                                {spareInput.prodDesc.map((descData, descIndx) => (
                                  <div key={`prodDesc-${descIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                    <Row className="mx-1 pt-2">
                                      <Col lg="11" xs="10">
                                        <div className="pt-1">
                                          <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                          <span className="ml-1">{ descData }</span>
                                        </div>
                                      </Col>
                                      <Col lg="1" xs="2">
                                        <div className="align-end">
                                          <Button className="button-icon" onClick={() => handleRemoveProdDesc(descIndx)}>
                                            <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                          </Button>
                                        </div>
                                      </Col>
                                    </Row>
                                  </div>
                                ))}
                              </Card>
                            ) : null }
                        </div>
                      </Col>
                    </Row>
                    <div className="align-center pt-4 mx-2">
                      <Button
                        className="powermart-button-fullwidth mx-1"
                        disabled={Array.from(prodImageFile.fileData.keys()).length === 0}
                      >
                        Save
                      </Button>
                    </div>
                  </Form>
                </Col>
              </Row>
              <div className="pt-5" />
            </Card>
          </div>
        </Col>
      </Row>
    </div>
  );
};
export default AddPrivSpareDetails;
