import { GetAllSellerProductResponse, GetAllSellerProductResults } from './get-all-seller-product.service.types';

export const GetAllSellerProductTransform: (response: GetAllSellerProductResponse) => GetAllSellerProductResults = (getAllSellerProductResults) => {
  const { data, is_error, message } = getAllSellerProductResults;
  const result: GetAllSellerProductResults = {
    data: {
      tCnt: 0,
      fCnt: 0,
      lastEvalKey: {
        ProdTp: '',
        ProdId: '',
      },
      prodData: [{
        ProdTp: '',
        ProdId: '',
        nme: '',
        techSpec: [],
        prodDesc: [],
        wrnty: 0,
        costVal: '',
        gstVal: '',
        cost: '',
        gst: '',
        tCost: '',
        prodUrl: [],
        usrManual: [],
        prodTpVal: '',
        catTp: '',
        catTpVal: '',
        keyW: '',
        modlN: '',
        tagIn: '',
        hsn: '',
        oem: '',
      }],
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
