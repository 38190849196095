import { DELKartProdResponse, DELKartProdResults } from './del-kart-prod.service.types';

export const DELKartProdTransform: (response: DELKartProdResponse) => DELKartProdResults = (passKeyResults) => {
  const { is_error, message } = passKeyResults;
  const result :DELKartProdResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = 'executed';
  }
  return result;
};
