import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Col, Row,
} from 'reactstrap';
import '../cart.scss';
import { APIHeader } from '../../../../../utils/constants';
import LoaderData from '../../../../../utils/loader';
import { errorAlert } from '../../../../../utils/alert';
import { getDiscomUtilSessionStorage, getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../../utils/service/localstorage-service';
import { getKartListReq } from '../../../../../store/powerMart/actions';
import { KartsResult } from '../../../../../services/products/list-kart/get-all-kart.types';
import CartPrivateList from '../kart-comp/cart-list';

export const PreProdKartComponent: React.FC = () => {
  const dispatch = useDispatch();
  const discomUtil = getDiscomUtilSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [discomUtility, setDiscomUtil] = React.useState('');
  const [activeTab, setActiveTab] = React.useState('kart');
  const [loaderProduct, setLoaderProduct] = React.useState(false);
  const listKartProducts = useSelector((state: RootState) => state.powerMart.getKartList);
  const [productsData, setProductsData] = React.useState<KartsResult>({
    fCnt: 0,
    addr: '',
    mSts: '',
    ordId: '',
    sumCost: '',
    sumGst: '',
    sumTotal: '',
    prodTxnData: [],
  });

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  React.useEffect(() => {
    if (userTp !== '') {
      setLoaderProduct(true);
      dispatch(getKartListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_IN_CART,
        status: 'INACT',
        discTp: discomUtility,
        // discomId: discomUtility,
      }));
    }
  }, [userTp, tokenData]);

  React.useEffect(() => {
    if (discomUtil) {
      setDiscomUtil(discomUtil);
    }
  }, [discomUtil]);

  React.useEffect(() => {
    if (listKartProducts.isError && listKartProducts.message !== '') {
      setLoaderProduct(false);
      setAlertMsg(listKartProducts.message);
      setErrorShowAlert(true);
    }
  }, [listKartProducts.isError, listKartProducts.message]);

  React.useEffect(() => {
    if (!listKartProducts.isError && listKartProducts.message === 'executed') {
      setLoaderProduct(false);
      setProductsData(listKartProducts.data);
    }
  }, [listKartProducts.isError, listKartProducts.message, listKartProducts.data, productsData]);

  return (
    <div className="label-margin-to-nav">
      { loaderProduct ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-1 pt-2">
        <span className="powermart-main-label">
          Quick View - My Pre Ordered Product
        </span>
      </div>
      <Row>
        <Col xs="12">
          {productsData.mSts === 'INACT' && activeTab === 'kart'
            ? <CartPrivateList productsData={productsData} setProductsData={setProductsData} setActiveTab={setActiveTab} />
            : (
              <div className="pt-5 my-5 align-center">
                <span className="form-collapse-card-header">Cart is Empty. No Records Found</span>
              </div>
            )}
        </Col>
      </Row>
    </div>
  );
};

export default PreProdKartComponent;
