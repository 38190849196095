import { ForgotPasswordResponse, ForgotPasswordResults } from './forgot-password.service.types';

export const ForgotPasswordTransform: (response: ForgotPasswordResponse) => ForgotPasswordResults = (genTokenResults) => {
  const { data, is_error, message } = genTokenResults;
  const result: ForgotPasswordResults = {
    forgotPasswordData: '',
    message: '',
    isError: false,
  };
  if (is_error) {
    result.message = message;
    result.isError = is_error;
  } else {
    result.forgotPasswordData = data;
    result.isError = is_error;
    result.message = '';
  }
  return result;
};
