import { KartStatusPutResponse, KartStatusPutResults } from './update-kart-status.service.types';

export const KartStatusPutTransform: (response: KartStatusPutResponse) => KartStatusPutResults = (passKeyResults) => {
  const { is_error, message } = passKeyResults;
  const result :KartStatusPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = 'executed';
  }
  return result;
};
