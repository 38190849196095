import React from 'react';
import './Modal.scss';
import { Button } from 'reactstrap';

interface ModalProps {
  show: boolean;
  disCancel: boolean;
  onClose: () => void;
  title: any;
  targetId: string;
  children: React.ReactNode;
  size: string; // SM, MD, XL DEFAULT-SM
}

const Modal: React.FC<ModalProps> = ({
  show,
  onClose,
  title,
  children,
  disCancel,
  targetId,
  size = 'SM',
}) => {
  if (!show) {
    return null;
  }

  return (
    <div className="modal-overlay" id={targetId}>
      <div className={`modal modal-${size.toLowerCase()}`}>
        <div className="modal-header">
          <span className="title-modal ml">{title}</span>
          {!disCancel
          && <Button onClick={onClose} className="modal-close-button">&times;</Button>}
        </div>
        <div className="modal-content">
          {children}
        </div>
      </div>
    </div>
  );
};

export default Modal;
