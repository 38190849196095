import React from 'react';
import { faArrowRightLong, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button, Card, CardImg,
  Col,
  Row,
} from 'reactstrap';
import { ProdStsProps } from './home.type';

const MultiProductList: React.FC<ProdStsProps> = ({ catSelected, setCatSelected, setIsFirstAPICall }) => (
  <div>
    <div className="align-center">
      <Button to="all-products" className="link-text-italic button-icon" target="_blank" href="/all-products">
        Show All <FontAwesomeIcon icon={faArrowRightLong} />
      </Button>
    </div>
    <Row className="align-center pt-4">
      <Col lg="2" xs="2">
        <Card className="key-highlights-cards cursor-pointer" onClick={() => { setCatSelected('DIST'); setIsFirstAPICall(true); }}>
          <div className="align-center">
            <CardImg alt="prodLogo" className="cat-prod-image" src="/images/category/distribution.png" />
          </div>
          <div className="text-center-aligned pt-3">
            <span className="text-cat-header home-filter-icon">Distribution
              {catSelected === 'DIST'
                ? <FontAwesomeIcon icon={faCheckCircle} className="correct-pass-key mx-1" />
                : null}
            </span>
          </div>
        </Card>
      </Col>
      <Col lg="2" xs="2">
        <Card className="key-highlights-cards cursor-pointer ml-1" onClick={() => { setCatSelected('TRANS'); setIsFirstAPICall(true); }}>
          <div className="align-center">
            <CardImg alt="prodLogo" className="cat-prod-image" src="/images/category/transmission.png" />
          </div>
          <div className="text-center-aligned pt-3">
            <span className="text-cat-header home-filter-icon">Transmission
              {catSelected === 'TRANS'
                ? <FontAwesomeIcon icon={faCheckCircle} className="correct-pass-key mx-1" />
                : null}
            </span>
          </div>
        </Card>
      </Col>
      <Col lg="2" xs="2">
        <Card className="key-highlights-cards-disabled ml-1">
          <div className="align-center">
            <CardImg alt="prodLogo" className="cat-prod-image" src="/images/category/thermal.png" />
          </div>
          <div className="text-center-aligned pt-3">
            <span className="text-cat-header home-filter-icon">Thermal
              {catSelected === 'THRML'
                ? <FontAwesomeIcon icon={faCheckCircle} className="correct-pass-key mx-1" />
                : null}
            </span>
          </div>
        </Card>
      </Col>
      <Col lg="2" xs="2">
        <Card className="key-highlights-cards-disabled ml-1">
          <div className="align-center">
            <CardImg alt="prodLogo" className="cat-prod-image" src="/images/category/hydro.png" />
          </div>
          <div className="text-center-aligned pt-3">
            <span className="text-cat-header home-filter-icon">Hydro
              {catSelected === 'HYDRO'
                ? <FontAwesomeIcon icon={faCheckCircle} className="correct-pass-key mx-1" />
                : null}
            </span>
          </div>
        </Card>
      </Col>
      <Col lg="2" xs="2">
        <Card className="key-highlights-cards-disabled ml-1">
          <div className="align-center">
            <CardImg alt="prodLogo" className="cat-prod-image" src="/images/category/renewable.png" />
          </div>
          <div className="text-center-aligned pt-3">
            <span className="text-cat-header home-filter-icon">Renewable
              {catSelected === 'RNWE'
                ? <FontAwesomeIcon icon={faCheckCircle} className="correct-pass-key mx-1" />
                : null}
            </span>
          </div>
        </Card>
      </Col>
    </Row>
  </div>
);
export default MultiProductList;
