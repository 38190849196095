import React, { useState, ReactNode } from 'react';
import './accord.scss';
import { Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

interface AccordionProps {
  title: string;
  children: ReactNode;
  defaultOpen: boolean;
  targetId: string;
}

const Accordion: React.FC<AccordionProps> = ({
  title, children, defaultOpen = false, targetId,
}) => {
  const [isOpen, setIsOpen] = useState(defaultOpen);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`accordion ${isOpen ? 'open' : ''}`} id={targetId}>
      <Button className="accordion-header" onClick={toggleAccordion}>
        <div className="ml">
          <h2>{title}</h2>
        </div>
        {isOpen ? <FontAwesomeIcon icon={faChevronUp} className="accordion-toggle-open mr" />
          : <FontAwesomeIcon icon={faChevronDown} className="accordion-toggle-closed mr" />}
      </Button>
      {isOpen && <div className="accordion-content">{children}</div>}
    </div>
  );
};

export default Accordion;
