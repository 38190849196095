import { DeleteAWSFolderResponse, DeleteAWSFolderResults } from './delete-folder.service.types';

export const DeleteAWSFolderTransform: (response: DeleteAWSFolderResponse) => DeleteAWSFolderResults = (createAWSFolderDBResults) => {
  const { data } = createAWSFolderDBResults;
  const result: DeleteAWSFolderResults = {
    folderName: '',
  };
  result.folderName = data;
  return result;
};
