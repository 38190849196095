import { ListUserResponse, ListUserResults } from './list-user.service.types';

export const ListUserTransform: (response: ListUserResponse) => ListUserResults = (listUserResults) => {
  const { data, is_error } = listUserResults;
  const result: ListUserResults = {
    tCnt: 0,
    fCnt: 0,
    userData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = data.message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.userData = data.userData;
    result.message = 'success';
  }
  return result;
};
