/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import {
  Button,
  Col, Form, Input, Modal, ModalBody, ModalHeader, Row,
} from 'reactstrap';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { APIHeader } from '../../../../../utils/constants';
import {
  getStateListReq, getDivisionListReq, createDivisionReq, clearCreateDivisionReq,
} from '../../../../../store/powerMart/actions';
import { StateResult } from '../../../../../services/meta-data/list-state/list-state.types';
import { DivisionResult } from '../../../../../services/meta-data/list-division/list-division.types';
import { LoaderData } from '../../../../../utils/loader';
import { PostDivisionInput } from '../../../../../services/meta-data/add-division/post-division.types';
import { errorAlert, successAlert } from '../../../../../utils/alert';
import { getUserProfileSessionStorage, getTokenFromSessionStorage } from '../../../../../utils/service/localstorage-service';

export const DivisionData: React.FC = () => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [loaderDiv, setLoaderDiv] = useState(false);
  const [loaderState, setLoaderState] = useState(false);
  const [stateData, setStateData] = useState('');
  const [showAddDivForm, setShowAddDivForm] = React.useState(false);
  const [stateListData, setStateListData] = React.useState<StateResult[]>([]);
  const [divListData, setDivListData] = useState<DivisionResult[]>([]);
  const stateResData = useSelector((state: RootState) => state.powerMart.getStateMetaList);
  const divResData = useSelector((state: RootState) => state.powerMart.getDivMetaList);
  const addDivResponse = useSelector((state: RootState) => state.powerMart.createDivisions);
  const [divInput, setDivInput] = React.useState<PostDivisionInput>({
    div: [{
      st: '',
      nme: '',
      tZone: '',
    }],
  });

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  const stateoptions = stateListData && stateListData.map((state) => ({
    label: state.nme,
    value: state.MetaId,
  }));

  const selectedStateOptions = stateoptions && stateoptions.find(
    (option) => option.value === stateData,
  );

  React.useEffect(() => {
    setLoaderState(true);
    dispatch(getStateListReq({
      requestType: APIHeader.REQ_ALL_ST_META,
    }));
  }, []);

  React.useEffect(() => {
    if (!stateResData.isError) {
      setLoaderState(false);
      setStateListData(stateResData.stData);
    }
  }, [stateResData.stData]);

  const handleSelectState = (e:any) => {
    setStateData(e.value);
    setLoaderDiv(true);
    dispatch(getDivisionListReq({
      requestType: APIHeader.REQ_ALL_DIV_META,
      state: e.value,
    }));
  };

  React.useEffect(() => {
    if (divInput.div[0].st === '') {
      setDivInput((prevInput) => ({
        ...prevInput,
        div: [
          {
            ...prevInput.div[0],
            st: stateData,
          },
          ...prevInput.div.slice(1),
        ],
      }));
    }
  }, [divInput.div[0].st, stateData]);

  const handleAddDivInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setDivInput((prevInput) => ({
      ...prevInput,
      div: [
        {
          ...prevInput.div[0],
          [e.target.name]: value,
        },
        ...prevInput.div.slice(1),
      ],
    }));
  };

  React.useEffect(() => {
    if (!divResData.isError) {
      setLoaderDiv(false);
      setDivListData(divResData.divData);
    }
  }, [divResData.divData]);

  const handleShowAddDivision = () => {
    setShowAddDivForm(!showAddDivForm);
  };

  const handleDivSubmit = (e:any) => {
    e.preventDefault();
    setLoaderDiv(true);
    Promise.resolve(
      dispatch(createDivisionReq({
        userType: userTp,
        requestType: APIHeader.REQ_ADD_DIV_META,
        inputBody: divInput,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (addDivResponse.error && addDivResponse.message !== '') {
      setLoaderDiv(false);
      setAlertMsg(addDivResponse.message);
      dispatch(clearCreateDivisionReq());
      setErrorShowAlert(true);
    }
  }, [addDivResponse.error, addDivResponse.message]);

  React.useEffect(() => {
    if (!addDivResponse.error && addDivResponse.message !== '') {
      setLoaderDiv(false);
      setAlertMsg(addDivResponse.message);
      dispatch(clearCreateDivisionReq());
      setSuccessShowAlert(true);
    }
  }, [addDivResponse.error, addDivResponse.message]);

  return (
    <Row>
      { loaderDiv ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col xs="12">
        <div className="align-end mr">
          <Button className="powermart-button-solid mx-1" onClick={() => handleShowAddDivision()}>
            Add Division
          </Button>
        </div>
        <Modal
          isOpen={showAddDivForm || false}
          target="addDivision"
          className="pt-5"
          size="lg"
        >
          <ModalHeader toggle={() => handleShowAddDivision()} className="card-borderless">
            <span className="td-text-highlight">Add New Division</span>
          </ModalHeader>
          <ModalBody className="padding-zero my-2">
            <Form onSubmit={handleDivSubmit}>
              <Row className="align-center">
                <Col lg="11" className="mx-1">
                  <h6 className="text-lite-grey mx-2 my-1">select state for Division<span className="mandate-star">*</span></h6>
                  <Select
                    value={stateData === '' ? null : selectedStateOptions}
                    options={stateoptions}
                    onChange={handleSelectState}
                    menuPortalTarget={document.body}
                    styles={{
                      menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                      menu: (base) => ({
                        ...base,
                      // position: 'relative',
                      }),
                      control: (base: any) => ({
                        ...base,
                        '&:hover': { borderColor: '#05cbddff' },
                        border: '1px solid #BFBFBF',
                        minHeight: '45px',
                        borderRadius: '5px',
                        padding: 1,
                        textAlign: 'left',
                        fontSize: '16px',
                        fontWeight: 400,
                        color: '#383838',
                        boxShadow: 'none',
                        backgroundColor: '#fff',
                        '&:focus': {
                          borderColor: '#E1EDF8',
                          boxShadow: 'none',
                          color: '#575656',
                          background: '#fff',
                        },
                      }),
                      option: (provided: any, state: { isSelected: any; }) => ({
                        ...provided,
                        color: state.isSelected ? '#383838' : '#212121',
                        padding: 15,
                        textAlign: 'left',
                        background: state.isSelected ? '#FAFCFB' : '#fff',
                        '&:hover': {
                          background: '#FAFCFB',
                        },
                      }),
                    }}
                    isLoading={loaderState}
                    noOptionsMessage={() => 'No State Found'}
                    placeholder="Select State"
                  />
                </Col>
                <Col lg="11" className="mx-1 pt-3">
                  <h6 className="text-lite-grey mx-2 my-1">Division Name<span className="mandate-star">*</span></h6>
                  <Input
                    placeholder="Division Name"
                    type="text"
                    required
                    name="nme"
                    className="powermart-input mx-1"
                    value={divInput.div[0].nme}
                    onChange={handleAddDivInputChange}
                  />
                </Col>
                <Col lg="11" className="mx-1 pt-3">
                  <h6 className="text-lite-grey mx-2 my-1">Transmission Zone(District)<span className="mandate-star">*</span></h6>
                  <Input
                    placeholder="Transmission Zone"
                    type="text"
                    required
                    name="tZone"
                    className="powermart-input mx-1"
                    value={divInput.div[0].tZone}
                    onChange={handleAddDivInputChange}
                  />
                </Col>
                <Col lg="11">
                  <div className="align-center pt-2 my-3 mx-2">
                    <Button
                      className="powermart-button-fullwidth mx-1"
                      disabled={divInput.div[0].st === '' && divInput.div[0].nme === '' && divInput.div[0].tZone === ''}
                    >
                      SAVE
                    </Button>
                  </div>
                </Col>
              </Row>
            </Form>
          </ModalBody>
        </Modal>
      </Col>
      <Col lg="5" className="mx-3">
        <h6 className="text-lite-grey mx-2 my-1">select state for Division<span className="mandate-star">*</span></h6>
        <Select
          value={stateData === '' ? null : selectedStateOptions}
          options={stateoptions}
          onChange={handleSelectState}
          menuPortalTarget={document.body}
          styles={{
            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
            menu: (base) => ({
              ...base,
              // position: 'relative',
            }),
            control: (base: any) => ({
              ...base,
              '&:hover': { borderColor: '#05cbddff' },
              border: '1px solid #BFBFBF',
              minHeight: '45px',
              borderRadius: '5px',
              padding: 1,
              textAlign: 'left',
              fontSize: '16px',
              fontWeight: 400,
              color: '#383838',
              boxShadow: 'none',
              backgroundColor: '#fff',
              '&:focus': {
                borderColor: '#E1EDF8',
                boxShadow: 'none',
                color: '#575656',
                background: '#fff',
              },
            }),
            option: (provided: any, state: { isSelected: any; }) => ({
              ...provided,
              color: state.isSelected ? '#383838' : '#212121',
              padding: 15,
              textAlign: 'left',
              background: state.isSelected ? '#FAFCFB' : '#fff',
              '&:hover': {
                background: '#FAFCFB',
              },
            }),
          }}
          isLoading={loaderState}
          noOptionsMessage={() => 'No State Found'}
          placeholder="Select State"
        />
      </Col>
      <Col lg="11" className="pt-4 mx-3">
        {divListData && divListData.length === 0 ? (
          <div className="pt-5 align-center">
            <span className="form-collapse-card-header">No Record Found</span>
          </div>
        )
          : (
            <div className="align-center">
              <table className="styled-table">
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>State</th>
                    <th>Time Zone</th>
                    <th>Division Name</th>
                  </tr>
                </thead>
                <tbody>
                  {divListData && divListData.map((div, divIndex) => (
                    <tr key={`cellDiv-${divIndex}`} className="table-primary">
                      <td>{div.MetaId}</td>
                      <td>{div.MetaTp}</td>
                      <td>{div.tZone}</td>
                      <td>{div.nme}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
      </Col>
    </Row>
  );
};

export default DivisionData;
