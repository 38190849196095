import React from 'react';
import './horizontalStepper.scss';
import { HorzProps } from './horz.type';

export const HorizontalStepper: React.FC<HorzProps> = ({ horzSteps, indexStep, status }) => {
  const renderSteps = () => horzSteps.map((step, index) => {
    const isEditing = index === indexStep;
    const isDone = index < indexStep;

    let stepClass = 'step';
    if (isEditing) {
      stepClass += ' editing';
    } else if (isDone) {
      stepClass += ' done';
    }

    const displayLabel = step.label === 'TO DELIVER' && status === 'SHPC' ? 'DELIVERED' : step.label;
    return (
      <div key={`step-${index.toString()}`} className={stepClass}>
        <div className="step-circle">
          <span>{index + 1}</span>
        </div>
        <div className="step-title">{displayLabel}</div>
        <div className="step-optional">{step.prodSts}</div>
        {index < horzSteps.length - 1 && <div className="step-bar-left" />}
        <div className="step-bar-right" />
      </div>
    );
  });

  return (
    <div className="stepper-horizontal" id="stepper1">
      {renderSteps()}
    </div>
  );
};

export default HorizontalStepper;
