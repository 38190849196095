import React from 'react';
import '../dashboard.scss';
import {
  Row, Col, Card, CardBody, CardImg,
  Input,
  CardHeader,
  Button,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowCircleDown,
  faEnvelope, faHeadset, faLocation, faMobileRetro, faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { getConfig } from '../../../../../utils/config/config';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../../utils/service/localstorage-service';
import { StatusComponentProps } from '../dash.type';
import { APIHeader, DiscomDistributionOptions, DiscomTransmissionOptions } from '../../../../../utils/constants';
import { LoaderData } from '../../../../../utils/loader';
import { changeDateFormat, errorAlert, successAlert } from '../../../../../utils/alert';
import { OrdersResult, OrdersResultData } from '../../../../../services/products/power-orders/get-all-orders.types';
import {
  clearGetAWSFileUrlReq,
  clearOrdersListReq, clearPutPendOrdrAdmReq, getAWSFileUrlReq, getOrdersListReq, putPendOrdrAdmReq,
} from '../../../../../store/powerMart/actions';
// import PdfViewer from '../PdfViewer';
import { PendOrdrStsEditData } from '../../../../../services/products/update-pend-ordr/update-pend-order.types';
import Tooltip from '../../../../../utils/Tooltip/Tooltip';
import Modal from '../../../../../utils/Modal/Modal';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const InitiatedOrdersAdmin: React.FC<StatusComponentProps> = ({ orderSts, orderNme }) => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const [loaderOrder, setLoaderOrder] = React.useState(false);
  const [loaderUpdateOrder, setLoaderUpdateOrder] = React.useState(false);
  const [userTp, setUserTp] = React.useState('');
  const [whichIndexApprove, setWhichIndexApprove] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [isRejectReason, setIsRejectReason] = React.useState(false);
  const [isOnHoldReason, setIsOnHoldReason] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [isApproveConfirmed, setIsApproveConfirmed] = React.useState<Record<string, boolean>>({});
  const [modalConfirmApprove, setModalConfirmApprove] = React.useState<Record<string, boolean>>({});
  const pendingOrdersResponse = useSelector((state: RootState) => state.powerMart.getOrdersList);
  const updatePendOrderStsResponse = useSelector((state: RootState) => state.powerMart.updatePendOrdrByAdm);
  const [openModalSeller, setOpenModalSeller] = React.useState<Record<string, boolean>>({});
  const [poUrlIndex, setPOUrlIndex] = React.useState('');
  const [poUrl, setPOUrl] = React.useState<Record<string, string>>({});
  const getAWSFileUrlRes = useSelector((state: RootState) => state.powerMart.getAWSFileUrl);
  const [pendingOrderData, setPendingOrdersData] = React.useState<OrdersResult[]>([{
    fCnt: 0,
    usrInfo: {
      addr: '',
      div: '',
      eId: '',
      nme: '',
      ph: '',
      st: '',
      subDiv: '',
    },
    mSts: '',
    txnId: '',
    txnDt: '',
    ordDt: '',
    sumCost: '',
    sumGst: '',
    sumTotal: '',
    prodTxnData: [],
    ordId: '',
    usrOrg: '',
  }]);
  const [orderStatusInput, setOrderStatusInput] = React.useState<PendOrdrStsEditData>({
    ActTp: '',
    ActId: '',
    sSts: '',
    mSts: 'PEND',
    rsn: '',
    ordId: '',
    shipDet: [
      {
        nCnt: 0,
        shipDt: '',
      },
    ],
  });
  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  React.useEffect(() => {
    if (orderSts === 'RBUY' && userTp !== '') {
      setLoaderOrder(true);
      dispatch(getOrdersListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
        status: 'RBUY',
      }));
    }
  }, [userTp, tokenData, orderSts]);

  React.useEffect(() => {
    if (pendingOrdersResponse.isError && pendingOrdersResponse.message !== '' && orderSts === 'RBUY') {
      setLoaderOrder(false);
      setAlertMsg(pendingOrdersResponse.message);
      setErrorShowAlert(true);
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message, orderSts]);

  React.useEffect(() => {
    if (!pendingOrdersResponse.isError && pendingOrdersResponse.message === 'executed' && orderSts === 'RBUY') {
      setLoaderOrder(false);
      setPendingOrdersData(pendingOrdersResponse.data);
      dispatch(clearPutPendOrdrAdmReq());
      dispatch(clearOrdersListReq());
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message, orderSts, pendingOrdersResponse.data, orderSts]);

  const handleReasonChange = (e: any) => {
    setOrderStatusInput({ ...orderStatusInput, rsn: e.target.value });
  };

  // const handleOpenReason = (accTp: string, accId: string) => {
  //   setOrderStatusInput({
  //     ...orderStatusInput, ActTp: accTp, ActId: accId, sSts: 'REJCT', rsn: '',
  //   });
  //   setIsRejectReason(true);
  //   setIsOnHoldReason(false);
  // };

  // const handleOpenOnHold = (accTp: string, accId: string) => {
  //   setOrderStatusInput({
  //     ...orderStatusInput, ActTp: accTp, ActId: accId, sSts: 'ONHLD', rsn: '',
  //   });
  //   setIsRejectReason(false);
  //   setIsOnHoldReason(true);
  // };
  // GENERATE AWS IMAGE Start
  const handleShowOrders = (target: string, folderDet: string, pdfUrl: string) => {
    setPOUrlIndex(target);
    dispatch(getAWSFileUrlReq({
      bucketNme: AWS_PRODUCT_BUCKET,
      folderName: `p-orders/${folderDet}`,
      fileNme: pdfUrl,
      requestType: '',
    }));
  };

  React.useEffect(() => {
    if (getAWSFileUrlRes.fileUrl !== '') {
      setPOUrl((prevState) => ({
        ...prevState,
        [poUrlIndex]: getAWSFileUrlRes.fileUrl,
      }));
      dispatch(clearGetAWSFileUrlReq());
    }
  }, [getAWSFileUrlRes.fileUrl]);

  const handleDownload = (url:string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'downloaded-file.pdf';
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  // GENERATE AWS IMAGE End

  const sellerToggleModal = (target: string) => {
    setOpenModalSeller((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleConfApprove = (target: string, orderId: string) => {
    setIsRejectReason(false);
    setIsOnHoldReason(false);
    setOrderStatusInput({
      ...orderStatusInput, ordId: orderId, sSts: 'APPR',
    });
    setModalConfirmApprove((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleConfirmApprove = (target: string, status: string) => {
    setWhichIndexApprove(target);
    if (status === 'approve') {
      setIsApproveConfirmed((prevState) => ({
        ...prevState,
        [target]: true,
      }));
    } else {
      setIsApproveConfirmed((prevState) => ({
        ...prevState,
        [target]: false,
      }));
    }
    setModalConfirmApprove((prevState) => ({
      ...prevState,
      [target]: false,
    }));
  };
  const handleSubmitReject = () => {
    setLoaderUpdateOrder(true);
    Promise.resolve(
      dispatch(putPendOrdrAdmReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_PND_ORDR_BY_ADM,
        inputBody: orderStatusInput,
        token: tokenData,
      })),
    );
  };

  const handleSubmitOnHold = () => {
    setLoaderUpdateOrder(true);
    Promise.resolve(
      dispatch(putPendOrdrAdmReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_PND_ORDR_BY_ADM,
        inputBody: orderStatusInput,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (isApproveConfirmed[whichIndexApprove]) {
      setLoaderUpdateOrder(true);
      Promise.resolve(
        dispatch(putPendOrdrAdmReq({
          userType: userTp,
          requestType: APIHeader.REQ_UPDATE_PND_ORDR_BY_ADM,
          inputBody: orderStatusInput,
          token: tokenData,
        })),
      );
    }
  }, [isApproveConfirmed[whichIndexApprove]]);

  React.useEffect(() => {
    if (updatePendOrderStsResponse.error && updatePendOrderStsResponse.message !== '') {
      setLoaderUpdateOrder(false);
      setAlertMsg(updatePendOrderStsResponse.message);
      setErrorShowAlert(true);
      dispatch(clearPutPendOrdrAdmReq());
    }
  }, [updatePendOrderStsResponse.error, updatePendOrderStsResponse.message]);

  React.useEffect(() => {
    if (!updatePendOrderStsResponse.error && updatePendOrderStsResponse.message !== '') {
      setLoaderUpdateOrder(false);
      setAlertMsg(updatePendOrderStsResponse.message);
      setSuccessShowAlert(true);
      setIsOnHoldReason(false);
      Promise.resolve(
        dispatch(getOrdersListReq({
          userType: userTp,
          token: tokenData,
          requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
          status: 'RBUY',
        })),
      ).then(() => {
        dispatch(clearPutPendOrdrAdmReq());
      });
    }
  }, [updatePendOrderStsResponse.error, updatePendOrderStsResponse.message]);

  const handleDisable = (prodTxnData: OrdersResultData[]) => {
    if (!prodTxnData || prodTxnData.length === 0) return true;
    const isAnyOtherStatus = prodTxnData.some((txn) => txn.sSts === '');
    return isAnyOtherStatus;
  };

  return (
    <div>
      {loaderOrder ? <LoaderData /> : null}
      {loaderUpdateOrder ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row className="align-center">
        <Col xs="12">
          <Row className="pt-2 mx-1">
            <Col lg="6" xs="6">
              <div className="pt-3 align-start">
                <span className="meta-waranty-lite">{orderNme}</span>
              </div>
            </Col>
            <Col lg="6" xs="6">
              <div className="pt-3 align-end">
                <span className="meta-waranty-lite">Order Count: {pendingOrderData.length}</span>
              </div>
            </Col>
            <hr />
          </Row>
        </Col>
        <Col xs="12">
          <Row className="pt-2">
            <Col lg="12">
              {pendingOrderData && pendingOrderData.length > 0
                ? pendingOrderData.map((orderDet, index) => (
                  <div key={`order-txn-${index.toString()}`} className="my-1">
                    <Card className="dash-card">
                      <Row>
                        <Col lg="7" xs="12">
                          <CardHeader>
                            <Row>
                              <div className="my-1 align-start">
                                <span className="super-dashboard-header mr">Buyer Information:</span>
                                <span className="order-id-text">{orderDet.ordId}<span className="mx-2 super-dashboard-header">|</span>{changeDateFormat(orderDet.ordDt)}</span>
                              </div>
                              <Col xs="12">
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-name">{orderDet.usrInfo?.nme} ({orderDet.usrOrg})</span>
                                </div>
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-sub-name">{ DiscomDistributionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                                  <span className="super-dashboard-sub-name">{ DiscomTransmissionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                                </div>
                              </Col>
                              <Col xs="6" lg="6">
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-sub-name">
                                    <FontAwesomeIcon icon={faEnvelope} className="mr" />
                                    {orderDet.usrInfo?.eId}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="6" lg="6">
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-sub-name">
                                    <FontAwesomeIcon icon={faPhone} className="mr" />
                                    +91 {orderDet.usrInfo?.ph}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="12" lg="12">
                                <div className="pt-2 align-start">
                                  <span className="super-dashboard-sub-name">
                                    <FontAwesomeIcon icon={faLocation} className="mr" />
                                    {orderDet.usrInfo?.addr}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="padding-zero ml">
                            <div className="pt-2">
                              <span className="super-dashboard-sub-name">Total Products: {orderDet.fCnt}</span>
                            </div>
                            <Row className="pt-2">
                              {orderDet && orderDet.prodTxnData.length > 0
                                ? orderDet.prodTxnData.map((allOrders, orderIndex) => (
                                  <Col xs="12" key={`allOrder-txn-${orderIndex.toString()}`} className="my-1">
                                    <Card className={allOrders.sSts === 'REJCT' ? 'card-lite-opaq' : 'card-lite'}>
                                      <Row>
                                        <Col lg="12">
                                          <div className="align-start ml">
                                            <span className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : 'card-toggle-text'}>{allOrders.prodInfo.nme}</span>
                                          </div>
                                        </Col>
                                        {/* <Col lg="3">
                                          <div className="align-end mb">
                                            <Button className="button-icon wrong-pass-key" onClick={() => handleShowOrders(`${index.toString()}`, orderDet.ordId, `po-${orderDet.ordId}_1.pdf`)}>
                                              PO<FontAwesomeIcon icon={faFilePdf} className="mx-1" />
                                            </Button>
                                          </div>
                                        </Col> */}
                                      </Row>
                                      <Row>
                                        <Col lg="3" xs="4">
                                          <div className="align-start my-2">
                                            <div className="image-container-prod-power ml">
                                              <CardImg src={allOrders.prodInfo.imgUrl} alt="Prod Image" className="dash-product-image-prod-power" />
                                              {allOrders.sSts !== '' && allOrders.sSts === 'REJCT' && (
                                              <div className="overlay-prod-power">
                                                <CardImg src="/images/stop.png" alt="Prod Image" className="image-reject-prod-power" />
                                              </div>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                                        <Col lg="9" xs="8">
                                          <Row>
                                            <Col lg="12" xs="12">
                                              <Row className="pt-1">
                                                <Col lg="4" xs="4">
                                                  <div className="align-start mx-1 pt-2">
                                                    <span className="text-lite-grey">Each Price</span>
                                                  </div>
                                                  <div className="align-start mx-1 my-2">
                                                    <span className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                                      {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(allOrders.prodInfo.cost))}
                                                    </span>
                                                  </div>
                                                </Col>
                                                <Col lg="4" xs="4">
                                                  <div className="align-start mx-2 pt-2">
                                                    <span className="text-lite-grey">Quantity</span>
                                                  </div>
                                                  <div className="align-start mx-2">
                                                    <Input
                                                      placeholder="Quantity"
                                                      type="number"
                                                      disabled
                                                      min="0"
                                                      name="cnt"
                                                      defaultValue={allOrders.prodCnt || ''}
                                                      className="powermart-input"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col lg="4" xs="4">
                                                  <div className="align-end mx-1 pt-2">
                                                    <span className="text-lite-grey">Total</span>
                                                  </div>
                                                  <div className="align-end mx-1 my-2">
                                                    <span className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                                      {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(allOrders.prodCost))}
                                                    </span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col xs="12">
                                          <div className="ml">
                                            {allOrders.rsn !== '' ? <span className="wrong-pass-key">Reason: {allOrders.rsn}</span> : null }
                                          </div>
                                        </Col>
                                        <Col lg="7" xs="7">
                                          <div className="align-start ml">
                                            {allOrders.shipDt !== ''
                                              ? <span className="text-bold-grey"><span className="text-lite-grey">Est Shipment:</span> {changeDateFormat(allOrders.shipDt)}</span> : <span className="text-lite-grey">Est Shipment: NA</span>}
                                          </div>
                                        </Col>
                                        <Col lg="5" xs="5">
                                          <div className="align-end mx-2">
                                            {allOrders.sSts === ''
                                              ? <span className="text-bold-grey">In-Progress</span>
                                              : allOrders.sSts === 'SAPPR' || allOrders.sSts === 'SUPDT'
                                                ? (
                                                  <span className="correct-pass-key">Approved
                                                    {allOrders.sSts === 'SUPDT' ? <span className="wrong-pass-key">(M)</span> : null }
                                                  </span>
                                                )
                                                : allOrders.sSts === 'REJCT'
                                                  ? <span className="wrong-pass-key">Rejected</span>
                                                  : null}
                                            <span className="mx-2 text-lite-grey">|</span>
                                            <Tooltip
                                              content="CHAT/CALL WITH SELLER"
                                              position="top"
                                              customClassName={`chatSeller-${orderIndex}`}
                                            >
                                              <Button className="button-icon padding-zero" id={`chatSeller-${orderIndex}`} onClick={() => sellerToggleModal(`sellerDet-${index}-${orderIndex}`)}>
                                                <FontAwesomeIcon icon={faHeadset} className="dash-seller-chat-icon" />
                                              </Button>
                                            </Tooltip>
                                            <Modal
                                              show={openModalSeller[`sellerDet-${index}-${orderIndex}`] || false}
                                              disCancel={false}
                                              targetId={`sellerDet-${index}-${orderIndex}`}
                                              onClose={() => sellerToggleModal(`sellerDet-${index}-${orderIndex}`)}
                                              title={(
                                                <div className="align-start">
                                                  <CardImg src={allOrders.prodInfo.tagInfo.logo} alt="Seller Image" className="seller-image-icon" />
                                                  <span className="dash-seller-chat-icon ml">{allOrders.prodInfo.tagInfo.nme}</span>
                                                </div>
)}
                                              size="MD"
                                            >
                                              <div className="my-1 align-start">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faUserCircle} className="text-lite-grey mx-2" />
                                                  {allOrders.prodInfo.tagInfo.cntNme}
                                                </span>
                                              </div>
                                              <div className="my-1 align-start">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faEnvelope} className="text-lite-grey mx-2" />
                                                  {allOrders.prodInfo.tagInfo.cntEId}
                                                </span>
                                              </div>
                                              <div className="my-1 align-start">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faPhone} className="text-lite-grey mx-2" />
                                                  +91 {allOrders.prodInfo.tagInfo.cntPh1}
                                                </span>
                                              </div>
                                              <div className="my-1 align-start">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faMobileRetro} className="text-lite-grey mx-2" />
                                                  {allOrders.prodInfo.tagInfo.cntPh2}
                                                </span>
                                              </div>
                                              <div className="my-1 align-start">
                                                <span className="text-lite-grey mx-2">Designation:</span>
                                                <span className="powermart-dark-icon">
                                                  {allOrders.prodInfo.tagInfo.Desg}
                                                </span>
                                              </div>
                                            </Modal>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                )) : (
                                  <div className="pt-4 align-center">
                                    <span className="form-collapse-card-header">No Orders avilable</span>
                                  </div>
                                )}
                            </Row>
                            <Row className="pt-3">
                              <hr className="dash-hr-white" />
                              {/* {orderDet.sSts !== '' && orderDet.sSts === 'ONHLD'
                                ? (
                                  <Col xs="12">
                                    <div className="text-center-aligned my-2">
                                      <span className="super-dashboard-on-hold">Reason: {orderDet.rsn}</span>
                                    </div>
                                  </Col>
                                ) : null}
                              {orderDet.sSts !== '' && orderDet.sSts === 'ONHLD'
                                ? (
                                  <Col xs="3" lg="3">
                                    <div className="align-center ml-2">
                                      <CardImg src="/images/stop.png" alt="Prod Image" className="avatar-cards" />
                                    </div>
                                    <div className="align-center ml-2 pt-2">
                                      <span className="super-dashboard-name">On HOLD</span>
                                    </div>
                                  </Col>
                                ) : null} */}
                              <Col xs="12">
                                <div className="align-end my-1">
                                  <span className="super-dashboard-sub-name mx-1">Product Sum : </span>
                                  <span className="super-dashboard-name ml">
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumCost))}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="12">
                                <div className="align-end my-1">
                                  <span className="super-dashboard-sub-name mx-1">GST Sum(18%) : </span>
                                  <span className="super-dashboard-name ml">
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumGst))}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="12">
                                <div className="align-end my-1">
                                  <span className="super-dashboard-sub-name mx-1">Sum Total : </span>
                                  <span className="super-dashboard-name ml">
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumTotal))}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <Col xs="12">
                              <div className="align-end my-1 pt-3">
                                {/* <Button className="powermart-button-red ml-1" onClick={() => handleOpenReason(orderDet.ordId, orderDet.ordId)}>
                                    REJECT
                                  </Button>
                                  <Button className="powermart-button-red ml-1" onClick={() => handleOpenOnHold(orderDet.ordId, orderDet.ordId)}>
                                    ON-HOLD
                                  </Button> */}
                                <Button
                                  className="powermart-button-red ml-1"
                                  onClick={() => handleConfApprove(`orderApprove-${index}`, orderDet.ordId)}
                                  disabled={handleDisable(orderDet.prodTxnData)}
                                >
                                  APPROVE
                                </Button>
                              </div>
                              {isRejectReason
                                ? (
                                  <div className="pt-2">
                                    <Input
                                      type="textarea"
                                      placeholder="Provide Rejection Reason"
                                      className="powermart-input-desc"
                                      onChange={(e) => handleReasonChange(e)}
                                    />
                                    <div className="align-end pt-2">
                                      <Button disabled={orderStatusInput.rsn === ''} className="powermart-button-red ml-1" onClick={handleSubmitReject}>
                                        SUBMIT
                                      </Button>
                                    </div>
                                  </div>
                                ) : null}
                              {isOnHoldReason
                                ? (
                                  <div className="pt-2">
                                    <Input
                                      type="textarea"
                                      placeholder="Provide On-Hold Reason"
                                      className="powermart-input-desc"
                                      onChange={(e) => handleReasonChange(e)}
                                    />
                                    <div className="align-end pt-2">
                                      <Button disabled={orderStatusInput.rsn === ''} className="powermart-button-red ml-1" onClick={handleSubmitOnHold}>
                                        SUBMIT
                                      </Button>
                                    </div>
                                  </div>
                                ) : null}
                              <Modal
                                show={modalConfirmApprove[`orderApprove-${index}`] || false}
                                disCancel={false}
                                targetId={`orderApprove-${index}`}
                                onClose={() => handleConfirmApprove(`orderApprove-${index}`, 'cancel')}
                                title="Do you really want to Approve ?"
                                size="SM"
                              >
                                <div className="align-center my-3">
                                  <Button className="powermart-button-red mx-1" onClick={() => handleConfirmApprove(`orderApprove-${index}`, 'cancel')}>
                                    CANCEL
                                  </Button>
                                  <Button className="powermart-button-solid mx-1" onClick={() => handleConfirmApprove(`orderApprove-${index}`, 'approve')}>
                                    APPROVE
                                  </Button>
                                </div>
                              </Modal>
                            </Col>
                          </CardBody>
                        </Col>
                        <Col lg="5" xs="12">
                          {poUrl[index] === undefined
                            ? (
                              <div className="mx-2 pt-5">
                                <span className="text-show-white-po">Click on below icon <br />to show purchase order</span><br />
                                <div className="pt-2">
                                  <Button className="button-icon" onClick={() => handleShowOrders(`${index.toString()}`, orderDet.ordId, `po-${orderDet.ordId}_1.pdf`)}>
                                    <FontAwesomeIcon icon={faArrowCircleDown} className="text-show-white-po" />
                                  </Button>
                                </div>
                              </div>
                            )
                            : (
                              <object
                                id={`order-PDF-${index.toString()}`}
                                data={poUrl[`${index.toString()}`]}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{ display: 'block' }}
                              >
                                <div className="mx-2">
                                  <p className="dash-card-header my-3 mx-3">This browser does not support embedded PDFs. You can download the PDF
                                    <Button onClick={() => handleDownload(poUrl[`${index.toString()}`])} className="button-icon padding-zero ml mb-1">here</Button>.
                                  </p>
                                </div>
                              </object>
                            )}
                          {/* {orderDet.ordId !== ''
                            ? <PdfViewer key={`order-PDF-${index.toString()}`} pdfUrl={`po-${orderDet.ordId}_1.pdf`} folderDet={orderDet.ordId} indexDet={index.toString()} />
                            : null} */}
                        </Col>
                      </Row>
                    </Card>
                  </div>
                ))
                : (
                  <div className="pt-4 align-center">
                    <span className="form-collapse-card-header">No Orders Found</span>
                  </div>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default InitiatedOrdersAdmin;
