import React, { useState } from 'react';
import './home.scss';
import { CardImg } from 'reactstrap';
import { ImageGalleryProps } from './home.type';

const TransClientImageGallery: React.FC<ImageGalleryProps> = ({ images }) => {
  const [scrollLeft, setScrollLeft] = useState(0);
  const imageWidth = 250; // Adjust this based on your image width
  const spacing = 100;

  React.useEffect(() => {
    const scrollInterval = setInterval(() => {
      setScrollLeft((prevPosition) => {
        const newPosition = prevPosition + 2;
        return newPosition >= images.length * (imageWidth + spacing) ? 0 : newPosition;
      });
    }, 40);
    return () => clearInterval(scrollInterval);
  }, [images.length]);

  return (
    <div className="image-gallery-container my-2 intro-product-cat-card">
      <div className="image-gallery" style={{ transform: `translateX(-${scrollLeft}px)` }}>
        {images.map((imageUrl, index) => (
          <CardImg key={`${index.toString()}`} src={imageUrl} alt={`Image ${index}`} className="gal-img" />
        ))}
        {images.map((imageUrl, index) => (
          <CardImg key={`${index.toString()}-clone`} src={imageUrl} alt={`Image ${index}`} className="gal-img" />
        ))}
      </div>
      <div className="pt-5" />
    </div>
  );
};

export default TransClientImageGallery;
