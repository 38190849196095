import React from 'react';
import { useNavigate } from 'react-router';
import { NavLink } from 'react-router-dom';
import './nav.scss';
import {
  ButtonDropdown,
  CardImg,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
} from 'reactstrap';
import {
  faClose, faBars, faBoxOpen, faHeadset,
  faStore,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleUser, faUserCircle } from '@fortawesome/free-regular-svg-icons';

export const Navbar: React.FC = () => {
  const history = useNavigate();
  // const location = useLocation();
  const [click, setClick] = React.useState(false);
  const [changeIcon, setChangeIcon] = React.useState(false);
  const [showLogin, setShowLogin] = React.useState(false);

  const handleClick = () => {
    setChangeIcon(!changeIcon);
    setClick(!click);
  };

  const handleShowLogin = () => setShowLogin((prevState) => !prevState);

  return (
    <div className="navbar-container">
      <Row className="navbar">
        <Col lg="4" xs="4">
          <NavLink to="/" className="nav-logo">
            <div className="ml-2 my-2">
              <CardImg
                src="/powerMart.png"
                alt="powerMart"
                className="logo-bg"
              />
            </div>
          </NavLink>
        </Col>
        <Col lg="8" xs="8">
          <div className="align-end display-desk">
            <NavLink
              className="mx-5 pt-3"
              to="/all-products"
              id="all-products"
              target="_blank"
              title="Our Products"
              // onClick={() => { history('/all-products'); }} opening parent page as well
            >
              <div className="align-center">
                <FontAwesomeIcon icon={faBoxOpen} className="nav-icons-lite mx-1" />
              </div>
              <div className="align-center pt-1">
                <span className="nav-text-small">Products</span>
              </div>
            </NavLink>
            <NavLink
              className="mr-3 pt-3"
              to="/power-support"
              title="Support"
              onClick={() => { history('/power-support'); window.scrollTo(0, 0); }}
            >
              <div className="align-center">
                <FontAwesomeIcon icon={faHeadset} className="nav-icons-lite mx-1" />
              </div>
              <div className="align-center pt-1">
                <span className="nav-text-small">Support</span>
              </div>
            </NavLink>
            <ButtonDropdown
              isOpen={showLogin}
              toggle={() => handleShowLogin()}
              className="mx-4 margin-top-dropdwn"
            >
              <DropdownToggle caret className="button-open-login">
                <span>
                  <FontAwesomeIcon icon={faCircleUser} className="mr" />Login
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => { history('/login/puser#logn'); window.scrollTo(0, 0); }} className="my-2">
                  User Login
                </DropdownItem>
                <DropdownItem onClick={() => { history('/seller-login/seller#logn'); window.scrollTo(0, 0); }} className="my-2">
                  Seller Login
                </DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          </div>
          <NavLink
            className="nav-icon mr-1"
            onClick={() => { handleClick(); }}
            to="#"
          >
            {changeIcon ? <FontAwesomeIcon icon={faClose} className="nav-close-btn" /> : <FontAwesomeIcon icon={faBars} className="nav-open-btn" />}
          </NavLink>
        </Col>
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <div className="mobile-view-nav pt-2">
            <div className="pt-3 mx-1">
              <NavLink
                className="nav-links ml-1"
                to="/all-products"
                target="_blank"
                title="Our Products"
                onClick={() => { history('/all-products'); }}
              >
                <FontAwesomeIcon icon={faBoxOpen} className="nav-icons mx-2" />
                <span className="nav-text mx-2">Products</span>
              </NavLink>
            </div>
            <div className="pt-4 mx-1">
              <NavLink
                className="nav-links ml-1"
                to="/power-support"
                title="Support"
                onClick={() => { history('/power-support'); window.scrollTo(0, 0); }}
              >
                <FontAwesomeIcon icon={faHeadset} className="nav-icons mx-2" />
                <span className="nav-text mx-2">Support</span>
              </NavLink>
            </div>
            <div className="pt-4 mx-1">
              <NavLink
                className="nav-links ml-1"
                to="/seller-login/seller#logn"
                title="Sellers"
              >
                <FontAwesomeIcon icon={faStore} className="nav-icons mx-2" />
                <span className="nav-text mx-2">Seller Login</span>
              </NavLink>
            </div>
            <hr className="hr-horz ml-1" />
            <div className="pt-1 mx-1">
              <NavLink
                className="nav-links ml-1"
                to="/login/puser#logn"
                title="Sellers"
              >
                <FontAwesomeIcon icon={faUserCircle} className="nav-icons mx-2" />
                <span className="nav-text mx-2">User Login</span>
              </NavLink>
            </div>
          </div>
        </ul>
      </Row>
    </div>
  );
};

export default Navbar;
