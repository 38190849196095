import React from 'react';
import {
  Button,
  Card,
  CardImg,
  Col, Input, Row,
} from 'reactstrap';
import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getConfig } from '../../../../../utils/config/config';
import '../cart.scss';
import {
  clearKartCountReq,
  getKartCountReq,
  kartStatusPUTReq, kartTxnIdPUTReq,
} from '../../../../../store/powerMart/actions';
import { APIHeader } from '../../../../../utils/constants';
import {
  getDiscomUtilSessionStorage,
  getTokenFromSessionStorage, getUserProfileSessionStorage,
} from '../../../../../utils/service/localstorage-service';
import { TxnIdProps } from '../cart.type';
import LoaderData from '../../../../../utils/loader';
import { errorAlert } from '../../../../../utils/alert';
import Modal from '../../../../../utils/Modal/Modal';

const {
  WDS_ACCOUNT_INFO, WDS_ACCOUNT_NAME, WDS_ACCOUNT_IFSC, WDS_ACCOUNT_TYPE,
} = getConfig();

export const TxnCheckout: React.FC<TxnIdProps> = ({ productsData }) => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const discomUtil = getDiscomUtilSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [discomUtility, setDiscomUtil] = React.useState('');
  const [isCancelTxn, setIsCancelTxn] = React.useState(false);
  const [orderIdData, setOrderIdData] = React.useState('');
  const [modalCancelTxn, setModalCancelTxn] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [loaderTxn, setLoaderTxn] = React.useState(false);
  const [showSuccess, setShowSuccess] = React.useState(false);
  const [txnIdData, setTxnIdData] = React.useState('');
  const updateCheckOutStatusRes = useSelector((state: RootState) => state.powerMart.updateKartStatus);
  const today = new Date().toISOString().split('T')[0];
  const [txnDate, setTxnDate] = React.useState<string>(new Date().toISOString().split('T')[0]);
  const updateKartTxnIdRes = useSelector((state: RootState) => state.powerMart.updateKartTxnId);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
      setUserId(userInitTp && JSON.parse(userInitTp).profileData.UserCd);
    }
  }, [
    userInitTp && JSON.parse(userInitTp).profileData.UserTp,
  ]);

  React.useEffect(() => {
    if (discomUtil) {
      setDiscomUtil(discomUtil);
    }
  }, [discomUtil]);

  const handleChangeTxnDate = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTxnDate(e.target.value);
  };

  const handleChangeTxnId = (e:any) => {
    setTxnIdData(e.target.value);
  };

  const handleSubmitTxn = (orderId: string) => {
    setLoaderTxn(true);
    Promise.resolve(
      dispatch(kartTxnIdPUTReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_KART_TXN_ID,
        txnId: txnIdData,
        txnDt: txnDate,
        ordId: orderId,
        // ActTp: 'productsData.ActTp',
        // ActId: 'productsData.ActId',
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (updateKartTxnIdRes.error && updateKartTxnIdRes.message !== '') {
      setLoaderTxn(false);
      setAlertMsg(updateKartTxnIdRes.message);
      setErrorShowAlert(true);
    }
  }, [updateKartTxnIdRes.error, updateKartTxnIdRes.message]);

  React.useEffect(() => {
    if (!updateKartTxnIdRes.error && updateKartTxnIdRes.message === 'executed') {
      setLoaderTxn(false);
      setShowSuccess(true);
    }
  }, [updateKartTxnIdRes.error, updateKartTxnIdRes.message]);

  const handleShowOrders = () => {
    history('/puser/my-orders');
    window.location.reload();
  };

  const handleCancelTxn = () => {
    setModalCancelTxn(true);
  };

  const handleConfirmCancelTxn = (status: string, orderId: string) => {
    if (status === 'cancel') {
      setIsCancelTxn(false);
    } else if (status === 'confirm') {
      setIsCancelTxn(true);
      setOrderIdData(orderId);
    }
    setModalCancelTxn(false);
  };

  React.useEffect(() => {
    if (isCancelTxn) {
      setLoaderTxn(true);
      // dispatch(clearKartCountReq());
      Promise.resolve(
        dispatch(kartStatusPUTReq({
          userType: userTp,
          requestType: APIHeader.REQ_UPDATE_KART_MAIN_STATUS,
          mSts: 'KART',
          ordId: orderIdData,
          token: tokenData,
        })),
      );
    }
  }, [isCancelTxn]);

  React.useEffect(() => {
    if (updateCheckOutStatusRes.error && updateCheckOutStatusRes.message !== '') {
      setLoaderTxn(false);
      setAlertMsg(updateCheckOutStatusRes.message);
      setErrorShowAlert(true);
    }
  }, [updateCheckOutStatusRes.error, updateCheckOutStatusRes.message]);

  React.useEffect(() => {
    if (!updateCheckOutStatusRes.error && updateCheckOutStatusRes.message === 'executed') {
      setLoaderTxn(false);
      Promise.resolve(
        dispatch(getKartCountReq({
          userType: userTp,
          token: tokenData,
          requestType: APIHeader.REQ_GET_KART_COUNT,
          UserTp: userTp,
          UserCd: userId,
          status: 'KART',
          discTp: discomUtility,
          // discomId: discomUtility,
        })),
      ).then(() => {
        // dispatch(clearKartStatusPUTReq());
        dispatch(clearKartCountReq());
        history('/puser/dashboard');
      });
    }
  }, [updateCheckOutStatusRes.error, updateCheckOutStatusRes.message]);

  return (
    <div className="bank-bg">
      { loaderTxn ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="align-center">
        <span className="cart-checkout-label">
          RTGS/NEFT Payment Details
        </span>
      </div>
      <Row className="pt-4 align-center">
        <Col xs="12" lg="11">
          <Card className="card-kotak">
            <Row>
              <Col lg="4" xs="4">
                <div className="pt-1 align-start">
                  <CardImg alt="logo-pnb" className="kot-icon-des" src="/images/pnb-icon.png" />
                </div>
              </Col>
              <Col lg="8" xs="8">
                <div className="align-end pt-1 mx-2">
                  <Button className="button-icon padding-zero" onClick={handleCancelTxn}>
                    <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                  </Button>
                </div>
                <div className="align-end pt-1 mr">
                  <span className="acc-add-text">BANGLORE, Karnataka</span>
                </div>
                <div className="align-end mr">
                  <span className="acc-add-text">560009</span>
                </div>
              </Col>
              <Col xs="12">
                <div className="ml pt-2 align-start">
                  <span className="acc-numb-label">Account Name: </span>
                  <span className="acc-add-text">{WDS_ACCOUNT_NAME}</span>
                </div>
                <div className="ml pt-1 align-start">
                  <span className="acc-numb-label">Account Number: </span>
                  <span className="acc-add-text">{WDS_ACCOUNT_INFO}</span>
                </div>
                <div className="ml pt-1 align-start">
                  <span className="acc-numb-label">IFSC: </span>
                  <span className="acc-add-text">{WDS_ACCOUNT_IFSC}</span>
                </div>
                <div className="ml pt-1 align-start">
                  <span className="acc-numb-label">Account Type: </span>
                  <span className="acc-add-text">{WDS_ACCOUNT_TYPE}</span>
                </div>
              </Col>
              <Col lg="12">
                <div className="align-end mr-1">
                  <div className="checkout-amount-total-label">
                    <span className="acc-name-text">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumTotal))}</span>
                  </div>
                </div>
              </Col>
              <Col lg="5" xs="12">
                <div className="mx-1">
                  <div className="align-start">
                    <h6 className="text-lite-grey mx-2">Transaction Id<span className="mandate-star">*</span></h6>
                  </div>
                  <Input
                    placeholder="Transaction Id"
                    type="text"
                    name="txnId"
                    value={txnIdData}
                    onChange={handleChangeTxnId}
                    className="powermart-input"
                  />
                </div>
              </Col>
              <Col lg="5" xs="12">
                <div className=" mx-1">
                  <div className="align-start">
                    <h6 className="text-lite-grey mx-2">Txn Date<span className="mandate-star">*</span></h6>
                  </div>
                  <Input
                    value={txnDate}
                    type="date"
                    max={today}
                    onChange={handleChangeTxnDate}
                    className="powermart-input"
                  />
                </div>
              </Col>
              <Col lg="2" xs="12">
                <div className="pt-1 align-start acc-margin-bottom">
                  <Button disabled={txnIdData === ''} className="powermart-button-solid ml-1" onClick={() => handleSubmitTxn(productsData.ordId)}>
                    SUBMIT
                  </Button>
                </div>
              </Col>
              <Col lg="12">
                <div className="mx-2 my-3 pt-3 align-end">
                  <span className="txn-info-text">NOTE: Please remember to update the transaction ID
                    and transaction date after completing the payment through your bank.
                    This information will be valuable for future reference.
                    Additionally, ensure that the paid amount matches the total amount.
                    Any discrepancy in the amount could cause delays in the approval and shipment
                    of the product.
                  </span>
                </div>
              </Col>
            </Row>
          </Card>
        </Col>
        <Col xs="11">
          <Modal
            show={modalCancelTxn}
            onClose={() => ('NA')}
            disCancel={false}
            targetId="cancelTxn"
            title="Are you sure you want to cancel this transaction? Doing so will return the product to your cart"
            size="MD"
          >

            <div className="align-center my-3 mx-2">
              <Button className="powermart-button-fullwidth mx-1" onClick={() => handleConfirmCancelTxn('cancel', productsData.ordId)}>
                NO
              </Button>
              <Button className="powermart-button-fullwidth-red mx-1" onClick={() => handleConfirmCancelTxn('confirm', productsData.ordId)}>
                YES
              </Button>
            </div>
          </Modal>
        </Col>
      </Row>
      <Modal
        show={showSuccess || false}
        onClose={() => ('NA')}
        disCancel
        targetId="successOrder"
        title=""
        size="MD"
      >
        <div className="my-4">
          <Row className="align-center">
            <Col lg="3" xs="3">
              <CardImg src="/images/send-money.png" alt="Prod Image" className="mx-1" />
            </Col>
            <Col lg="9" xs="9">
              <div className="my-2 align-center">
                <span className="mx-2 notification-text">
                  Congratulations! Your purchase transaction has been successfully processed.
                  Our team is currently reviewing the transactions, and once verified, the
                  shipping process will commence. You can check the current status of your
                  order on the My-Orders page. <br /><br />Click the button below to view all your orders.
                </span>
              </div>
            </Col>
          </Row>
        </div>
        <div className="align-center mx-2">
          <Button className="powermart-button-fullwidth mx-1" onClick={handleShowOrders}>
            SHOW MY ORDERS
          </Button>
        </div>
        <div className="pt-3" />
      </Modal>
    </div>
  );
};

export default TxnCheckout;
