import { KartCountPutResponse, KartCountPutResults } from './update-kart-count.service.types';

export const KartCountPutTransform: (response: KartCountPutResponse) => KartCountPutResults = (passKeyResults) => {
  const { data, is_error, message } = passKeyResults;
  const result :KartCountPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.message = data.message;
  }
  return result;
};
