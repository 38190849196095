import React from 'react';
import '../cart.scss';
import {
  Row, Col, Card, CardBody, CardImg,
  Input,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { KartReviewProps } from '../cart.type';
import { KartsResultData } from '../../../../../services/products/list-kart/get-all-kart.types';

export const CartApprovedPrivateList: React.FC<KartReviewProps> = ({ productsData }) => {
  const handleDisable = (prodTxnData: KartsResultData[]) => {
    if (!prodTxnData || prodTxnData.length === 0) return true;
    const isAnyOtherStatus = prodTxnData.some((txn) => txn.sSts !== 'SUPDT' && txn.sSts !== 'SAPPR');
    return isAnyOtherStatus;
  };

  const checkMultiOrder = (prodTxnData: KartsResultData[]) => {
    if (!prodTxnData || prodTxnData.length === 0) return true;
    const isAnyOtherStatus = prodTxnData.some((txn) => txn.sSts === 'SUPDT');
    return isAnyOtherStatus;
  };

  const checkRejectOrder = (prodTxnData: KartsResultData[]) => {
    if (!prodTxnData || prodTxnData.length === 0) return true;
    const isAnyOtherStatus = prodTxnData.some((txn) => txn.sSts === 'REJCT');
    return isAnyOtherStatus;
  };

  return (
    <div>
      <Row className="align-center">
        <Col lg="11">
          <Row className="pt-2">
            <Col lg="4" xs="9">
              <div className="pt-3 align-start">
                <span className="meta-waranty-lite">Orders Approved</span>
                <span className="correct-pass-key ml">(Complete Payment)</span>
              </div>
            </Col>
            <Col lg="4" xs="3">
              <div className="pt-3">
                <span className="text-bold-grey">{productsData.fCnt} items</span>
              </div>
            </Col>
            <Col lg="4">
              <div className="align-end pt-3">
                <span className="meta-waranty-lite">
                  <span className="text-bold-grey">Need help? Call </span>
                  <FontAwesomeIcon icon={faWhatsapp} className="correct-pass-key mx-1" />
                  +91 8618679984
                </span>
              </div>
            </Col>
            <hr />
          </Row>
        </Col>
        <Col lg="11">
          <Row>
            <Col lg="12">
              <div className="mb-2">
                {handleDisable(productsData.prodTxnData) === false
                  ? (
                    <span className="text-lite-grey">
                      {checkMultiOrder(productsData.prodTxnData) === true
                        ? <span className="text-lite-grey">(Some products will be shipped in multiple shipments (M))</span>
                        : null}
                    </span>
                  )
                  : (
                    <span className="correct-pass-key">Once the payment is completed, you can receive the approved product within 4 weeks.
                      <br />Please finalize the payment and provide the TRANSACTION ID below to initiate the shipment process.
                      {checkMultiOrder(productsData.prodTxnData) === true
                        ? <span className="correct-pass-key mx-1">(Some products will be shipped in multiple shipments (M))</span>
                        : null}
                    </span>
                  )}
                {checkRejectOrder(productsData.prodTxnData) === true
                  ? <span className="wrong-pass-key mx-1"><br />Please note: Some products have been rejected, and valid reasons have been provided. The costs have been adjusted accordingly.</span>
                  : null}
              </div>
            </Col>
            <Col lg="8">
              {productsData && productsData.prodTxnData.length > 0
                ? productsData.prodTxnData.map((prodDet, index) => (
                  <div key={`prod-txn-${index.toString()}`} className="align-end my-2">
                    <Card className={prodDet.sSts === 'REJCT' ? 'card-lite-opaq' : 'card-lite'}>
                      <CardBody className="my-1">
                        <Row>
                          <Col xs="12">
                            <Row className="my-1">
                              <Col lg="10" xs="9">
                                <div className="align-start">
                                  <span className={prodDet.sSts === 'REJCT' ? 'text-lite-grey' : 'card-toggle-text'}>{prodDet.prodInfo.nme}</span>
                                </div>
                              </Col>
                              <Col lg="2" xs="3">
                                <div className="align-end mx-2">
                                  {prodDet.sSts === ''
                                    ? <span className="text-bold-grey">In-Progress</span>
                                    : prodDet.sSts === 'SAPPR' || prodDet.sSts === 'SUPDT'
                                      ? (
                                        <span className="correct-pass-key">Approved
                                          {prodDet.sSts === 'SUPDT' ? <span className="wrong-pass-key">(M)</span> : null }
                                        </span>
                                      )
                                      : prodDet.sSts === 'REJCT'
                                        ? <span className="wrong-pass-key">Rejected</span>
                                        : null}
                                </div>
                              </Col>
                            </Row>
                          </Col>
                          <Col xs="12">
                            <Row className="pt-1">
                              <Col lg="2" xs="3">
                                <div className="align-center mx-1">
                                  <div className="image-container-prod-power">
                                    <CardImg src={prodDet.prodInfo.imgUrl} alt="Prod Image" className="dash-product-image-prod-power" />
                                    {prodDet.sSts !== '' && prodDet.sSts === 'REJCT' && (
                                    <div className="overlay-prod-power">
                                      <CardImg src="/images/stop.png" alt="Prod Image" className="image-reject-prod-power" />
                                    </div>
                                    )}
                                  </div>
                                </div>
                              </Col>
                              <Col lg="4" xs="5">
                                <div className="align-start mx-1 pt-2">
                                  <span className="text-lite-grey">Each Price</span>
                                </div>
                                <div className="align-start mx-1 my-2">
                                  <span className={prodDet.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.prodInfo.cost))}
                                  </span>
                                </div>
                              </Col>
                              <Col lg="2" xs="4">
                                <div className="align-start mx-2 pt-2">
                                  <span className="text-lite-grey">Quantity</span>
                                </div>
                                <div className="align-start mx-2">
                                  <Input
                                    placeholder="Quantity"
                                    type="number"
                                    min="0"
                                    disabled
                                    name="cnt"
                                    defaultValue={prodDet.prodCnt || ''}
                                    className={prodDet.sSts === 'REJCT' ? 'text-lite-grey' : 'powermart-input'}
                                  />
                                </div>
                              </Col>
                              <Col lg="4" xs="12">
                                <div className="align-end mx-1 pt-2">
                                  <span className="text-lite-grey">Total</span>
                                </div>
                                <div className="align-end mx-1 my-2">
                                  <span className={prodDet.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.prodCost))}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                ))
                : (
                  <div className="pt-4 align-center">
                    <span className="form-collapse-card-header">No Product Found in Cart</span>
                  </div>
                )}
            </Col>
            <Col lg="4">
              <div>
                <Row className="pt-2 ml">
                  <Col xs="6">
                    <div className="align-start">
                      <span>Shipping cost</span>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="align-end">
                      <span className="correct-pass-key">FREE</span>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3 ml">
                  <Col xs="6">
                    <div className="align-start">
                      <span>Products Cost</span>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="align-end">
                      <span>
                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumCost))}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3 ml">
                  <Col xs="6">
                    <div className="align-start">
                      <span>GST</span>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="align-end">
                      <span>
                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumGst))}
                      </span>
                    </div>
                  </Col>
                </Row>
                <Row className="pt-3 ml">
                  <Col xs="6">
                    <div className="align-start">
                      <span className="notification-text">Total Cost</span>
                    </div>
                  </Col>
                  <Col xs="6">
                    <div className="align-end">
                      <span className="notification-text">
                        {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(productsData.sumTotal))}
                      </span>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};
export default CartApprovedPrivateList;
