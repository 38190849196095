import { ProfileResponse, ProfileResults } from './get-profile.service.types';

export const ProfileTransform: (response: ProfileResponse) => ProfileResults = (profileResults) => {
  const { data, is_error, message } = profileResults;
  const result :ProfileResults = {
    profileData: {
      nme: '',
      ph: '',
      st: '',
      UserCd: '',
      UserTp: '',
      avatar: '',
      isPKReset: false,
      lgnSts: '',
      eId: '',
      corpData: {
        nme: '',
        logo: '',
      },
      oem: [],
      discom: [{
        discomTp: '',
        discomUtil: '',
        st: '',
      }],
      div: '',
      subDiv: '',
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.profileData.nme = data.nme ?? '';
    result.profileData.ph = data.ph ?? '';
    result.profileData.st = data.st ?? 'IN';
    result.profileData.eId = data.eId ?? '';
    result.profileData.avatar = data.avatar ?? '';
    result.profileData.corpData = data.corpData ?? { logo: '', nme: '' };
    result.profileData.discom = data.discom ?? [];
    result.profileData.oem = data.oem ?? [];
    result.profileData.div = data.div ?? 'UNK';
    result.profileData.subDiv = data.subDiv ?? 'UNK';
    result.profileData.isPKReset = data.isPKReset ?? false;
    result.profileData.lgnSts = data.lgnSts ?? 'UNK';
    result.profileData.UserCd = data.UserCd ?? '';
    result.profileData.UserTp = data.UserTp ?? '';
    result.isError = is_error;
    result.message = 'executed';
  }
  return result;
};
