import React from 'react';
import './powerMart.scss';
import PowerSupport from '../../components/public/home/support';
import Navbar from '../../components/public/navbar/powerMart.navbar';

export const SupportComponent: React.FC = () => (
  <div>
    <Navbar />
    <PowerSupport />
  </div>
);

export default SupportComponent;
