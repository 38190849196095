import { OEMPutResponse, OEMPutResults } from './update-oem.service.types';

export const OEMPutTransform: (response: OEMPutResponse) => OEMPutResults = (passKeyResults) => {
  const { is_error, message } = passKeyResults;
  const result :OEMPutResults = {
    message: '',
    error: false,
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = 'executed';
  }
  return result;
};
