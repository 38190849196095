import React from 'react';
import './powerMart.scss';
import Navbar from '../../components/public/navbar/powerMart.navbar';
import { FullviewPowermartProducts } from '../../components/public/home/fullview-product-powermart';

export const AllPowermartProductComponent: React.FC = () => (
  <div>
    <Navbar />
    <div className="pt-5">
      <FullviewPowermartProducts />
    </div>
  </div>
);

export default AllPowermartProductComponent;
