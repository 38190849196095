import React from 'react';
import './dashboard.scss';
import {
  Row, Col, Card, CardBody, CardImg,
  Input,
  CardHeader,
  Button,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faEnvelope, faHeadset, faLocation, faMagnifyingGlass, faMobileRetro, faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import { APIHeader, DiscomDistributionOptions, DiscomTransmissionOptions } from '../../../../utils/constants';
import { LoaderData } from '../../../../utils/loader';
import { changeDateFormat, errorAlert, successAlert } from '../../../../utils/alert';
import { clearPutPendOrdrAdmReq, getOrderTxnByIdReq } from '../../../../store/powerMart/actions';
import PdfViewer from './PdfViewer';
import { GetOrderTxnByIdOrdersResult } from '../../../../services/get-order-txn-by-id/get-order-txn-by-id.types';

export const SearchOrders: React.FC = () => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const [loaderOrder, setLoaderOrder] = React.useState(false);
  const [userTp, setUserTp] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [activityId, setActivityId] = React.useState('');
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [activId, setActivId] = React.useState('');
  const [fileUrlStatus, setFileUrlStatus] = React.useState('PO-0');
  const [fileStstTp, setFileStstTp] = React.useState('PO');
  const ordersTxnByIdResponse = useSelector((state: RootState) => state.powerMart.getOrderTxnById);
  const [openModalSeller, setOpenModalSeller] = React.useState<Record<string, boolean>>({});
  const [orderDet, setOrderDet] = React.useState<GetOrderTxnByIdOrdersResult>({
    fCnt: 0,
    usrOrg: '',
    usrInfo: {
      addr: '',
      div: '',
      eId: '',
      nme: '',
      ph: '',
      st: '',
      subDiv: '',
    },
    subSts: '',
    rsn: '',
    mSts: '',
    txnId: '',
    txnDt: '',
    ordDt: '',
    poUrl: '',
    sumCost: '',
    sumGst: '',
    sumTotal: '',
    prodTxnData: [],
    ordId: '',
  });

  const hasRequiredStatusData = (data: string): boolean => data.includes('INACT') || data.includes('KART') || data.includes('RBUY') || data.includes('PEND') || data.includes('PCOMP') || data.includes('TACK');

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  const handleActSearch = () => {
    setLoaderOrder(true);
    dispatch(getOrderTxnByIdReq({
      userType: userTp,
      token: tokenData,
      requestType: APIHeader.REQ_GET_ORDER_TXN_BY_ACTID,
      actId: activityId,
    }));
  };

  React.useEffect(() => {
    if (ordersTxnByIdResponse.isError && ordersTxnByIdResponse.message !== '') {
      setLoaderOrder(false);
      setAlertMsg(ordersTxnByIdResponse.message);
      setErrorShowAlert(true);
    }
  }, [ordersTxnByIdResponse.isError, ordersTxnByIdResponse.message]);

  React.useEffect(() => {
    if (!ordersTxnByIdResponse.isError && ordersTxnByIdResponse.message === 'executed') {
      setLoaderOrder(false);
      setOrderDet(ordersTxnByIdResponse.data);
      dispatch(clearPutPendOrdrAdmReq());
    }
  }, [ordersTxnByIdResponse.isError, ordersTxnByIdResponse.message, ordersTxnByIdResponse.data]);

  const sellerToggleModal = (target: string) => {
    setOpenModalSeller((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleSearchChange = (e:any) => {
    setActivityId(e.target.value);
  };

  const handleDownloadEWay = (activtyId: string, indexData: string) => {
    setActivId(`${activtyId}`);
    // setOrderId(`${orderDetId}`);
    setFileUrlStatus(`EWAY-${indexData}`);
    setFileStstTp('EWAY');
  };

  const handleDownloadInvoice = (activtyId: string, indexData: string) => {
    setActivId(`${activtyId}`);
    // setOrderId(`${orderDetId}`);
    setFileUrlStatus(`INVOICE-${indexData}`);
    setFileStstTp('INVOICE');
  };

  const handleDownloadPO = (indexData: string) => {
    setFileUrlStatus(`PO-${indexData}`);
    setFileStstTp('PO');
  };

  return (
    <div>
      {loaderOrder ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row>
        <Col lg="5">
          <div className="my-4 ml">
            <span className="tex-red-italic-lite">Search Order Details by Order Id</span>
          </div>
        </Col>
        <Col lg="6">
          <div className="my-4">
            <InputGroup>
              <Input
                type="text"
                className="powermart-input"
                placeholder="Search by order Id..."
                value={activityId}
                onChange={handleSearchChange}
              />
              <InputGroupText className="input-search-layer">
                <Button className="button-icon align-center" onClick={handleActSearch}>
                  <FontAwesomeIcon icon={faMagnifyingGlass} className="round-small-icon" />
                </Button>
              </InputGroupText>
            </InputGroup>
          </div>
        </Col>
      </Row>
      <Row className="pt-3 mx-1">
        <Col lg="12">
          {orderDet && orderDet.ordId && orderDet.ordId !== ''
            ? (
              <Card className="dash-card">
                <Row>
                  <Col lg="8" xs="12">
                    <CardHeader>
                      <Row>
                        <div className="my-1">
                          <span className="order-id-text">{orderDet.ordId}<span className="mx-3 super-dashboard-header">|</span>{changeDateFormat(orderDet.ordDt)}</span>
                        </div>
                        <div className="my-1">
                          <span className="super-dashboard-header">Buyer Information:</span>
                        </div>
                        <Col xs="12">
                          <div className="my-1">
                            <span className="super-dashboard-name">{orderDet.usrInfo?.nme} ({orderDet.usrOrg})</span>
                          </div>
                          <div className="my-1">
                            <span className="super-dashboard-sub-name">{ DiscomDistributionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                            <span className="super-dashboard-sub-name">{ DiscomTransmissionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                          </div>
                        </Col>
                        <Col xs="6" lg="6">
                          <div className="my-1">
                            <span className="super-dashboard-sub-name">
                              <FontAwesomeIcon icon={faEnvelope} className="mr" />
                              {orderDet.usrInfo?.eId}
                            </span>
                          </div>
                        </Col>
                        <Col xs="6" lg="6">
                          <div className="my-1">
                            <span className="super-dashboard-sub-name">
                              <FontAwesomeIcon icon={faPhone} className="mr" />
                              +91 {orderDet.usrInfo?.ph}
                            </span>
                          </div>
                        </Col>
                        <Col xs="12" lg="12">
                          <div className="pt-2">
                            <span className="super-dashboard-sub-name">
                              <FontAwesomeIcon icon={faLocation} className="mr" />
                              {orderDet.usrInfo?.addr}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody className="padding-zero ml">
                      <div className="pt-2">
                        <span className="super-dashboard-sub-name">Total Products: {orderDet.fCnt}</span>
                      </div>
                      <Row className="pt-2">
                        {orderDet && orderDet.prodTxnData.length > 0
                          ? orderDet.prodTxnData.map((allOrders, orderIndex) => (
                            <Col xs="4" key={`allOrder-txn-${orderIndex.toString()}`} className="my-1">
                              <Card className={allOrders.subSts === 'REJCT' ? 'card-lite-opaq' : 'card-lite'}>
                                <Row>
                                  <Col xs="12">
                                    <div className="align-start ml">
                                      <span className={allOrders.subSts === 'REJCT' ? 'text-lite-grey' : 'card-toggle-text'}>{allOrders.prodInfo.nme}</span>
                                    </div>
                                  </Col>
                                  <Col xs="12">
                                    <div className="align-center my-2 ml">
                                      <div className="image-container-prod-power">
                                        <CardImg src={allOrders.prodInfo.imgUrl} alt="Prod Image" className="dash-product-image-prod-power" />
                                        {allOrders.subSts !== '' && allOrders.subSts === 'REJCT' && (
                                        <div className="overlay-prod-power">
                                          <CardImg src="/images/stop.png" alt="Prod Image" className="image-reject-prod-power" />
                                        </div>
                                        )}
                                      </div>
                                    </div>
                                  </Col>
                                  <Col xs="12">
                                    <Row className="pt-1">
                                      <Col xs="12">
                                        <div className="align-start mx-1">
                                          <span className="text-lite-grey mr">Each Price : </span>
                                          <span className={allOrders.subSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(allOrders.prodInfo.cost))}
                                          </span>
                                        </div>
                                      </Col>
                                      <Col xs="12">
                                        <div className="align-start mx-1">
                                          <span className="text-lite-grey mr">Quantity : </span>
                                          <span className="powermart-input1">{allOrders.prodCnt}</span>
                                        </div>
                                      </Col>
                                      <Col xs="12">
                                        <div className="align-start mx-1">
                                          <span className="text-lite-grey mr">Total : </span>
                                          <span className={allOrders.subSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                            {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(allOrders.prodCost))}
                                          </span>
                                        </div>
                                      </Col>
                                    </Row>
                                    <hr className="hr-horz my-3" />
                                  </Col>
                                  <Col xs="12">
                                    <div className="align-center my-1">
                                      <div className="align-center">
                                        <Button disabled={hasRequiredStatusData(allOrders.mSts)} className="powermart-button-link" onClick={() => handleDownloadEWay(allOrders.ActId, orderIndex.toString())}>
                                          <span className="transit-button-display-mob">EWAY</span>
                                          <span className="transit-button-display-desk">EWay</span>
                                        </Button>
                                      </div>
                                      <span className="mx-3 mb-1 text-lite-grey">|</span>
                                      <div className="align-center">
                                        <Button disabled={hasRequiredStatusData(allOrders.mSts)} className="powermart-button-link mb" onClick={() => handleDownloadInvoice(allOrders.ActId, orderIndex.toString())}>
                                          Invoice
                                        </Button>
                                      </div>
                                      <span className="mx-3 mb-1 text-lite-grey">|</span>
                                      <div className="align-center">
                                        <Button disabled={hasRequiredStatusData(allOrders.mSts)} className="powermart-button-link mb" onClick={() => handleDownloadPO(orderIndex.toString())}>
                                          <span className="transit-button-display-mob">PO</span>
                                          <span className="transit-button-display-desk">PO</span>
                                        </Button>
                                      </div>
                                    </div>
                                    <hr className="hr-horz my-3" />
                                  </Col>
                                  <Col xs="12">
                                    <div className="align-end mx-1">
                                      {allOrders.trckNo !== ''
                                        ? (
                                          <Button
                                            className="powermart-button-link"
                                            href={allOrders.trckUrl}
                                            target="_blank"
                                          >
                                            {allOrders.trckNo} <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="button-icon-url mx-1" />
                                          </Button>
                                        ) : null}
                                    </div>
                                  </Col>
                                  <Col xs="12">
                                    <Row className="my-2">
                                      <Col lg="5" xs="5">
                                        <div className="align-start pt-2">
                                          {allOrders.subSts === ''
                                            ? <span className="text-bold-grey">In-Progress</span>
                                            : allOrders.subSts === 'SAPPR' || allOrders.subSts === 'SUPDT'
                                              ? (
                                                <span className="correct-pass-key">Approved
                                                  {allOrders.subSts === 'SUPDT' ? <span className="wrong-pass-key">(M)</span> : null }
                                                </span>
                                              )
                                              : allOrders.subSts === 'REJCT'
                                                ? <span className="wrong-pass-key">Rejected</span>
                                                : null}
                                        </div>
                                      </Col>
                                      <Col lg="7" xs="7">
                                        <div className="align-end">
                                          {allOrders.mSts === 'SHPC'
                                            ? (
                                              <span className="correct-pass-key pt-2">Shipped</span>
                                            )
                                            : null}
                                          {allOrders.mSts === 'SHPC' ? <span className="mx-2 text-lite-grey">|</span> : null}
                                          <Button className="button-icon padding-zero mt" id={`chatSeller-${orderIndex}`} onClick={() => sellerToggleModal(`sellerDet-${orderIndex}`)}>
                                            <FontAwesomeIcon icon={faHeadset} className="dash-seller-chat-icon mt" />
                                          </Button>
                                          <UncontrolledTooltip delay={0} target={`chatSeller-${orderIndex}`}>
                                            CHAT/CALL WITH SELLER
                                          </UncontrolledTooltip>
                                          <Modal
                                            isOpen={openModalSeller[`sellerDet-${orderIndex}`] || false}
                                            target={`sellerDet-${orderIndex}`}
                                          >
                                            <ModalHeader toggle={() => sellerToggleModal(`sellerDet-${orderIndex}`)} className="form-card-border-none my-1">
                                              <CardImg src={allOrders.prodInfo.tagInfo.logo} alt="Seller Image" className="seller-image-icon mx-2" />
                                              <span className="dash-seller-chat-icon">{allOrders.prodInfo.tagInfo.nme}</span>
                                            </ModalHeader>
                                            <ModalBody className="padding-zero mx-3">
                                              <div className="my-2">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faUserCircle} className="text-lite-grey mx-2" />
                                                  {allOrders.prodInfo.tagInfo.cntNme}
                                                </span>
                                              </div>
                                              <div className="my-2">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faEnvelope} className="text-lite-grey mx-2" />
                                                  {allOrders.prodInfo.tagInfo.cntEId}
                                                </span>
                                              </div>
                                              <div className="my-2">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faPhone} className="text-lite-grey mx-2" />
                                                  +91 {allOrders.prodInfo.tagInfo.cntPh1}
                                                </span>
                                              </div>
                                              <div className="my-2">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faMobileRetro} className="text-lite-grey mx-2" />
                                                  {allOrders.prodInfo.tagInfo.cntPh2}
                                                </span>
                                              </div>
                                              <div className="my-2">
                                                <span className="text-lite-grey mx-2">Designation:</span>
                                                <span className="powermart-dark-icon">
                                                  {allOrders.prodInfo.tagInfo.Desg}
                                                </span>
                                              </div>
                                            </ModalBody>
                                          </Modal>
                                        </div>
                                      </Col>
                                    </Row>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>
                          ))
                          : (
                            <div className="pt-4 align-center">
                              <span className="form-collapse-card-header">No Orders avilable</span>
                            </div>
                          )}
                      </Row>
                      <Row className="pt-3">
                        <hr className="dash-hr-white" />
                        {/* {orderDet.subSts !== '' && orderDet.subSts === 'ONHLD'
                                ? (
                                  <Col xs="12">
                                    <div className="text-center-aligned my-2">
                                      <span className="super-dashboard-on-hold">Reason: {orderDet.rsn}</span>
                                    </div>
                                  </Col>
                                ) : null}
                              {orderDet.subSts !== '' && orderDet.subSts === 'ONHLD'
                                ? (
                                  <Col xs="3" lg="3">
                                    <div className="align-center ml-2">
                                      <CardImg src="/images/stop.png" alt="Prod Image" className="avatar-cards" />
                                    </div>
                                    <div className="align-center ml-2 pt-2">
                                      <span className="super-dashboard-name">On HOLD</span>
                                    </div>
                                  </Col>
                                ) : null} */}
                        <Col xs="12">
                          <div className="align-end my-1">
                            <span className="super-dashboard-sub-name mx-1">Product Sum : </span>
                            <span className="super-dashboard-name ml">
                              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumCost))}
                            </span>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="align-end my-1">
                            <span className="super-dashboard-sub-name mx-1">GST Sum(18%) : </span>
                            <span className="super-dashboard-name ml">
                              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumGst))}
                            </span>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="align-end my-1">
                            <span className="super-dashboard-sub-name mx-1">Sum Total : </span>
                            <span className="super-dashboard-name ml">
                              {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumTotal))}
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Col>
                  <Col lg="4" xs="12">
                    {fileStstTp === 'PO'
                      ? <PdfViewer pdfUrl={`po-${orderDet.ordId}_1.pdf`} folderDet={orderDet.ordId} indexDet={fileUrlStatus} />
                      : fileStstTp === 'EWAY'
                        ? <PdfViewer pdfUrl={`eway-${orderDet.ordId}_${activId}_1.pdf`} folderDet={orderDet.ordId} indexDet={fileUrlStatus} />
                        : fileStstTp === 'INVOICE'
                          ? <PdfViewer pdfUrl={`invoice-${orderDet.ordId}_${activId}_1.pdf`} folderDet={orderDet.ordId} indexDet={fileUrlStatus} />
                          : null}
                  </Col>
                </Row>
              </Card>
            )
            : (
              <div className="pt-4 align-center">
                <span className="form-collapse-card-header">No Orders Found. Create New Search</span>
              </div>
            )}
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default SearchOrders;
