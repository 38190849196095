import React from 'react';
import {
  Row, Col, Button, Card, CardBody, CardFooter, CardHeader,
} from 'reactstrap';
import './profile.scss';
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { faBolt, faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APIHeader, ListCPOptions } from '../../../utils/constants';
import { OEMDATA } from '../../../services/register-seller/seller-register.types';
import { getTokenFromSessionStorage, getUserProfileSessionStorage, setUserProfileSessionStorage } from '../../../utils/service/localstorage-service';
import { LoaderData } from '../../../utils/loader';
import { errorAlert } from '../../../utils/alert';
import {
  clearOEMReq, getProfile, oemReq,
} from '../../../store/powerMart/actions';

export const MYOEM: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const [userOEM, setUserOEM] = React.useState<string[]>([]);
  const [oEMInitOptions, setOEMOInitptions] = React.useState<OEMDATA[]>([]);
  const [oEMData, setOEMData] = React.useState<string | null>(null);
  const [loaderOEM, setLoaderOEM] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const loginProfileData = useSelector((state: RootState) => state.powerMart.profileData);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const updateUserOEMRes = useSelector((state: RootState) => state.powerMart.updateOEM);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData) {
      const initialOEM = JSON.parse(userInitTp).profileData.oem;
      setUserOEM(initialOEM);
      setUserTp(JSON.parse(userInitTp).profileData.UserTp);
      setOEMOInitptions(ListCPOptions.filter((option) => initialOEM.includes(option.value)));
    }
  }, []);

  const oemOptions = ListCPOptions.map((oem) => ({
    label: oem.label,
    value: oem.value,
  }));

  const selectedUpdateOEMOptions = oemOptions.find(
    (option) => option.value === oEMData,
  );

  const handleSelectOEM = (e: any) => {
    const selectedValue = e.value;
    const selectedLabel = e.label;

    if (!oEMInitOptions.some((option) => option.value === selectedValue)) {
      setUserOEM((prevUserOEM) => [...prevUserOEM, selectedValue]);

      const updatedLabel: OEMDATA[] = [
        ...oEMInitOptions,
        { label: selectedLabel, value: selectedValue },
      ];
      setOEMOInitptions(updatedLabel);
      setOEMData(null);
    }
  };

  const handleRemoveOEM = (oemDet: string) => {
    setUserOEM((prevUserOEM) => prevUserOEM.filter((oem) => oem !== oemDet));
    setOEMOInitptions((prevOptions) => prevOptions.filter((option) => option.value !== oemDet));
  };

  const handleUpdateOEM = () => {
    setLoaderOEM(true);
    dispatch(oemReq({
      requestType: APIHeader.REQ_UPDATE_OEM,
      inputBody: { oem: userOEM },
      token: tokenData,
      userType: userTp,
    }));
  };

  React.useEffect(() => {
    if (updateUserOEMRes.error && updateUserOEMRes.message !== '') {
      setLoaderOEM(false);
      setAlertMsg(updateUserOEMRes.message);
      setErrorShowAlert(true);
      dispatch(clearOEMReq());
    }
  }, [updateUserOEMRes.error, updateUserOEMRes.message]);

  React.useEffect(() => {
    if (!updateUserOEMRes.error && updateUserOEMRes.message === 'executed') {
      setLoaderOEM(false);
      dispatch(clearOEMReq());
      dispatch(getProfile({
        userReqFor: userTp,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: tokenData,
      }));
    }
  }, [updateUserOEMRes.error, updateUserOEMRes.message]);

  React.useEffect(() => {
    if (!loginProfileData.isError && loginProfileData.message !== '' && userOEM) {
      setUserProfileSessionStorage(JSON.stringify(loginProfileData));
    }
  }, [loginProfileData.isError, loginProfileData.message, userOEM]);

  return (
    <div className="pt-5">
      { loaderOEM ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Card className="intro-product-cat-card">
        <CardHeader className="form-card-header">
          Update My OEM/Category
        </CardHeader>
        <CardBody>
          <Row className="mx-2">
            <Col lg="12" className="pt-3">
              <h6 className="text-lite-grey mx-2 my-1">
                OEM/CATEGORY<span className="mandate-star">*</span>
              </h6>
              <Select
                value={oEMData === '' ? null : selectedUpdateOEMOptions}
                options={oemOptions}
                onChange={handleSelectOEM}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({ ...base }),
                  control: (base) => ({
                    ...base,
                    '&:hover': { borderColor: '#05cbddff' },
                    border: '1px solid #BFBFBF',
                    minHeight: '45px',
                    borderRadius: '5px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#383838',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided, state) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
                noOptionsMessage={() => 'No OEM/CATEGORY Found'}
                placeholder="Select OEM/CATEGORY"
              />
            </Col>
            {oEMInitOptions.length > 0 && (
              <h6 className="text-lite-grey mx-2 my-2">
                Selected OEM Type<span className="mandate-star">*</span>
              </h6>
            )}
            {oEMInitOptions.length > 0
          && oEMInitOptions.map((oemDet, index) => (
            <div key={`oem-${index.toString()}`} className="ml my-1 align-start">
              <div className="mr">
                <FontAwesomeIcon icon={faBolt} className="prod-tech-spec-icon" />
              </div>
              <div>
                <span className="discom-liting-text">{oemDet.label}</span>
                <Button className="button-icon padding-zero ml mb" onClick={() => handleRemoveOEM(oemDet.value)}>
                  <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                </Button>
              </div>
            </div>
          ))}
          </Row>
        </CardBody>
        <CardFooter className="form-card-footer">
          <div className="align-end pt-3">
            <Button disabled={userOEM.length === 0} className="powermart-button-solid mx-1" onClick={() => handleUpdateOEM()}>
              UPDATE
            </Button>
          </div>
        </CardFooter>
      </Card>
      <div className="pt-5" />
    </div>
  );
};

export default MYOEM;
