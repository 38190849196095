import { GenTokenResponse, GenTokenResults } from './gen-token.service.types';

export const GenTokenTransform: (response: GenTokenResponse) => GenTokenResults = (genTokenResults) => {
  const { data, is_error, message } = genTokenResults;
  const result: GenTokenResults = {
    token: '',
    isError: false,
    message: '',
  };
  if (is_error) {
    result.message = message;
    result.isError = is_error;
  } else {
    result.token = data;
    result.isError = is_error;
    result.message = 'executed';
  }
  return result;
};
