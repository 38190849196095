import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { Button } from 'reactstrap';
import { getConfig } from '../../../../utils/config/config';
import { clearGetAWSFileUrlReq, getAWSFileUrlReq } from '../../../../store/powerMart/actions';

const { AWS_PRODUCT_BUCKET } = getConfig();

const PdfViewer: React.FC<{ pdfUrl: string, folderDet: string, indexDet: string, }> = ({ pdfUrl, folderDet, indexDet }) => {
  const dispatch = useDispatch();
  const getAWSFileUrlRes = useSelector((state: RootState) => state.powerMart.getAWSFileUrl);
  const [poUrl, setPOUrl] = React.useState('');

  React.useEffect(() => {
    if (pdfUrl !== '' && indexDet !== '') {
      dispatch(getAWSFileUrlReq({
        bucketNme: AWS_PRODUCT_BUCKET,
        folderName: `p-orders/${folderDet}`,
        fileNme: pdfUrl,
        requestType: '',
      }));
    }
  }, [pdfUrl, dispatch, indexDet]);

  React.useEffect(() => {
    if (getAWSFileUrlRes.fileUrl !== '' && indexDet !== '') {
      setPOUrl(getAWSFileUrlRes.fileUrl);
      dispatch(clearGetAWSFileUrlReq());
    }
  }, [getAWSFileUrlRes.fileUrl, indexDet]);

  const handleDownload = (url:string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'downloaded-file.pdf';
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  return (
    <div style={{ height: '100%', overflow: 'auto', overflowY: 'scroll' }}>
      {poUrl ? (
        <object
          id={indexDet}
          data={poUrl}
          type="application/pdf"
          width="100%"
          height="100%"
          style={{ display: 'block' }}
        >
          <div className="mx-2">
            <p className="dash-card-header my-3 mx-3">This browser does not support embedded PDFs. You can download the PDF
              <Button onClick={() => handleDownload(poUrl)} className="button-icon padding-zero ml mb-1">here</Button>.
            </p>
          </div>
        </object>
      ) : (
        <div className="mx-2">
          <p className="dash-card-header my-3 mx-3">No PDF available.</p>
        </div>
      )}
    </div>
  );
};

export default PdfViewer;
