import { UserStatusPutResponse, UserStatusPutResults } from './update-status.service.types';

export const UserStatusPutTransform: (response: UserStatusPutResponse) => UserStatusPutResults = (batchResults) => {
  const {
    data, is_error, message,
  } = batchResults;
  const result :UserStatusPutResults = {
    message: '',
    error: false,
    data: '',
  };
  if (is_error) {
    result.error = is_error;
    // result.errorMessage = 'Backend Issue';
    result.message = message;
  } else {
    result.error = is_error;
    result.data = data;
    result.message = 'executed';
  }
  return result;
};
