import {
  CardImg,
  Col, Row,
} from 'reactstrap';
import './home.scss';

const AboutPowerMart: React.FC = () => (
  <div className="mx-1">
    <div className="text-center-aligned">
      <span className="text-header">PowerMart - Your One-Stop Energy Solution</span>
    </div>
    <Row className="align-center pt-3">
      <Col xs="12" lg="9">
        <div className="pt-2 text-center-aligned">
          <span className="about-title">Your go-to for energy products, tracking, and customized deals—delivering a seamless, all-in-one experience.</span>
        </div>
      </Col>
      <Col xs="12" lg="6">
        <div className="pt-5">
          <CardImg src="/images/one-shop.jpeg" alt="one-stop" />
        </div>
      </Col>
      <Col xs="12" lg="6">
        <div className="pt-5 mx-3 align-text-justify">
          <span className="about-text">
            Comprehensive Energy Solutions offers the best deals on a wide range of energy
            products. Our diverse product range ensures you find exactly what you need,
            from solar panels to energy-efficient appliances. We pride ourselves on providing
            a hassle-free experience, guiding you through every step of your energy solution
            journey. Our customized products are tailored to meet your specific requirements,
            ensuring maximum efficiency and cost savings. Trust us to deliver top-notch quality
            and service, making your transition to greener energy smooth and seamless. Discover
            the best in energy solutions with our exceptional deals and personalized products
          </span>
        </div>
      </Col>
      <Col xs="12" lg="9">
        <div className="pt-5 text-center-aligned">
          <span className="mx-1">At PowerMart, we&apos;re committed to empowering you with the knowledge and tools needed to make smart energy choices. Experience the convenience, reliability, and efficiency of PowerMart today.</span>
        </div>
      </Col>
    </Row>
    <div className="pt-5" />
  </div>
);

export default AboutPowerMart;
