/* eslint-disable react/require-default-props */
import React, {
  useState, ReactNode, useRef, useEffect,
} from 'react';
import classNames from 'classnames';
import './popover.scss';
import { Button } from 'reactstrap';

interface PopoverProps {
  header: ReactNode;
  content: ReactNode;
  children: ReactNode;
  position?: 'top' | 'right' | 'bottom' | 'left';
  customClassName?: string;
  isClickClose: boolean;
  setIsClickClose: (isClickClose: boolean) => void;
}

const Popover: React.FC<PopoverProps> = ({
  header,
  content,
  children,
  position = 'top',
  customClassName,
  isClickClose = false,
  setIsClickClose,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const popoverRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if ((popoverRef.current && !popoverRef.current.contains(event.target as Node))) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mouseup', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (isClickClose) {
      setIsOpen(false);
      setIsClickClose(false);
    }
  }, [isClickClose]);

  return (
    <div className="popover-wrapper" ref={popoverRef}>
      <Button className="button-icon" onClick={() => setIsOpen(!isOpen)}>{children}</Button>
      {isOpen && (
        <div
          className={classNames(
            'popover',
            `popover-${position}`,
            customClassName,
          )}
        >
          <div className="popover-header align-center">{header}</div>
          <div className="popover-content">{content}</div>
        </div>
      )}
    </div>
  );
};

export default Popover;
