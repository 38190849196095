import { GetKartCountResponse, GetKartCountResults } from './get-kart-count.service.types';

export const GetKartCountTransform: (response: GetKartCountResponse) => GetKartCountResults = (getKartCountResults) => {
  const { data, is_error, message } = getKartCountResults;
  const result: GetKartCountResults = {
    data: {
      itmCnt: 0,
      mSts: '',
    },
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
