import React from 'react';
import './powerMart.scss';
import Navbar from '../../components/public/navbar/powerMart.navbar';
import { LoginSignupComponent } from '../../components/public/login/puser/login-signup.component';

export const LoginPowerMartComponent: React.FC = () => (
  <div>
    <Navbar />
    <LoginSignupComponent />
  </div>
);

export default LoginPowerMartComponent;
