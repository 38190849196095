import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Card, CardBody, Button, Input, InputGroup, InputGroupText, Offcanvas, OffcanvasHeader, OffcanvasBody,
  // Spinner,
  Modal,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import {
  faCirclePlus, faCube, faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit } from '@fortawesome/free-regular-svg-icons';
import { errorAlert, successAlert } from '../../../utils/alert';
import LoaderData from '../../../utils/loader';
import { ProdImgFileInput } from '../../../services/products/upload-prod-Image/create-prodimage.types';
import { APIHeader, ApiLIMIT, ListCPOptions } from '../../../utils/constants';
import {
  clearProdDescReq, clearPutProductReq, getSellerProductsListReq, prodDescReq, putProductReq,
} from '../../../store/powerMart/actions';
import { getUserProfileSessionStorage, getTokenFromSessionStorage } from '../../../utils/service/localstorage-service';
import { ProductDescResult } from '../../../services/products/prod-desc/product-desc.types';
import { ProductsResult } from '../../../services/products/list-products/get-all-product.types';
import { ProductsEditData } from '../../../services/products/update-products/update-product.types';
import ImageGallery from './add-products/thumbnail-image';
import { StatusComponentProps } from '../super-admin/dashboard/dash.type';

export const SellerPendingProductDetails: React.FC<StatusComponentProps> = ({ orderSts }) => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const tokenData = getTokenFromSessionStorage();
  const [loaderAddProduct, setLoaderAddProduct] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [prodDetailsOpen, setProdDetailsOpen] = React.useState<Record<string, boolean>>({});
  const [techSpec, setTechSpec] = React.useState('');
  const [prodDesc, setProdDesc] = React.useState('');
  const [loadingMore, setLoadingMore] = React.useState(false);
  const updateProductResponse = useSelector((state: RootState) => state.powerMart.updateProduct);
  const [openModalProd, setOpenModalProd] = React.useState<Record<string, boolean>>({});
  const [prodImageFile, setProdImageDataFile] = React.useState<ProdImgFileInput>({
    fileData: new FormData(),
  });
  const [productUpdateInput, setProductUpdateInput] = React.useState<ProductsEditData>({
    ProdTp: '',
    ProdId: '',
    nme: '',
    techSpec: [],
    prodDesc: [],
    wrnty: 0,
    cost: '',
    gst: '',
    keyW: '',
    modlN: '',
    tagIn: '',
    hsn: '',
    oem: '',
  });

  const [pendProductData, setPendProductData] = React.useState<ProductsResult>({
    tCnt: 0,
    fCnt: 0,
    prodData: [],
    lastEvalKey: {
      ProdTp: '',
      ProdId: '',
    },
  });
  // const [loaderProdDesc, setLoaderProdDesc] = React.useState(false);
  // const [loaderDescIndex, setLoaderDescIndex] = React.useState<number | null>(null);
  const listSellerProducts = useSelector((state: RootState) => state.powerMart.getSellerProductList);
  const prodDescRes = useSelector((state: RootState) => state.powerMart.prodDesc);
  const [prodDescTp, setProdDescTp] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  const [selectedProdIndex, setSelectedProdIndex] = React.useState<number | null>(null);
  const [productTechSpec, setProductTechSpec] = React.useState<ProductDescResult>({
    data: {
      prodDesc: [],
      techSpec: [],
    },
    isError: false,
    message: '',
  });
  const [productDesc, setProductDesc] = React.useState<ProductDescResult>({
    data: {
      prodDesc: [],
      techSpec: [],
    },
    isError: false,
    message: '',
  });

  const [showProdDesc, setShowProdDesc] = React.useState(Array(pendProductData.prodData.length).fill(false));

  const handleHideClick = (index: number) => {
    setShowProdDesc((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = false;
      return updatedState;
    });
    setSelectedProdIndex(null);
  };

  const prodToggleModal = (target: string, rowData: ProductsEditData, productDescription: string[], technicSpec: string[]) => {
    setOpenModalProd((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setProductUpdateInput({
      ...productUpdateInput,
      ProdTp: rowData.ProdTp ? rowData.ProdTp : '',
      ProdId: rowData.ProdId ? rowData.ProdId : '',
      nme: rowData.nme ? rowData.nme : '',
      techSpec: technicSpec,
      prodDesc: productDescription,
      wrnty: rowData.wrnty ? rowData.wrnty : 0,
      keyW: rowData.keyW ? rowData.keyW : '',
      modlN: rowData.modlN ? rowData.modlN : '',
      tagIn: rowData.tagIn ? rowData.tagIn : '',
      hsn: rowData.hsn ? rowData.hsn : '',
      cost: rowData.cost ? rowData.cost : '',
      gst: rowData.gst ? rowData.gst : '',
      oem: rowData.oem ? rowData.oem : '',
    });
  };

  // const handleShowAllClick = (prodTp: string, prodId: string, index: number) => {
  //   setLoaderDescIndex(index);
  //   setLoaderProdDesc(true);
  //   setProdDescTp('TECH_SPEC');
  //   dispatch(prodDescReq({
  //     requestType: APIHeader.REQ_GET_PRODUCT_DESC,
  //     inSts: 'TECH_SPEC',
  //     ProdTp: prodTp,
  //     ProdId: prodId,
  //   }));
  //   setSelectedProdIndex(index);
  // };

  const toggleProdDetails = (target: string, prodTp: string, prodId: string) => {
    setProdDescTp('PROD_DESC');
    setLoaderAddProduct(true);
    dispatch(prodDescReq({
      requestType: APIHeader.REQ_GET_PRODUCT_DESC,
      inSts: 'PROD_DESC',
      ProdTp: prodTp,
      ProdId: prodId,
    }));
    setProdDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  React.useEffect(() => {
    if (prodDescRes.isError && prodDescRes.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(prodDescRes.message);
      setErrorShowAlert(true);
    }
  }, [prodDescRes.isError, prodDescRes.message]);

  React.useEffect(() => {
    if (!prodDescRes.isError && prodDescRes.message === 'executed') {
      setLoaderAddProduct(false);
      if (prodDescTp === 'PROD_DESC') {
        setProductDesc(prodDescRes);
      } else if (prodDescTp === 'TECH_SPEC' && selectedProdIndex !== null) {
        setProductTechSpec(prodDescRes);
        setShowProdDesc((prevState) => {
          const updatedState = [...prevState];
          updatedState[selectedProdIndex] = true;
          return updatedState;
        });
      }
      dispatch(clearProdDescReq());
    }
  }, [prodDescRes.isError, prodDescRes.message, prodDescRes.data, selectedProdIndex, prodDescTp, clearProdDescReq]);

  const toggleDescProdDetails = (target: string) => {
    setProdDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  React.useEffect(() => {
    setProdImageDataFile(prodImageFile);
  }, [prodImageFile.fileData]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  const handleFieldUpdateChange = (e:any) => {
    const fieldName = e.target.name;
    setProductUpdateInput({ ...productUpdateInput, [fieldName]: e.target.value });
  };

  const handleTechSpecChange = (event: any) => {
    setTechSpec(event.target.value);
  };

  const handleUpdateTechSpec = () => {
    if (techSpec !== '') {
      if (techSpec !== '') {
        const updatedInitTechSpec = { ...productUpdateInput };
        updatedInitTechSpec.techSpec.push(techSpec);
        setProductUpdateInput(updatedInitTechSpec);
        setTechSpec('');
      }
    }
  };

  const handleUpdateRemoveTechSpec = (specIndx: number) => {
    const updatedInitProdSpec = { ...productUpdateInput };
    updatedInitProdSpec.techSpec.splice(specIndx, 1);
    setProductUpdateInput(updatedInitProdSpec);
  };

  const handleProdDescChange = (event: any) => {
    setProdDesc(event.target.value);
  };

  const handleUpdateProdDesc = () => {
    if (prodDesc !== '') {
      if (prodDesc !== '') {
        const updatedInitProdDesc = { ...productUpdateInput };
        updatedInitProdDesc.prodDesc.push(prodDesc);
        setProductUpdateInput(updatedInitProdDesc);
        setProdDesc('');
      }
    }
  };

  const handleUpdateRemoveProdDesc = (descIndx: number) => {
    const updatedInitProdSpec = { ...productUpdateInput };
    updatedInitProdSpec.prodDesc.splice(descIndx, 1);
    setProductUpdateInput(updatedInitProdSpec);
  };

  React.useEffect(() => {
    if (evalKeyText === '' && evalTpText === '' && userTp !== '' && orderSts === 'pend-product') {
      setLoaderAddProduct(true);
      dispatch(getSellerProductsListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_SELLER,
        isAccsProdF: 'YES',
        limit: ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
      }));
    }
  }, [evalKeyText, evalTpText, userTp, orderSts]);

  const fetchMoreData = () => {
    setLoaderAddProduct(true);
    dispatch(
      getSellerProductsListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_SELLER,
        isAccsProdF: 'YES',
        limit: ApiLIMIT.LIMIT,
        evalKey: evalKeyText,
        evalTp: evalTpText,
        isFilter: 'NO',
        isSearch: 'NO',
        token: tokenData,
      }),
    );
  };

  React.useEffect(() => {
    if (listSellerProducts.isError && listSellerProducts.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(listSellerProducts.message);
      setErrorShowAlert(true);
    }
  }, [listSellerProducts.isError, listSellerProducts.message]);

  React.useEffect(() => {
    if (!listSellerProducts.isError && listSellerProducts.message === 'executed') {
      setLoaderAddProduct(false);
      if (listSellerProducts.data.lastEvalKey !== null) {
        const newData = listSellerProducts.data.prodData.filter((item) => !pendProductData.prodData.some((existingItem) => existingItem.ProdId === item.ProdId));
        setPendProductData((prevData) => ({
          ...prevData,
          tCnt: listSellerProducts.data.tCnt,
          fCnt: listSellerProducts.data.fCnt,
          prodData: [...prevData.prodData, ...newData],
          lastEvalKey: listSellerProducts.data.lastEvalKey,
        }));
        setEvalKeyText(listSellerProducts.data.lastEvalKey.ProdId);
        setEvalTpText(listSellerProducts.data.lastEvalKey.ProdTp);
        setLoadingMore(true);
      } else {
        setPendProductData((prevData) => ({
          ...prevData,
          tCnt: listSellerProducts.data.tCnt,
          fCnt: listSellerProducts.data.fCnt,
          prodData: [...prevData.prodData, ...listSellerProducts.data.prodData],
        }));
        setLoadingMore(false);
      }
    }
  }, [listSellerProducts.isError, listSellerProducts.message, listSellerProducts.data.prodData, listSellerProducts.data.lastEvalKey, evalKeyText]);

  const handleUpdateProductSubmit = () => {
    setLoaderAddProduct(true);
    Promise.resolve(
      dispatch(putProductReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_PRODUCTS,
        inputBody: productUpdateInput,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (updateProductResponse.error && updateProductResponse.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(updateProductResponse.message);
      setErrorShowAlert(true);
    }
  }, [updateProductResponse.error, updateProductResponse.message]);

  React.useEffect(() => {
    if (!updateProductResponse.error && updateProductResponse.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(updateProductResponse.message);
      dispatch(clearPutProductReq());
      setSuccessShowAlert(true);
      if (userTp !== '') {
        setPendProductData((prevData) => ({
          ...prevData,
          prodData: [],
          lastEvalKey: {
            ProdId: '',
            ProdTp: '',
          },
        }));
        dispatch(getSellerProductsListReq({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_PRODUCTS_SELLER,
          isAccsProdF: 'YES',
          limit: ApiLIMIT.LIMIT,
          evalKey: '',
          evalTp: '',
          isFilter: 'NO',
          isSearch: 'NO',
          token: tokenData,
        }));
      }
    }
  }, [updateProductResponse.error, updateProductResponse.message]);

  return (
    <div>
      { loaderAddProduct ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Row className="mx-1 pt-3">
        <Col lg="4" xs="6">
          <div className="align-start">
            <span className="card-toggle-text">
              Pending Products
            </span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col lg="11">
          <div className="align-end my-2">
            <span className="text-lite-grey mx-2">Count: {pendProductData && pendProductData.prodData.length} / {pendProductData.tCnt}</span>
          </div>
          <InfiniteScroll
            dataLength={pendProductData.prodData.length}
            next={fetchMoreData}
            hasMore={loadingMore}
            loader={loaderAddProduct}
          >
            <Row className="mx-1">
              {pendProductData && pendProductData.prodData.length > 0
                ? pendProductData.prodData.map((prodDet, index) => (
                  <Col lg={{ size: 12, order: 1 }} xs={{ order: 1 }} key={`pending-${index.toString()}`}>
                    <Card className="card-borderless my-2">
                      <CardBody>
                        <Row>
                          <Col lg="4" xs="12">
                            <div className="align-center">
                              <span className="text-channel-partner">
                                { ListCPOptions.find((cp) => cp.value === prodDet.oem)?.label || ''}
                              </span>
                            </div>
                            <ImageGallery imageGal={prodDet.prodUrl} isDesc={false} />
                            {/* <div className="my-2 mx-2">ImageGallery
                            <CardImg alt="logo" className="product-image" src={prodDet.prodUrl} />
                          </div> */}
                          </Col>
                          <Col lg="5" xs="12">
                            <div className="my-2 meta-margin-mobile">
                              <div className="align-start ml">
                                <span className="product-name-header">{prodDet.nme}</span>
                              </div>
                              <div className="my-2 meta-margin-mobile">
                                <div className="pt-2" key={`prodDet-${index.toString()}`}>
                                  {showProdDesc[index] && (
                                  <div>
                                    {productTechSpec.data.techSpec.map((prodSpecData, pSepcIndx) => (
                                      <div key={`pSpec-${pSepcIndx.toString()}`} className="my-2 align-start">
                                        <div className="mx-2">
                                          <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                        </div>
                                        <div>
                                          <span className="prod-tech-spec-text">{prodSpecData}</span>
                                        </div>
                                      </div>
                                    ))}
                                    <div className="align-end">
                                      <Button className="powermart-button-link" onClick={() => handleHideClick(index)}>
                                        hide...
                                      </Button>
                                    </div>
                                  </div>
                                  )}
                                  {!showProdDesc[index] && (
                                  <div>
                                    {prodDet.techSpec.map((techSpecData, tSepcIndx) => (
                                      <div key={`tSpec-${tSepcIndx.toString()}`} className="my-2 align-start">
                                        <div className="mx-2">
                                          <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                        </div>
                                        <div>
                                          <span className="prod-tech-spec-text">{techSpecData}</span>
                                        </div>
                                      </div>
                                    ))}
                                    {/* <div className="align-end">
                                      <Button className="powermart-button-link" onClick={() => handleShowAllClick(prodDet.ProdTp, prodDet.ProdId, index)}>
                                        show all {loaderProdDesc && index === loaderDescIndex ? <><Spinner type="grow" className="spin-grow-loader" /><Spinner type="grow" className="spin-grow-loader" /><Spinner type="grow" className="spin-grow-loader" /></> : null}
                                      </Button>
                                    </div> */}
                                  </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col lg="3" xs="12">
                            <div className="my-2 meta-margin-mobile">
                              <div className="pt-1">
                                <div className="align-center">
                                  <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                                </div>
                                <div className="align-center">
                                  <span className="meta-price-lite">Cost: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.costVal))}</span>
                                </div>
                                <div className="align-center">
                                  <span className="meta-price-lite">GST: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.gstVal))}</span>
                                </div>
                              </div>
                              <div className="pt-2">
                                <div className="align-center">
                                  <span className="meta-waranty-lite">warranty {prodDet.wrnty} months</span>
                                </div>
                              </div>
                              <div className="pt-5 align-center mx-2">
                                <Button className="powermart-button-fullwidth-red mx-1" onClick={() => toggleProdDetails(`prodDetails-${index}`, prodDet.ProdTp, prodDet.ProdId)}>PRODUCT DETAILS</Button>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Offcanvas
                      direction="end"
                      isOpen={prodDetailsOpen[`prodDetails-${index}`]}
                      toggle={() => toggleDescProdDetails(`prodDetails-${index}`)}
                    >
                      <OffcanvasHeader toggle={() => toggleDescProdDetails(`prodDetails-${index}`)} className="margin-0 mx-1" />
                      <div className="align-start border-bottom-box">
                        <div>
                          <span className="product-name-details-header">{prodDet.nme}
                            <Button className="button-icon padding-zero ml-1 mb-2" onClick={() => prodToggleModal(`prodDet-${index}`, prodDet, productDesc.data.prodDesc, productDesc.data.techSpec)}>
                              <FontAwesomeIcon icon={faEdit} className="wrong-pass-key" />
                            </Button>
                          </span>
                        </div>
                        <Modal
                          isOpen={openModalProd[`prodDet-${index}`] || false}
                          target={`prodDet-${index}`}
                          size="lg"
                        >
                          <ModalHeader toggle={() => prodToggleModal(`prodDet-${index}`, prodDet, productDesc.data.prodDesc, productDesc.data.techSpec)} className="form-card-border-none mx-3 my-2">
                            <span className="text-lite-grey">{prodDet.nme}</span>
                          </ModalHeader>
                          <ModalBody className="padding-zero mx-2">
                            <Row className="mx-1">
                              <Col lg="8" className="pt-1">
                                <h6 className="text-lite-grey mx-2 my-1">Product Name<span className="mandate-star">*</span></h6>
                                <Input
                                  placeholder="Enter product name"
                                  type="text"
                                  required
                                  name="nme"
                                  value={productUpdateInput.nme}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="4" className="pt-1">
                                <h6 className="text-lite-grey mx-2 my-1">HSN Code<span className="mandate-star">*</span></h6>
                                <Input
                                  placeholder="Enter HSN Code"
                                  type="text"
                                  required
                                  name="hsn"
                                  value={productUpdateInput.hsn}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="5" xs="5" className="pt-3">
                                <h6 className="text-lite-grey mx-2 my-1">Warranty(months)<span className="mandate-star">*</span></h6>
                                <Input
                                  placeholder="Warranty in month"
                                  type="number"
                                  required
                                  name="wrnty"
                                  disabled
                                  value={productUpdateInput.wrnty}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="4" xs="5" className="pt-3">
                                <h6 className="text-lite-grey mx-2 my-1">Cost<span className="mandate-star">*</span></h6>
                                <Input
                                  type="number"
                                  required
                                  name="cost"
                                  disabled
                                  value={productUpdateInput.cost}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="3" xs="3" className="pt-3">
                                <h6 className="text-lite-grey mx-2 my-1">GST<span className="mandate-star">*</span></h6>
                                <Input
                                  type="number"
                                  required
                                  name="gst"
                                  disabled
                                  value={productUpdateInput.gst}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="12" className="pt-3">
                                <h6 className="text-lite-grey mx-2 my-1">Model<span className="mandate-star">*</span></h6>
                                <Input
                                  placeholder="Enter Model"
                                  type="text"
                                  required
                                  name="modlN"
                                  value={productUpdateInput.modlN}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col lg="12" className="pt-3">
                                <h6 className="text-lite-grey mx-2 my-1">Keyword<span className="mandate-star">*</span></h6>
                                <Input
                                  placeholder="Enter Keyword"
                                  type="text"
                                  required
                                  name="keyW"
                                  value={productUpdateInput.keyW}
                                  onChange={handleFieldUpdateChange}
                                  className="powermart-input"
                                />
                              </Col>
                              <Col xs="12">
                                <div className="text-right-login-campus-register mx-1 pt-4">
                                  <h6 className="text-lite-grey mx-2 my-1">Tech Specifications</h6>
                                  <InputGroup>
                                    <Input
                                      type="text"
                                      placeholder="Provide Tech Specifications"
                                      className="powermart-input"
                                      value={techSpec}
                                      onChange={(e) => handleTechSpecChange(e)}
                                    />
                                    <InputGroupText className="powermart-input mx-1 cursor-pointer" onClick={() => handleUpdateTechSpec()}>
                                      <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                                    </InputGroupText>
                                  </InputGroup>
                                  {productUpdateInput.techSpec.length > 0
                                    ? (
                                      <Card className="my-3 meta-card">
                                        <span className="my-1 text-lite-grey ml-1">Tech Specification Details</span>
                                        {productUpdateInput.techSpec.map((techSpecData, techSpecIndx) => (
                                          <div key={`techSpec-${techSpecIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                            <Row className="mx-1 pt-2">
                                              <Col lg="11" xs="10">
                                                <div className="pt-1">
                                                  <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                                  <span className="ml-1">{ techSpecData }</span>
                                                </div>
                                              </Col>
                                              <Col lg="1" xs="2">
                                                <div className="align-end">
                                                  <Button className="button-icon" onClick={() => handleUpdateRemoveTechSpec(techSpecIndx)}>
                                                    <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        ))}
                                      </Card>
                                    ) : null }
                                </div>
                              </Col>
                              <Col xs="12">
                                <div className="text-right-login-campus-register mx-1 pt-4">
                                  <h6 className="text-lite-grey mx-2 my-1">Product Description</h6>
                                  <InputGroup>
                                    <Input
                                      type="textarea"
                                      placeholder="Provide Product Description"
                                      className="powermart-input-desc"
                                      value={prodDesc}
                                      onChange={(e) => handleProdDescChange(e)}
                                    />
                                    <InputGroupText className="powermart-input mx-1 cursor-pointer my-4" onClick={() => handleUpdateProdDesc()}>
                                      <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                                    </InputGroupText>
                                  </InputGroup>
                                  {productUpdateInput.prodDesc.length > 0
                                    ? (
                                      <Card className="my-3 meta-card">
                                        <span className="my-1 text-lite-grey ml-1">Product Description Details</span>
                                        {productUpdateInput.prodDesc.map((descData, descIndx) => (
                                          <div key={`prodDesc-${descIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                            <Row className="mx-1 pt-2">
                                              <Col lg="11" xs="10">
                                                <div className="pt-1">
                                                  <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                                  <span className="ml-1">{ descData }</span>
                                                </div>
                                              </Col>
                                              <Col lg="1" xs="2">
                                                <div className="align-end">
                                                  <Button className="button-icon" onClick={() => handleUpdateRemoveProdDesc(descIndx)}>
                                                    <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                                  </Button>
                                                </div>
                                              </Col>
                                            </Row>
                                          </div>
                                        ))}
                                      </Card>
                                    ) : null }
                                </div>
                              </Col>
                              <div className="my-2 mx-2">
                                <span className="cat-check-text">Note: Certain fields are disabled and can only be modified by the Admin. Please reach out to the admin if any changes are needed for this product</span>
                              </div>
                            </Row>
                            <div className="align-center pt-4 mx-2">
                              <Button
                                className="powermart-button-fullwidth mx-1"
                                onClick={handleUpdateProductSubmit}
                              >
                                UPDATE
                              </Button>
                            </div>
                            <div className="pt-4" />
                          </ModalBody>
                        </Modal>
                      </div>
                      <OffcanvasBody>
                        <Row>
                          <Col xs="12" lg="11">
                            <div className="pt-2">
                              <div className="align-center">
                                <span className="meta-waranty-lite">Warranty {prodDet.wrnty} months</span>
                              </div>
                            </div>
                            <div className="pt-2">
                              <ImageGallery imageGal={prodDet.prodUrl} isDesc />
                            </div>
                            {/* <div className="align-center">
                            <CardImg alt="logo" className="product-image" src={prodDet.prodUrl} />
                          </div> */}
                          </Col>
                          <Col xs="12">
                            <div className="my-2 meta-margin-mobile">
                              <div className="pt-4">
                                <div className="align-center">
                                  <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                                </div>
                                <div className="align-center meta-price-lite">
                                  ( <span className="meta-price-lite">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.costVal))}</span>
                                  <span className="mx-2">+</span>
                                  <span className="meta-price-lite">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.gstVal))}</span>
                                  )
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xs="12">
                            <div className="my-2 meta-margin-mobile">
                              <div className="align-start pt-3">
                                <span className="product-name-header">Model</span>
                              </div>
                              <div className="my-1 align-start pt-2">
                                <span className="prod-tech-spec-text">{prodDet.modlN}</span>
                              </div>
                            </div>
                          </Col>
                          <Col xs="12">
                            <div className="my-2 meta-margin-mobile">
                              <div className="align-start pt-3">
                                <span className="product-name-header">Technical Specification</span>
                              </div>
                              <div className="pt-2">
                                {productDesc.data.techSpec.map((techSpecData, tSepcIndx) => (
                                  <div key={`prodDesc-${tSepcIndx.toString()}`} className="my-1 align-start">
                                    <div className="mr">
                                      <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                    </div>
                                    <div>
                                      <span className="prod-tech-spec-text">{techSpecData}</span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                          <Col xs="12">
                            <div className="my-2 meta-margin-mobile">
                              <div className="align-start pt-3">
                                <span className="product-name-header">Product Description</span>
                              </div>
                              <div className="pt-2">
                                {productDesc.data.prodDesc.map((descData, descIndx) => (
                                  <div key={`prodDesc-${descIndx.toString()}`} className="my-3 align-start">
                                    <div className="mr">
                                      <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                    </div>
                                    <div>
                                      <span className="prod-tech-spec-text">{ descData }</span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </OffcanvasBody>
                    </Offcanvas>
                  </Col>
                )) : <div className="pt-4 align-center"><span className="form-collapse-card-header">No Product Found</span></div>}
            </Row>
          </InfiniteScroll>
        </Col>
      </Row>
    </div>
  );
};
export default SellerPendingProductDetails;
