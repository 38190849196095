import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { faBan } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Col, Nav, NavItem, NavLink, Row, TabContent, TabPane,
} from 'reactstrap';
import './user.scss';
import { faCircleCheck, faHourglass } from '@fortawesome/free-regular-svg-icons';
import { APIHeader } from '../../../../utils/constants';
import LoaderData from '../../../../utils/loader';
import { errorAlert } from '../../../../utils/alert';
import { AllUserSegments } from './user-status';
import { UserData } from '../../../../services/all-users/user-list/list-user.types';
import { getUserListReq } from '../../../../store/powerMart/actions';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import Tooltip from '../../../../utils/Tooltip/Tooltip';

export const UserStatusComponent: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState('pending');
  const [loaderUser, setLoaderUser] = React.useState(false);
  const listUser = useSelector((state: RootState) => state.powerMart.getUserList);
  const [userPendingData, setPendingUserData] = React.useState<UserData>({
    tCnt: 0,
    fCnt: -1,
    message: '',
    userData: [],
  });
  const [userApprovedData, setApprovedUserData] = React.useState<UserData>({
    tCnt: 0,
    fCnt: -1,
    message: '',
    userData: [],
  });
  const [userRevokedData, setRevokedUserData] = React.useState<UserData>({
    tCnt: 0,
    fCnt: -1,
    message: '',
    userData: [],
  });

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  const handlePending = () => {
    setLoaderUser(true);
    setActiveTab('pending');
    dispatch(getUserListReq({
      userType: userTp,
      requestType: APIHeader.REQ_ALL_USER_BY_STATUS,
      status: 'PADM',
      // limit: ApiLIMIT.LIMIT,
      token: tokenData,
      // searchData: '',
    }));
  };
  const handleApproved = (e: any) => {
    e.preventDefault();
    setLoaderUser(true);
    setActiveTab('approved');
    dispatch(getUserListReq({
      userType: userTp,
      requestType: APIHeader.REQ_ALL_USER_BY_STATUS,
      status: 'APPR',
      // limit: ApiLIMIT.LIMIT,
      token: tokenData,
      // searchData: '',
    }));
  };

  const handleRejected = () => {
    setLoaderUser(true);
    setActiveTab('revoked');
    dispatch(getUserListReq({
      userType: userTp,
      requestType: APIHeader.REQ_ALL_USER_BY_STATUS,
      status: 'NACC',
      // limit: ApiLIMIT.LIMIT,
      token: tokenData,
      // searchData: '',
    }));
  };

  React.useEffect(() => {
    setLoaderUser(true);
    if (activeTab === 'pending' && userTp !== '') {
      dispatch(getUserListReq({
        userType: userTp,
        requestType: APIHeader.REQ_ALL_USER_BY_STATUS,
        status: 'PADM',
        // limit: ApiLIMIT.LIMIT,
        token: tokenData,
        // searchData: '',
      }));
    }
  }, [userTp]);

  React.useEffect(() => {
    if (listUser.isError && listUser.message !== '') {
      setLoaderUser(false);
      setAlertMsg(listUser.message);
      setErrorShowAlert(true);
    }
  }, [listUser.isError, listUser.message]);

  React.useEffect(() => {
    if (!listUser.isError && listUser.message === 'success') {
      setLoaderUser(false);
      if (activeTab === 'pending') {
        setPendingUserData(listUser);
      } else if (activeTab === 'approved') {
        setApprovedUserData(listUser);
      } else if (activeTab === 'revoked') {
        setRevokedUserData(listUser);
      }
      // dispatch(clearUserListReq());
    }
  }, [listUser.isError, listUser.message, listUser.userData]);

  return (
    <div className="label-margin-to-nav">
      { loaderUser ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="mb-1 pt-5">
        <span className="powermart-main-label">
          Quick View - User Status
        </span>
      </div>
      <Row>
        <Col xs="12" className="pt-3">
          <Nav tabs className="user-sadmin-profile-tab">
            <div className="user-sadmin-row-profile-tab">
              <div className="user-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer">
                  <NavLink className="user-sadmin-profile-nav-tabs" active={activeTab === 'pending'} onClick={handlePending}>
                    <FontAwesomeIcon icon={faHourglass} className="user-sadmin-tab-profile-icon" />
                    <span>Pending</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="user-sadmin-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="user-sadmin-profile-nav-tabs" active={activeTab === 'revoked'} onClick={handleRejected}>
                    <FontAwesomeIcon icon={faBan} className="user-sadmin-tab-profile-icon" />
                    <span className="user-sadmin-tab-text">Revoked</span>
                  </NavLink>
                </NavItem>
              </div>
              <div className="user-sadmin-column-profile-tab-right">
                <NavItem className="cursor-pointer">
                  <NavLink className="user-sadmin-profile-nav-tabs" active={activeTab === 'approved'} onClick={(e) => handleApproved(e)}>
                    <FontAwesomeIcon icon={faCircleCheck} className="user-sadmin-tab-profile-icon" />
                    <span className="user-sadmin-tab-text">Approved</span>
                  </NavLink>
                </NavItem>
              </div>
            </div>
            <div className="user-sadmin-row-profile-tab-mobile">
              <div className="user-sadmin-column-profile-tab-left">
                <NavItem className="cursor-pointer user-sadmin-profile-tab">
                  <Tooltip
                    content="Pending"
                    position="bottom"
                    customClassName="pendingId"
                  >
                    <NavLink id="pendingId" className="user-sadmin-profile-nav-tabs" active={activeTab === 'pending'} onClick={handlePending}>
                      <FontAwesomeIcon icon={faHourglass} className="user-sadmin-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
              <div className="user-sadmin-column-profile-tab-right">
                <NavItem className="profile-pointer-user-sadmin user-sadmin-profile-tab">
                  <Tooltip
                    content="Revoked"
                    position="bottom"
                    customClassName="revokedId"
                  >
                    <NavLink id="revokedId" className="user-sadmin-profile-nav-tabs" active={activeTab === 'revoked'} onClick={handleRejected}>
                      <FontAwesomeIcon icon={faBan} className="user-sadmin-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
              <div className="user-sadmin-column-profile-tab-right">
                <NavItem className="profile-pointer-user-sadmin user-sadmin-profile-tab">
                  <Tooltip
                    content="Approved"
                    position="bottom"
                    customClassName="completedId"
                  >
                    <NavLink id="completedId" className="user-sadmin-profile-nav-tabs" active={activeTab === 'approved'} onClick={handleApproved}>
                      <FontAwesomeIcon icon={faCircleCheck} className="user-sadmin-tab-profile-icon" />
                    </NavLink>
                  </Tooltip>
                </NavItem>
              </div>
            </div>
          </Nav>
        </Col>
        <Col xs="12">
          <TabContent activeTab={activeTab}>
            {activeTab === 'pending' && (
            <TabPane tabId="pending">
              <AllUserSegments userData={userPendingData} userTp={userTp} tabId="pending" />
            </TabPane>
            )}
            {activeTab === 'approved' && (
            <TabPane tabId="approved">
              <AllUserSegments userData={userApprovedData} userTp={userTp} tabId="approved" />
            </TabPane>
            )}
            {activeTab === 'revoked' && (
            <TabPane tabId="revoked">
              <AllUserSegments userData={userRevokedData} userTp={userTp} tabId="revoked" />
            </TabPane>
            )}
          </TabContent>
        </Col>
      </Row>
    </div>
  );
};

export default UserStatusComponent;
