import { useNavigate } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import {
  CardBody, Card, Input, Button, InputGroup, InputGroupText, NavLink, Form, Col, Row, CardImg,
} from 'reactstrap';
import { useLocation } from 'react-router-dom';
import '../login.scss';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons';
import React, { useState } from 'react';
import {
  getLoginToken, getForgotPassword, getProfile, clearLoginTokenReq,
} from '../../../../store/powerMart/actions';
import LoaderData from '../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../utils/alert';
import RegisterUser from './seller-sign-up';
import { APIHeader } from '../../../../utils/constants';
import { setTokenSessionStorage, setUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';

export const SellerLogin: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const location = useLocation();
  const path = location.pathname.split('/');
  const { hash } = window.location;
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isLoginButtonDisabled, setIsLoginButtonDisabled] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);
  // const [isForgotPassword, setIsForgotPassword] = useState(false);
  // const [isSignup, setIsSignup] = useState(false);
  const [powerUrl, setPowerUrl] = useState('');
  // const [loginUrl, setLoginUrl] = useState('');
  // const [forgotPwdUrl, setForgotPwdUrl] = useState('');
  // const [isPassword, setIsPassword] = React.useState(true);
  const [isLoader, setIsLoader] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [userType, setUserType] = useState('');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [loginPassKey, setLoginPassKey] = useState('');
  const [loginUserName, setLoginUserName] = useState('');
  const [forgotUserName, setForgotUserName] = useState('');
  const loginTokenData = useSelector((state: RootState) => state.powerMart.genToken);
  const loginProfileData = useSelector((state: RootState) => state.powerMart.profileData);
  const forgotPasswordData = useSelector((state: RootState) => state.powerMart.forgotPassKeyData);

  React.useEffect(() => {
    if (path[2] === 'seller') {
      setUserType('SELLER');
    }
  }, [path[2]]);

  React.useEffect(() => {
    if (hash) {
      setPowerUrl(hash.slice(1));
    }
  }, [hash.slice(1), powerUrl]);

  const handleForgotPassword = () => {
    // setIsForgotPassword(true);
    // setIsPassword(false);
    // setIsSignup(false);
    history('/seller-login/seller#forgotPwd');
  };
  const handleSwitchToPassword = () => {
    // setIsForgotPassword(false);
    // setIsPassword(true);
    // setIsSignup(false);
    history('/seller-login/seller#logn');
  };
  const handleSwitchToSignup = () => {
    // setIsForgotPassword(false);
    // setIsPassword(false);
    // setIsSignup(true);
    history('/seller-login/seller#signUp');
  };
  const handlePassKeyChange = (e: any) => {
    setLoginPassKey(e.target.value);
  };
  const handleUserNameChange = (e: any) => {
    setLoginUserName(e.target.value);
    const emailPattern = /\b[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}\b/;
    const numberPattern = /^\d{10}$/;
    const isValidEmail = emailPattern.test(e.target.value);
    const isValidNumber = numberPattern.test(e.target.value);

    // Enable the button only if both patterns are satisfied
    setIsLoginButtonDisabled(!(isValidEmail || isValidNumber));
  };
  const handleClickShowPassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleForgotUserNameChange = (e: any) => {
    setForgotUserName(e.target.value);
    const emailPattern = /\b[A-Za-z0-9._%+-]+@(?:[A-Za-z0-9-]+\.)+[A-Za-z]{2,}\b/;
    const numberPattern = /^\d{10}$/;
    const isValidEmail = emailPattern.test(e.target.value);
    const isValidNumber = numberPattern.test(e.target.value);

    // Enable the button only if both patterns are satisfied
    setIsButtonDisabled(!(isValidEmail || isValidNumber));
  };

  const handlePasswordSubmit = async (e:any) => {
    e.preventDefault();
    if (loginUserName !== '' && loginPassKey !== '') {
      setIsLoader(true);
      dispatch(getLoginToken({
        userName: loginUserName,
        passKey: loginPassKey,
        isPublic: false,
        userType,
        requestType: APIHeader.USER_LOGIN,
      }));
    }
  };
  React.useEffect(() => {
    if (loginTokenData.isError && loginTokenData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginTokenData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginTokenData.message, loginTokenData.isError]);

  const handleForgotPasskeySubmit = (e:any) => {
    e.preventDefault();
    setIsLoader(!isLoader);
    dispatch(getForgotPassword({
      userName: forgotUserName,
      userType,
      requestType: APIHeader.REQ_PUB_FORGOT_PASSWORD,
    }));
  };

  React.useEffect(() => {
    if (!loginTokenData.isError && loginTokenData.message === 'executed' && loginTokenData.token !== '') {
      setIsLoader(false);
      setTokenSessionStorage(loginTokenData.token);
      dispatch(getProfile({
        userReqFor: userType,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: loginTokenData.token,
      }));
    }
  }, [loginTokenData.token, loginTokenData.isError]);

  React.useEffect(() => {
    if (loginProfileData.isError && loginProfileData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(loginProfileData.message);
      setLoginUserName('');
      setLoginPassKey('');
    }
  }, [loginProfileData.message, loginProfileData.isError]);

  React.useEffect(() => {
    if (!loginProfileData.isError && loginProfileData.message !== '' && loginProfileData.profileData.UserTp === userType) {
      setIsLoader(false);
      setUserProfileSessionStorage(JSON.stringify(loginProfileData));
      dispatch(clearLoginTokenReq());
      if (loginProfileData.profileData.UserTp === 'SELLER') {
        history('/seller/dashboard');
      }
    }
  }, [loginProfileData.message, loginTokenData.isError]);

  React.useEffect(() => {
    if (forgotPasswordData.isError && forgotPasswordData.message !== '') {
      setIsLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(forgotPasswordData.message);
    }
  }, [forgotPasswordData.message, forgotPasswordData.isError]);

  React.useEffect(() => {
    if (!forgotPasswordData.isError && forgotPasswordData.forgotPasswordData !== '') {
      setIsLoader(false);
      setSuccessShowAlert(true);
      setAlertMsg(forgotPasswordData.forgotPasswordData);
    }
  }, [forgotPasswordData.isError, forgotPasswordData.forgotPasswordData]);

  return (
    <div className="margin-login">
      { isLoader ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(true, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row className="mx-1 pt-1">
        <Col lg={{ size: 6, order: 1 }} xs={{ order: 2 }}>
          <div className="align-center margin-login-column">
            <CardImg
              className="process-image"
              src="/images/login-seller.jpg"
            />
          </div>
          {/* <div className="text-center-aligned">
            <span className="login-tagline-text">Empowering Efficiency: Your One-Stop Supply Platform for Reliable Power Equipment Solutions</span>
          </div> */}
        </Col>
        <Col lg={{ size: 5, order: 2 }} xs={{ order: 1 }}>
          <div className="pt-2 margin-login-card">
            <Card className="card-lite">
              <CardBody>
                <div className="align-center pt-1 mx-3">
                  <span className="login-header">
                    {powerUrl === 'forgotPwd' ? 'Reset Password' : powerUrl === 'signUp' ? 'Sign up' : powerUrl === 'logn' ? 'Seller Login' : null}
                  </span>
                </div>
                {powerUrl === 'logn'
                  ? (
                    <div className="pt-5 mx-2">
                      <Form onSubmit={handlePasswordSubmit}>
                        <div className="mx-1">
                          <h6 className="text-lite-grey mx-2 my-1">Username<span className="mandate-star">*</span></h6>
                          <Input
                            placeholder="Email Id / Registered Phone"
                            type="text"
                            required
                            className="powermart-input mx-1"
                            value={loginUserName}
                            onChange={handleUserNameChange}
                          />
                        </div>
                        <div className="mx-1 pt-4">
                          <h6 className="text-lite-grey mx-2 my-1">Password<span className="mandate-star">*</span></h6>
                          <InputGroup>
                            <Input
                              placeholder="Password"
                              type={passwordShown ? 'text' : 'password'}
                              required
                              value={loginPassKey}
                              onChange={handlePassKeyChange}
                              className="powermart-input mx-1"
                            />
                            <InputGroupText className="powermart-input cursor-pointer" onClick={handleClickShowPassword}>
                              {passwordShown
                                ? (<FontAwesomeIcon icon={faEye} className="powermart-dark-icon" />)
                                : (<FontAwesomeIcon icon={faEyeSlash} className="powermart-lite-icon" />
                                )}
                            </InputGroupText>
                          </InputGroup>
                        </div>
                        <div className="align-end pt-1">
                          <NavLink className="text-link cursor-pointer mx-1" to="#" onClick={handleForgotPassword}>forgot password ?</NavLink>
                        </div>
                        <div className="align-center pt-4 mx-2">
                          <Button className="powermart-button-fullwidth mx-1" disabled={isLoginButtonDisabled || loginPassKey === ''}>
                            LOGIN
                          </Button>
                        </div>
                        <div className="align-center my-2">
                          <NavLink className="text-link cursor-pointer mx-1" to="/seller-login/seller#signUp" onClick={handleSwitchToSignup}>Signup</NavLink>
                        </div>
                      </Form>
                    </div>
                  ) : null}
                {powerUrl === 'forgotPwd'
                  ? (
                    <div className="pt-5 mr">
                      <Form onSubmit={handleForgotPasskeySubmit}>
                        <div className="mx-1">
                          <h6 className="text-lite-grey mx-2 my-1">Username<span className="mandate-star">*</span></h6>
                          <Input
                            placeholder="Email Id / Phone"
                            type="text"
                            required
                            value={forgotUserName}
                            onChange={handleForgotUserNameChange}
                            className="powermart-input mx-1"
                          />
                        </div>
                        <div className="align-end pt-2 mx-2">
                          <NavLink className="text-link cursor-pointer mx-1" to="#" onClick={handleSwitchToPassword}> Back to Login ?</NavLink>
                        </div>
                        <div className="align-center my-3">
                          <Button className="powermart-button-solid mx-1" disabled={isButtonDisabled}>
                            SUBMIT
                          </Button>
                        </div>
                      </Form>
                    </div>
                  ) : null}
                {powerUrl === 'signUp'
                  ? (
                    <div className="pt-4">
                      <RegisterUser />
                      <div className="align-center my-2">
                        <NavLink className="text-link cursor-pointer" to="#" onClick={handleSwitchToPassword}>Back to Login ?</NavLink>
                      </div>
                    </div>
                  ) : null}
              </CardBody>
            </Card>
          </div>
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default SellerLogin;
