import { DeleteProdImgResponse, DeleteProdImgResults } from './delete-prodimage.service.types';

export const DeleteProdImgTransform: (response: DeleteProdImgResponse) => DeleteProdImgResults = (createProdImgDBResults) => {
  const { data } = createProdImgDBResults;
  const result: DeleteProdImgResults = {
    fileName: '',
  };
  result.fileName = data;
  return result;
};
