import { GetAWSFileUrlResponse, GetAWSFileUrlResults } from './get-aws-file.service.types';

export const GetAWSFileUrlTransform: (response: GetAWSFileUrlResponse) => GetAWSFileUrlResults = (createProdImgDBResults) => {
  const { data } = createProdImgDBResults;
  const result: GetAWSFileUrlResults = {
    fileUrl: '',
  };
  result.fileUrl = data;
  return result;
};
