import React from 'react';
import '../dashboard.scss';
import {
  Row, Col, CardImg,
  Button,
  Input,
  InputGroup,
  InputGroupText,
  Card,
} from 'reactstrap';
import Select from 'react-select';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCirclePlus,
  faCube,
  faEnvelope, faGear, faLocation, faPhone,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { faEdit, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { getConfig } from '../../../../../utils/config/config';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../../utils/service/localstorage-service';
import { PartsCountProps } from '../dash.type';
import {
  APIHeader, DiscomDistributionOptions, DiscomTransmissionOptions, ListCPOptions,
} from '../../../../../utils/constants';
import { LoaderData } from '../../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../../utils/alert';
import { OrdersResult } from '../../../../../services/products/power-orders/get-all-orders.types';
import {
  clearCreateProductReq,
  clearDeleteProdImg,
  clearGetListProdImg,
  clearOrdersListReq, clearProdDescReq, clearPutPendOrdrAdmReq, clearPutProductReq, clearUploadProdImg, deleteProdImg, getCorpListReq, getListProdImg, getOrdersListReq,
  prodDescReq,
  putProductReq,
  uploadProdImg,
} from '../../../../../store/powerMart/actions';
import Tooltip from '../../../../../utils/Tooltip/Tooltip';
import Modal from '../../../../../utils/Modal/Modal';
import { FileDetails } from '../../../../../services/products/get-list-product-images/getList-prodimage.types';
import { ProdImgFileInput } from '../../../../../services/products/upload-prod-Image/create-prodimage.types';
import { ProductsEditData } from '../../../../../services/products/update-products/update-product.types';
import { CorpResult } from '../../../../../services/meta-data/list-corp/list-corp.types';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const PartsSaleAdmin: React.FC<PartsCountProps> = ({ setOrderPartsCnt, orderSts, orderNme }) => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const [loaderOrder, setLoaderOrder] = React.useState(false);
  const [userTp, setUserTp] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [loaderAddProduct, setLoaderAddProduct] = React.useState(false);
  const [modelProdIndex, setModalProdIndex] = React.useState('');
  const [selectedDelImages, setSelectedDelImages] = React.useState<string[]>([]);
  const [openModalProduct, setOpenModalProduct] = React.useState<Record<string, boolean>>({});
  const corpResData = useSelector((state: RootState) => state.powerMart.getCorpMetaList);
  const pendingOrdersResponse = useSelector((state: RootState) => state.powerMart.getOrdersList);
  const getProdImageAwsResponse = useSelector((state: RootState) => state.powerMart.getListProductsImageAWS);
  const deleteImageAwsResponse = useSelector((state: RootState) => state.powerMart.deleteProductsAWS);
  const uploadProductAwsResponse = useSelector((state: RootState) => state.powerMart.uploadProductsAWS);
  const addProductResponse = useSelector((state: RootState) => state.powerMart.createNewProducts);
  const updateProductResponse = useSelector((state: RootState) => state.powerMart.updateProduct);
  const prodDescRes = useSelector((state: RootState) => state.powerMart.prodDesc);
  const [openModalBuyer, setOpenModalBuyer] = React.useState<Record<string, boolean>>({});
  const [openModalTechSpec, setOpenModalTechSpec] = React.useState<Record<string, boolean>>({});
  const [prodImageFile, setProdImageDataFile] = React.useState<ProdImgFileInput>({
    fileData: new FormData(),
  });
  const [prodEditImgList, setEditProdImgList] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);

  const [pendingOrderData, setPendingOrdersData] = React.useState<OrdersResult[]>([{
    fCnt: 0,
    usrInfo: {
      addr: '',
      div: '',
      eId: '',
      nme: '',
      ph: '',
      st: '',
      subDiv: '',
    },
    mSts: '',
    txnId: '',
    txnDt: '',
    ordDt: '',
    sumCost: '',
    sumGst: '',
    sumTotal: '',
    prodTxnData: [],
    ordId: '',
    usrOrg: '',
  }]);
  const [techSpec, setTechSpec] = React.useState('');
  const [prodDesc, setProdDesc] = React.useState('');
  const [loaderCorp, setLoaderCorp] = React.useState(false);
  const [corpListData, setCorpListData] = React.useState<CorpResult[]>([]);
  const [openModalProd, setOpenModalProd] = React.useState<Record<string, boolean>>({});
  const [productUpdateInput, setProductUpdateInput] = React.useState<ProductsEditData>({
    ProdTp: '',
    ProdId: '',
    nme: '',
    techSpec: [],
    prodDesc: [],
    wrnty: 0,
    cost: '',
    gst: '',
    keyW: '',
    modlN: '',
    tagIn: '',
    hsn: '',
    oem: '',
    isAcs: 'True',
  });

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  React.useEffect(() => {
    if (orderSts === 'INACT' && userTp !== '') {
      setLoaderOrder(true);
      dispatch(getOrdersListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
        status: 'INACT',
      }));
    }
  }, [userTp, tokenData, orderSts]);

  React.useEffect(() => {
    if (pendingOrdersResponse.isError && pendingOrdersResponse.message !== '' && orderSts === 'INACT') {
      setLoaderOrder(false);
      setAlertMsg(pendingOrdersResponse.message);
      setErrorShowAlert(true);
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message, orderSts]);

  React.useEffect(() => {
    if (!pendingOrdersResponse.isError && pendingOrdersResponse.message === 'executed' && orderSts === 'INACT') {
      setLoaderOrder(false);
      setPendingOrdersData(pendingOrdersResponse.data);
      setOrderPartsCnt(pendingOrdersResponse.data && pendingOrdersResponse.data.length);
      dispatch(clearPutPendOrdrAdmReq());
      dispatch(clearOrdersListReq());
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message, orderSts, pendingOrdersResponse.data, orderSts]);

  const buyerInfoToggleModal = (target: string) => {
    setOpenModalBuyer((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  // Image Updation Start

  const techSpecInfoToggleModal = (target: string) => {
    setOpenModalTechSpec((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };
  const productToggleModal = (target: string, productId:string) => {
    if (productId !== '') {
      dispatch(clearGetListProdImg());
      setEditProdImgList([{ url: '', nme: '' }]);
      setLoaderAddProduct(true);
      dispatch(getListProdImg({
        requestType: '',
        bucketNme: 'powermart',
        folderName: `products/${productId}`,
      }));
    }

    setOpenModalProduct((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    setModalProdIndex(target);
  };

  React.useEffect(() => {
    if (getProdImageAwsResponse.data) {
      setLoaderAddProduct(false);
      setEditProdImgList(getProdImageAwsResponse.data);
    }
  }, [getProdImageAwsResponse.data]);

  React.useEffect(() => {
    if (deleteImageAwsResponse.fileName === 'deleted') {
      setLoaderAddProduct(false);
      setAlertMsg('Files Deleted Successfully');
      setSuccessShowAlert(true);
      dispatch(clearDeleteProdImg());
      setOpenModalProduct((prevState) => ({
        ...prevState,
        [modelProdIndex]: false,
      }));
      dispatch(clearUploadProdImg());
    }
  }, [deleteImageAwsResponse.fileName]);

  const isImage = (fileName: string) => /\.(jpg|jpeg|png|gif)$/i.test(fileName);

  const isPDF = (fileName: string) => /\.pdf$/i.test(fileName);

  const handleDelChangeImages = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    if (checked) {
      setSelectedDelImages((prevImages) => [...prevImages, name]);
    } else {
      setSelectedDelImages((prevImages) => prevImages.filter((imgName) => imgName !== name));
    }
  };

  const isProdEditImgListEmpty = (list: FileDetails[]) => (
    list.length === 0 || list.every((item) => item.url === '' && item.nme === '')
  );

  const handleUpdateEditProd = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const maxSizeInBytes = 5 * 1024 * 1024;
    const formData = new FormData();
    const newProdImgList: FileDetails[] = [];

    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setAlertMsg('File size exceeds the limit of 5 MB.');
        setErrorShowAlert(true);
        return;
      }
      formData.append(file.name, file);
      // formData.append('fileData', file); // Use the same name for all files
      const objectUrl = URL.createObjectURL(file);
      newProdImgList.push({ url: objectUrl, nme: file.name });
    });

    setEditProdImgList((prevList) => [...prevList, ...newProdImgList]);

    setProdImageDataFile({ ...prodImageFile, fileData: formData });
  };

  const handleDeleteProductImage = (prodId: string) => {
    setLoaderAddProduct(true);
    Promise.resolve(
      dispatch(deleteProdImg({
        bucketNme: AWS_PRODUCT_BUCKET,
        folderName: `products/${prodId}`,
        delFiles: selectedDelImages,
        requestType: '',
      })),
    );
  };

  const handleUploadEditProduct = (prodId: string) => {
    setLoaderAddProduct(true);
    Promise.resolve(
      dispatch(uploadProdImg({
        bucketNme: AWS_PRODUCT_BUCKET,
        folderName: `products/${prodId}`,
        fileNmeInit: prodId,
        formData: prodImageFile.fileData,
        requestType: '',
        isEdit: true,
      })),
    );
  };

  React.useEffect(() => {
    if (!addProductResponse.error && addProductResponse.message !== '') {
      if (addProductResponse.prodId !== '') {
        dispatch(uploadProdImg({
          bucketNme: AWS_PRODUCT_BUCKET,
          folderName: `products/${addProductResponse.prodId}`,
          fileNmeInit: addProductResponse.prodId,
          formData: prodImageFile.fileData,
          requestType: '',
          isEdit: false,
        }));
      } else {
        setLoaderAddProduct(false);
        setAlertMsg('Product Image upload failed');
        setErrorShowAlert(true);
      }
    }
  }, [addProductResponse.error, addProductResponse.message, addProductResponse.prodId]);

  React.useEffect(() => {
    if (uploadProductAwsResponse.fileName !== '') {
      setLoaderAddProduct(false);
      setAlertMsg('File Uploaded Successfully');
      setSuccessShowAlert(true);
      dispatch(clearCreateProductReq());
      setOpenModalProduct((prevState) => ({
        ...prevState,
        [modelProdIndex]: false,
      }));
      dispatch(clearUploadProdImg());
    }
  }, [uploadProductAwsResponse.fileName]);

  // Image Updation End

  // Product Update Module Start

  const toggleProdDetails = (target: string, allOrders: any) => {
    setLoaderAddProduct(true);
    dispatch(prodDescReq({
      requestType: APIHeader.REQ_GET_PRODUCT_DESC,
      inSts: 'PROD_DESC',
      ProdTp: allOrders.ProdTp,
      ProdId: allOrders.ProdId,
    }));
    setProductUpdateInput({
      ...productUpdateInput,
      ProdTp: allOrders.ProdTp ? allOrders.ProdTp : '',
      ProdId: allOrders.ProdId ? allOrders.ProdId : '',
      nme: allOrders.prodInfo.nme ? allOrders.prodInfo.nme : '',
      techSpec: allOrders.prodInfo.techSpec,
      wrnty: allOrders.prodInfo.wrnty ? allOrders.prodInfo.wrnty : 0,
      keyW: allOrders.prodInfo.keyW ? allOrders.prodInfo.keyW : '',
      modlN: allOrders.prodInfo.modlN ? allOrders.prodInfo.modlN : '',
      tagIn: allOrders.prodInfo.tagInfo.corpId ? allOrders.prodInfo.tagInfo.corpId : '',
      hsn: allOrders.prodInfo.hsn ? allOrders.prodInfo.hsn : '',
      cost: allOrders.prodInfo.cost ? allOrders.prodInfo.cost : '',
      gst: allOrders.prodInfo.gst ? allOrders.prodInfo.gst : '',
      oem: allOrders.prodInfo.oem ? allOrders.prodInfo.oem : '',
    });
    setOpenModalProd((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };
  console.log('NEWWW Edit', productUpdateInput);
  React.useEffect(() => {
    if (prodDescRes.isError && prodDescRes.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(prodDescRes.message);
      setErrorShowAlert(true);
    }
  }, [prodDescRes.isError, prodDescRes.message]);

  React.useEffect(() => {
    if (!prodDescRes.isError && prodDescRes.message === 'executed') {
      setLoaderAddProduct(false);
      setProductUpdateInput({
        ...productUpdateInput,
        prodDesc: prodDescRes.data.prodDesc,
      });
      dispatch(clearProdDescReq());
    }
  }, [prodDescRes.isError, prodDescRes.message, prodDescRes.data, clearProdDescReq]);

  const oemOptions = ListCPOptions.map((oemCat) => ({
    label: oemCat.label,
    value: oemCat.value,
  }));

  const selectedUpdateOEMOptions = oemOptions.find(
    (option) => option.value === productUpdateInput.oem,
  );

  const handleSelectUpdateOEMData = (e: any) => {
    if (e) {
      setProductUpdateInput({ ...productUpdateInput, oem: e.value });
    } else {
      setProductUpdateInput({ ...productUpdateInput, oem: '' });
    }
  };

  const handleSelectCorp = (e: any) => {
    if (e) {
      setProductUpdateInput({ ...productUpdateInput, tagIn: e.value });
    } else {
      setProductUpdateInput({ ...productUpdateInput, tagIn: '' });
    }
  };

  const corpOptions = corpListData && corpListData.map((corp) => ({
    label: corp.nme,
    value: corp.MetaId,
  }));

  const selectedUodatedCorpOptions = corpOptions && corpOptions.find(
    (option) => option.value === productUpdateInput.tagIn,
  );

  React.useEffect(() => {
    setLoaderCorp(true);
    dispatch(getCorpListReq({
      requestType: APIHeader.REQ_ALL_CORP_META,
    }));
  }, []);

  React.useEffect(() => {
    if (!corpResData.isError) {
      setLoaderCorp(false);
      setCorpListData(corpResData.corpData);
    }
  }, [corpResData.corpData]);

  const handleUpdateTechSpec = () => {
    if (techSpec !== '') {
      if (techSpec !== '') {
        const updatedInitTechSpec = { ...productUpdateInput };
        updatedInitTechSpec.techSpec.push(techSpec);
        setProductUpdateInput(updatedInitTechSpec);
        setTechSpec('');
      }
    }
  };

  const handleUpdateRemoveTechSpec = (specIndx: number) => {
    const updatedInitProdSpec = { ...productUpdateInput };
    updatedInitProdSpec.techSpec.splice(specIndx, 1);
    setProductUpdateInput(updatedInitProdSpec);
  };

  const handleUpdateProdDesc = () => {
    if (prodDesc !== '') {
      if (prodDesc !== '') {
        const updatedInitProdDesc = { ...productUpdateInput };
        updatedInitProdDesc.prodDesc.push(prodDesc);
        setProductUpdateInput(updatedInitProdDesc);
        setProdDesc('');
      }
    }
  };

  const handleUpdateRemoveProdDesc = (descIndx: number) => {
    const updatedInitProdSpec = { ...productUpdateInput };
    updatedInitProdSpec.prodDesc.splice(descIndx, 1);
    setProductUpdateInput(updatedInitProdSpec);
  };

  const handleFieldUpdateChange = (e:any) => {
    const fieldName = e.target.name;
    setProductUpdateInput({ ...productUpdateInput, [fieldName]: e.target.value });
  };

  const handleUpdateNumberFieldChange = (e:any) => {
    const fieldName = e.target.name;
    if (e.target.value > 0) {
      setProductUpdateInput({ ...productUpdateInput, [fieldName]: e.target.value });
    } else {
      setProductUpdateInput({ ...productUpdateInput, [fieldName]: 0 });
    }
  };

  const handleTechSpecChange = (event: any) => {
    setTechSpec(event.target.value);
  };

  const handleProdDescChange = (event: any) => {
    setProdDesc(event.target.value);
  };

  const handleUpdateProductSubmit = () => {
    setLoaderAddProduct(true);
    Promise.resolve(
      dispatch(putProductReq({
        userType: userTp,
        requestType: APIHeader.REQ_UPDATE_PRODUCTS,
        inputBody: productUpdateInput,
        token: tokenData,
      })),
    );
  };

  React.useEffect(() => {
    if (updateProductResponse.error && updateProductResponse.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(updateProductResponse.message);
      setErrorShowAlert(true);
    }
  }, [updateProductResponse.error, updateProductResponse.message]);

  React.useEffect(() => {
    if (!updateProductResponse.error && updateProductResponse.message !== '') {
      setLoaderAddProduct(false);
      setAlertMsg(updateProductResponse.message);
      dispatch(clearPutProductReq());
      setSuccessShowAlert(true);
      setOpenModalProd({});
      dispatch(getOrdersListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
        status: 'INACT',
      }));
    }
  }, [updateProductResponse.error, updateProductResponse.message]);

  // Product Update Module End

  return (
    <div>
      {loaderOrder ? <LoaderData /> : null}
      {loaderAddProduct ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row className="align-center">
        <Col xs="12">
          <Row className="pt-2 mx-1">
            <Col lg="6" xs="6">
              <div className="pt-3 align-start">
                <span className="meta-waranty-lite">{orderNme}</span>
              </div>
            </Col>
            <Col lg="6" xs="6">
              <div className="pt-3 align-end">
                <span className="meta-waranty-lite">Order Count: {pendingOrderData.length}</span>
              </div>
            </Col>
            <hr />
          </Row>
        </Col>
        <Col xs="12">
          {pendingOrderData && pendingOrderData.length === 0 ? (
            <div className="pt-5 align-center">
              <span className="form-collapse-card-header">No Record Found</span>
            </div>
          )
            : (
              <div className="align-center mx-1">
                <table className="styled-table">
                  <thead>
                    <tr>
                      <th>Order ID</th>
                      <th>Image</th>
                      <th>Name</th>
                      <th>Cost</th>
                      <th>Quantity</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pendingOrderData && pendingOrderData.length > 0 ? (
                      pendingOrderData.map((orderDet, index) => (
                        <React.Fragment key={`order-txn-${index.toString()}`}>
                          {orderDet.prodTxnData.length > 0 ? (
                            orderDet.prodTxnData.map((allOrders, orderIndex) => (
                              <tr key={`order-prod-${orderIndex.toString()}`}>
                                {orderIndex === 0 && (
                                <td rowSpan={orderDet.prodTxnData.length}>
                                  {orderDet.ordId} <span className="button-icon-url">({orderDet.fCnt})</span>
                                  <Tooltip
                                    content="Buyer Info"
                                    position="top"
                                    customClassName={`buyerInfo-${orderIndex}`}
                                  >
                                    <Button className="button-icon padding-zero ml" id={`buyerInfo-${orderIndex}`} onClick={() => buyerInfoToggleModal(`buyerDet-${index}-${orderIndex}`)}>
                                      <FontAwesomeIcon icon={faUserCircle} className="text-channel-partner" />
                                    </Button>
                                  </Tooltip>
                                  <Modal
                                    show={openModalBuyer[`buyerDet-${index}-${orderIndex}`] || false}
                                    disCancel={false}
                                    targetId={`buyerDet-${index}-${orderIndex}`}
                                    onClose={() => buyerInfoToggleModal(`buyerDet-${index}-${orderIndex}`)}
                                    title={(
                                      <div className="align-start">
                                        <div className="my-1 align-start">
                                          <span className="my-1">{ DiscomDistributionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                                          <span className="my-1">{ DiscomTransmissionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                                        </div>
                                      </div>
)}
                                    size="MD"
                                  >
                                    <div className="my-1 align-start ml-1">
                                      <span className="text-bold-grey">{orderDet.usrInfo?.nme} ({orderDet.usrOrg})</span>
                                    </div>
                                    <div className="my-1 align-start ml-1">
                                      <span className="text-bold-grey">
                                        <FontAwesomeIcon icon={faEnvelope} className="mr" />
                                        {orderDet.usrInfo?.eId}
                                      </span>
                                    </div>
                                    <div className="my-1 align-start ml-1">
                                      <span className="text-bold-grey">
                                        <FontAwesomeIcon icon={faPhone} className="mr" />
                                        +91 {orderDet.usrInfo?.ph}
                                      </span>
                                    </div>
                                    <div className="pt-2 align-start ml-1">
                                      <span className="text-bold-grey">
                                        <FontAwesomeIcon icon={faLocation} className="mr" />
                                        {orderDet.usrInfo?.addr}
                                      </span>
                                    </div>
                                    <div className="my-2" />
                                  </Modal>
                                </td>
                                )}
                                <td>
                                  <div className="image-container-prod-power">
                                    <CardImg src={allOrders.prodInfo.imgUrl} alt="Prod Image" className="dash-product-image-prod-power" />
                                    <Tooltip
                                      content="Image Upload/Delete"
                                      position="bottom"
                                      customClassName={`imageEdit-${index}`}
                                    >
                                      <Button id={`imageEdit-${index}`} className="button-icon" onClick={() => productToggleModal(`productDet-${index}`, allOrders.ProdId)}>
                                        <FontAwesomeIcon icon={faEdit} />
                                      </Button>
                                    </Tooltip>
                                    <Modal
                                      show={openModalProduct[`productDet-${index}`] || false}
                                      onClose={() => productToggleModal(`productDet-${index}`, '')}
                                      disCancel={false}
                                      targetId={`productDet-${index}`}
                                      title={allOrders.prodInfo.nme}
                                      size="XL"
                                    >
                                      <Row className="pt-1">
                                        {isProdEditImgListEmpty(prodEditImgList)
                                          ? <div className="pt-2 align-center"><span className="form-collapse-card-header">No Product Image Found</span></div>
                                          : (prodEditImgList.map((imgDet, imgIndex) => (
                                            <Col lg="4" key={`imgList-${imgIndex.toString()}`} className="border-image">
                                              <div className="align-end">
                                                <Input
                                                  type="checkbox"
                                                  name={imgDet.nme}
                                                  onChange={handleDelChangeImages}
                                                  className="powermart-check-box cursor-pointer"
                                                />
                                              </div>
                                              {isImage(imgDet.nme) ? (
                                                <div>
                                                  <div className="align-center pt-1">
                                                    <span className="text-lite-grey">{imgDet.nme}</span>
                                                  </div>
                                                  <div className="align-center pt-1">
                                                    <CardImg className="seller-image-dim" src={imgDet.url} alt={`ImgData ${imgIndex + 1}`} />
                                                  </div>
                                                </div>
                                              ) : isPDF(imgDet.nme) ? (
                                                <div>
                                                  <div className="align-center pt-1">
                                                    <span className="text-lite-grey">{imgDet.nme}</span>
                                                  </div>
                                                  <div className="align-center pt-1">
                                                    <object
                                                      id={imgDet.nme}
                                                      data={imgDet.url}
                                                      type="application/pdf"
                                                      className="seller-image-dim"
                                                      // width="auto"
                                                      // height="seller-image-dim"
                                                      style={{ display: 'block' }}
                                                    >
                                                      <p>No Preview Available</p>
                                                    </object>
                                                  </div>
                                                </div>
                                              ) : (
                                                <p>Unsupported file type</p>
                                              )}
                                            </Col>
                                          )))}
                                      </Row>
                                      <Row className="my-3 pt-3 align-center">
                                        <Col xs="12" lg="11">
                                          <div className="upload-box">
                                            <Input
                                              type="file"
                                              accept=".png, .jpg, .jpeg"
                                              name="fileData"
                                              multiple
                                              className="upload-box-input"
                                              onChange={(e) => handleUpdateEditProd(e)}
                                            />
                                            <span className="register-file-link">Upload New Files</span>
                                          </div>
                                        </Col>
                                        <Col xs="12" lg="11">
                                          <div className="align-center pt-4">
                                            <Button disabled={selectedDelImages.length === 0} className="powermart-button-red mx-1" onClick={() => handleDeleteProductImage(allOrders.ProdId)}>
                                              DELETE
                                            </Button>
                                            <Button disabled={Array.from(prodImageFile.fileData.keys()).length === 0} className="powermart-button-solid mx-1" onClick={() => handleUploadEditProduct(allOrders.ProdId)}>
                                              UPLOAD
                                            </Button>
                                          </div>
                                        </Col>
                                      </Row>
                                      <div className="pt-4" />
                                    </Modal>
                                    {allOrders.sSts === 'REJCT' && (
                                    <div className="overlay-prod-power">
                                      <CardImg src="/images/stop.png" alt="Prod Image" className="image-reject-prod-power" />
                                    </div>
                                    )}
                                  </div>
                                </td>
                                <td className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : 'card-toggle-text'}>
                                  {allOrders.prodInfo.nme}
                                  <Tooltip
                                    content="Tech Spec"
                                    position="top"
                                    customClassName={`techSpecInfo-${orderIndex}`}
                                  >
                                    <Button className="button-icon padding-zero ml" id={`techSpecInfo-${orderIndex}`} onClick={() => techSpecInfoToggleModal(`techSpecDet-${index}-${orderIndex}`)}>
                                      <FontAwesomeIcon icon={faGear} className="text-channel-partner" />
                                    </Button>
                                  </Tooltip>
                                  <Modal
                                    show={openModalTechSpec[`techSpecDet-${index}-${orderIndex}`] || false}
                                    disCancel={false}
                                    targetId={`techSpecDet-${index}-${orderIndex}`}
                                    onClose={() => techSpecInfoToggleModal(`techSpecDet-${index}-${orderIndex}`)}
                                    title="Tech Specifications"
                                    size="MD"
                                  >
                                    <div className="ml my-2">
                                      <span className="wrong-pass-key">{ allOrders.prodInfo.nme }</span>
                                    </div>
                                    <div className="ml">
                                      {allOrders.prodInfo.techSpec.map((techSpecData, techSpecIndx) => (
                                        <div key={`techSpec-${techSpecIndx.toString()}`}>
                                          <FontAwesomeIcon icon={faCube} className="powermart-super-lite-icon mr" />
                                          <span className="text-bold-grey">{ techSpecData }</span>
                                        </div>
                                      ))}
                                    </div>
                                    <div className="my-2" />
                                  </Modal>
                                </td>
                                <td className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                  {new Intl.NumberFormat('en-IN', {
                                    style: 'currency',
                                    currency: 'INR',
                                    minimumFractionDigits: 2,
                                  }).format(Number(allOrders.prodInfo.cost))}  + {allOrders.prodInfo.gst}%
                                </td>
                                <td>
                                  {allOrders.prodCnt}
                                </td>
                                <td>
                                  <Button className="powermart-button-red mx-1" onClick={() => toggleProdDetails(`prodDetails-${index}-${orderIndex}`, allOrders)}>
                                    UPDATE
                                  </Button>
                                  <Button className="powermart-button-solid mx-1">
                                    ASSIGN
                                  </Button>
                                  <Modal
                                    show={openModalProd[`prodDetails-${index}-${orderIndex}`] || false}
                                    onClose={() => toggleProdDetails(`prodDetails-${index}-${orderIndex}`, allOrders)}
                                    disCancel={false}
                                    targetId="orderHelp"
                                    title={allOrders.prodInfo.nme}
                                    size="XL"
                                  >
                                    <Row className="mx-2">
                                      <Col lg="8" className="pt-1">
                                        <div className="align-start pt-2">
                                          <h6 className="text-lite-grey mx-2 my-1">Product Name<span className="mandate-star">*</span></h6>
                                        </div>
                                        <Input
                                          placeholder="Enter product name"
                                          type="text"
                                          required
                                          name="nme"
                                          value={productUpdateInput.nme}
                                          onChange={handleFieldUpdateChange}
                                          className="powermart-input"
                                        />
                                      </Col>
                                      <Col lg="4" className="pt-1">
                                        <div className="align-start pt-2">
                                          <h6 className="text-lite-grey mx-2 my-1">HSN Code<span className="mandate-star">*</span></h6>
                                        </div>
                                        <Input
                                          placeholder="Enter HSN Code"
                                          type="text"
                                          required
                                          name="hsn"
                                          value={productUpdateInput.hsn}
                                          onChange={handleFieldUpdateChange}
                                          className="powermart-input"
                                        />
                                      </Col>
                                      <Col lg="5" xs="5" className="pt-3">
                                        <div className="align-start">
                                          <h6 className="text-lite-grey mx-2 my-1">Warranty(months)<span className="mandate-star">*</span></h6>
                                        </div>
                                        <Input
                                          placeholder="Warranty in month"
                                          type="number"
                                          required
                                          name="wrnty"
                                          value={productUpdateInput.wrnty || ''}
                                          onChange={handleUpdateNumberFieldChange}
                                          className="powermart-input"
                                        />
                                      </Col>
                                      <Col lg="4" xs="5" className="pt-3">
                                        <div className="align-start">
                                          <h6 className="text-lite-grey mx-2 my-1">Cost<span className="mandate-star">*</span></h6>
                                        </div>
                                        <Input
                                          type="number"
                                          required
                                          name="cost"
                                          value={productUpdateInput.cost || ''}
                                          onChange={handleUpdateNumberFieldChange}
                                          className="powermart-input"
                                        />
                                      </Col>
                                      <Col lg="3" xs="3" className="pt-3">
                                        <div className="align-start">
                                          <h6 className="text-lite-grey mx-2 my-1">GST<span className="mandate-star">*</span></h6>
                                        </div>
                                        <Input
                                          type="number"
                                          required
                                          name="gst"
                                          value={productUpdateInput.gst || ''}
                                          onChange={handleUpdateNumberFieldChange}
                                          className="powermart-input"
                                        />
                                      </Col>
                                      <Col lg="6" className="pt-3">
                                        <div className="align-start">
                                          <h6 className="text-lite-grey mx-2 my-1">Model<span className="mandate-star">*</span></h6>
                                        </div>
                                        <Input
                                          placeholder="Enter Model"
                                          type="text"
                                          required
                                          name="modlN"
                                          value={productUpdateInput.modlN}
                                          onChange={handleFieldUpdateChange}
                                          className="powermart-input"
                                        />
                                      </Col>
                                      <Col lg="6" className="pt-3">
                                        <div className="align-start">
                                          <h6 className="text-lite-grey mx-2 my-1">OEM/Category<span className="mandate-star">*</span></h6>
                                        </div>
                                        <Select
                                          value={productUpdateInput.oem === '' ? null : selectedUpdateOEMOptions}
                                          options={oemOptions}
                                          onChange={handleSelectUpdateOEMData}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (base) => ({
                                              ...base,
                                            }),
                                            control: (base) => ({
                                              ...base,
                                              '&:hover': { borderColor: '#05cbddff' },
                                              border: '1px solid #BFBFBF',
                                              minHeight: '45px',
                                              borderRadius: '5px',
                                              padding: 1,
                                              textAlign: 'left',
                                              fontSize: '16px',
                                              fontWeight: 400,
                                              color: '#383838',
                                              boxShadow: 'none',
                                              backgroundColor: '#fff',
                                              '&:focus': {
                                                borderColor: '#E1EDF8',
                                                boxShadow: 'none',
                                                color: '#575656',
                                                background: '#fff',
                                              },
                                            }),
                                            option: (provided, state) => ({
                                              ...provided,
                                              color: state.isSelected ? '#383838' : '#212121',
                                              padding: 15,
                                              textAlign: 'left',
                                              background: state.isSelected ? '#FAFCFB' : '#fff',
                                              '&:hover': {
                                                background: '#FAFCFB',
                                              },
                                            }),
                                          }}
                                          noOptionsMessage={() => 'No OEM Found'}
                                          placeholder="Select OEM"
                                        />
                                      </Col>
                                      <Col lg="12" className="pt-3">
                                        <div className="align-start">
                                          <h6 className="text-lite-grey mx-2 my-1">Keyword<span className="mandate-star">*</span></h6>
                                        </div>
                                        <Input
                                          placeholder="Enter Keyword"
                                          type="text"
                                          required
                                          name="keyW"
                                          value={productUpdateInput.keyW}
                                          onChange={handleFieldUpdateChange}
                                          className="powermart-input"
                                        />
                                      </Col>
                                      <Col lg="12" className="pt-4">
                                        <div className="align-start">
                                          <h6 className="text-lite-grey mx-2 my-1">Tag Product<span className="mandate-star">*</span></h6>
                                        </div>
                                        <Select
                                          value={productUpdateInput.tagIn === '' ? null : selectedUodatedCorpOptions}
                                          options={corpOptions}
                                          onChange={handleSelectCorp}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (base) => ({
                                              ...base,
                                              // position: 'relative',
                                            }),
                                            control: (base: any) => ({
                                              ...base,
                                              '&:hover': { borderColor: '#05cbddff' },
                                              border: '1px solid #BFBFBF',
                                              minHeight: '45px',
                                              borderRadius: '5px',
                                              padding: 1,
                                              textAlign: 'left',
                                              fontSize: '16px',
                                              fontWeight: 400,
                                              color: '#383838',
                                              boxShadow: 'none',
                                              backgroundColor: '#fff',
                                              '&:focus': {
                                                borderColor: '#E1EDF8',
                                                boxShadow: 'none',
                                                color: '#575656',
                                                background: '#fff',
                                              },
                                            }),
                                            option: (provided: any, state: { isSelected: any; }) => ({
                                              ...provided,
                                              color: state.isSelected ? '#383838' : '#212121',
                                              padding: 15,
                                              textAlign: 'left',
                                              background: state.isSelected ? '#FAFCFB' : '#fff',
                                              '&:hover': {
                                                background: '#FAFCFB',
                                              },
                                            }),
                                          }}
                                          isLoading={loaderCorp}
                                          noOptionsMessage={() => 'No Organization Found'}
                                          placeholder="Select Organization"
                                        />
                                      </Col>
                                      <Col xs="12">
                                        <div className="text-right-login-campus-register mx-1 pt-4">
                                          <div className="align-start">
                                            <h6 className="text-lite-grey mx-2 my-1">Tech Specifications</h6>
                                          </div>
                                          <InputGroup>
                                            <Input
                                              type="text"
                                              placeholder="Provide Tech Specifications"
                                              className="powermart-input"
                                              value={techSpec}
                                              onChange={(e) => handleTechSpecChange(e)}
                                            />
                                            <InputGroupText className="powermart-input mx-1 cursor-pointer" onClick={() => handleUpdateTechSpec()}>
                                              <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                                            </InputGroupText>
                                          </InputGroup>
                                          {productUpdateInput.techSpec.length > 0
                                            ? (
                                              <Card className="my-3 meta-card">
                                                <span className="my-1 text-lite-grey ml-1">Tech Specification Details</span>
                                                {productUpdateInput.techSpec.map((techSpecData, techSpecIndx) => (
                                                  <div key={`techSpec-${techSpecIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                                    <Row className="mx-1 pt-2">
                                                      <Col lg="11" xs="10">
                                                        <div className="pt-1">
                                                          <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                                          <span className="ml-1">{ techSpecData }</span>
                                                        </div>
                                                      </Col>
                                                      <Col lg="1" xs="2">
                                                        <div className="align-end">
                                                          <Button className="button-icon" onClick={() => handleUpdateRemoveTechSpec(techSpecIndx)}>
                                                            <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                                          </Button>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                ))}
                                              </Card>
                                            ) : null }
                                        </div>
                                      </Col>
                                      <Col xs="12">
                                        <div className="text-right-login-campus-register mx-1 pt-4">
                                          <div className="align-start">
                                            <h6 className="text-lite-grey mx-2 my-1">Product Description</h6>
                                          </div>
                                          <InputGroup>
                                            <Input
                                              type="textarea"
                                              placeholder="Provide Product Description"
                                              className="powermart-input-desc"
                                              value={prodDesc}
                                              onChange={(e) => handleProdDescChange(e)}
                                            />
                                            <InputGroupText className="powermart-input mx-1 cursor-pointer my-4" onClick={() => handleUpdateProdDesc()}>
                                              <FontAwesomeIcon icon={faCirclePlus} className="button-icon" />
                                            </InputGroupText>
                                          </InputGroup>
                                          {productUpdateInput.prodDesc.length > 0
                                            ? (
                                              <Card className="my-3 meta-card">
                                                <span className="my-1 text-lite-grey ml-1">Product Description Details</span>
                                                {productUpdateInput.prodDesc.map((descData, descIndx) => (
                                                  <div key={`prodDesc-${descIndx.toString()}`} className="meta-card-header my-2 mx-2">
                                                    <Row className="mx-1 pt-2">
                                                      <Col lg="11" xs="10">
                                                        <div className="pt-1">
                                                          <FontAwesomeIcon icon={faCube} className="text-lite-grey" />
                                                          <span className="ml-1">{ descData }</span>
                                                        </div>
                                                      </Col>
                                                      <Col lg="1" xs="2">
                                                        <div className="align-end">
                                                          <Button className="button-icon" onClick={() => handleUpdateRemoveProdDesc(descIndx)}>
                                                            <FontAwesomeIcon icon={faXmark} className="wrong-pass-key" />
                                                          </Button>
                                                        </div>
                                                      </Col>
                                                    </Row>
                                                  </div>
                                                ))}
                                              </Card>
                                            ) : null }
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="align-center pt-4 mx-2">
                                      <Button
                                        className="powermart-button-fullwidth mx-1"
                                        onClick={handleUpdateProductSubmit}
                                        disabled={productUpdateInput.tagIn === '' || productUpdateInput.keyW === ''}
                                      >
                                        UPDATE
                                      </Button>
                                    </div>
                                    <div className="pt-4" />
                                  </Modal>
                                </td>
                              </tr>
                            ))
                          ) : (
                            <tr key={`order-no-prod-${index.toString()}`}>
                              <td>{orderDet.ordId}</td>
                              <td colSpan={3}>No products</td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={4}>No pending orders</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            )}
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default PartsSaleAdmin;
