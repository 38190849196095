import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Button, Col, Form, Input, Row,
} from 'reactstrap';
import { ResetPwdProps } from './reset-passkey.type';
import { PassKeyPutDataInput } from '../../../services/update-passkey/update-passkey.types';
import {
  clearPassKeyReq, clearProfileReq, getProfile, passKeyReq,
} from '../../../store/powerMart/actions';
import { APIHeader } from '../../../utils/constants';
import LoaderData from '../../../utils/loader';
import { errorAlert, successAlert } from '../../../utils/alert';
import { getTokenFromSessionStorage, setUserProfileSessionStorage } from '../../../utils/service/localstorage-service';

export const ModalPasskeyUpdate: React.FC<ResetPwdProps> = ({ setIsResetPasskey, userType }) => {
  const dispatch = useDispatch();
  const generatedToken = getTokenFromSessionStorage();
  const [matchPassKey, setMatchPassKey] = useState(false);
  const [disableText, setDisableText] = useState(true);
  const [loaderProfile, setLoaderProfile] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const loginProfileData = useSelector((state: RootState) => state.powerMart.profileData);
  const passKeyResponse = useSelector((state: RootState) => state.powerMart.updatePassKey);
  const [myPassKey, setMyPassKey] = useState<PassKeyPutDataInput>({
    psKey: '',
    prePsKey: '',
  });
  const [confirmPassKey, setConfirmPassKey] = useState(
    {
      confirmKey: '',
    },
  );

  const handleMyPassKey = (e:any) => {
    setMyPassKey({ ...myPassKey, [e.target.name]: e.target.value });
  };

  const handleConfirmPassKey = (e:any) => {
    setConfirmPassKey({ ...confirmPassKey, [e.target.name]: e.target.value });
  };

  React.useEffect(() => {
    if (!loginProfileData.isError && loginProfileData.message === 'executed') {
      setIsResetPasskey(loginProfileData.profileData.isPKReset);
      setUserProfileSessionStorage(JSON.stringify(loginProfileData));
      dispatch(clearProfileReq());
    }
  }, [loginProfileData.isError, loginProfileData.message]);

  React.useEffect(() => {
    if ((confirmPassKey.confirmKey === myPassKey.psKey) && (confirmPassKey.confirmKey !== '' && myPassKey.psKey !== '')) {
      setMatchPassKey(true);
      setDisableText(false);
    } else {
      setMatchPassKey(false);
    }
  }, [confirmPassKey.confirmKey, myPassKey.psKey]);

  const handleSubmitPassKey = (e: any) => {
    e.preventDefault();
    setLoaderProfile(true);
    dispatch(passKeyReq({
      inputBody: myPassKey,
      userReqFor: userType,
      requestType: APIHeader.REQ_UPDATE_PASSWORD,
      token: generatedToken,
    }));
  };

  React.useEffect(() => {
    if (passKeyResponse.error && passKeyResponse.message !== '') {
      setLoaderProfile(false);
      setAlertMsg(passKeyResponse.message);
      setErrorShowAlert(true);
    }
  }, [passKeyResponse.error, passKeyResponse.message]);

  React.useEffect(() => {
    if (!passKeyResponse.error && passKeyResponse.message !== '') {
      setLoaderProfile(false);
      dispatch(getProfile({
        userReqFor: userType,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: generatedToken,
      }));
      dispatch(clearPassKeyReq());
      setMyPassKey({
        psKey: '',
        prePsKey: '',
      });
      setConfirmPassKey({
        confirmKey: '',
      });
      setAlertMsg(passKeyResponse.message);
      setSuccessShowAlert(true);
    }
  }, [passKeyResponse.error, passKeyResponse.message]);

  return (
    <Row className="pt-2 align-center">
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg="11" xs="12">
        <Form onSubmit={handleSubmitPassKey}>
          <div className="collapse-bg pt-2">
            <div className="pt-1">
              <div className="align-start">
                <h6 className="text-lite-grey mx-2 my-1">current password<span className="mandate-star">*</span></h6>
              </div>
              <Input
                placeholder="Enter Your Current Password"
                type="password"
                name="prePsKey"
                value={myPassKey.prePsKey}
                required
                onChange={handleMyPassKey}
                className="powermart-input"
              />
            </div>
            <div className="pt-4 mx-1">
              <div className="align-start">
                <h6 className="text-lite-grey mx-2 my-1">new password<span className="mandate-star">*</span></h6>
              </div>
              <Input
                placeholder="Enter Your New Password"
                type="password"
                name="psKey"
                value={myPassKey.psKey}
                required
                onChange={handleMyPassKey}
                className="powermart-input"
              />
            </div>
            <div className="pt-4 mx-1">
              <div className="align-start">
                <h6 className="text-lite-grey mx-2 my-1">confirm password<span className="mandate-star">*</span></h6>
              </div>
              <Input
                placeholder="Confirm your Password"
                type="password"
                name="confirmKey"
                required
                value={confirmPassKey.confirmKey}
                onChange={handleConfirmPassKey}
                className="powermart-input"
              />
              {myPassKey.psKey !== '' && confirmPassKey.confirmKey !== '' ? <div className="mx-1"> {matchPassKey ? <span className="correct-pass-key">Password matched</span> : <span className="wrong-pass-key">Password mismatch</span>}</div> : null}
            </div>
          </div>
          <div className="align-center pt-3 my-2">
            <Button className="powermart-button-fullwidth mx-1" disabled={loaderProfile || !matchPassKey || disableText}>
              SAVE
            </Button>
          </div>
          <div className="pt-3" />
        </Form>
      </Col>
    </Row>
  );
};

export default ModalPasskeyUpdate;
