import { createAction } from 'typesafe-actions';
import {
  ADDRESS_DATA,
  API_ERROR, CLEAR_ADDRESS_DATA, CLEAR_CONTACT_US_DATA,
  CLEAR_CORP_LIST, CLEAR_CREATE_ADD_KART, CLEAR_CREATE_DIV,
  CLEAR_CREATE_PRODUCT, CLEAR_CREATE_SPARE, CLEAR_DELETE_AWS_FOLDER, CLEAR_DELETE_PROD_IMG, CLEAR_DEL_KART_PROD,
  CLEAR_DEL_PROD,
  CLEAR_DIV_LIST, CLEAR_GET_AWS_FILE_URL, CLEAR_GET_LIST_PRODUCT,
  CLEAR_KART_COUNT, CLEAR_KART_COUNT_PUT, CLEAR_KART_LIST, CLEAR_KART_STATUS_PUT,
  CLEAR_KART_TXNID_PUT, CLEAR_LOGIN_TOKEN, CLEAR_MY_ORDERS, CLEAR_OEM_DATA, CLEAR_ORDERS_LIST,
  CLEAR_ORDER_TXN_BY_ID, CLEAR_PASS_KEY_DATA, CLEAR_PRODUCTS_LIST, CLEAR_PRODUCT_DESC,
  CLEAR_PROFILE, CLEAR_PUT_ACK_SHIP, CLEAR_PUT_PEND_ORDER_STS_ADM, CLEAR_PUT_PRODUCT,
  CLEAR_REGISTER_DATA, CLEAR_SELLER_PRODUCTS_LIST, CLEAR_SELLER_REGISTER_DATA,
  CLEAR_STATE_LIST, CLEAR_UPLOAD_PRODUCT, CLEAR_USER_LIST_DATA, CLEAR_USER_STATUS_DATA,
  CONTACT_US_DATA, CREATE_ADD_KART, CREATE_DIV, CREATE_KART_COUNT_PUT,
  CREATE_KART_STATUS_PUT, CREATE_KART_TXNID_PUT, CREATE_PRODUCT, CREATE_SPARE,
  DELETE_AWS_FOLDER,
  DELETE_PROD_IMG,
  DEL_KART_PROD, DEL_PROD, GET_AWS_FILE_URL, GET_CORP_LIST, GET_DIV_LIST, GET_FORGOT_PASSWORD,
  GET_KART_COUNT, GET_KART_LIST, GET_LIST_PROD_IMG, GET_LOGIN_TOKEN, GET_ORDERS_LIST,
  GET_ORDER_TXN_BY_ID, GET_PRODUCTS_LIST, GET_PROFILE, GET_SELLER_PRODUCTS_LIST,
  GET_STATE_LIST, GET_USER_LIST, MY_ORDERS, OEM_DATA, PASS_KEY_DATA, PRODUCT_DESC, PUT_ACK_SHIP,
  PUT_PEND_ORDER_STS_ADM, PUT_PRODUCT, REGISTER_DATA, SELLER_REGISTER_DATA, SHOW_DATA,
  UPDATE_ADDRESS_DATA, UPDATE_CONTACT_US_DATA, UPDATE_CORP_LIST,
  UPDATE_CREATE_ADD_KART, UPDATE_CREATE_DIV, UPDATE_CREATE_PRODUCT, UPDATE_CREATE_SPARE,
  UPDATE_DATA, UPDATE_DELETE_AWS_FOLDER, UPDATE_DELETE_PROD_IMG, UPDATE_DEL_KART_PROD, UPDATE_DEL_PROD, UPDATE_DIV_LIST, UPDATE_FORGOT_PASSWORD,
  UPDATE_GET_AWS_FILE_URL, UPDATE_GET_LIST_PROD_IMG, UPDATE_KART_COUNT, UPDATE_KART_COUNT_PUT,
  UPDATE_KART_LIST, UPDATE_KART_STATUS_PUT, UPDATE_KART_TXNID_PUT, UPDATE_LOGIN_TOKEN,
  UPDATE_MY_ORDERS, UPDATE_OEM_DATA, UPDATE_ORDERS_LIST, UPDATE_ORDER_TXN_BY_ID, UPDATE_PASS_KEY_DATA,
  UPDATE_PRODUCTS_LIST, UPDATE_PRODUCT_DESC, UPDATE_PROFILE, UPDATE_PUT_ACK_SHIP,
  UPDATE_PUT_PEND_ORDER_STS_ADM, UPDATE_PUT_PRODUCT, UPDATE_REGISTER_DATA,
  UPDATE_SELLER_PRODUCTS_LIST, UPDATE_SELLER_REGISTER_DATA, UPDATE_STATE_LIST,
  UPDATE_UPLOAD_PROD_IMG, UPDATE_USER_LIST, UPDATE_USER_STATUS_DATA, UPLOAD_PROD_IMG,
  USER_STATUS_DATA,
} from './constants';
import { ContactUsOptions, ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import { ErrorAction } from '../../services/types';
import { ForgotPasswordOptions, ForgotPasswordResults } from '../../services/forgot-password/forgot-password.service.types';
import { GenTokenOptions, GenTokenResults } from '../../services/gen-token/gen-token.service.types';
import { ProfileOptions, ProfileResults } from '../../services/get-profile/get-profile.service.types';
import { ListDivisionOptions, ListDivisionResults } from '../../services/meta-data/list-division/list-division.service.types';
import { RegisterOptions, RegisterResults } from '../../services/register/register.service.types';
import { ListStateOptions, ListStateResults } from '../../services/meta-data/list-state/list-state.service.types';
import { PassKeyPutOptions, PassKeyPutResults } from '../../services/update-passkey/update-passkey.service.types';
import { UserStatusPutOptions, UserStatusPutResults } from '../../services/all-users/update-status/update-status.service.types';
import { ListUserOptions, ListUserResults } from '../../services/all-users/user-list/list-user.service.types';
import { PostDivisionOptions, PostDivisionResults } from '../../services/meta-data/add-division/post-division.service.types';
import { PostProductOptions, PostProductResults } from '../../services/products/add-products/post-product.service.types';
import { GetAllProductOptions, GetAllProductResults } from '../../services/products/list-products/get-all-product.service.types';
import { CreateProdImgOptions, CreateProdImgResults } from '../../services/products/upload-prod-Image/create-prodimage.service.types';
import { PostAddKartOptions, PostAddKartResults } from '../../services/products/add-kart/post-addkart.service.types';
import { GetAllKartOptions, GetAllKartResults } from '../../services/products/list-kart/get-all-kart.service.types';
import { DELKartProdOptions, DELKartProdResults } from '../../services/products/del-kart/del-kart-prod.service.types';
import { GetKartCountOptions, GetKartCountResults } from '../../services/products/get-kart-count/get-kart-count.service.types';
import { KartCountPutOptions, KartCountPutResults } from '../../services/products/update-kart-count/update-kart-count.service.types';
import { KartStatusPutOptions, KartStatusPutResults } from '../../services/products/update-kart-status/update-kart-status.service.types';
import { KartTxnIdPutOptions, KartTxnIdPutResults } from '../../services/products/update-kart-txn-id/update-kart-txnid.service.types';
import { GetAllMyOrdersOptions, GetAllMyOrdersResults } from '../../services/products/list-my-orders/get-all-myorders.service.types';
import { ProductDescOptions, ProductDescResults } from '../../services/products/prod-desc/product-desc.service.types';
import { PostSpareOptions, PostSpareResults } from '../../services/products/add-spares/post-spare.service.types';
import { ProductPutOptions, ProductPutResults } from '../../services/products/update-products/update-product.service.types';
import { ListCorpOptions, ListCorpResults } from '../../services/meta-data/list-corp/list-corp.service.types';
import { SellerRegisterOptions, SellerRegisterResults } from '../../services/register-seller/seller-register.service.types';
import { GetAllSellerProductOptions, GetAllSellerProductResults } from '../../services/products/list-seller-product/get-all-seller-product.service.types';
import { GetAllOrdersOptions, GetAllOrdersResults } from '../../services/products/power-orders/get-all-orders.service.types';
import { AddressPutOptions, AddressPutResults } from '../../services/all-users/update-address/update-address.service.types';
import { GetAWSFileUrlOptions, GetAWSFileUrlResults } from '../../services/get-aws-files/get-aws-file.service.types';
import { PendOrdrStsPutOptions, PendOrdrStsPutResults } from '../../services/products/update-pend-ordr/update-pend-order.service.types';
import { AckShipPutOptions, AckShipPutResults } from '../../services/products/update-ack-ship/update-ack-ship.service.types';
import { GetOrderTxnByIdOptions, GetOrderTxnByIdResults } from '../../services/get-order-txn-by-id/get-order-txn-by-id.service.types';
import { GetListProdImgOptions, GetListProdImgResults } from '../../services/products/get-list-product-images/getList-prodimage.service.types';
import { DeleteProdImgOptions, DeleteProdImgResults } from '../../services/products/delete-prod-image/delete-prodimage.service.types';
import { OEMPutOptions, OEMPutResults } from '../../services/update-oem-profile/update-oem.service.types';
import { DELProdOptions, DELProdResults } from '../../services/products/delete-product/del-api-data/del-prod.service.types';
import { DeleteAWSFolderOptions, DeleteAWSFolderResults } from '../../services/products/delete-product/del-folder-aws-s3/delete-folder.service.types';

export const apiError = createAction(API_ERROR)<ErrorAction>();
export const showData = createAction(SHOW_DATA)<{}>();
export const updateData = createAction(UPDATE_DATA)<string>();
export const contactUsPostReq = createAction(CONTACT_US_DATA)<ContactUsOptions>();
export const updateContactUsPostReq = createAction(UPDATE_CONTACT_US_DATA)<ContactUsResults>();
export const clearContactUsPostReq = createAction(CLEAR_CONTACT_US_DATA)();
export const registerReq = createAction(REGISTER_DATA)<RegisterOptions>();
export const updateRegisterPostReq = createAction(UPDATE_REGISTER_DATA)<RegisterResults>();
export const clearRegisterPostReq = createAction(CLEAR_REGISTER_DATA)();
export const getLoginToken = createAction(GET_LOGIN_TOKEN)<GenTokenOptions>();
export const updateToken = createAction(UPDATE_LOGIN_TOKEN)<GenTokenResults>();
export const clearLoginTokenReq = createAction(CLEAR_LOGIN_TOKEN)();
export const getProfile = createAction(GET_PROFILE)<ProfileOptions>();
export const updateProfile = createAction(UPDATE_PROFILE)<ProfileResults>();
export const clearProfileReq = createAction(CLEAR_PROFILE)();
export const getForgotPassword = createAction(GET_FORGOT_PASSWORD)<ForgotPasswordOptions>();
export const updateForgotPassword = createAction(UPDATE_FORGOT_PASSWORD)<ForgotPasswordResults>();
export const getStateListReq = createAction(GET_STATE_LIST)<ListStateOptions>();
export const updateStateListReq = createAction(UPDATE_STATE_LIST)<ListStateResults>();
export const clearStateListReq = createAction(CLEAR_STATE_LIST)();
export const passKeyReq = createAction(PASS_KEY_DATA)<PassKeyPutOptions>();
export const updatePassKeyReq = createAction(UPDATE_PASS_KEY_DATA)<PassKeyPutResults>();
export const clearPassKeyReq = createAction(CLEAR_PASS_KEY_DATA)();
export const userStatusReq = createAction(USER_STATUS_DATA)<UserStatusPutOptions>();
export const updateUserStatusReq = createAction(UPDATE_USER_STATUS_DATA)<UserStatusPutResults>();
export const clearUserStatusReq = createAction(CLEAR_USER_STATUS_DATA)();
export const getUserListReq = createAction(GET_USER_LIST)<ListUserOptions>();
export const updateUserListReq = createAction(UPDATE_USER_LIST)<ListUserResults>();
export const clearUserListReq = createAction(CLEAR_USER_LIST_DATA)();
export const getDivisionListReq = createAction(GET_DIV_LIST)<ListDivisionOptions>();
export const updateDivisionListReq = createAction(UPDATE_DIV_LIST)<ListDivisionResults>();
export const clearDivisionListReq = createAction(CLEAR_DIV_LIST)();
export const createDivisionReq = createAction(CREATE_DIV)<PostDivisionOptions>();
export const updateCreateDivisionReq = createAction(UPDATE_CREATE_DIV)<PostDivisionResults>();
export const clearCreateDivisionReq = createAction(CLEAR_CREATE_DIV)();
export const createProductReq = createAction(CREATE_PRODUCT)<PostProductOptions>();
export const updateCreateProductReq = createAction(UPDATE_CREATE_PRODUCT)<PostProductResults>();
export const clearCreateProductReq = createAction(CLEAR_CREATE_PRODUCT)();
export const getProductsListReq = createAction(GET_PRODUCTS_LIST)<GetAllProductOptions>();
export const updateProductsListReq = createAction(UPDATE_PRODUCTS_LIST)<GetAllProductResults>();
export const clearProductsListReq = createAction(CLEAR_PRODUCTS_LIST)();
export const uploadProdImg = createAction(UPLOAD_PROD_IMG)<CreateProdImgOptions>();
export const updateUploadProdImg = createAction(UPDATE_UPLOAD_PROD_IMG)<CreateProdImgResults>();
export const clearUploadProdImg = createAction(CLEAR_UPLOAD_PRODUCT)();
export const createAddKartReq = createAction(CREATE_ADD_KART)<PostAddKartOptions>();
export const updateCreateAddKartReq = createAction(UPDATE_CREATE_ADD_KART)<PostAddKartResults>();
export const clearCreateAddKartReq = createAction(CLEAR_CREATE_ADD_KART)();
export const getKartListReq = createAction(GET_KART_LIST)<GetAllKartOptions>();
export const updateKartListReq = createAction(UPDATE_KART_LIST)<GetAllKartResults>();
export const clearKartListReq = createAction(CLEAR_KART_LIST)();
export const getKartCountReq = createAction(GET_KART_COUNT)<GetKartCountOptions>();
export const updateKartCountReq = createAction(UPDATE_KART_COUNT)<GetKartCountResults>();
export const clearKartCountReq = createAction(CLEAR_KART_COUNT)();
export const kartCountPUTReq = createAction(CREATE_KART_COUNT_PUT)<KartCountPutOptions>();
export const updateKartCountPUTReq = createAction(UPDATE_KART_COUNT_PUT)<KartCountPutResults>();
export const clearKartCountPUTReq = createAction(CLEAR_KART_COUNT_PUT)();
export const delKartProdReq = createAction(DEL_KART_PROD)<DELKartProdOptions>();
export const updateDelKartProdReq = createAction(UPDATE_DEL_KART_PROD)<DELKartProdResults>();
export const clearDelKartProdReq = createAction(CLEAR_DEL_KART_PROD)();
export const kartStatusPUTReq = createAction(CREATE_KART_STATUS_PUT)<KartStatusPutOptions>();
export const updateKartStatusPUTReq = createAction(UPDATE_KART_STATUS_PUT)<KartStatusPutResults>();
export const clearKartStatusPUTReq = createAction(CLEAR_KART_STATUS_PUT)();
export const kartTxnIdPUTReq = createAction(CREATE_KART_TXNID_PUT)<KartTxnIdPutOptions>();
export const updateKartTxnIdPUTReq = createAction(UPDATE_KART_TXNID_PUT)<KartTxnIdPutResults>();
export const clearKartTxnIdPUTReq = createAction(CLEAR_KART_TXNID_PUT)();
export const prodDescReq = createAction(PRODUCT_DESC)<ProductDescOptions>();
export const updateProdDescReq = createAction(UPDATE_PRODUCT_DESC)<ProductDescResults>();
export const clearProdDescReq = createAction(CLEAR_PRODUCT_DESC)();
export const myOrdersReq = createAction(MY_ORDERS)<GetAllMyOrdersOptions>();
export const updateMyOrdersReq = createAction(UPDATE_MY_ORDERS)<GetAllMyOrdersResults>();
export const clearMyOrdersReq = createAction(CLEAR_MY_ORDERS)();
export const createSpareReq = createAction(CREATE_SPARE)<PostSpareOptions>();
export const updateCreateSpareReq = createAction(UPDATE_CREATE_SPARE)<PostSpareResults>();
export const clearCreateSpareReq = createAction(CLEAR_CREATE_SPARE)();
export const putProductReq = createAction(PUT_PRODUCT)<ProductPutOptions>();
export const updatePutProductReq = createAction(UPDATE_PUT_PRODUCT)<ProductPutResults>();
export const clearPutProductReq = createAction(CLEAR_PUT_PRODUCT)();
export const getCorpListReq = createAction(GET_CORP_LIST)<ListCorpOptions>();
export const updateCorpListReq = createAction(UPDATE_CORP_LIST)<ListCorpResults>();
export const clearCorpListReq = createAction(CLEAR_CORP_LIST)();
export const sellerRegisterReq = createAction(SELLER_REGISTER_DATA)<SellerRegisterOptions>();
export const updateSellerRegisterPostReq = createAction(UPDATE_SELLER_REGISTER_DATA)<SellerRegisterResults>();
export const clearSellerRegisterPostReq = createAction(CLEAR_SELLER_REGISTER_DATA)();
export const getSellerProductsListReq = createAction(GET_SELLER_PRODUCTS_LIST)<GetAllSellerProductOptions>();
export const updateSellerProductsListReq = createAction(UPDATE_SELLER_PRODUCTS_LIST)<GetAllSellerProductResults>();
export const clearSellerProductsListReq = createAction(CLEAR_SELLER_PRODUCTS_LIST)();
export const getOrdersListReq = createAction(GET_ORDERS_LIST)<GetAllOrdersOptions>();
export const updateOrdersListReq = createAction(UPDATE_ORDERS_LIST)<GetAllOrdersResults>();
export const clearOrdersListReq = createAction(CLEAR_ORDERS_LIST)();
export const addressReq = createAction(ADDRESS_DATA)<AddressPutOptions>();
export const updateAddressReq = createAction(UPDATE_ADDRESS_DATA)<AddressPutResults>();
export const clearAddressReq = createAction(CLEAR_ADDRESS_DATA)();
export const getAWSFileUrlReq = createAction(GET_AWS_FILE_URL)<GetAWSFileUrlOptions>();
export const updateGetAWSFileUrlReq = createAction(UPDATE_GET_AWS_FILE_URL)<GetAWSFileUrlResults>();
export const clearGetAWSFileUrlReq = createAction(CLEAR_GET_AWS_FILE_URL)();
export const putPendOrdrAdmReq = createAction(PUT_PEND_ORDER_STS_ADM)<PendOrdrStsPutOptions>();
export const updatePutPendOrdrAdmReq = createAction(UPDATE_PUT_PEND_ORDER_STS_ADM)<PendOrdrStsPutResults>();
export const clearPutPendOrdrAdmReq = createAction(CLEAR_PUT_PEND_ORDER_STS_ADM)();
export const putAckShipReq = createAction(PUT_ACK_SHIP)<AckShipPutOptions>();
export const updatePutAckShipReq = createAction(UPDATE_PUT_ACK_SHIP)<AckShipPutResults>();
export const clearPutAckShipReq = createAction(CLEAR_PUT_ACK_SHIP)();
export const getOrderTxnByIdReq = createAction(GET_ORDER_TXN_BY_ID)<GetOrderTxnByIdOptions>();
export const updateOrderTxnByIdReq = createAction(UPDATE_ORDER_TXN_BY_ID)<GetOrderTxnByIdResults>();
export const clearOrderTxnByIdReq = createAction(CLEAR_ORDER_TXN_BY_ID)();
export const getListProdImg = createAction(GET_LIST_PROD_IMG)<GetListProdImgOptions>();
export const updateGetListProdImg = createAction(UPDATE_GET_LIST_PROD_IMG)<GetListProdImgResults>();
export const clearGetListProdImg = createAction(CLEAR_GET_LIST_PRODUCT)();
export const deleteProdImg = createAction(DELETE_PROD_IMG)<DeleteProdImgOptions>();
export const updateDeleteProdImg = createAction(UPDATE_DELETE_PROD_IMG)<DeleteProdImgResults>();
export const clearDeleteProdImg = createAction(CLEAR_DELETE_PROD_IMG)();
export const oemReq = createAction(OEM_DATA)<OEMPutOptions>();
export const updateOEMReq = createAction(UPDATE_OEM_DATA)<OEMPutResults>();
export const clearOEMReq = createAction(CLEAR_OEM_DATA)();
export const delProdReq = createAction(DEL_PROD)<DELProdOptions>();
export const updateDelProdReq = createAction(UPDATE_DEL_PROD)<DELProdResults>();
export const clearDelProdReq = createAction(CLEAR_DEL_PROD)();
export const deleteAWSFolder = createAction(DELETE_AWS_FOLDER)<DeleteAWSFolderOptions>();
export const updateDeleteAWSFolder = createAction(UPDATE_DELETE_AWS_FOLDER)<DeleteAWSFolderResults>();
export const clearDeleteAWSFolder = createAction(CLEAR_DELETE_AWS_FOLDER)();
