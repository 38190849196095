import {
  faBolt,
  faPhone,
  faStreetView,
} from '@fortawesome/free-solid-svg-icons';
// faEnvelope, faPhone,
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import {
  Row, Col, Button, Card, CardBody, Modal, ModalBody, CardImg,
  ModalHeader,
  FormGroup,
  Input,
  Label,
} from 'reactstrap';
import { faEdit, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { errorAlert } from '../../../../utils/alert';
import {
  APIHeader, DiscomDistributionOptions, DiscomTransmissionOptions, ListStateOptions,
} from '../../../../utils/constants';
import LoaderData from '../../../../utils/loader';
import { UserProps } from './user.type';
import { UserPutDataInput } from '../../../../services/all-users/update-status/update-status.types';
import {
  clearUserListReq, clearUserStatusReq, getCorpListReq, getUserListReq, userStatusReq,
} from '../../../../store/powerMart/actions';
import { getTokenFromSessionStorage } from '../../../../utils/service/localstorage-service';
import { CorpResult } from '../../../../services/meta-data/list-corp/list-corp.types';
// import { UserResult } from '../../../../services/all-users/user-list/list-user.types';

export const AllUserSegments: React.FC<UserProps> = ({ userData, userTp, tabId }) => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromSessionStorage();
  const [loaderUpdateUser, setLoaderUpdateUser] = React.useState(false);
  const [isApproveConfirmed, setIsApproveConfirmed] = useState(false);
  const [isRejectConfirmed, setIsRejectConfirmed] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [alertMsg, setAlertMsg] = React.useState('');
  const [modalConfirmApprove, setModalConfirmApprove] = useState(false);
  const [modalConfirmReject, setModalConfirmReject] = useState(false);
  const [isSellerEdit, setIsSellerEdit] = React.useState<Record<string, boolean>>({});
  const updateUserPendingStatus = useSelector((state: RootState) => state.powerMart.updateUserStatus);
  const [corpData, setCorpData] = React.useState('');
  const [radioConfirmDupCorpId, setRadioConfirmDupCorpId] = React.useState('');
  const [corpId, setCorpId] = React.useState('');
  const [loaderCorp, setLoaderCorp] = React.useState(false);
  const [corpListData, setCorpListData] = React.useState<CorpResult[]>([]);
  const corpResData = useSelector((state: RootState) => state.powerMart.getCorpMetaList);
  const [pendingUser, setPendingUser] = useState<UserPutDataInput>({
    isAcs: '',
    lgnSts: '',
    userCd: '',
    reason: '',
    usrReqFor: '',
  });

  const handleSelectCorp = (e: any) => {
    setCorpData(e.label);
    setCorpId(e.value);
  };

  const corpOptions = corpListData && corpListData.map((corp) => ({
    label: corp.nme,
    value: corp.MetaId,
  }));

  const selectedCorpOptions = corpOptions && corpOptions.find(
    (option) => option.value === corpData,
  );

  React.useEffect(() => {
    setLoaderCorp(true);
    dispatch(getCorpListReq({
      requestType: APIHeader.REQ_ALL_CORP_META,
    }));
  }, []);

  React.useEffect(() => {
    if (!corpResData.isError) {
      setLoaderCorp(false);
      setCorpListData(corpResData.corpData);
    }
  }, [corpResData.corpData]);

  const handleConfApprove = (userCode: string, userType: string) => {
    setPendingUser({ ...pendingUser, userCd: userCode, usrReqFor: userType });
    setModalConfirmApprove(true);
  };

  const handleConfReject = (userCode: string, userType: string) => {
    setPendingUser({ ...pendingUser, userCd: userCode, usrReqFor: userType });
    setModalConfirmReject(true);
  };

  const handleConfirmApprove = (status: string) => {
    if (status === 'approve') {
      setPendingUser({
        ...pendingUser, isAcs: 'True', lgnSts: 'APPR',
      });
      setIsApproveConfirmed(true);
    } else {
      setIsApproveConfirmed(false);
    }
    setModalConfirmApprove(false);
  };

  const handleConfirmReject = (status: string) => {
    if (status === 'reject') {
      setPendingUser({
        ...pendingUser, isAcs: 'False', lgnSts: 'NACC',
      });
      setIsRejectConfirmed(true);
    } else {
      setIsRejectConfirmed(false);
    }
    setModalConfirmReject(false);
  };
  React.useEffect(() => {
    if (isApproveConfirmed) {
      setLoaderUpdateUser(true);
      Promise.resolve(
        dispatch(clearUserListReq()),
      ).then(() => {
        dispatch(userStatusReq({
          requestType: APIHeader.REQ_UPDATE_USER_STATUS,
          userType: userTp,
          token: tokenData,
          status: pendingUser.lgnSts,
          isAccess: pendingUser.isAcs,
          userCd: pendingUser.userCd,
          usrReqFor: pendingUser.usrReqFor,
          reason: pendingUser.reason,
          corpId,
          isDup: radioConfirmDupCorpId,
        }));
      });
    }
  }, [isApproveConfirmed]);

  React.useEffect(() => {
    if (isRejectConfirmed) {
      setLoaderUpdateUser(true);
      Promise.resolve(
        dispatch(clearUserListReq()),
      ).then(() => {
        dispatch(userStatusReq({
          requestType: APIHeader.REQ_UPDATE_USER_STATUS,
          userType: userTp,
          token: tokenData,
          status: pendingUser.lgnSts,
          isAccess: pendingUser.isAcs,
          userCd: pendingUser.userCd,
          usrReqFor: pendingUser.usrReqFor,
          reason: pendingUser.reason,
          corpId,
          isDup: radioConfirmDupCorpId,
        }));
      });
    }
  }, [isRejectConfirmed]);

  React.useEffect(() => {
    if (updateUserPendingStatus.error && updateUserPendingStatus.message !== '') {
      setLoaderUpdateUser(false);
      setAlertMsg(updateUserPendingStatus.message);
      setErrorShowAlert(true);
    }
  }, [updateUserPendingStatus.error, updateUserPendingStatus.message]);

  React.useEffect(() => {
    if (!updateUserPendingStatus.error && updateUserPendingStatus.message === 'executed') {
      setLoaderUpdateUser(false);
      Promise.resolve(
        dispatch(getUserListReq({
          userType: userTp,
          requestType: APIHeader.REQ_ALL_USER_BY_STATUS,
          status: 'PADM',
          // limit: ApiLIMIT.LIMIT,
          token: tokenData,
          // searchData: '',
        })),
      ).then(() => {
        dispatch(clearUserStatusReq());
      });
    }
  }, [updateUserPendingStatus.error, updateUserPendingStatus.message]);

  const handleSellerEdit = (target: string) => {
    setIsSellerEdit((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
    // userDetail: UserResult
    // setProductUpdateInput({
    //   ...productUpdateInput,
    //   ProdTp: rowData.ProdTp ? rowData.ProdTp : '',
    //   ProdId: rowData.ProdId ? rowData.ProdId : '',
    //   nme: rowData.nme ? rowData.nme : '',
    //   techSpec: technicSpec,
    //   prodDesc: productDescription,
    //   wrnty: rowData.wrnty ? rowData.wrnty : 0,
    //   keyW: rowData.keyW ? rowData.keyW : '',
    //   modlN: rowData.modlN ? rowData.modlN : '',
    //   tagIn: rowData.tagIn ? rowData.tagIn : '',
    //   hsn: rowData.hsn ? rowData.hsn : '',
    //   cost: rowData.cost ? rowData.cost : '',
    //   gst: rowData.gst ? rowData.gst : '',
    // });
  };
  const handleFilterData = (e: any) => {
    setRadioConfirmDupCorpId(e.target.name);
  };

  return (
    <div>
      { loaderUpdateUser ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="my-1 mx-3 pt-2">
        <span className="card-toggle-text">
          {tabId === 'pending' ? 'Pending Users' : tabId === 'approved' ? 'Approved Users' : tabId === 'revoked' ? 'Revoked Access' : null }
        </span>
      </div>
      {/* <Row className="align-center mx-2">
        <Col lg="11" xs="12">
          <InputGroup className="pt-2">
            <Input
              type="text"
              className="powermart-input"
              placeholder="Search pending users...."
            />
          </InputGroup>
        </Col>
      </Row> */}
      <Row className="mx-1">
        {userData && userData.userData && userData.userData.length > 0
          ? userData.userData.map((userDet, index) => (
            <Col className="my-2" lg={{ size: 6, order: 1 }} md={{ size: 6, order: 1 }} xs={{ order: 1 }} key={`pending-${index.toString()}`}>
              <Card className="card-lite">
                <div className="align-end mx-2 pt-2">
                  <span className="user-type-bg">{userDet.UserTp}</span>
                </div>
                <CardBody>
                  <Row>
                    <Col xs="12">
                      <div className="align-start">
                        <div>
                          <CardImg alt="logo" className="img-fluid rounded-circle avatar-cards" src="/images/avatar.png" />
                          <div className="box-user-type pt-2">
                            { ListStateOptions.find((state) => state.value === userDet.st)?.label || ''}
                          </div>
                        </div>
                        <div className="ml-1 pt-1">
                          <div className="align-start">
                            <span className="super-user-name">{userDet.nme}</span>
                          </div>
                          <div className="align-start pt-2">
                            <span className="text-lite-grey">
                              <FontAwesomeIcon icon={faEnvelope} className="mr" />
                              {userDet.eId}
                            </span>
                          </div>
                          <div className="align-start">
                            <span className="text-lite-grey">
                              <FontAwesomeIcon icon={faPhone} className="mr" />
                              +91 {userDet.ph}
                            </span>
                          </div>
                          {userDet.UserTp === 'SELLER'
                            ? (
                              <div className="align-start pt-2">
                                <CardImg
                                  className="seller-corp-logo"
                                  src={userDet.corpData && userDet.corpData.logo ? userDet.corpData.logo : '/images/business.png'}
                                  alt="..."
                                />
                                <span className="text-lite-grey pt-3 mx-2">
                                  {userDet.corpData?.nme}
                                  <Button className="button-icon padding-zero ml mb" onClick={() => handleSellerEdit(`sellerDet-${index}`)}>
                                    <FontAwesomeIcon icon={faEdit} className="wrong-pass-key" />
                                  </Button>
                                </span>
                                <Modal
                                  isOpen={isSellerEdit[`sellerDet-${index}`] || false}
                                  target={`sellerDet-${index}`}
                                >
                                  <ModalHeader toggle={() => handleSellerEdit(`sellerDet-${index}`)} className="form-card-border-none mx-3 my-2">
                                    <span className="text-lite-grey">{userDet.nme}</span>
                                    <span className="user-type-bg ml-1">{userDet.UserTp}</span>
                                    <div>
                                      <span className="text-lite-grey">{userDet.eId}</span>
                                    </div>
                                  </ModalHeader>
                                  <ModalBody className="padding-zero mx-2">
                                    <Row className="mx-1">
                                      <Col lg="12" className="pt-1">
                                        <CardImg
                                          className="seller-corp-logo"
                                          src={userDet.corpData && userDet.corpData.logo ? userDet.corpData.logo : '/images/business.png'}
                                          alt="..."
                                        />
                                        <span className="text-lite-grey pt-3 mx-2">
                                          {userDet.corpData?.nme}
                                        </span>
                                      </Col>
                                      <Col lg="12" className="pt-3">
                                        <h6 className="text-lite-grey mx-2 my-1">Check Corporate Presence<span className="mandate-star">*</span></h6>
                                        <Select
                                          value={corpData === '' ? null : selectedCorpOptions}
                                          options={corpOptions}
                                          onChange={handleSelectCorp}
                                          menuPortalTarget={document.body}
                                          styles={{
                                            menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                                            menu: (base) => ({
                                              ...base,
                                            // position: 'relative',
                                            }),
                                            control: (base: any) => ({
                                              ...base,
                                              '&:hover': { borderColor: '#05cbddff' },
                                              border: '1px solid #BFBFBF',
                                              minHeight: '45px',
                                              borderRadius: '5px',
                                              padding: 1,
                                              textAlign: 'left',
                                              fontSize: '16px',
                                              fontWeight: 400,
                                              color: '#383838',
                                              boxShadow: 'none',
                                              backgroundColor: '#fff',
                                              '&:focus': {
                                                borderColor: '#E1EDF8',
                                                boxShadow: 'none',
                                                color: '#575656',
                                                background: '#fff',
                                              },
                                            }),
                                            option: (provided: any, state: { isSelected: any; }) => ({
                                              ...provided,
                                              color: state.isSelected ? '#383838' : '#212121',
                                              padding: 15,
                                              textAlign: 'left',
                                              background: state.isSelected ? '#FAFCFB' : '#fff',
                                              '&:hover': {
                                                background: '#FAFCFB',
                                              },
                                            }),
                                          }}
                                          isLoading={loaderCorp}
                                          noOptionsMessage={() => 'No Organization Found'}
                                          placeholder="Select Organization"
                                        />
                                      </Col>
                                      <Col lg="12" className="pt-3">
                                        <div className="pt-1">
                                          <span className="listing-price-text">Is Company Duplicate</span>
                                          <div className="pt-2 align-start">
                                            <FormGroup>
                                              <Label check className="cat-check-text">
                                                <Input
                                                  type="radio"
                                                  role="switch"
                                                  name="YES"
                                                  disabled={corpId === ''}
                                                  onChange={handleFilterData}
                                                  checked={radioConfirmDupCorpId === 'YES'}
                                                  className="powermart-check-box mr cursor-pointer"
                                                />
                                                YES
                                              </Label>
                                            </FormGroup>
                                            <FormGroup className="ml-3">
                                              <Label check className="cat-check-text">
                                                <Input
                                                  type="radio"
                                                  role="switch"
                                                  name="NO"
                                                  disabled={corpId === ''}
                                                  onChange={handleFilterData}
                                                  checked={radioConfirmDupCorpId === 'NO'}
                                                  className="powermart-check-box mr cursor-pointer"
                                                />
                                                NO
                                              </Label>
                                            </FormGroup>
                                          </div>
                                          <div className="pt-2 align-start">
                                            <span>{radioConfirmDupCorpId === 'YES' ? 'Since you have confirmed that the company selected by the user is a duplicate, we are removing the corporate key from the database and assigning it to the existing organization'
                                              : radioConfirmDupCorpId === 'NO' ? 'As confirmed no duplicate please go ahead with User addition' : null}
                                            </span>
                                          </div>
                                        </div>
                                      </Col>
                                    </Row>
                                    <div className="pt-4" />
                                  </ModalBody>
                                </Modal>
                              </div>
                            ) : (
                              <div>
                                <div className="align-start pt-2">
                                  <span className="text-lite-grey">
                                    <FontAwesomeIcon icon={faStreetView} className="mr" />
                                    {userDet.div}
                                  </span>
                                </div>
                                <div className="align-start pt-2">
                                  <span className="text-lite-grey">
                                    <FontAwesomeIcon icon={faStreetView} className="mr" />
                                    {userDet.subDiv}
                                  </span>
                                </div>
                              </div>
                            ) }
                        </div>
                      </div>
                    </Col>
                    {userDet.UserTp !== 'SELLER'
                      ? (
                        <Col xs="12">
                          <span className="text-lite-grey my-1">Applied for Discom</span>
                          {userDet.discom && userDet.discom.length > 0
                            ? userDet.discom.map((discom, discomIndex) => (
                              <div key={`discom-${discomIndex.toString()}`} className="ml my-1 align-start">
                                <div className="mr">
                                  <FontAwesomeIcon icon={faBolt} className="prod-tech-spec-icon" />
                                </div>
                                <div>
                                  <span className="discom-liting-text">
                                    { DiscomDistributionOptions.find((state) => state.value === discom.discomUtil)?.label || ''}
                                  </span>
                                  <span className="discom-liting-text">
                                    { DiscomTransmissionOptions.find((state) => state.value === discom.discomUtil)?.label || ''}
                                  </span>
                                </div>
                              </div>
                            )) : (
                              <div className="pt-4 align-center">
                                <span className="form-collapse-card-header">No Record Found</span>
                              </div>
                            ) }
                        </Col>
                      ) : null }
                  </Row>
                  <div className="align-end pt-2">
                    {tabId === 'pending'
                      ? (
                        <div>
                          <Button disabled={userDet.UserTp === 'SELLER' && radioConfirmDupCorpId === ''} className="powermart-button-solid mx-1" onClick={() => handleConfReject(userDet.UserCd, userDet.UserTp)}>
                            <span className="mx-1">Reject</span>
                          </Button>
                          <Button disabled={userDet.UserTp === 'SELLER' && radioConfirmDupCorpId === ''} className="powermart-button-solid mx-1" onClick={() => handleConfApprove(userDet.UserCd, userDet.UserTp)}>
                            <span className="mx-1">Approve</span>
                          </Button>
                        </div>
                      )
                      : tabId === 'approved'
                        ? (
                          <Button className="powermart-button-solid mx-1" onClick={() => handleConfReject(userDet.UserCd, userDet.UserTp)}>
                            <span className="mx-1">REJECT</span>
                          </Button>
                        )
                        : tabId === 'revoked'
                          ? (
                            <Button className="powermart-button-solid mx-1" onClick={() => handleConfApprove(userDet.UserCd, userDet.UserTp)}>
                              <span className="mx-1">APPROVE</span>
                            </Button>
                          ) : null}
                  </div>
                </CardBody>
                <div className="pt-1" />
              </Card>
            </Col>
          )) : <div className="pt-4 align-center"><span className="form-collapse-card-header">No User Found</span></div>}
      </Row>
      <Modal isOpen={modalConfirmApprove}>
        <ModalBody>
          {radioConfirmDupCorpId === 'YES'
            ? (
              <div className="align-center my-3">
                <span className="mx-2 wrong-pass-key">You have chosen to delete the existing user organization and realign it with the new organization ID - {corpData}</span>
              </div>
            ) : null }
          <div className="align-center my-2">
            <span className="mx-2 input-checkbox-text-grey">Do you really want to Approve ?</span>
          </div>
          <div className="align-center my-3">
            <Button className="powermart-button-red mx-1" onClick={() => handleConfirmApprove('cancel')}>
              CANCEL
            </Button>
            <Button className="powermart-button-solid mx-1" onClick={() => handleConfirmApprove('approve')}>
              APPROVE
            </Button>
          </div>
        </ModalBody>
      </Modal>
      <Modal isOpen={modalConfirmReject}>
        <ModalBody>
          <div className="align-center my-4">
            <span className="mx-2 notification-text">Do you really want to Revoke Access ?</span>
          </div>
          <div className="align-center my-3 mx-2">
            <Button className="powermart-button-fullwidth mx-1" onClick={() => handleConfirmReject('cancel')}>
              CANCEL
            </Button>
            <Button className="powermart-button-fullwidth-red mx-1" onClick={() => handleConfirmReject('reject')}>
              REJECT
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};
export default AllUserSegments;
