import { PostAddKartResponse, PostAddKartResults } from './post-addkart.service.types';

export const PostAddKartTransform: (response: PostAddKartResponse) => PostAddKartResults = (createPostResults) => {
  const { data, is_error, message } = createPostResults;
  const result: PostAddKartResults = {
    message: '',
    error: false,
    data: '',
    prodId: '',
  };
  if (is_error) {
    result.error = is_error;
    result.message = message;
  } else {
    result.message = data.message;
    result.prodId = data.id;
  }
  return result;
};
