import React from 'react';
import '../dashboard.scss';
import {
  Row, Col, Card, CardBody, CardImg,
  Input,
  CardHeader,
  Button,
  Spinner,
  Form,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEnvelope, faHeadset, faLocation, faMobileRetro, faPhone,
  faTruckFast,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../../utils/service/localstorage-service';
import { StatusComponentProps } from '../dash.type';
import { APIHeader, DiscomDistributionOptions, DiscomTransmissionOptions } from '../../../../../utils/constants';
import { LoaderData } from '../../../../../utils/loader';
import { getConfig } from '../../../../../utils/config/config';
import { changeDateFormat, errorAlert, successAlert } from '../../../../../utils/alert';
import { OrdersResult } from '../../../../../services/products/power-orders/get-all-orders.types';
import {
  clearGetAWSFileUrlReq,
  clearGetListProdImg,
  clearOrdersListReq,
  clearPutAckShipReq,
  clearPutPendOrdrAdmReq, clearUploadProdImg, getAWSFileUrlReq, getListProdImg, getOrdersListReq,
  putAckShipReq,
  putPendOrdrAdmReq,
  uploadProdImg,
} from '../../../../../store/powerMart/actions';
// import PdfViewer from '../PdfViewer';
import { PendOrdrStsEditData } from '../../../../../services/products/update-pend-ordr/update-pend-order.types';
import { AckShipsEditData } from '../../../../../services/products/update-ack-ship/update-ack-ship.types';
import { FileDetails } from '../../../../../services/products/get-list-product-images/getList-prodimage.types';
import { ProdImgFileInput } from '../../../../../services/products/upload-prod-Image/create-prodimage.types';
import Tooltip from '../../../../../utils/Tooltip/Tooltip';
import Modal from '../../../../../utils/Modal/Modal';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const PaymentAckOrderAdmin: React.FC<StatusComponentProps> = ({ orderSts, orderNme }) => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const [loaderOrder, setLoaderOrder] = React.useState(false);
  const [loaderUpdateOrder, setLoaderUpdateOrder] = React.useState(false);
  const [loaderShipment, setLoaderShipment] = React.useState(false);
  const [loaderUploadEWAYBills, setLoaderUploadEWAYBills] = React.useState(false);
  const [loaderUploadInvoiceBills, setLoaderUploadInvoiceBills] = React.useState(false);
  const [userTp, setUserTp] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [alertMsgFileSize, setAlertMsgFileSize] = React.useState('');
  const [alertMsgInvcFileSize, setAlertMsgInvcFileSize] = React.useState('');
  // const [radioSelected, setRadioSelected] = React.useState('PEND');
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const pendingOrdersResponse = useSelector((state: RootState) => state.powerMart.getOrdersList);
  const [openModalSeller, setOpenModalSeller] = React.useState<Record<string, boolean>>({});
  const [openModalShipment, setOpenModalShipment] = React.useState<Record<string, boolean>>({});
  const [whichIndexApprove, setWhichIndexApprove] = React.useState('');
  const [whichIndexShipment, setWhichIndexShipment] = React.useState('');
  const [isApproveConfirmed, setIsApproveConfirmed] = React.useState<Record<string, boolean>>({});
  const [modalConfirmApprove, setModalConfirmApprove] = React.useState<Record<string, boolean>>({});
  const updateAckShipResponse = useSelector((state: RootState) => state.powerMart.updateAckShip);
  const updatePendOrderStsResponse = useSelector((state: RootState) => state.powerMart.updatePendOrdrByAdm);
  const getProdImageAwsResponse = useSelector((state: RootState) => state.powerMart.getListProductsImageAWS);
  const uploadroductAwsResponse = useSelector((state: RootState) => state.powerMart.uploadProductsAWS);
  const [eWayFileName, setEWayFileName] = React.useState('');
  const [eWayBillFile, setEWayBillFile] = React.useState<ProdImgFileInput>({
    fileData: new FormData(),
  });
  const [invoiceFileName, setInvoiceFileName] = React.useState('');
  const [uploadedFileName, setUploadedFileName] = React.useState('');
  const [orderDetId, setOrderDetId] = React.useState('');
  // const [orderFirstDetId, setOrderFirstDetId] = React.useState('');
  const [poUrlIndex, setPOUrlIndex] = React.useState('');
  const [poUrl, setPOUrl] = React.useState<Record<string, string>>({});
  const getAWSFileUrlRes = useSelector((state: RootState) => state.powerMart.getAWSFileUrl);
  const [invoiceFile, setInvoiceFile] = React.useState<ProdImgFileInput>({
    fileData: new FormData(),
  });
  // const [activId, setActivId] = React.useState('');
  // const [fileUrlStatus, setFileUrlStatus] = React.useState('PO-0');
  // const [fileStstTp, setFileStstTp] = React.useState('PO');
  const [activId, setActivId] = React.useState<string[]>([]);
  // const [fileUrlStatus, setFileUrlStatus] = React.useState<string[]>([]);
  // const [fileStstTp, setFileStstTp] = React.useState<string[]>([]);
  const [pendingOrderData, setPendingOrdersData] = React.useState<OrdersResult[]>([{
    fCnt: 0,
    usrOrg: '',
    usrInfo: {
      addr: '',
      div: '',
      eId: '',
      nme: '',
      ph: '',
      st: '',
      subDiv: '',
    },
    txnId: '',
    txnDt: '',
    ordDt: '',
    sumCost: '',
    sumGst: '',
    sumTotal: '',
    prodTxnData: [],
    ordId: '',
    mSts: '',
  }]);
  const [orderStatusInput, setOrderStatusInput] = React.useState<PendOrdrStsEditData>({
    ActTp: '',
    ActId: '',
    sSts: '',
    mSts: 'TACK',
    rsn: '',
    ordId: '',
    shipDet: [
      {
        nCnt: 0,
        shipDt: '',
      },
    ],
  });
  const [ackShipInput, setAckShipInput] = React.useState<AckShipsEditData>({
    ActTp: '',
    ActId: '',
    txnSts: '',
    courNme: '',
    trckNo: '',
    trckUrl: '',
    estDt: new Date().toISOString().split('T')[0],
  });
  const [shipmentFiles, setShipmentFiles] = React.useState<FileDetails[]>([{
    url: '',
    nme: '',
  }]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  React.useEffect(() => {
    if (userTp !== '') {
      setLoaderOrder(true);
      dispatch(getOrdersListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
        status: orderSts,
      }));
    }
  }, [userTp, tokenData, orderSts]);

  // const handleFilterData = (category: string) => {
  //   // setRadioSelected(category);
  //   setLoaderOrder(true);
  //   dispatch(getOrdersListReq({
  //     userType: userTp,
  //     token: tokenData,
  //     requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
  //     status: category,
  //   }));
  // };

  React.useEffect(() => {
    if (pendingOrdersResponse.isError && pendingOrdersResponse.message !== '') {
      setLoaderOrder(false);
      setAlertMsg(pendingOrdersResponse.message);
      setErrorShowAlert(true);
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message]);

  React.useEffect(() => {
    if (!pendingOrdersResponse.isError && pendingOrdersResponse.message === 'executed') {
      setLoaderOrder(false);
      setPendingOrdersData(pendingOrdersResponse.data);
      // setOrderFirstDetId(pendingOrdersResponse.data[0].ordId);
      dispatch(clearPutPendOrdrAdmReq());
      dispatch(clearOrdersListReq());
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message, orderSts, pendingOrdersResponse.data]);

  const sellerToggleModal = (target: string) => {
    setOpenModalSeller((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleFieldUpdateChange = (e:any) => {
    const fieldName = e.target.name;
    setAckShipInput({ ...ackShipInput, [fieldName]: e.target.value });
  };

  // const handleDownloadWarranty = (activtyId: string, indexData: string) => {
  //   setActivId((prev) => [...prev, activtyId]);
  //   // setFileUrlStatus((prev) => [...prev, `WARRANTY-${indexData}`]);
  //   setFileStstTp((prev) => [...prev, `WARRANTY-${indexData}`]);
  // };
  // React.useEffect(() => {
  //   if (fileStstTp.length === 0 && orderFirstDetId !== '') {
  //     setPOUrlIndex('0');
  //     dispatch(getAWSFileUrlReq({
  //       bucketNme: AWS_PRODUCT_BUCKET,
  //       folderName: `p-orders/${orderFirstDetId}`,
  //       fileNme: `po-${orderFirstDetId}_1.pdf`,
  //       requestType: '',
  //     }));
  //   }
  // }, [fileStstTp.length, orderFirstDetId]);
  // console.log('orderFirstDetId', orderFirstDetId);
  const handleDownloadPOWarranty = (orderId: string, indexData: string, orderTp: string, activityId: string) => {
    setPOUrlIndex(indexData);
    // setFileStstTp((prev) => [...prev, `${indexData}`]);
    dispatch(getAWSFileUrlReq({
      bucketNme: AWS_PRODUCT_BUCKET,
      folderName: `p-orders/${orderId}`,
      fileNme: orderTp === 'po' ? `${orderTp}-${orderId}_1.pdf` : orderTp === 'warranty' ? `${orderTp}-${orderId}_${activityId}_1.pdf` : '',
      requestType: '',
    }));
    // setActivId((prev) => [...prev, activtyId]);
    // setFileUrlStatus((prev) => [...prev, `PO-${indexData}`]);
    // setFileStstTp((prev) => [...prev, `PO-${indexData}`]);
  };

  // GENERATE AWS IMAGE Start
  // const handleShowOrders = (target: string, folderDet: string, pdfUrl: string) => {
  //   setPOUrlIndex(target);
  //   dispatch(getAWSFileUrlReq({
  //     bucketNme: AWS_PRODUCT_BUCKET,
  //     folderName: `p-orders/${folderDet}`,
  //     fileNme: pdfUrl,
  //     requestType: '',
  //   }));
  // };

  React.useEffect(() => {
    if (getAWSFileUrlRes.fileUrl !== '') {
      setPOUrl((prevState) => ({
        ...prevState,
        [poUrlIndex]: getAWSFileUrlRes.fileUrl,
      }));
      dispatch(clearGetAWSFileUrlReq());
    }
  }, [getAWSFileUrlRes.fileUrl]);

  const handleDownload = (url:string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'downloaded-file.pdf';
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  // GENERATE AWS IMAGE End

  const shipmentToggleModal = (target: string, actType: string, actId: string, orderId: string) => {
    dispatch(getListProdImg({
      requestType: '',
      bucketNme: 'powermart',
      folderName: `p-orders/${orderId}`,
    }));
    setAckShipInput((prevState) => ({
      ...prevState,
      ActTp: actType,
      ActId: actId,
      txnSts: 'SHIP',
    }));
    setOpenModalShipment((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleConfApprove = (target: string, orderId: string) => {
    setOrderStatusInput({
      ...orderStatusInput, ordId: orderId, sSts: 'APPR',
    });
    setModalConfirmApprove((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleConfirmApprove = (target: string, status: string) => {
    setWhichIndexApprove(target);
    if (status === 'approve') {
      setIsApproveConfirmed((prevState) => ({
        ...prevState,
        [target]: true,
      }));
    } else {
      setIsApproveConfirmed((prevState) => ({
        ...prevState,
        [target]: false,
      }));
    }
    setModalConfirmApprove((prevState) => ({
      ...prevState,
      [target]: false,
    }));
  };

  React.useEffect(() => {
    if (isApproveConfirmed[whichIndexApprove]) {
      setLoaderUpdateOrder(true);
      Promise.resolve(
        dispatch(putPendOrdrAdmReq({
          userType: userTp,
          requestType: APIHeader.REQ_UPDATE_PND_ORDR_BY_ADM,
          inputBody: orderStatusInput,
          token: tokenData,
        })),
      );
    }
  }, [isApproveConfirmed[whichIndexApprove]]);

  React.useEffect(() => {
    if (updatePendOrderStsResponse.error && updatePendOrderStsResponse.message !== '') {
      setLoaderUpdateOrder(false);
      setAlertMsg(updatePendOrderStsResponse.message);
      setErrorShowAlert(true);
      dispatch(clearPutPendOrdrAdmReq());
    }
  }, [updatePendOrderStsResponse.error, updatePendOrderStsResponse.message]);

  React.useEffect(() => {
    if (!updatePendOrderStsResponse.error && updatePendOrderStsResponse.message !== '') {
      setLoaderUpdateOrder(false);
      setAlertMsg(updatePendOrderStsResponse.message);
      setSuccessShowAlert(true);
      Promise.resolve(
        dispatch(getOrdersListReq({
          userType: userTp,
          token: tokenData,
          requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
          status: 'PCOMP',
        })),
      ).then(() => {
        dispatch(clearPutPendOrdrAdmReq());
      });
    }
  }, [updatePendOrderStsResponse.error, updatePendOrderStsResponse.message]);

  const handleUpdatePackageShipment = (e: any, target: string, status: string) => {
    e.preventDefault();
    setWhichIndexShipment(target);
    if (status === 'approve') {
      Promise.resolve(
        dispatch(putAckShipReq({
          userType: userTp,
          requestType: APIHeader.REQ_UPDATE_ACK_SHIP_BY_ADM,
          inputBody: ackShipInput,
          token: tokenData,
        })),
      );
    }
  };

  React.useEffect(() => {
    if (updateAckShipResponse.error && updateAckShipResponse.message !== '') {
      setLoaderUpdateOrder(false);
      setAlertMsg(updateAckShipResponse.message);
      setErrorShowAlert(true);
      dispatch(clearPutAckShipReq());
    }
  }, [updateAckShipResponse.error, updateAckShipResponse.message]);

  React.useEffect(() => {
    if (!updateAckShipResponse.error && updateAckShipResponse.message !== '') {
      setLoaderUpdateOrder(false);
      setOpenModalShipment((prevState) => ({
        ...prevState,
        [whichIndexShipment]: false,
      }));
      setAlertMsg(updateAckShipResponse.message);
      setSuccessShowAlert(true);
      if (ackShipInput.txnSts === 'SHIP') {
        Promise.resolve(
          dispatch(getOrdersListReq({
            userType: userTp,
            token: tokenData,
            requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
            status: 'TACK',
          })),
        ).then(() => {
          dispatch(clearPutAckShipReq());
        });
      }
    }
  }, [updateAckShipResponse.error, updateAckShipResponse.message, ackShipInput.txnSts, orderSts]);

  const handleCheckEWAY = (eWayBill: FileDetails[], orderId: string, activityId: string) => {
    if (!eWayBill || eWayBill.length === 0) return true;
    const isAnyOtherStatus = eWayBill.some((eway) => eway.nme === `eway-${orderId}_${activityId}_1.pdf`);
    return isAnyOtherStatus;
  };

  const handleCheckInvoice = (invoiceBill: FileDetails[], orderId: string, activityId: string) => {
    if (!invoiceBill || invoiceBill.length === 0) return true;
    const isAnyOtherStatus = invoiceBill.some((invoic) => invoic.nme === `invoice-${orderId}_${activityId}_1.pdf`);
    return isAnyOtherStatus;
  };

  const handleUploadInvoice = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    setAlertMsgInvcFileSize('');
    if (!files || files.length === 0) return;
    const file = files[0];
    const maxSizeInBytes = 3 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      setAlertMsgInvcFileSize('File size exceeds the limit of 3 MB');
      // setAlertMsg('File size exceeds the limit of 2 MB.');
      // setErrorShowAlert(true);
      return;
    }
    const formData = new FormData();
    formData.append(file.name, file);
    setInvoiceFileName(file.name);
    setInvoiceFile({ ...invoiceFile, fileData: formData });
    dispatch(clearUploadProdImg());
  };

  const handleUploadEWay = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    setAlertMsgFileSize('');
    if (!files || files.length === 0) return;
    const file = files[0];
    const maxSizeInBytes = 3 * 1024 * 1024;
    if (file.size > maxSizeInBytes) {
      setAlertMsgFileSize('File size exceeds the limit of 3 MB');
      // setAlertMsg('File size exceeds the limit of 2 MB.');
      // setErrorShowAlert(true);
      return;
    }
    const formData = new FormData();
    formData.append(file.name, file);
    setEWayFileName(file.name);
    setEWayBillFile({ ...eWayBillFile, fileData: formData });
    dispatch(clearUploadProdImg());
  };

  const handleSubmitEWayBill = (orderId: String, activityId: string) => {
    setLoaderUploadEWAYBills(true);
    setUploadedFileName(`eway-${orderId}_${activityId}`);
    dispatch(clearGetListProdImg());
    setOrderDetId(`${orderId}`);
    setActivId((prev) => [...prev, activityId]);
    dispatch(uploadProdImg({
      bucketNme: AWS_PRODUCT_BUCKET,
      folderName: `p-orders/${orderId}`,
      fileNmeInit: `eway-${orderId}_${activityId}`,
      formData: eWayBillFile.fileData,
      requestType: '',
      isEdit: false,
    }));
  };

  const handleSubmitInvoice = (orderId: String, activityId: string) => {
    setLoaderUploadInvoiceBills(true);
    dispatch(clearGetListProdImg());
    setUploadedFileName(`invoice-${orderId}_${activityId}`);
    setOrderDetId(`${orderId}`);
    setActivId((prev) => [...prev, activityId]);
    dispatch(uploadProdImg({
      bucketNme: AWS_PRODUCT_BUCKET,
      folderName: `p-orders/${orderId}`,
      fileNmeInit: `invoice-${orderId}_${activityId}`,
      formData: invoiceFile.fileData,
      requestType: '',
      isEdit: false,
    }));
  };

  React.useEffect(() => {
    const pattern = new RegExp(`^p-orders/${orderDetId}/${uploadedFileName}_.+\\.pdf$`);
    if (pattern.test(uploadroductAwsResponse.fileName) && userTp !== '') {
      setLoaderUploadEWAYBills(false);
      setLoaderUploadInvoiceBills(false);
      setInvoiceFile({ ...invoiceFile, fileData: new FormData() });
      setEWayFileName('');
      setInvoiceFileName('');
      dispatch(getListProdImg({
        requestType: '',
        bucketNme: 'powermart',
        folderName: `p-orders/${orderDetId}`,
      }));
      dispatch(clearUploadProdImg());
    }
  }, [uploadroductAwsResponse.fileName, userTp]);

  React.useEffect(() => {
    if (getProdImageAwsResponse.data.length !== 0) {
      setLoaderShipment(false);
      setShipmentFiles(getProdImageAwsResponse.data);
      handleCheckInvoice(shipmentFiles, orderDetId, activId[0]);
      handleCheckEWAY(shipmentFiles, orderDetId, activId[0]);
    }
  }, [getProdImageAwsResponse.data, activId[0], orderDetId]);

  return (
    <div>
      {loaderOrder ? <LoaderData /> : null}
      {loaderShipment ? <LoaderData /> : null}
      {loaderUpdateOrder ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row className="align-center">
        <Col xs="12">
          <Row className="pt-2 mx-1">
            <Col lg="6" xs="6">
              <div className="pt-3 align-start">
                <span className="meta-waranty-lite">{orderNme}</span>
                {/* <span className="meta-waranty-lite">{radioSelected === 'PEND' ? 'Pending Transactions' : radioSelected === 'PCOMP' ? 'Completed Transactions' : 'ALL TRANSACTIONS'}</span> */}
              </div>
            </Col>
            <Col lg="6" xs="6">
              <div className="pt-3 align-end">
                <span className="meta-waranty-lite">Order Count: {pendingOrderData.length}</span>
              </div>
            </Col>
            <hr />
          </Row>
        </Col>
        <Col xs="12">
          {/* <div className="align-end">
            <div className="mx-3">
              <FormGroup>
                <Label check className="dash-txn-check">
                  <Input
                    type="radio"
                    role="switch"
                    onClick={() => handleFilterData('PEND')}
                    checked={radioSelected === 'PEND'}
                    className="powermart-check-box mr cursor-pointer"
                  />
                  PENDING
                </Label>
              </FormGroup>
            </div>
            <div className="mx-3">
              <FormGroup>
                <Label check className="dash-txn-check">
                  <Input
                    type="radio"
                    role="switch"
                    onClick={() => handleFilterData('PCOMP')}
                    checked={radioSelected === 'PCOMP'}
                    className="powermart-check-box mr mb cursor-pointer"
                  />
                  COMPLETED
                </Label>
              </FormGroup>
            </div>
            <div className="mx-3">
              <FormGroup>
                <Label check className="dash-txn-check">
                  <Input
                    type="radio"
                    role="switch"
                    onClick={() => handleFilterData('TACK')}
                    checked={radioSelected === 'TACK'}
                    className="powermart-check-box mr mb cursor-pointer"
                  />
                  ACK
                </Label>
              </FormGroup>
            </div>
          </div> */}
          <Row className="pt-2">
            <Col lg="12">
              {pendingOrderData && pendingOrderData.length > 0
                ? pendingOrderData.map((orderDet, index) => (
                  <div key={`order-txn-${index.toString()}`} className="my-1">
                    <Card className="dash-card">
                      <Row>
                        <Col lg="7" xs="12">
                          <CardHeader>
                            <Row>
                              <div className="my-1 align-start">
                                <span className="super-dashboard-header mr">Buyer Information:</span>
                                <span className="order-id-text">{orderDet.ordId}<span className="mx-3 super-dashboard-header">|</span>{changeDateFormat(orderDet.ordDt)}</span>
                              </div>
                              <Col xs="12">
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-name">{orderDet.usrInfo?.nme} ({orderDet.usrOrg})</span>
                                </div>
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-sub-name">{ DiscomDistributionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                                  <span className="super-dashboard-sub-name">{ DiscomTransmissionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                                </div>
                              </Col>
                              <Col xs="6" lg="6">
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-sub-name">
                                    <FontAwesomeIcon icon={faEnvelope} className="mr" />
                                    {orderDet.usrInfo?.eId}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="6" lg="6">
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-sub-name">
                                    <FontAwesomeIcon icon={faPhone} className="mr" />
                                    +91 {orderDet.usrInfo?.ph}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="12" lg="12">
                                <div className="pt-2 align-start">
                                  <span className="super-dashboard-sub-name">
                                    <FontAwesomeIcon icon={faLocation} className="mr" />
                                    {orderDet.usrInfo?.addr}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="padding-zero ml">
                            <Row className="pt-1">
                              {orderDet.txnId !== ''
                                ? (
                                  <Col xs="12">
                                    <div className="align-start my-1">
                                      <span className="super-dashboard-sub-name mx-1">Txn Details : </span>
                                      <span className="order-id-text">{orderDet.txnId}<span className="mx-3 super-dashboard-header">|</span>{changeDateFormat(orderDet.txnDt)}</span>
                                    </div>
                                  </Col>
                                ) : null}
                            </Row>
                            <div className="pt-2">
                              <span className="super-dashboard-sub-name">Total Products: {orderDet.fCnt}</span>
                            </div>
                            <Row className="pt-2">
                              {orderDet && orderDet.prodTxnData.length > 0
                                ? orderDet.prodTxnData.map((allOrders, orderIndex) => (
                                  <Col xs="12" key={`allOrder-txn-${orderIndex.toString()}`} className="my-1">
                                    <Card className={allOrders.sSts === 'REJCT' ? 'card-lite-opaq' : 'card-lite'}>
                                      <Row>
                                        <Col xs="12">
                                          <div className="align-start ml">
                                            <span className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : 'card-toggle-text'}>{allOrders.prodInfo.nme}</span>
                                          </div>
                                        </Col>
                                        <Col xs="12">
                                          <Row>
                                            <Col lg="2" xs="4">
                                              <div className="align-start my-2">
                                                <div className="image-container-prod-power ml">
                                                  <CardImg src={allOrders.prodInfo.imgUrl} alt="Prod Image" className="dash-product-image-prod-power" />
                                                  {allOrders.sSts !== '' && allOrders.sSts === 'REJCT' && (
                                                  <div className="overlay-prod-power">
                                                    <CardImg src="/images/stop.png" alt="Prod Image" className="image-reject-prod-power" />
                                                  </div>
                                                  )}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg="10" xs="8">
                                              <Row className="ml pt-2">
                                                <Col lg="12" xs="12">
                                                  <Row>
                                                    <Col lg="4" xs="4">
                                                      <div className="align-start mx-1 pt-3">
                                                        <span className="text-lite-grey">Each Price</span>
                                                      </div>
                                                      <div className="align-start mx-1 my-2">
                                                        <span className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                                          {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(allOrders.prodInfo.cost))}
                                                        </span>
                                                      </div>
                                                    </Col>
                                                    <Col lg="4" xs="4">
                                                      <div className="align-start mx-2 pt-3">
                                                        <span className="text-lite-grey">Quantity</span>
                                                      </div>
                                                      <div className="align-start mx-2">
                                                        <Input
                                                          placeholder="Quantity"
                                                          type="number"
                                                          disabled
                                                          min="0"
                                                          name="cnt"
                                                          defaultValue={allOrders.prodCnt || ''}
                                                          className="powermart-input"
                                                        />
                                                      </div>
                                                    </Col>
                                                    <Col lg="4" xs="4">
                                                      <div className="align-end mx-1 pt-3">
                                                        <span className="text-lite-grey">Total</span>
                                                      </div>
                                                      <div className="align-end mx-1 my-2">
                                                        <span className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                                          {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(allOrders.prodCost))}
                                                        </span>
                                                      </div>
                                                    </Col>
                                                  </Row>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col xs="12">
                                          <div className="ml">
                                            {allOrders.rsn !== '' ? <span className="wrong-pass-key">Reason: {allOrders.rsn}</span> : null }
                                          </div>
                                        </Col>
                                        <Col lg="7" xs="12">
                                          {allOrders.shpRdy === 'YES'
                                            ? (
                                              <div className="align-start">
                                                <Button className="powermart-button-link-red" onClick={() => shipmentToggleModal(`shipConfirm-${index}-${orderIndex}`, allOrders.ActTp, allOrders.ActId, orderDet.ordId)}>
                                                  <FontAwesomeIcon icon={faTruckFast} className="mx-2" />
                                                  <span className="text-bold-grey"> : {allOrders.shipDt !== '' ? changeDateFormat(allOrders.shipDt) : 'NA'}</span>
                                                </Button>
                                                <span className="mx-3 mb-1 text-lite-grey">|</span>
                                                <Button className="powermart-button-link-red mb" onClick={() => handleDownloadPOWarranty(orderDet.ordId, `${index}`, 'warranty', allOrders.ActId)}>
                                                  Warranty
                                                </Button>
                                                <span className="mx-3 mb-1 text-lite-grey">|</span>
                                                <Button className="powermart-button-link-red mb" onClick={() => handleDownloadPOWarranty(orderDet.ordId, `${index}`, 'po', allOrders.ActId)}>
                                                  PO
                                                </Button>
                                              </div>
                                            ) : null}
                                          {allOrders.shpRdy === '' && allOrders.shipDt !== ''
                                            ? <span className="text-bold-grey ml">Est Shipment : {changeDateFormat(allOrders.shipDt)}</span> : null}
                                          <Modal
                                            show={openModalShipment[`shipConfirm-${index}-${orderIndex}`] || false}
                                            disCancel={false}
                                            targetId={`shipConfirm-${index}-${orderIndex}`}
                                            onClose={() => shipmentToggleModal(`shipConfirm-${index}-${orderIndex}`, allOrders.ActTp, allOrders.ActId, orderDet.ordId)}
                                            title={(
                                              <div className="my-2">
                                                <CardImg src={allOrders.prodInfo.imgUrl} alt="Seller Image" className="shiping-image-modal" />
                                                <span className="ml">{allOrders.prodInfo.nme}</span>
                                              </div>
                                            )}
                                            size="XL"
                                          >
                                            <div className="my-2">
                                              <Row className="my-3 align-center mx-2">
                                                <Col xs="9" lg="10">
                                                  <div className="upload-box">
                                                    <Input
                                                      type="file"
                                                      accept=".pdf"
                                                      name="fileData"
                                                      disabled={handleCheckInvoice(shipmentFiles, orderDet.ordId, allOrders.ActId)}
                                                      className="upload-box-input"
                                                      onChange={(e) => handleUploadInvoice(e)}
                                                    />
                                                    {alertMsgInvcFileSize !== ''
                                                      ? <span className="wrong-pass-key">{alertMsgInvcFileSize}</span>
                                                      : eWayFileName !== '' && alertMsgInvcFileSize === ''
                                                        ? <span className="register-file-link-lite-active">INVOICE UPLOADED</span>
                                                        : <span className="register-file-link">UPLOAD INVOICE</span>}
                                                  </div>
                                                </Col>
                                                <Col xs="3" lg="2">
                                                  <div className="align-end">
                                                    {handleCheckInvoice(shipmentFiles, orderDet.ordId, allOrders.ActId)
                                                      ? (
                                                        <Button disabled className="button-icon padding-zero">
                                                          VERIFIED <FontAwesomeIcon icon={faCircleCheck} className="correct-pass-key" />
                                                        </Button>
                                                      )
                                                      : (
                                                        <Button disabled={invoiceFileName === '' || loaderUploadEWAYBills} className="button-icon padding-zero" onClick={() => handleSubmitInvoice(orderDet.ordId, allOrders.ActId)}>
                                                          SUBMIT
                                                          {loaderUploadInvoiceBills ? <><Spinner type="grow" className="spin-grow-loader ml" /><Spinner type="grow" className="spin-grow-loader" /><Spinner type="grow" className="spin-grow-loader" /></> : null}
                                                        </Button>
                                                      ) }
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Row className="my-3 align-center mx-2">
                                                <Col xs="9" lg="10">
                                                  <div className="upload-box">
                                                    <Input
                                                      type="file"
                                                      accept=".pdf"
                                                      name="fileData"
                                                      disabled={handleCheckEWAY(shipmentFiles, orderDet.ordId, allOrders.ActId)}
                                                      className="upload-box-input"
                                                      onChange={(e) => handleUploadEWay(e)}
                                                    />
                                                    {alertMsgFileSize !== ''
                                                      ? <span className="wrong-pass-key">{alertMsgFileSize}</span>
                                                      : eWayFileName !== '' && alertMsgFileSize === ''
                                                        ? <span className="register-file-link-lite-active">EWay Bill UPLOADED</span>
                                                        : <span className="register-file-link">UPLOAD EWay Bill</span>}
                                                  </div>
                                                </Col>
                                                <Col xs="3" lg="2">
                                                  <div className="align-end">
                                                    {handleCheckEWAY(shipmentFiles, orderDet.ordId, allOrders.ActId)
                                                      ? (
                                                        <Button disabled className="button-icon padding-zero">
                                                          VERIFIED <FontAwesomeIcon icon={faCircleCheck} className="correct-pass-key" />
                                                        </Button>
                                                      )
                                                      : (
                                                        <Button disabled={eWayFileName === '' || loaderUploadInvoiceBills} className="button-icon padding-zero" onClick={() => handleSubmitEWayBill(orderDet.ordId, allOrders.ActId)}>
                                                          SUBMIT
                                                          {loaderUploadEWAYBills ? <><Spinner type="grow" className="spin-grow-loader ml" /><Spinner type="grow" className="spin-grow-loader" /><Spinner type="grow" className="spin-grow-loader" /></> : null}
                                                        </Button>
                                                      ) }
                                                  </div>
                                                </Col>
                                              </Row>
                                              <Form onSubmit={(e) => handleUpdatePackageShipment(e, `shipConfirm-${index}-${orderIndex}`, 'approve')}>
                                                <Row className="my-3 mx-2">
                                                  <Col xs="12" lg="5">
                                                    <div className="align-start">
                                                      <h6 className="text-lite-grey mx-2 my-1">
                                                        Estimated Shipment Date<span className="mandate-star">*</span>
                                                      </h6>
                                                    </div>
                                                    <Input
                                                      type="date"
                                                      name="estDt"
                                                      min={new Date().toISOString().split('T')[0]}
                                                      value={ackShipInput.estDt || ''}
                                                      onChange={handleFieldUpdateChange}
                                                      className="powermart-input"
                                                    />
                                                  </Col>
                                                  <Col xs="12" lg="7">
                                                    <div className="align-start">
                                                      <h6 className="text-lite-grey mx-2 my-1">Tracking Number<span className="mandate-star">*</span></h6>
                                                    </div>
                                                    <Input
                                                      placeholder="Enter Tracking Detail"
                                                      type="text"
                                                      required
                                                      name="trckNo"
                                                      value={ackShipInput.trckNo}
                                                      onChange={handleFieldUpdateChange}
                                                      className="powermart-input"
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row className="my-3 mx-2">
                                                  <Col xs="12" lg="5">
                                                    <div className="align-start">
                                                      <h6 className="text-lite-grey mx-2 my-1">Courier From<span className="mandate-star">*</span></h6>
                                                    </div>
                                                    <Input
                                                      placeholder="Courier Vendor"
                                                      type="text"
                                                      required
                                                      name="courNme"
                                                      value={ackShipInput.courNme}
                                                      onChange={handleFieldUpdateChange}
                                                      className="powermart-input"
                                                    />
                                                  </Col>
                                                  <Col xs="12" lg="7">
                                                    <div className="align-start">
                                                      <h6 className="text-lite-grey mx-2 my-1">Tracking URL<span className="mandate-star">*</span></h6>
                                                    </div>
                                                    <Input
                                                      placeholder="Enter Tracking URL"
                                                      type="text"
                                                      required
                                                      name="trckUrl"
                                                      value={ackShipInput.trckUrl}
                                                      onChange={handleFieldUpdateChange}
                                                      className="powermart-input"
                                                    />
                                                  </Col>
                                                </Row>
                                                <Row className="my-3 mx-2 align-center">
                                                  <Col xs="12" lg="9">
                                                    <div className="my-1 pt-3">
                                                      <Button disabled={!handleCheckEWAY(shipmentFiles, orderDet.ordId, allOrders.ActId) || !handleCheckInvoice(shipmentFiles, orderDet.ordId, allOrders.ActId)} className="powermart-button-fullwidth mx-1">
                                                        SUBMIT
                                                      </Button>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              </Form>
                                            </div>
                                          </Modal>
                                        </Col>
                                        <Col xs="12" lg="5">
                                          <div className="align-end mx-2">
                                            {allOrders.sSts === ''
                                              ? <span className="text-bold-grey">In-Progress</span>
                                              : (allOrders.sSts === 'SAPPR' || allOrders.sSts === 'SUPDT') && allOrders.shpRdy !== ''
                                                ? (
                                                  <span className="correct-pass-key">Package Packed
                                                    {allOrders.sSts === 'SUPDT' ? <span className="wrong-pass-key">(M)</span> : null }
                                                  </span>
                                                )
                                                : (allOrders.sSts === 'SAPPR' || allOrders.sSts === 'SUPDT') && allOrders.shpRdy === '' && (orderDet.mSts === 'PCOMP' || orderDet.mSts === 'TACK')
                                                  ? (
                                                    <span className="wrong-pass-key">Package Pending
                                                      {allOrders.sSts === 'SUPDT' ? <span className="wrong-pass-key">(M)</span> : null }
                                                    </span>
                                                  )
                                                  : (allOrders.sSts === 'SAPPR' || allOrders.sSts === 'SUPDT') && allOrders.shpRdy === '' && orderDet.mSts === 'PEND'
                                                    ? (
                                                      <span className="wrong-pass-key">Payment Pending
                                                        {allOrders.sSts === 'SUPDT' ? <span className="wrong-pass-key">(M)</span> : null }
                                                      </span>
                                                    )
                                                    : allOrders.sSts === 'REJCT'
                                                      ? <span className="wrong-pass-key">Rejected</span>
                                                      : null}
                                            <span className="mx-2 text-lite-grey">|</span>
                                            <Tooltip
                                              content="CHAT/CALL WITH SELLER"
                                              position="top"
                                              customClassName={`chatSeller-${orderIndex}`}
                                            >
                                              <Button className="button-icon padding-zero" id={`chatSeller-${orderIndex}`} onClick={() => sellerToggleModal(`sellerDet-${index}-${orderIndex}`)}>
                                                <FontAwesomeIcon icon={faHeadset} className="dash-seller-chat-icon" />
                                              </Button>
                                            </Tooltip>
                                            <Modal
                                              show={openModalSeller[`sellerDet-${index}-${orderIndex}`] || false}
                                              targetId={`sellerDet-${index}-${orderIndex}`}
                                              disCancel={false}
                                              onClose={() => sellerToggleModal(`sellerDet-${index}-${orderIndex}`)}
                                              title={(
                                                <div className="align-start">
                                                  <CardImg src={allOrders.prodInfo.tagInfo.logo} alt="Seller Image" className="seller-image-icon" />
                                                  <span className="dash-seller-chat-icon ml">{allOrders.prodInfo.tagInfo.nme}</span>
                                                </div>
                                              )}
                                              size="MD"
                                            >
                                              <div className="my-1 align-start">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faUserCircle} className="text-lite-grey mx-2" />
                                                  {allOrders.prodInfo.tagInfo.cntNme}
                                                </span>
                                              </div>
                                              <div className="my-1 align-start">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faEnvelope} className="text-lite-grey mx-2" />
                                                  {allOrders.prodInfo.tagInfo.cntEId}
                                                </span>
                                              </div>
                                              <div className="my-1 align-start">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faPhone} className="text-lite-grey mx-2" />
                                                  +91 {allOrders.prodInfo.tagInfo.cntPh1}
                                                </span>
                                              </div>
                                              <div className="my-1 align-start">
                                                <span className="powermart-dark-icon">
                                                  <FontAwesomeIcon icon={faMobileRetro} className="text-lite-grey mx-2" />
                                                  {allOrders.prodInfo.tagInfo.cntPh2}
                                                </span>
                                              </div>
                                              <div className="my-1 align-start">
                                                <span className="text-lite-grey mx-2">Designation:</span>
                                                <span className="powermart-dark-icon">
                                                  {allOrders.prodInfo.tagInfo.Desg}
                                                </span>
                                              </div>
                                            </Modal>
                                          </div>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                )) : (
                                  <div className="pt-4 align-center">
                                    <span className="form-collapse-card-header">No Orders avilable</span>
                                  </div>
                                )}
                            </Row>
                            <Row className="pt-3">
                              <hr className="dash-hr-white" />
                              {/* {orderDet.sSts !== '' && orderDet.sSts === 'ONHLD'
                                ? (
                                  <Col xs="12">
                                    <div className="text-center-aligned my-2">
                                      <span className="super-dashboard-on-hold">Reason: {orderDet.rsn}</span>
                                    </div>
                                  </Col>
                                ) : null}
                              {orderDet.sSts !== '' && orderDet.sSts === 'ONHLD'
                                ? (
                                  <Col xs="3" lg="3">
                                    <div className="align-center ml-2">
                                      <CardImg src="/images/stop.png" alt="Prod Image" className="avatar-cards" />
                                    </div>
                                    <div className="align-center ml-2 pt-2">
                                      <span className="super-dashboard-name">On HOLD</span>
                                    </div>
                                  </Col>
                                ) : null} */}
                              <Col xs="12">
                                <div className="align-end my-1">
                                  <span className="super-dashboard-sub-name mx-1">Product Sum : </span>
                                  <span className="super-dashboard-name ml">
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumCost))}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="12">
                                <div className="align-end my-1">
                                  <span className="super-dashboard-sub-name mx-1">GST Sum(18%) : </span>
                                  <span className="super-dashboard-name ml">
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumGst))}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="12">
                                <div className="align-end my-1">
                                  <span className="super-dashboard-sub-name mx-1">Sum Total : </span>
                                  <span className="super-dashboard-name ml">
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumTotal))}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                            <Col xs="12">
                              <div className="align-end my-1 pt-3">
                                {orderDet.mSts === 'PCOMP' ? (
                                  <Button
                                    className="powermart-button-red ml-1"
                                    onClick={() => handleConfApprove(`orderApprove-${index}`, orderDet.ordId)}
                                  >
                                    CONFIRM PAYMENT
                                  </Button>
                                ) : null}
                              </div>
                              <Modal
                                show={modalConfirmApprove[`orderApprove-${index}`] || false}
                                disCancel={false}
                                targetId={`orderApprove-${index}`}
                                onClose={() => handleConfirmApprove(`orderApprove-${index}`, 'cancel')}
                                title="Do you really want to Approve ?"
                                size="MD"
                              >
                                <div className="align-center my-3">
                                  <Button className="powermart-button-red mx-2" onClick={() => handleConfirmApprove(`orderApprove-${index}`, 'cancel')}>
                                    CANCEL
                                  </Button>
                                  <Button className="powermart-button-solid mx-2" onClick={() => handleConfirmApprove(`orderApprove-${index}`, 'approve')}>
                                    APPROVE
                                  </Button>
                                </div>
                              </Modal>
                            </Col>
                          </CardBody>
                        </Col>
                        <Col lg="5" xs="12">
                          {poUrl[index] === undefined
                            ? (
                              <div className="mx-2 pt-5">
                                <span className="text-show-white-po">Choose one of the option <br />to show file details<br /><br />(PO/Warranty)</span><br />
                                {/* <div className="pt-2">
                                  <Button className="button-icon" onClick={() => handleShowOrders(`${index.toString()}`, orderDet.ordId, `po-${orderDet.ordId}_1.pdf`)}>
                                    <FontAwesomeIcon icon={faArrowCircleDown} className="text-show-white-po" />
                                  </Button>
                                </div> */}
                              </div>
                            )
                            : (
                              <object
                                id={`order-PDF-${index.toString()}`}
                                data={poUrl[`${index.toString()}`]}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{ display: 'block' }}
                              >
                                <div className="mx-2">
                                  <p className="dash-card-header my-3 mx-3">This browser does not support embedded PDFs. You can download the PDF
                                    <Button onClick={() => handleDownload(poUrl[`${index.toString()}`])} className="button-icon padding-zero ml mb-1">here</Button>.
                                  </p>
                                </div>
                              </object>
                            )}
                          {/* {orderDet.ordId !== ''
                            ? <PdfViewer key={`order-PDF-${index.toString()}`} pdfUrl={`po-${orderDet.ordId}_1.pdf`} folderDet={orderDet.ordId} indexDet={index.toString()} />
                            : null} */}
                        </Col>
                        {/* <Col lg="5" xs="12">
                          {orderDet.ordId !== '' && fileStstTp[index] === `PO-${index}`
                            ? <PdfViewer pdfUrl={`po-${orderDet.ordId}_1.pdf`} folderDet={orderDet.ordId} indexDet={fileUrlStatus[index]} />
                            : null}
                          {orderDet.ordId !== '' && fileStstTp[index] === `WARRANTY-${index}`
                            ? <PdfViewer pdfUrl={`warranty-${orderDet.ordId}_${activId}_1.pdf`} folderDet={orderDet.ordId} indexDet={fileUrlStatus[index]} />
                            : null}
                        </Col> */}
                      </Row>
                    </Card>
                  </div>
                ))
                : (
                  <div className="pt-4 align-center">
                    <span className="form-collapse-card-header">No Transactions Found</span>
                  </div>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default PaymentAckOrderAdmin;
