import React from 'react';
import '../dashboard.scss';
import {
  Row, Col, Card, CardBody, CardImg,
  Input,
  CardHeader,
  Button,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faArrowUpRightFromSquare,
  faCertificate,
  faEnvelope, faHeadset, faLocation, faMobileRetro, faPhone,
} from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck, faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../../utils/service/localstorage-service';
import { StatusComponentProps } from '../dash.type';
import { APIHeader, DiscomDistributionOptions, DiscomTransmissionOptions } from '../../../../../utils/constants';
import { LoaderData } from '../../../../../utils/loader';
import { changeDateFormat, errorAlert, successAlert } from '../../../../../utils/alert';
import { OrdersResult } from '../../../../../services/products/power-orders/get-all-orders.types';
import {
  clearGetAWSFileUrlReq,
  clearOrdersListReq,
  clearPutAckShipReq,
  clearPutPendOrdrAdmReq, getAWSFileUrlReq, getOrdersListReq,
  putAckShipReq,
} from '../../../../../store/powerMart/actions';
import { getConfig } from '../../../../../utils/config/config';
// import PdfViewer from '../PdfViewer';
import { AckShipsEditData } from '../../../../../services/products/update-ack-ship/update-ack-ship.types';
import Modal from '../../../../../utils/Modal/Modal';
import Tooltip from '../../../../../utils/Tooltip/Tooltip';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const InShipment: React.FC<StatusComponentProps> = ({ orderSts, orderNme }) => {
  const dispatch = useDispatch();
  const userInitTp = getUserProfileSessionStorage();
  const tokenData = getTokenFromSessionStorage();
  const [loaderOrder, setLoaderOrder] = React.useState(false);
  const [userTp, setUserTp] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  // const [radioSelected, setRadioSelected] = React.useState('SHPP');
  const [showSuccessAlert, setSuccessShowAlert] = React.useState(false);
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const pendingOrdersResponse = useSelector((state: RootState) => state.powerMart.getOrdersList);
  // const getAWSFileUrlRes = useSelector((state: RootState) => state.powerMart.getAWSFileUrl);
  const [openModalSeller, setOpenModalSeller] = React.useState<Record<string, boolean>>({});
  // const [activId, setActivId] = React.useState('');
  // const [orderId, setOrderId] = React.useState('');
  // const [fileUrlStatus, setFileUrlStatus] = React.useState('PO-0');
  // const [fileStstTp, setFileStstTp] = React.useState('PO');
  const [loaderUpdateOrder, setLoaderUpdateOrder] = React.useState(false);
  const [whichIndexApprove, setWhichIndexApprove] = React.useState('');
  const [poUrlIndex, setPOUrlIndex] = React.useState('');
  const [poUrl, setPOUrl] = React.useState<Record<string, string>>({});
  const getAWSFileUrlRes = useSelector((state: RootState) => state.powerMart.getAWSFileUrl);
  const updateAckShipResponse = useSelector((state: RootState) => state.powerMart.updateAckShip);
  // const [isApproveConfirmed, setIsApproveConfirmed] = React.useState<Record<string, boolean>>({});
  const [modalConfirmApprove, setModalConfirmApprove] = React.useState<Record<string, boolean>>({});
  const [modalApproveWarranty, setModalAproveWarranty] = React.useState<Record<string, boolean>>({});
  const [ackShipInput, setAckShipInput] = React.useState<AckShipsEditData>({
    ActTp: '',
    ActId: '',
    txnSts: '',
    courNme: '',
    trckNo: '',
    trckUrl: '',
    estDt: new Date().toISOString().split('T')[0],
  });
  const [pendingOrderData, setPendingOrdersData] = React.useState<OrdersResult[]>([{
    fCnt: 0,
    usrOrg: '',
    usrInfo: {
      addr: '',
      div: '',
      eId: '',
      nme: '',
      ph: '',
      st: '',
      subDiv: '',
    },
    txnId: '',
    txnDt: '',
    ordDt: '',
    sumCost: '',
    sumGst: '',
    sumTotal: '',
    prodTxnData: [],
    ordId: '',
    mSts: '',
  }]);
  //   const [shipmentFiles, setShipmentFiles] = React.useState<FileDetails[]>([{
  //     url: '',
  //     nme: '',
  //   }]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userTp, userInitTp && JSON.parse(userInitTp).profileData.UserTp]);

  React.useEffect(() => {
    if (userTp !== '') {
      setLoaderOrder(true);
      dispatch(getOrdersListReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
        status: orderSts,
      }));
    }
  }, [userTp, tokenData, orderSts]);

  // const handleFilterData = (category: string) => {
  //   setRadioSelected(category);
  //   setLoaderOrder(true);
  //   dispatch(getOrdersListReq({
  //     userType: userTp,
  //     token: tokenData,
  //     requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
  //     status: category,
  //   }));
  // };

  React.useEffect(() => {
    if (pendingOrdersResponse.isError && pendingOrdersResponse.message !== '') {
      setLoaderOrder(false);
      setAlertMsg(pendingOrdersResponse.message);
      setErrorShowAlert(true);
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message, orderSts]);

  React.useEffect(() => {
    if (!pendingOrdersResponse.isError && pendingOrdersResponse.message === 'executed') {
      setLoaderOrder(false);
      setPendingOrdersData(pendingOrdersResponse.data);
      dispatch(clearPutPendOrdrAdmReq());
      dispatch(clearOrdersListReq());
    }
  }, [pendingOrdersResponse.isError, pendingOrdersResponse.message, orderSts, pendingOrdersResponse.data]);

  const sellerToggleModal = (target: string) => {
    setOpenModalSeller((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleDownloadPOWarranty = (orderId: string, indexData: string, orderTp: string, activityId: string) => {
    setPOUrlIndex(indexData);
    dispatch(getAWSFileUrlReq({
      bucketNme: AWS_PRODUCT_BUCKET,
      folderName: `p-orders/${orderId}`,
      fileNme: orderTp === 'po'
        ? `${orderTp}-${orderId}_1.pdf`
        : orderTp === 'eway' || orderTp === 'invoice' || orderTp === 'warranty'
          ? `${orderTp}-${orderId}_${activityId}_1.pdf`
          : '',
      requestType: '',
    }));
  };

  // GENERATE AWS IMAGE Start
  React.useEffect(() => {
    if (getAWSFileUrlRes.fileUrl !== '') {
      setPOUrl((prevState) => ({
        ...prevState,
        [poUrlIndex]: getAWSFileUrlRes.fileUrl,
      }));
      dispatch(clearGetAWSFileUrlReq());
    }
  }, [getAWSFileUrlRes.fileUrl]);

  const handleDownload = (url:string) => {
    const anchor = document.createElement('a');
    anchor.href = url;
    anchor.download = 'downloaded-file.pdf';
    anchor.target = '_blank';
    document.body.appendChild(anchor);
    anchor.click();
    document.body.removeChild(anchor);
  };

  // GENERATE AWS IMAGE End

  // const handleDownloadEWay = (activtyId: string, indexData: string) => {
  //   setActivId(`${activtyId}`);
  //   // setOrderId(`${orderDetId}`);
  //   setFileUrlStatus(`EWAY-${indexData}`);
  //   setFileStstTp('EWAY');
  // };

  // const handleDownloadInvoice = (activtyId: string, indexData: string) => {
  //   setActivId(`${activtyId}`);
  //   // setOrderId(`${orderDetId}`);
  //   setFileUrlStatus(`INVOICE-${indexData}`);
  //   setFileStstTp('INVOICE');
  // };

  // const handleDownloadPO = (indexData: string) => {
  //   setFileUrlStatus(`PO-${indexData}`);
  //   setFileStstTp('PO');
  // };

  // const handleDownloadWarranty = (activtyId: string, indexData: string) => {
  //   setActivId(`${activtyId}`);
  //   // setOrderId(`${orderDetId}`);
  //   setFileUrlStatus(`WARRANTY-${indexData}`);
  //   setFileStstTp('WARRANTY');
  // };

  const handleConfApprove = (target: string, accTp: string, accId: string) => {
    setAckShipInput({
      ...ackShipInput, ActTp: accTp, ActId: accId, txnSts: 'SHIPCOMP',
    });
    setModalConfirmApprove((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleWarrantyApprove = (target: string, accTp: string, accId: string) => {
    setAckShipInput({
      ...ackShipInput, ActTp: accTp, ActId: accId, txnSts: 'WARRANTY',
    });
    setModalAproveWarranty((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  const handleConfirmApprove = (target: string, status: string) => {
    setWhichIndexApprove(target);
    if (status === 'approve') {
      Promise.resolve(
        dispatch(putAckShipReq({
          userType: userTp,
          requestType: APIHeader.REQ_UPDATE_ACK_SHIP_BY_ADM,
          inputBody: ackShipInput,
          token: tokenData,
        })),
      );
    } else {
      setModalConfirmApprove((prevState) => ({
        ...prevState,
        [target]: false,
      }));
    }
    setModalConfirmApprove((prevState) => ({
      ...prevState,
      [target]: false,
    }));
  };

  const handleConfirmWarranty = (target: string, status: string) => {
    if (status === 'approve') {
      Promise.resolve(
        dispatch(putAckShipReq({
          userType: userTp,
          requestType: APIHeader.REQ_UPDATE_ACK_SHIP_BY_ADM,
          inputBody: ackShipInput,
          token: tokenData,
        })),
      );
    } else {
      setModalAproveWarranty((prevState) => ({
        ...prevState,
        [target]: false,
      }));
    }
    setModalAproveWarranty((prevState) => ({
      ...prevState,
      [target]: false,
    }));
  };

  // React.useEffect(() => {
  //   if (isApproveConfirmed[whichIndexApprove] && radioSelected === 'SHPP') {
  //     setLoaderUpdateOrder(true);
  //     Promise.resolve(
  //       dispatch(putAckShipReq({
  //         userType: userTp,
  //         requestType: APIHeader.REQ_UPDATE_ACK_SHIP_BY_ADM,
  //         inputBody: ackShipInput,
  //         token: tokenData,
  //       })),
  //     );
  //   }
  // }, [isApproveConfirmed[whichIndexApprove], radioSelected]);

  React.useEffect(() => {
    if (updateAckShipResponse.error && updateAckShipResponse.message !== '') {
      setLoaderUpdateOrder(false);
      setAlertMsg(updateAckShipResponse.message);
      setErrorShowAlert(true);
      dispatch(clearPutAckShipReq());
    }
  }, [updateAckShipResponse.error, updateAckShipResponse.message]);

  React.useEffect(() => {
    if (!updateAckShipResponse.error && updateAckShipResponse.message !== '') {
      setLoaderUpdateOrder(false);
      setAlertMsg(updateAckShipResponse.message);
      setSuccessShowAlert(true);
      setModalConfirmApprove((prevState) => ({
        ...prevState,
        [whichIndexApprove]: false,
      }));
      if (ackShipInput.txnSts === 'SHIPCOMP') {
        Promise.resolve(
          dispatch(getOrdersListReq({
            userType: userTp,
            token: tokenData,
            requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
            status: 'SHPP',
          })),
        ).then(() => {
          setWhichIndexApprove('');
        }).then(() => {
          dispatch(clearPutAckShipReq());
        });
      } else if (ackShipInput.txnSts === 'WARRANTY') {
        Promise.resolve(
          dispatch(getOrdersListReq({
            userType: userTp,
            token: tokenData,
            requestType: APIHeader.REQ_GET_ALL_PROD_TXN_FOR_SADM_SELR,
            status: 'SHPC',
          })),
        ).then(() => {
          dispatch(clearPutAckShipReq());
        });
      }
    }
  }, [updateAckShipResponse.error, updateAckShipResponse.message, ackShipInput.txnSts, orderSts]);

  return (
    <div>
      {loaderOrder ? <LoaderData /> : null}
      {loaderUpdateOrder ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      {showSuccessAlert ? (
        successAlert(false, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      <Row className="align-center">
        <Col xs="12">
          <Row className="pt-2 mx-1">
            <Col lg="6" xs="6">
              <div className="pt-3 align-start">
                <span className="meta-waranty-lite">{orderNme}</span>
                {/* <span className="meta-waranty-lite">{radioSelected === 'PEND' ? 'Pending Transactions' : radioSelected === 'PCOMP' ? 'Completed Transactions' : 'ALL TRANSACTIONS'}</span> */}
              </div>
            </Col>
            <Col lg="6" xs="6">
              <div className="pt-3 align-end">
                <span className="meta-waranty-lite">Order Count: {pendingOrderData.length}</span>
              </div>
            </Col>
            <hr />
          </Row>
        </Col>
        <Col xs="12">
          {/* <div className="align-end mr-2">
            <div className="mr-1">
              <FormGroup>
                <Label check className="dash-txn-check">
                  <Input
                    type="radio"
                    role="switch"
                    onClick={() => handleFilterData('SHPP')}
                    checked={radioSelected === 'SHPP'}
                    className="powermart-check-box mr cursor-pointer"
                  />
                  IN-PROGRESS
                </Label>
              </FormGroup>
            </div>
            <div className="ml-1">
              <FormGroup>
                <Label check className="dash-txn-check">
                  <Input
                    type="radio"
                    role="switch"
                    onClick={() => handleFilterData('SHPC')}
                    checked={radioSelected === 'SHPC'}
                    className="powermart-check-box mr mb cursor-pointer"
                  />
                  COMPLETED
                </Label>
              </FormGroup>
            </div>
          </div> */}
          <Row className="pt-2">
            <Col lg="12">
              {pendingOrderData && pendingOrderData.length > 0
                ? pendingOrderData.map((orderDet, index) => (
                  <div key={`order-txn-${index.toString()}`} className="my-1">
                    <Card className="dash-card">
                      <Row>
                        <Col lg="7" xs="12">
                          <CardHeader>
                            <Row>
                              <div className="my-1 align-start">
                                <span className="super-dashboard-header mr">Buyer Information:</span>
                                <span className="order-id-text">{orderDet.ordId}<span className="mx-2 super-dashboard-header">|</span>{changeDateFormat(orderDet.ordDt)}</span>
                              </div>
                              <Col xs="12">
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-name">{orderDet.usrInfo?.nme} ({orderDet.usrOrg})</span>
                                </div>
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-sub-name">{ DiscomDistributionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                                  <span className="super-dashboard-sub-name">{ DiscomTransmissionOptions.find((state) => state.value === orderDet.usrOrg)?.label || ''}</span>
                                </div>
                              </Col>
                              <Col xs="6" lg="6">
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-sub-name">
                                    <FontAwesomeIcon icon={faEnvelope} className="mr" />
                                    {orderDet.usrInfo?.eId}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="6" lg="6">
                                <div className="my-1 align-start">
                                  <span className="super-dashboard-sub-name">
                                    <FontAwesomeIcon icon={faPhone} className="mr" />
                                    +91 {orderDet.usrInfo?.ph}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="12" lg="12">
                                <div className="pt-2 align-start">
                                  <span className="super-dashboard-sub-name">
                                    <FontAwesomeIcon icon={faLocation} className="mr" />
                                    {orderDet.usrInfo?.addr}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody className="padding-zero ml">
                            <div className="pt-2">
                              <span className="super-dashboard-sub-name">Total Products: {orderDet.fCnt}</span>
                            </div>
                            <Row className="pt-2">
                              {orderDet && orderDet.prodTxnData.length > 0
                                ? orderDet.prodTxnData.map((allOrders, orderIndex) => (
                                  <Col xs="12" key={`allOrder-txn-${orderIndex.toString()}`} className="my-1">
                                    <Card className={allOrders.sSts === 'REJCT' ? 'card-lite-opaq' : 'card-lite'}>
                                      <Row>
                                        <Col xs="12">
                                          <div className="align-center my-1">
                                            <div className="align-center">
                                              <Button className="powermart-button-link mb" onClick={() => handleDownloadPOWarranty(orderDet.ordId, `${index}`, 'po', allOrders.ActId)}>
                                                <span className="transit-button-display-mob">PO</span>
                                                <span className="transit-button-display-desk">Purchase Order</span>
                                              </Button>
                                            </div>
                                            <span className="mx-3 mb-1 text-lite-grey">|</span>
                                            <div className="align-center">
                                              <Button className="powermart-button-link" onClick={() => handleDownloadPOWarranty(orderDet.ordId, `${index}`, 'eway', allOrders.ActId)}>
                                                <span className="transit-button-display-mob">EWAY</span>
                                                <span className="transit-button-display-desk">EWay Bill</span>
                                              </Button>
                                            </div>
                                            <span className="mx-3 mb-1 text-lite-grey">|</span>
                                            <div className="align-center">
                                              <Button className="powermart-button-link mb" onClick={() => handleDownloadPOWarranty(orderDet.ordId, `${index}`, 'invoice', allOrders.ActId)}>
                                                Invoice
                                              </Button>
                                            </div>
                                            <span className="mx-3 mb-1 text-lite-grey">|</span>
                                            <div className="align-center">
                                              <Button className="powermart-button-link mb" onClick={() => handleDownloadPOWarranty(orderDet.ordId, `${index}`, 'warranty', allOrders.ActId)}>
                                                <span className="transit-button-display-mob">Warranty</span>
                                                <span className="transit-button-display-desk">Warranty</span>
                                              </Button>
                                            </div>
                                          </div>
                                        </Col>
                                        <Col xs="12">
                                          <div className="align-start ml">
                                            <span className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : 'card-toggle-text'}>{allOrders.prodInfo.nme}</span>
                                          </div>
                                        </Col>
                                        <Col xs="12">
                                          <Row>
                                            <Col lg="2" xs="12">
                                              <div className="align-center my-2 ml">
                                                <div className="image-container-prod-power pt-2">
                                                  <CardImg src={allOrders.prodInfo.imgUrl} alt="Prod Image" className="dash-product-image-prod-power" />
                                                  {allOrders.sSts !== '' && allOrders.sSts === 'REJCT' && (
                                                  <div className="overlay-prod-power">
                                                    <CardImg src="/images/stop.png" alt="Prod Image" className="image-reject-prod-power" />
                                                  </div>
                                                  )}
                                                </div>
                                              </div>
                                            </Col>
                                            <Col lg="10" xs="12">
                                              <Row className="pt-1">
                                                <Col lg="4" xs="4">
                                                  <div className="align-start mx-1 pt-3">
                                                    <span className="text-lite-grey">Each Price</span>
                                                  </div>
                                                  <div className="align-start mx-1 my-2">
                                                    <span className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                                      {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(allOrders.prodInfo.cost))}
                                                    </span>
                                                  </div>
                                                </Col>
                                                <Col lg="4" xs="4">
                                                  <div className="align-start mx-2 pt-3">
                                                    <span className="text-lite-grey">Quantity</span>
                                                  </div>
                                                  <div className="align-start mx-2">
                                                    <Input
                                                      placeholder="Quantity"
                                                      type="number"
                                                      disabled
                                                      min="0"
                                                      name="cnt"
                                                      defaultValue={allOrders.prodCnt || ''}
                                                      className="powermart-input"
                                                    />
                                                  </div>
                                                </Col>
                                                <Col lg="4" xs="4">
                                                  <div className="align-end mx-1 pt-3">
                                                    <span className="text-lite-grey">Total</span>
                                                  </div>
                                                  <div className="align-end mx-1 my-2">
                                                    <span className={allOrders.sSts === 'REJCT' ? 'text-lite-grey' : ''}>
                                                      {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(allOrders.prodCost))}
                                                    </span>
                                                  </div>
                                                </Col>
                                              </Row>
                                            </Col>
                                          </Row>
                                        </Col>
                                        <Col xs="12">
                                          <Row className="my-2">
                                            <Col lg="8" xs="12">
                                              <div className="align-start pt-2">
                                                {/* {allOrders.sSts === ''
                                                  ? <span className="text-bold-grey">In-Progress</span>
                                                  : allOrders.sSts === 'SAPPR' || allOrders.sSts === 'SUPDT'
                                                    ? (
                                                      <span className="correct-pass-key">Approved
                                                        {allOrders.sSts === 'SUPDT' ? <span className="wrong-pass-key">(M)</span> : null }
                                                      </span>
                                                    )
                                                    : allOrders.sSts === 'REJCT'
                                                      ? <span className="wrong-pass-key">Rejected</span>
                                                      : null}
                                                <span className="mx-2 text-lite-grey">|</span> */}
                                                <Button
                                                  className="powermart-button-link"
                                                  href={allOrders.trckUrl}
                                                  target="_blank"
                                                >
                                                  {allOrders.trckNo} <FontAwesomeIcon icon={faArrowUpRightFromSquare} className="upward-font-ship mb mx-1" />
                                                </Button>
                                                <span className="mx-2 text-lite-grey">|</span>
                                                <Tooltip
                                                  content="CHAT/CALL WITH SELLER"
                                                  position="top"
                                                  customClassName={`chatSeller-${orderIndex}`}
                                                >
                                                  <Button className="button-icon padding-zero mb" id={`chatSeller-${orderIndex}`} onClick={() => sellerToggleModal(`sellerDet-${index}-${orderIndex}`)}>
                                                    <FontAwesomeIcon icon={faHeadset} className="dash-seller-chat-icon" />
                                                  </Button>
                                                </Tooltip>
                                                <Modal
                                                  show={openModalSeller[`sellerDet-${index}-${orderIndex}`] || false}
                                                  disCancel={false}
                                                  targetId={`sellerDet-${index}-${orderIndex}`}
                                                  onClose={() => sellerToggleModal(`sellerDet-${index}-${orderIndex}`)}
                                                  title={(
                                                    <div className="align-start">
                                                      <CardImg src={allOrders.prodInfo.tagInfo.logo} alt="Seller Image" className="seller-image-icon mx-2" />
                                                      <span className="dash-seller-chat-icon">{allOrders.prodInfo.tagInfo.nme}</span>
                                                    </div>
                                                  )}
                                                  size="MD"
                                                >
                                                  <div className="my-1 align-start ml">
                                                    <span className="powermart-dark-icon">
                                                      <FontAwesomeIcon icon={faUserCircle} className="text-lite-grey mx-2" />
                                                      {allOrders.prodInfo.tagInfo.cntNme}
                                                    </span>
                                                  </div>
                                                  <div className="my-1 align-start ml">
                                                    <span className="powermart-dark-icon">
                                                      <FontAwesomeIcon icon={faEnvelope} className="text-lite-grey mx-2" />
                                                      {allOrders.prodInfo.tagInfo.cntEId}
                                                    </span>
                                                  </div>
                                                  <div className="my-1 align-start ml">
                                                    <span className="powermart-dark-icon">
                                                      <FontAwesomeIcon icon={faPhone} className="text-lite-grey mx-2" />
                                                      +91 {allOrders.prodInfo.tagInfo.cntPh1}
                                                    </span>
                                                  </div>
                                                  <div className="my-1 align-start ml">
                                                    <span className="powermart-dark-icon">
                                                      <FontAwesomeIcon icon={faMobileRetro} className="text-lite-grey mx-2" />
                                                      {allOrders.prodInfo.tagInfo.cntPh2}
                                                    </span>
                                                  </div>
                                                  <div className="my-1 align-start ml">
                                                    <span className="text-lite-grey mx-2">Designation:</span>
                                                    <span className="powermart-dark-icon">
                                                      {allOrders.prodInfo.tagInfo.Desg}
                                                    </span>
                                                  </div>
                                                </Modal>
                                              </div>
                                            </Col>
                                            <Col lg="4" xs="12">
                                              <div className="align-end">
                                                {orderDet.mSts === 'SHPC'
                                                  ? (
                                                    <div className="pt-1">
                                                      <span className="correct-pass-key pt-2">Shipped<FontAwesomeIcon icon={faCircleCheck} className="mx-1" /></span>
                                                      <span className="mx-2 text-lite-grey">|</span>
                                                      <Tooltip
                                                        content="Submit Warranty"
                                                        position="top"
                                                        customClassName={`warrantyReq-${index}-${orderIndex}`}
                                                      >
                                                        <Button className="button-icon padding-zero" id={`warrantyReq-${index}-${orderIndex}`} onClick={() => handleWarrantyApprove(`warrantyApprove-${index}-${orderIndex}`, allOrders.ActTp, allOrders.ActId)}>
                                                          <FontAwesomeIcon icon={faCertificate} className="dash-seller-cert-icon" />
                                                        </Button>
                                                      </Tooltip>
                                                      <Modal
                                                        show={modalApproveWarranty[`warrantyApprove-${index}-${orderIndex}`] || false}
                                                        disCancel
                                                        targetId={`warrantyApprove-${index}-${orderIndex}`}
                                                        onClose={() => handleConfirmWarranty(`warrantyApprove-${index}-${orderIndex}`, 'cancel')}
                                                        title=""
                                                        size="SM"
                                                      >
                                                        <div className="text-center-aligned my-3 mx-2">
                                                          <span className="mx-2 input-checkbox-text-grey">Please confirm to release warranty. Do you want to approve?</span>
                                                        </div>
                                                        <div className="align-center my-3">
                                                          <Button className="powermart-button-red mx-1" onClick={() => handleConfirmWarranty(`warrantyApprove-${index}-${orderIndex}`, 'cancel')}>
                                                            CANCEL
                                                          </Button>
                                                          <Button className="powermart-button-solid mx-1" onClick={() => handleConfirmWarranty(`warrantyApprove-${index}-${orderIndex}`, 'approve')}>
                                                            APPROVE
                                                          </Button>
                                                        </div>
                                                      </Modal>
                                                    </div>
                                                  )
                                                  : null}
                                                {orderDet.mSts === 'SHPP'
                                                  ? (
                                                    <Button className="powermart-button-red padding-zero" onClick={() => handleConfApprove(`shipApprove-${index}-${orderIndex}`, allOrders.ActTp, allOrders.ActId)}>
                                                      <span className="transit-button-display-desk mx-2 my-1">SHIPMENT <FontAwesomeIcon icon={faCircleCheck} className="dash-seller-chat-icon1" /></span>
                                                      <span className="transit-button-display-mob mx-2 my-1"><FontAwesomeIcon icon={faCircleCheck} className="dash-seller-chat-icon1" /></span>
                                                    </Button>
                                                  ) : null }
                                                <Modal
                                                  show={modalConfirmApprove[`shipApprove-${index}-${orderIndex}`] || false}
                                                  disCancel
                                                  targetId={`shipApprove-${index}-${orderIndex}`}
                                                  onClose={() => handleConfirmApprove(`shipApprove-${index}-${orderIndex}`, 'cancel')}
                                                  title=""
                                                  size="SM"
                                                >
                                                  <div className="text-center-aligned my-4 mx-3">
                                                    <span className="mx-2 input-checkbox-text-grey">Please confirm that the shipment has been completed. <br /><br />Do you want to approve?</span>
                                                  </div>
                                                  <div className="align-center my-3">
                                                    <Button className="powermart-button-red mx-1" onClick={() => handleConfirmApprove(`shipApprove-${index}-${orderIndex}`, 'cancel')}>
                                                      CANCEL
                                                    </Button>
                                                    <Button className="powermart-button-solid mx-1" onClick={() => handleConfirmApprove(`shipApprove-${index}-${orderIndex}`, 'approve')}>
                                                      APPROVE
                                                    </Button>
                                                  </div>
                                                </Modal>
                                              </div>
                                            </Col>
                                          </Row>
                                        </Col>
                                      </Row>
                                    </Card>
                                  </Col>
                                ))
                                : (
                                  <div className="pt-4 align-center">
                                    <span className="form-collapse-card-header">No Orders avilable</span>
                                  </div>
                                )}
                            </Row>
                            <Row className="pt-3">
                              <hr className="dash-hr-white" />
                              {/* {orderDet.sSts !== '' && orderDet.sSts === 'ONHLD'
                                ? (
                                  <Col xs="12">
                                    <div className="text-center-aligned my-2">
                                      <span className="super-dashboard-on-hold">Reason: {orderDet.rsn}</span>
                                    </div>
                                  </Col>
                                ) : null}
                              {orderDet.sSts !== '' && orderDet.sSts === 'ONHLD'
                                ? (
                                  <Col xs="3" lg="3">
                                    <div className="align-center ml-2">
                                      <CardImg src="/images/stop.png" alt="Prod Image" className="avatar-cards" />
                                    </div>
                                    <div className="align-center ml-2 pt-2">
                                      <span className="super-dashboard-name">On HOLD</span>
                                    </div>
                                  </Col>
                                ) : null} */}
                              <Col xs="12">
                                <div className="align-end my-1">
                                  <span className="super-dashboard-sub-name mx-1">Product Sum : </span>
                                  <span className="super-dashboard-name ml">
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumCost))}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="12">
                                <div className="align-end my-1">
                                  <span className="super-dashboard-sub-name mx-1">GST Sum(18%) : </span>
                                  <span className="super-dashboard-name ml">
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumGst))}
                                  </span>
                                </div>
                              </Col>
                              <Col xs="12">
                                <div className="align-end my-1">
                                  <span className="super-dashboard-sub-name mx-1">Sum Total : </span>
                                  <span className="super-dashboard-name ml">
                                    {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumTotal))}
                                  </span>
                                </div>
                              </Col>
                            </Row>
                          </CardBody>
                        </Col>
                        <Col lg="5" xs="12">
                          {poUrl[index] === undefined
                            ? (
                              <div className="mx-2 pt-5">
                                <span className="text-show-white-po">Choose one of the option <br />to show file details<br /><br />(PO/Warranty)</span><br />
                              </div>
                            )
                            : (
                              <object
                                id={`order-PDF-${index.toString()}`}
                                data={poUrl[`${index.toString()}`]}
                                type="application/pdf"
                                width="100%"
                                height="100%"
                                style={{ display: 'block' }}
                              >
                                <div className="mx-2">
                                  <p className="dash-card-header my-3 mx-3">This browser does not support embedded PDFs. You can download the PDF
                                    <Button onClick={() => handleDownload(poUrl[`${index.toString()}`])} className="button-icon padding-zero ml mb-1">here</Button>.
                                  </p>
                                </div>
                              </object>
                            )}
                          {/* {orderDet.ordId !== ''
                            ? <PdfViewer key={`order-PDF-${index.toString()}`} pdfUrl={`po-${orderDet.ordId}_1.pdf`} folderDet={orderDet.ordId} indexDet={index.toString()} />
                            : null} */}
                        </Col>
                        {/* <Col lg="5" xs="12">
                          {fileStstTp === 'PO'
                            ? <PdfViewer pdfUrl={`po-${orderDet.ordId}_1.pdf`} folderDet={orderDet.ordId} indexDet={fileUrlStatus} />
                            : fileStstTp === 'EWAY'
                              ? <PdfViewer pdfUrl={`eway-${orderDet.ordId}_${activId}_1.pdf`} folderDet={orderDet.ordId} indexDet={fileUrlStatus} />
                              : fileStstTp === 'INVOICE'
                                ? <PdfViewer pdfUrl={`invoice-${orderDet.ordId}_${activId}_1.pdf`} folderDet={orderDet.ordId} indexDet={fileUrlStatus} />
                                : fileStstTp === 'WARRANTY'
                                  ? <PdfViewer pdfUrl={`warranty-${orderDet.ordId}_${activId}_1.pdf`} folderDet={orderDet.ordId} indexDet={fileUrlStatus} />
                                  : null}
                        </Col> */}
                      </Row>
                    </Card>
                  </div>
                ))
                : (
                  <div className="pt-4 align-center">
                    <span className="form-collapse-card-header">No Transactions Found</span>
                  </div>
                )}
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default InShipment;
