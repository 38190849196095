import React from 'react';
import Scroller from '../../../utils/horz-scroller/hroz-scroll';

export const ProductInfo: React.FC = () => {
  const slides = [
    {
      image: '/images/prod-info/powermart-1.jpg',
      headerText: 'SINGLE PHASE TAMPER SIMULATOR',
      mainText: 'Three-Phase 4-Wire configuration with a nominal voltage of 415V±10% Line-to-Line (L-L) at a frequency of 50Hz+/-5%',
      subText: 'Adjustable output voltage within the range of 0-270V using an auto transformer with fine control',
    },
    {
      image: '/images/prod-info/powermart-2.jpg',
      headerText: 'SINGLE PHASE SEMI AUTOMATIC ENERGY METER TEST SYSTEM 40 POSITION',
      mainText: 'Power supply 415V(L-L)±10% ,50Hz±2Hz',
      subText: 'Resolution Better than ± 0.01% Indication Mains ON indication lamps for R-Y-B phase. Voltmeter : 750 V AC',
    },
    {
      image: '/images/prod-info/powermart-3.png',
      headerText: 'SHORT TIME OVER CURRENT INJECTION TEST SETUP',
      mainText: 'Two Phase 2 Wire 11KV ± 10%, L-L, 48-52 Hz',
      subText: 'Secondary Voltage : 240V, 288V selectable at output',
    },
    {
      image: '/images/prod-info/powermart-4.png',
      headerText: 'VOLTAGE DIPS & INTERRUPTION TESTER',
      mainText: 'The equipment is designed for Single Phase 2 Wire 240 ± 10%, 48-52 Hz power supply',
      subText: '1 Output Power Voltage 1x30VA at 300V & 2 Output Voltage Range 50V .....300V',
    },
    {
      image: '/images/prod-info/powermart-5.png',
      headerText: 'MAGNETIC INFLUENCE TEST SETUP',
      mainText: 'AC DC Magnet Test Setup for Single Phase and Three phase smart meter',
      subText: 'Suitable for test for influence quantities as per CBIP 325, IS158844 & IS16444. Can be tested at 0.5mT. Accuracy +/- 5%',
    },
    {
      image: '/images/prod-info/powermart-6.png',
      headerText: '35KV SPARK DISCHARGE TESTER',
      mainText: 'Spark Gap : Generally at lab condition the spark obtained at a distance of 1mm is 3KV : Max Spark obtained from a GAP of 10 -11mm the voltage generated is approximately 35 KV',
      subText: 'Output Voltage : 35KV defined by setting the Gap between probes and earth',
    },
    {
      image: '/images/prod-info/powermart-7.png',
      headerText: 'VOLTAGE DIPS & INTERRUPTION TESTER',
      mainText: 'The equipment is designed for Single Phase 2 Wire 240 ± 10%, 48-52 Hz power supply',
      subText: '1 Output Power Voltage 1x30VA at 300V2 Output Voltage Range 50V .....300V',
    },
    {
      image: '/images/prod-info/powermart-8.jpg',
      headerText: 'THREE PHASE TAMPER SIMULATOR 332 CONDITIONS',
      mainText: 'Supply Voltage: Input mains power supply Three Phase 4 Wire 440V ± 10% L-N, 48-52 Hz.',
      subText: 'Output Current: Load through resistors, inductors & capacitors. Current 5A, 10A, UPF 0.5 Lag & 0.5 LEAD – R Y B Phases',
    },
    {
      image: '/images/prod-info/powermart-9.jpg',
      headerText: 'DC EVEN HARMONICS',
      mainText: 'Output Voltage : 35KV defined by setting the Gap between probes and earth',
      subText: 'Spark Gap : Generally at lab condition the spark obtained at a distance of 1mm is 3KV : Max Spark obtained from a GAP of 10 -11mm the voltage generated is approximately 35 KV',
    },
  ];

  return (
    <div className="pt-4 my-2">
      <Scroller slides={slides} />
      <div className="my-5" />
    </div>
  );
};
export default ProductInfo;
