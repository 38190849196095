import { combineEpics, Epic } from 'redux-observable';
import {
  isActionOf, RootAction, RootState, Services,
} from 'typesafe-actions';
import { from } from 'rxjs';
import {
  filter, map, mergeMap, catchError,
} from 'rxjs/operators';
import {
  addressReq,
  apiError, contactUsPostReq, createAddKartReq, createDivisionReq, createProductReq, createSpareReq, deleteAWSFolder, deleteProdImg, delKartProdReq, delProdReq, getAWSFileUrlReq, getCorpListReq, getDivisionListReq, getForgotPassword, getKartCountReq, getKartListReq, getListProdImg, getLoginToken, getOrdersListReq, getOrderTxnByIdReq, getProductsListReq, getProfile, getSellerProductsListReq, getStateListReq, getUserListReq, kartCountPUTReq, kartStatusPUTReq, kartTxnIdPUTReq, myOrdersReq, oemReq, passKeyReq, prodDescReq, putAckShipReq, putPendOrdrAdmReq, putProductReq, registerReq, sellerRegisterReq, updateAddressReq, updateContactUsPostReq, updateCorpListReq, updateCreateAddKartReq, updateCreateDivisionReq, updateCreateProductReq, updateCreateSpareReq, updateDeleteAWSFolder, updateDeleteProdImg, updateDelKartProdReq, updateDelProdReq, updateDivisionListReq, updateForgotPassword, updateGetAWSFileUrlReq, updateGetListProdImg, updateKartCountPUTReq, updateKartCountReq, updateKartListReq, updateKartStatusPUTReq, updateKartTxnIdPUTReq, updateMyOrdersReq, updateOEMReq, updateOrdersListReq, updateOrderTxnByIdReq, updatePassKeyReq, updateProdDescReq, updateProductsListReq, updateProfile, updatePutAckShipReq, updatePutPendOrdrAdmReq, updatePutProductReq, updateRegisterPostReq, updateSellerProductsListReq, updateSellerRegisterPostReq, updateStateListReq, updateToken, updateUploadProdImg, updateUserListReq, updateUserStatusReq, uploadProdImg, userStatusReq,
} from './actions';
import { ContactUsResults } from '../../services/contact-us/contact-us.service.types';
import { ForgotPasswordResults } from '../../services/forgot-password/forgot-password.service.types';
import { GenTokenResults } from '../../services/gen-token/gen-token.service.types';
import { ProfileResults } from '../../services/get-profile/get-profile.service.types';
import { RegisterResults } from '../../services/register/register.service.types';
import { ListDivisionResults } from '../../services/meta-data/list-division/list-division.service.types';
import { ListStateResults } from '../../services/meta-data/list-state/list-state.service.types';
import { PassKeyPutResults } from '../../services/update-passkey/update-passkey.service.types';
import { ListUserResults } from '../../services/all-users/user-list/list-user.service.types';
import { UserStatusPutResults } from '../../services/all-users/update-status/update-status.service.types';
import { PostDivisionResults } from '../../services/meta-data/add-division/post-division.service.types';
import { PostProductResults } from '../../services/products/add-products/post-product.service.types';
import { GetAllProductResults } from '../../services/products/list-products/get-all-product.service.types';
import { CreateProdImgResults } from '../../services/products/upload-prod-Image/create-prodimage.service.types';
import { PostAddKartResults } from '../../services/products/add-kart/post-addkart.service.types';
import { GetAllKartResults } from '../../services/products/list-kart/get-all-kart.service.types';
import { DELKartProdResults } from '../../services/products/del-kart/del-kart-prod.service.types';
import { GetKartCountResults } from '../../services/products/get-kart-count/get-kart-count.service.types';
import { KartCountPutResults } from '../../services/products/update-kart-count/update-kart-count.service.types';
import { KartStatusPutResults } from '../../services/products/update-kart-status/update-kart-status.service.types';
import { KartTxnIdPutResults } from '../../services/products/update-kart-txn-id/update-kart-txnid.service.types';
import { GetAllMyOrdersResults } from '../../services/products/list-my-orders/get-all-myorders.service.types';
import { ProductDescResults } from '../../services/products/prod-desc/product-desc.service.types';
import { PostSpareResults } from '../../services/products/add-spares/post-spare.service.types';
import { ProductPutResults } from '../../services/products/update-products/update-product.service.types';
import { ListCorpResults } from '../../services/meta-data/list-corp/list-corp.service.types';
import { SellerRegisterResults } from '../../services/register-seller/seller-register.service.types';
import { GetAllSellerProductResults } from '../../services/products/list-seller-product/get-all-seller-product.service.types';
import { GetAllOrdersResults } from '../../services/products/power-orders/get-all-orders.service.types';
import { AddressPutResults } from '../../services/all-users/update-address/update-address.service.types';
import { GetAWSFileUrlResults } from '../../services/get-aws-files/get-aws-file.service.types';
import { PendOrdrStsPutResults } from '../../services/products/update-pend-ordr/update-pend-order.service.types';
import { AckShipPutResults } from '../../services/products/update-ack-ship/update-ack-ship.service.types';
import { GetOrderTxnByIdResults } from '../../services/get-order-txn-by-id/get-order-txn-by-id.service.types';
import { GetListProdImgResults } from '../../services/products/get-list-product-images/getList-prodimage.service.types';
import { DeleteProdImgResults } from '../../services/products/delete-prod-image/delete-prodimage.service.types';
import { OEMPutResults } from '../../services/update-oem-profile/update-oem.service.types';
import { DELProdResults } from '../../services/products/delete-product/del-api-data/del-prod.service.types';
import { DeleteAWSFolderResults } from '../../services/products/delete-product/del-folder-aws-s3/delete-folder.service.types';

export const contactUsPOSTEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(contactUsPostReq)),
  mergeMap((action) => from(powerMartService.contactUsService(action.payload)).pipe(
    map((response : ContactUsResults) => updateContactUsPostReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Wenex', errorMessage: error.message })),
  )),
);

export const registerUserEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(registerReq)),
  mergeMap((action) => from(powerMartService.registerService(action.payload)).pipe(
    map((response : RegisterResults) => updateRegisterPostReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Register Agent', errorMessage: error.message })),
  )),
);
export const generateTokenEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getLoginToken)),
  mergeMap((action) => from(powerMartService.genTokenService(action.payload)).pipe(
    map((response : GenTokenResults) => updateToken(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Login', errorMessage: error.message })),
  )),
);
export const getProfileEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getProfile)),
  mergeMap((action) => from(powerMartService.profileService(action.payload)).pipe(
    map((response : ProfileResults) => updateProfile(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Profile', errorMessage: error.message })),
  )),
);
export const getForgotPasswordEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getForgotPassword)),
  mergeMap((action) => from(powerMartService.forgotPasswordService(action.payload)).pipe(
    map((response : ForgotPasswordResults) => updateForgotPassword(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'ForgotPassword', errorMessage: error.message })),
  )),
);
export const getDivisionListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getDivisionListReq)),
  mergeMap((action) => from(powerMartService.divListService(action.payload)).pipe(
    map((response : ListDivisionResults) => updateDivisionListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Division Meta', errorMessage: error.message })),
  )),
);

export const getStateListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getStateListReq)),
  mergeMap((action) => from(powerMartService.stateListService(action.payload)).pipe(
    map((response : ListStateResults) => updateStateListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'State Meta', errorMessage: error.message })),
  )),
);
export const passKeyEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(passKeyReq)),
  mergeMap((action) => from(powerMartService.passKeyService(action.payload)).pipe(
    map((response : PassKeyPutResults) => updatePassKeyReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Pass Key Update', errorMessage: error.message })),
  )),
);

export const getUserListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getUserListReq)),
  mergeMap((action) => from(powerMartService.userListService(action.payload)).pipe(
    map((response : ListUserResults) => updateUserListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'User List', errorMessage: error.message })),
  )),
);

export const updateAgntStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(userStatusReq)),
  mergeMap((action) => from(powerMartService.userStatusService(action.payload)).pipe(
    map((response : UserStatusPutResults) => updateUserStatusReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update User Status', errorMessage: error.message })),
  )),
);

export const addDivisionEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(createDivisionReq)),
  mergeMap((action) => from(powerMartService.createDivService(action.payload)).pipe(
    map((response : PostDivisionResults) => updateCreateDivisionReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Create Divisions', errorMessage: error.message })),
  )),
);

export const addProductEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(createProductReq)),
  mergeMap((action) => from(powerMartService.createProductService(action.payload)).pipe(
    map((response : PostProductResults) => updateCreateProductReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Create New Products', errorMessage: error.message })),
  )),
);

export const getAllProductsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getProductsListReq)),
  mergeMap((action) => from(powerMartService.productListService(action.payload)).pipe(
    map((response : GetAllProductResults) => updateProductsListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Products', errorMessage: error.message })),
  )),
);

export const uploadProdImgEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(uploadProdImg)),
  mergeMap((action) => from(powerMartService.prodImgService(action.payload)).pipe(
    map((response : CreateProdImgResults) => updateUploadProdImg(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Upload Prod Image', errorMessage: error.message })),
  )),
);

export const addKartEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(createAddKartReq)),
  mergeMap((action) => from(powerMartService.createAddKartService(action.payload)).pipe(
    map((response : PostAddKartResults) => updateCreateAddKartReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Create New AddKart', errorMessage: error.message })),
  )),
);

export const getAllKartEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getKartListReq)),
  mergeMap((action) => from(powerMartService.kartListService(action.payload)).pipe(
    map((response : GetAllKartResults) => updateKartListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Kart', errorMessage: error.message })),
  )),
);

export const getKartCountEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getKartCountReq)),
  mergeMap((action) => from(powerMartService.getKartCountService(action.payload)).pipe(
    map((response : GetKartCountResults) => updateKartCountReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get Kart Count', errorMessage: error.message })),
  )),
);

export const updateKartCountEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(kartCountPUTReq)),
  mergeMap((action) => from(powerMartService.updateKartCountService(action.payload)).pipe(
    map((response : KartCountPutResults) => updateKartCountPUTReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update Kart Count', errorMessage: error.message })),
  )),
);

export const delKartProdEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(delKartProdReq)),
  mergeMap((action) => from(powerMartService.delKartProdService(action.payload)).pipe(
    map((response : DELKartProdResults) => updateDelKartProdReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Delete Kart Product', errorMessage: error.message })),
  )),
);

export const updateKartStatusEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(kartStatusPUTReq)),
  mergeMap((action) => from(powerMartService.updateKartStatusService(action.payload)).pipe(
    map((response : KartStatusPutResults) => updateKartStatusPUTReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update Kart Status', errorMessage: error.message })),
  )),
);

export const updateKartTxnIdEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(kartTxnIdPUTReq)),
  mergeMap((action) => from(powerMartService.updateKartTxnIdService(action.payload)).pipe(
    map((response : KartTxnIdPutResults) => updateKartTxnIdPUTReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Update Kart TxnId', errorMessage: error.message })),
  )),
);

export const myOrdersEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(myOrdersReq)),
  mergeMap((action) => from(powerMartService.myOrderListService(action.payload)).pipe(
    map((response : GetAllMyOrdersResults) => updateMyOrdersReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'My Orders List', errorMessage: error.message })),
  )),
);

export const prodDescEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(prodDescReq)),
  mergeMap((action) => from(powerMartService.prodDescService(action.payload)).pipe(
    map((response : ProductDescResults) => updateProdDescReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Product Descriptions', errorMessage: error.message })),
  )),
);

export const addSpareEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(createSpareReq)),
  mergeMap((action) => from(powerMartService.createSpareService(action.payload)).pipe(
    map((response : PostSpareResults) => updateCreateSpareReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Create New Spares', errorMessage: error.message })),
  )),
);

export const putProductEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(putProductReq)),
  mergeMap((action) => from(powerMartService.putProductService(action.payload)).pipe(
    map((response : ProductPutResults) => updatePutProductReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Product Update', errorMessage: error.message })),
  )),
);

export const getCorpListEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getCorpListReq)),
  mergeMap((action) => from(powerMartService.corpListService(action.payload)).pipe(
    map((response : ListCorpResults) => updateCorpListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Corp Meta', errorMessage: error.message })),
  )),
);

export const sellerRegisterUserEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(sellerRegisterReq)),
  mergeMap((action) => from(powerMartService.sellerRegisterService(action.payload)).pipe(
    map((response : SellerRegisterResults) => updateSellerRegisterPostReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Register Sellers', errorMessage: error.message })),
  )),
);

export const getAllSellerProductsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getSellerProductsListReq)),
  mergeMap((action) => from(powerMartService.sellerProductListService(action.payload)).pipe(
    map((response : GetAllSellerProductResults) => updateSellerProductsListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Seller Products', errorMessage: error.message })),
  )),
);

export const getAllOrdersEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getOrdersListReq)),
  mergeMap((action) => from(powerMartService.ordersListService(action.payload)).pipe(
    map((response : GetAllOrdersResults) => updateOrdersListReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Orders', errorMessage: error.message })),
  )),
);

export const addressUpdateEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(addressReq)),
  mergeMap((action) => from(powerMartService.addressService(action.payload)).pipe(
    map((response : AddressPutResults) => updateAddressReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Address Update', errorMessage: error.message })),
  )),
);

export const getAWSFileUrlEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getAWSFileUrlReq)),
  mergeMap((action) => from(powerMartService.getAWSFileUrlService(action.payload)).pipe(
    map((response : GetAWSFileUrlResults) => updateGetAWSFileUrlReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get AWS File URL', errorMessage: error.message })),
  )),
);

export const putPendOrdrStsEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(putPendOrdrAdmReq)),
  mergeMap((action) => from(powerMartService.putPendOrdrStsService(action.payload)).pipe(
    map((response : PendOrdrStsPutResults) => updatePutPendOrdrAdmReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Pending Order Status Update', errorMessage: error.message })),
  )),
);

export const putAckShipEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(putAckShipReq)),
  mergeMap((action) => from(powerMartService.putAckShipService(action.payload)).pipe(
    map((response : AckShipPutResults) => updatePutAckShipReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'AckShip Update', errorMessage: error.message })),
  )),
);

export const getOrderTxnByIdEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getOrderTxnByIdReq)),
  mergeMap((action) => from(powerMartService.ordersTxnByIdService(action.payload)).pipe(
    map((response : GetOrderTxnByIdResults) => updateOrderTxnByIdReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get All Order Txn by Id', errorMessage: error.message })),
  )),
);

export const getListProdImgEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(getListProdImg)),
  mergeMap((action) => from(powerMartService.getListProdImgService(action.payload)).pipe(
    map((response : GetListProdImgResults) => updateGetListProdImg(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Get List Prod Image', errorMessage: error.message })),
  )),
);

export const deleteProdImgEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(deleteProdImg)),
  mergeMap((action) => from(powerMartService.deleteProdImgService(action.payload)).pipe(
    map((response : DeleteProdImgResults) => updateDeleteProdImg(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Delete Prod Image', errorMessage: error.message })),
  )),
);

export const oemUpdateEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(oemReq)),
  mergeMap((action) => from(powerMartService.oemUpdateService(action.payload)).pipe(
    map((response : OEMPutResults) => updateOEMReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'OEM Update', errorMessage: error.message })),
  )),
);

export const delProdEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(delProdReq)),
  mergeMap((action) => from(powerMartService.delProdService(action.payload)).pipe(
    map((response : DELProdResults) => updateDelProdReq(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Delete Product', errorMessage: error.message })),
  )),
);

export const deleteAWSFolderEpic : Epic<RootAction, RootAction, RootState, Services> = (action$, state$, { powerMartService }) => action$.pipe(
  filter(isActionOf(deleteAWSFolder)),
  mergeMap((action) => from(powerMartService.deleteAWSFolderService(action.payload)).pipe(
    map((response : DeleteAWSFolderResults) => updateDeleteAWSFolder(response)),
    catchError(async (error: Error) => apiError({ failed: true, backend: 'Delete AWS Folder', errorMessage: error.message })),
  )),
);

export const powerMartEpics = combineEpics(
  contactUsPOSTEpic,
  registerUserEpic,
  generateTokenEpic,
  getProfileEpic,
  getForgotPasswordEpic,
  getDivisionListEpic,
  getStateListEpic,
  passKeyEpic,
  getUserListEpic,
  updateAgntStatusEpic,
  addDivisionEpic,
  addProductEpic,
  getAllProductsEpic,
  uploadProdImgEpic,
  addKartEpic,
  getAllKartEpic,
  getKartCountEpic,
  updateKartCountEpic,
  delKartProdEpic,
  updateKartStatusEpic,
  updateKartTxnIdEpic,
  myOrdersEpic,
  prodDescEpic,
  addSpareEpic,
  putProductEpic,
  getCorpListEpic,
  sellerRegisterUserEpic,
  getAllSellerProductsEpic,
  getAllOrdersEpic,
  addressUpdateEpic,
  getAWSFileUrlEpic,
  putPendOrdrStsEpic,
  putAckShipEpic,
  getOrderTxnByIdEpic,
  getListProdImgEpic,
  deleteProdImgEpic,
  oemUpdateEpic,
  delProdEpic,
  deleteAWSFolderEpic,
);
