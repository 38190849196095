/* eslint-disable react/require-default-props */
import React, { useState, ReactNode } from 'react';
import classNames from 'classnames';
import './tooltip.scss';

interface TooltipProps {
  content: ReactNode;
  children: ReactNode;
  position?: 'top' | 'right' | 'bottom' | 'left';
  customClassName?: string;
}

const Tooltip: React.FC<TooltipProps> = ({
  content,
  children,
  position = 'top',
  customClassName,
}) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="tooltip-wrapper">
      <div
        className="tooltip-children"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {children}
      </div>

      {isHovered && (
        <div
          className={classNames(
            'tooltip',
            `tooltip-${position}`,
            customClassName,
          )}
        >
          <div className="tooltip-content">{content}</div>
        </div>
      )}
    </div>
  );
};

export default Tooltip;
