import React from 'react';
import { faArrowRightLong } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from 'reactstrap';
import AllProductsGallery from './all-products';
import { ProductInfo } from './product-info';
import AboutPowerMart from './about-powermart';
import DiscomClientImageGallery from './discom-clients-buyer';
import TransClientImageGallery from './trans-clients-buyer';
import MultiProductList from './multi-prod-list';

const imagesGalTRANS = [
  '/images/clients/TRANS/AEGCL.png',
  '/images/clients/TRANS/APTRANSCO.png',
  '/images/clients/TRANS/BSPTCL.png',
  '/images/clients/TRANS/CSPTCL.png',
  '/images/clients/TRANS/DTL.png',
  '/images/clients/TRANS/GETCO.png',
  '/images/clients/TRANS/GOAPD.png',
  '/images/clients/TRANS/HPPTCL.png',
  '/images/clients/TRANS/HVPNL.png',
  '/images/clients/TRANS/JKPTCL.png',
  '/images/clients/TRANS/JUSNL.png',
  '/images/clients/TRANS/KMTL.png',
  '/images/clients/TRANS/KPTCL.png',
  '/images/clients/TRANS/KSEBL.png',
  '/images/clients/TRANS/LAKSHED.png',
  '/images/clients/TRANS/MEECL.png',
  '/images/clients/TRANS/MIZOPD.png',
  '/images/clients/TRANS/MPPTCL.png',
  '/images/clients/TRANS/PPCL.png',
  '/images/clients/TRANS/PSTCL.png',
  '/images/clients/TRANS/PTCUL.png',
  '/images/clients/TRANS/RRVPNL.png',
  '/images/clients/TRANS/TANTRANCO.png',
  '/images/clients/TRANS/TPTL.png',
  '/images/clients/TRANS/TPTL1.png',
  '/images/clients/TRANS/TSTRANSCO.png',
  '/images/clients/TRANS/UPPTCL.png',
  '/images/clients/TRANS/WBSETCL.png',
];

const imagesGalDISCOM = [
  '/images/clients/DISCOM/ANPD.png',
  '/images/clients/DISCOM/APCPDCL.png',
  '/images/clients/DISCOM/APEPDCL.png',
  '/images/clients/DISCOM/APSPDCL.png',
  '/images/clients/DISCOM/ArunachalPD.png',
  '/images/clients/DISCOM/APDCL.png',
  '/images/clients/DISCOM/NBPDCL.png',
  '/images/clients/DISCOM/SBPDCL.png',
  '/images/clients/DISCOM/CSPDCL.png',
  '/images/clients/DISCOM/NDMC.png',
  '/images/clients/DISCOM/BYPL.png',
  '/images/clients/DISCOM/BRPL.png',
  '/images/clients/DISCOM/TPDDL.png',
  '/images/clients/DISCOM/DGVCL.png',
  '/images/clients/DISCOM/MGVCL.png',
  '/images/clients/DISCOM/PGVCL.png',
  '/images/clients/DISCOM/UGVCL.png',
  '/images/clients/DISCOM/DHBVNL.png',
  '/images/clients/DISCOM/UHBVNL.png',
  '/images/clients/DISCOM/HPSEBL.png',
  '/images/clients/DISCOM/JPDCL.png',
  '/images/clients/DISCOM/KPDCL.png',
  '/images/clients/DISCOM/JBVNL.png',
  '/images/clients/DISCOM/BESCOM.png',
  '/images/clients/DISCOM/CESC.png',
  '/images/clients/DISCOM/GESCOM.png',
  '/images/clients/DISCOM/HESCOM.png',
  '/images/clients/DISCOM/HRECS.png',
  '/images/clients/DISCOM/MESCOM.png',
  '/images/clients/DISCOM/KSEBL.png',
  '/images/clients/DISCOM/TCED.png',
  '/images/clients/DISCOM/LadakhPDD.png',
  '/images/clients/DISCOM/LakshadweepED.png',
  '/images/clients/DISCOM/MPMaKVVCL.png',
  '/images/clients/DISCOM/MPPaKVVCL.png',
  '/images/clients/DISCOM/MPPoKVVCL.png',
  '/images/clients/DISCOM/BEST.png',
  '/images/clients/DISCOM/TPM.png',
  '/images/clients/DISCOM/MSEDCL.png',
  '/images/clients/DISCOM/MSPDCL.png',
  '/images/clients/DISCOM/MePDCL.png',
  '/images/clients/DISCOM/MizoramPD.png',
  '/images/clients/DISCOM/NagalandPD.png',
  '/images/clients/DISCOM/PuducherryPD.png',
  '/images/clients/DISCOM/PSPCL.png',
  '/images/clients/DISCOM/AVVNL.png',
  '/images/clients/DISCOM/JVVNL.png',
  '/images/clients/DISCOM/JdVVNL.png',
  '/images/clients/DISCOM/SikkimPD.png',
  '/images/clients/DISCOM/TANGEDCO.png',
  '/images/clients/DISCOM/TSNPDCL.png',
  '/images/clients/DISCOM/TSSPDCL.png',
  '/images/clients/DISCOM/TSECL.png',
  '/images/clients/DISCOM/DVVNL.png',
  '/images/clients/DISCOM/KESCO.png',
  '/images/clients/DISCOM/MVVNL.png',
  '/images/clients/DISCOM/PaVVNL.png',
  '/images/clients/DISCOM/PuVVNL.png',
  '/images/clients/DISCOM/UPCL.png',
  '/images/clients/DISCOM/WBSEDCL.png',
];

export const HomeComponentList: React.FC = () => {
  const [catSelected, setCatSelected] = React.useState('DIST');
  const [isFirstAPICall, setIsFirstAPICall] = React.useState(true);

  return (
    <div className="mx-1 pt-5">
      <ProductInfo />
      <div className="pt-5 my-5">
        <div className="align-center">
          <span className="text-title">Product Categories</span>
        </div>
        <MultiProductList catSelected={catSelected} setCatSelected={setCatSelected} setIsFirstAPICall={setIsFirstAPICall} />
      </div>
      <div className="pt-5 my-5">
        <div className="align-center">
          <span className="text-title">
            {catSelected === 'DIST' ? 'Distribution Products'
              : catSelected === 'TRANS' ? 'Transmission Products'
                : catSelected === 'THRML' ? 'Thermal Products'
                  : catSelected === 'HYDRO' ? 'Hydro Products'
                    : catSelected === 'RNWE' ? 'Renewable Products'
                      : null}
          </span>
        </div>
        <div className="align-center">
          <Button to="all-products" className="link-text-italic button-icon" target="_blank" href="/all-products">
            Show All <FontAwesomeIcon icon={faArrowRightLong} />
          </Button>
        </div>
        <AllProductsGallery prodSts={catSelected} isFirstAPICall={isFirstAPICall} setIsFirstAPICall={setIsFirstAPICall} />
      </div>
      <div className="pt-2">
        <div className="my-5 align-center">
          <span className="text-title">About Us</span>
        </div>
        <AboutPowerMart />
        <div className="pt-2">
          <div className="align-center">
            <span className="human-label my-5">HUMAN SAFETY SOLUTIONS FOR TRANSMISSIONS</span>
          </div>
          <TransClientImageGallery images={imagesGalTRANS} />
        </div>
        <div>
          <div className="my-2 align-center">
            <span className="human-label my-5">HUMAN SAFETY SOLUTIONS FOR DISCOM</span>
          </div>
          <DiscomClientImageGallery images={imagesGalDISCOM} />
        </div>
      </div>
      <div className="my-5" />
    </div>
  );
};
export default HomeComponentList;
