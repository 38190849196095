import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Card, CardHeader, CardBody, CardImg, Input, CardFooter, Button,
} from 'reactstrap';
import { getConfig } from '../../../utils/config/config';
import { successAlert, errorAlert } from '../../../utils/alert';
import LoaderData from '../../../utils/loader';
import { ProdImgFileInput } from '../../../services/products/upload-prod-Image/create-prodimage.types';
import { FileDetails } from '../../../services/products/get-list-product-images/getList-prodimage.types';
import { getTokenFromSessionStorage, getUserProfileSessionStorage, setUserProfileSessionStorage } from '../../../utils/service/localstorage-service';
import { clearUploadProdImg, getProfile, uploadProdImg } from '../../../store/powerMart/actions';
import { APIHeader } from '../../../utils/constants';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const MyAvatar: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const [loaderProfile, setLoaderProfile] = useState(false);
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [userId, setUserId] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [alertMsg, setAlertMsg] = useState('');
  const [filePreview, setFilePreview] = useState('/images/avatar.png');
  const loginProfileData = useSelector((state: RootState) => state.powerMart.profileData);
  const uploadroductAwsResponse = useSelector((state: RootState) => state.powerMart.uploadProductsAWS);
  const [prodImageFile, setProdImageDataFile] = React.useState<ProdImgFileInput>({
    fileData: new FormData(),
  });

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData) {
      setUserId(userInitTp && JSON.parse(userInitTp).profileData.UserCd);
      setUserTp(JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [
    userInitTp && JSON.parse(userInitTp).profileData,
  ]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.avatar) {
      setFilePreview(userInitTp && JSON.parse(userInitTp).profileData.avatar);
    }
  }, [
    userInitTp && JSON.parse(userInitTp).profileData.avatar,
  ]);

  const handleprofilephoto = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target;
    if (!files || files.length === 0) return;
    const maxSizeInBytes = 5 * 1024 * 1024;
    const formData = new FormData();
    const newProdImgList: FileDetails[] = [];
    Array.from(files).forEach((file) => {
      if (file.size > maxSizeInBytes) {
        setAlertMsg('File size exceeds the limit of 5 MB.');
        setErrorShowAlert(true);
        return;
      }
      // Rename the file to have a .png extension
      const newFile = new File([file], `${file.name.split('.')[0]}.png`, { type: 'image/png' });
      formData.append(newFile.name, newFile);
      const objectUrl = URL.createObjectURL(newFile);
      setFilePreview(objectUrl);
      newProdImgList.push({ url: objectUrl, nme: newFile.name });
    });
    // setEditProdImgList((prevList) => [...prevList, ...newProdImgList]);
    setProdImageDataFile({ ...prodImageFile, fileData: formData });
  };

  const handleUploadEditProduct = () => {
    setLoaderProfile(true);
    Promise.resolve(
      dispatch(uploadProdImg({
        bucketNme: AWS_PRODUCT_BUCKET,
        folderName: 'avatars',
        fileNmeInit: userId,
        formData: prodImageFile.fileData,
        requestType: '',
        isEdit: false,
      })),
    );
  };

  React.useEffect(() => {
    if (uploadroductAwsResponse.fileName !== '') {
      setLoaderProfile(false);
      setAlertMsg('Uploaded Successfully');
      setSuccessShowAlert(true);
      dispatch(getProfile({
        userReqFor: userTp,
        requestType: APIHeader.REQ_USER_PROFILE_INFO,
        token: tokenData,
      }));
    }
  }, [uploadroductAwsResponse.fileName]);

  React.useEffect(() => {
    if (!loginProfileData.isError && loginProfileData.message !== '') {
      setUserProfileSessionStorage(JSON.stringify(loginProfileData));
      dispatch(clearUploadProdImg());
    }
  }, [loginProfileData.isError, loginProfileData.message]);

  React.useEffect(() => {
    setProdImageDataFile(prodImageFile);
  }, [prodImageFile.fileData]);

  return (
    <Row className="pt-3 margin-bottom">
      { loaderProfile ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg={{ size: 12, order: 1 }} md={{ size: 12, order: 1 }} xs={{ order: 1 }}>
        <Card className="intro-product-cat-card">
          <CardHeader className="form-card-header">
            Update your Avatar
          </CardHeader>
          <CardBody>
            <div className="align-center my-2">
              <CardImg alt="logo" className="img-fluid rounded-circle avatar-user-profile-preview" src={filePreview} />
            </div>
            <div className="upload-box mx-3">
              <Input
                type="file"
                accept=".png, .jpg, .jpeg"
                name="fileData"
                className="upload-box-input"
                onChange={(e) => handleprofilephoto(e)}
              />
              <span className="register-file-link">Upload Your Avatar</span>
            </div>
          </CardBody>
          <CardFooter className="form-card-footer">
            <div className="align-end pt-3">
              <Button disabled={Array.from(prodImageFile.fileData.keys()).length === 0} className="powermart-button-solid mx-1" onClick={() => handleUploadEditProduct()}>
                UPLOAD
              </Button>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
};
export default MyAvatar;
