// export const setTokenToLocalStorage = (token: string) => {
//   localStorage.setItem('token', token);
// };
// export const setUserProfileLocalStorage = (userProfile: any) => {
//   localStorage.setItem('userProfile', userProfile);
// };
// export const setProfileUrlLocalStorage = (urlData: string) => {
//   localStorage.setItem('profileUrl', urlData);
// };
export const setTokenSessionStorage = (token: string) => {
  sessionStorage.setItem('token', token);
};
export const setUserProfileSessionStorage = (userProfile: any) => {
  sessionStorage.setItem('userProfile', userProfile);
};
export const setDiscomSessionStorage = (discomTp: string) => {
  sessionStorage.setItem('discomTp', discomTp);
};
export const setDiscomUtilSessionStorage = (discomUtil: string) => {
  sessionStorage.setItem('discomUtil', discomUtil);
};
// export const setProdStatusSessionStorage = (prodSts: string) => {
//   sessionStorage.setItem('prodSts', prodSts);
// };
// export const setProfileUrlStorage = (urlData: string) => {
//   sessionStorage.setItem('profileUrl', urlData);
// };
// export const getTokenFromLocalStorage = () => localStorage.getItem('token') || '';
// export const getUserProfileLocalStorage = () => localStorage.getItem('userProfile') || '';
// export const getProfileUrlLocalStorage = () => localStorage.getItem('profileUrl') || '';
export const getTokenFromSessionStorage = () => sessionStorage.getItem('token') || '';
export const getUserProfileSessionStorage = () => sessionStorage.getItem('userProfile');
export const getDiscomSessionStorage = () => sessionStorage.getItem('discomTp');
export const getDiscomUtilSessionStorage = () => sessionStorage.getItem('discomUtil');
// export const getProdStsSessionStorage = () => sessionStorage.getItem('prodSts');
// export const getProfileUrlSessionStorage = () => sessionStorage.getItem('profileUrl') || '';

// export const clearKeysFromLocalStorage = () => {
//   localStorage.clear();
// };

export const clearKeysFromSessionStorage = () => {
  sessionStorage.clear();
};
