export const API_ERROR = 'API_ERROR';
export const SHOW_DATA = 'SHOW_DATA';
export const UPDATE_DATA = 'UPDATE_DATA';
export const CONTACT_US_DATA = 'CONTACT_US_DATA';
export const UPDATE_CONTACT_US_DATA = 'UPDATE_CONTACT_US_DATA';
export const CLEAR_CONTACT_US_DATA = 'CLEAR_CONTACT_US_DATA';
export const REGISTER_DATA = 'REGISTER_DATA';
export const UPDATE_REGISTER_DATA = 'UPDATE_REGISTER_DATA';
export const CLEAR_REGISTER_DATA = 'CLEAR_REGISTER_DATA';
export const GET_LOGIN_TOKEN = 'GET_LOGIN_TOKEN';
export const UPDATE_LOGIN_TOKEN = 'UPDATE_LOGIN_TOKEN';
export const CLEAR_LOGIN_TOKEN = 'CLEAR_LOGIN_TOKEN';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE = 'UPDATE_PROFILE';
export const CLEAR_PROFILE = 'CLEAR_PROFILE';
export const GET_FORGOT_PASSWORD = 'GET_FORGOT_PASSWORD';
export const UPDATE_FORGOT_PASSWORD = 'UPDATE_FORGOT_PASSWORD';
export const GET_STATE_LIST = 'GET_STATE_LIST';
export const UPDATE_STATE_LIST = 'UPDATE_STATE_LIST';
export const CLEAR_STATE_LIST = 'CLEAR_STATE_LIST';
export const PASS_KEY_DATA = 'PASS_KEY_DATA';
export const UPDATE_PASS_KEY_DATA = 'UPDATE_PASS_KEY_DATA';
export const CLEAR_PASS_KEY_DATA = 'CLEAR_PASS_KEY_DATA';
export const USER_STATUS_DATA = 'USER_STATUS_DATA';
export const UPDATE_USER_STATUS_DATA = 'UPDATE_USER_STATUS_DATA';
export const CLEAR_USER_STATUS_DATA = 'CLEAR_USER_STATUS_DATA';
export const GET_USER_LIST = 'GET_USER_LIST';
export const UPDATE_USER_LIST = 'UPDATE_USEUPDATE_USER_LISTR_STATUS_LIST';
export const CLEAR_USER_LIST_DATA = 'CLEAR_USER_LIST_DATA';
export const GET_DIV_LIST = 'GET_DIV_LIST';
export const UPDATE_DIV_LIST = 'UPDATE_DIV_LIST';
export const CLEAR_DIV_LIST = 'CLEAR_DIV_LIST';
export const CREATE_DIV = 'CREATE_DIV';
export const UPDATE_CREATE_DIV = 'UPDATE_CREATE_DIV';
export const CLEAR_CREATE_DIV = 'CLEAR_CREATE_DIV';
export const GET_PRODUCTS_LIST = 'GET_PRODUCTS_LIST';
export const UPDATE_PRODUCTS_LIST = 'UPDATE_PRODUCTS_LIST';
export const CLEAR_PRODUCTS_LIST = 'CLEAR_PRODUCTS_LIST';
export const CREATE_PRODUCT = 'CREATE_PRODUCT';
export const UPDATE_CREATE_PRODUCT = 'UPDATE_CREATE_PRODUCT';
export const CLEAR_CREATE_PRODUCT = 'CLEAR_CREATE_PRODUCT';
export const UPLOAD_PROD_IMG = 'UPLOAD_PROD_IMG';
export const UPDATE_UPLOAD_PROD_IMG = 'UPDATE_UPLOAD_PROD_IMG';
export const CLEAR_UPLOAD_PRODUCT = 'CLEAR_UPLOAD_PRODUCT';
export const GET_KART_LIST = 'GET_KART_LIST';
export const UPDATE_KART_LIST = 'UPDATE_KART_LIST';
export const CLEAR_KART_LIST = 'CLEAR_KART_LIST';
export const CREATE_ADD_KART = 'CREATE_ADD_KART';
export const UPDATE_CREATE_ADD_KART = 'UPDATE_CREATE_ADD_KART';
export const CLEAR_CREATE_ADD_KART = 'CLEAR_CREATE_ADD_KART';
export const GET_KART_COUNT = 'GET_KART_COUNT';
export const UPDATE_KART_COUNT = 'UPDATE_KART_COUNT';
export const CLEAR_KART_COUNT = 'CLEAR_KART_COUNT';
export const CREATE_KART_COUNT_PUT = 'CREATE_KART_COUNT_PUT';
export const UPDATE_KART_COUNT_PUT = 'UPDATE_KART_COUNT_PUT';
export const CLEAR_KART_COUNT_PUT = 'CLEAR_KART_COUNT_PUT';
export const DEL_KART_PROD = 'DEL_KART_PROD';
export const UPDATE_DEL_KART_PROD = 'UPDATE_DEL_KART_PROD';
export const CLEAR_DEL_KART_PROD = 'CLEAR_DEL_KART_PROD';
export const CREATE_KART_STATUS_PUT = 'CREATE_KART_STATUS_PUT';
export const UPDATE_KART_STATUS_PUT = 'UPDATE_KART_STATUS_PUT';
export const CLEAR_KART_STATUS_PUT = 'CLEAR_KART_STATUS_PUT';
export const CREATE_KART_TXNID_PUT = 'CREATE_KART_TXNID_PUT';
export const UPDATE_KART_TXNID_PUT = 'UPDATE_KART_TXNID_PUT';
export const CLEAR_KART_TXNID_PUT = 'CLEAR_KART_TXNID_PUT';
export const PRODUCT_DESC = 'PRODUCT_DESC';
export const UPDATE_PRODUCT_DESC = 'UPDATE_PRODUCT_DESC';
export const CLEAR_PRODUCT_DESC = 'CLEAR_PRODUCT_DESC';
export const MY_ORDERS = 'MY_ORDERS';
export const UPDATE_MY_ORDERS = 'UPDATE_MY_ORDERS';
export const CLEAR_MY_ORDERS = 'CLEAR_MY_ORDERS';
export const CREATE_SPARE = 'CREATE_SPARE';
export const UPDATE_CREATE_SPARE = 'UPDATE_CREATE_SPARE';
export const CLEAR_CREATE_SPARE = 'CLEAR_CREATE_SPARE';
export const PUT_PRODUCT = 'PUT_PRODUCT';
export const UPDATE_PUT_PRODUCT = 'UPDATE_PUT_PRODUCT';
export const CLEAR_PUT_PRODUCT = 'CLEAR_PUT_PRODUCT';
export const GET_CORP_LIST = 'GET_CORP_LIST';
export const UPDATE_CORP_LIST = 'UPDATE_CORP_LIST';
export const CLEAR_CORP_LIST = 'CLEAR_CORP_LIST';
export const SELLER_REGISTER_DATA = 'SELLER_REGISTER_DATA';
export const UPDATE_SELLER_REGISTER_DATA = 'UPDATE_SELLER_REGISTER_DATA';
export const CLEAR_SELLER_REGISTER_DATA = 'CLEAR_SELLER_REGISTER_DATA';
export const GET_SELLER_PRODUCTS_LIST = 'GET_SELLER_PRODUCTS_LIST';
export const UPDATE_SELLER_PRODUCTS_LIST = 'UPDATE_SELLER_PRODUCTS_LIST';
export const CLEAR_SELLER_PRODUCTS_LIST = 'CLEAR_SELLER_PRODUCTS_LIST';
export const GET_ORDERS_LIST = 'GET_ORDERS_LIST';
export const UPDATE_ORDERS_LIST = 'UPDATE_ORDERS_LIST';
export const CLEAR_ORDERS_LIST = 'CLEAR_ORDERS_LIST';
export const ADDRESS_DATA = 'ADDRESS_DATA';
export const UPDATE_ADDRESS_DATA = 'UPDATE_ADDRESS_DATA';
export const CLEAR_ADDRESS_DATA = 'CLEAR_ADDRESS_DATA';
export const GET_AWS_FILE_URL = 'GET_AWS_FILE_URL';
export const UPDATE_GET_AWS_FILE_URL = 'UPDATE_GET_AWS_FILE_URL';
export const CLEAR_GET_AWS_FILE_URL = 'CLEAR_GET_AWS_FILE_URL';
export const PUT_PEND_ORDER_STS_ADM = 'PUT_PEND_ORDER_STS_ADM';
export const UPDATE_PUT_PEND_ORDER_STS_ADM = 'UPDATE_PUT_PEND_ORDER_STS_ADM';
export const CLEAR_PUT_PEND_ORDER_STS_ADM = 'CLEAR_PUT_PEND_ORDER_STS_ADM';
export const PUT_ACK_SHIP = 'PUT_ACK_SHIP';
export const UPDATE_PUT_ACK_SHIP = 'UPDATE_PUT_ACK_SHIP';
export const CLEAR_PUT_ACK_SHIP = 'CLEAR_PUT_ACK_SHIP';
export const GET_ORDER_TXN_BY_ID = 'GET_ORDER_TXN_BY_ID';
export const UPDATE_ORDER_TXN_BY_ID = 'UPDATE_ORDER_TXN_BY_ID';
export const CLEAR_ORDER_TXN_BY_ID = 'CLEAR_ORDER_TXN_BY_ID';
export const GET_LIST_PROD_IMG = 'GET_LIST_PROD_IMG';
export const UPDATE_GET_LIST_PROD_IMG = 'UPDATE_GET_LIST_PROD_IMG';
export const CLEAR_GET_LIST_PRODUCT = 'CLEAR_GET_LIST_PRODUCT';
export const DELETE_PROD_IMG = 'DELETE_PROD_IMG';
export const UPDATE_DELETE_PROD_IMG = 'UPDATE_DELETE_PROD_IMG';
export const CLEAR_DELETE_PROD_IMG = 'CLEAR_DELETE_PROD_IMG';
export const OEM_DATA = 'OEM_DATA';
export const UPDATE_OEM_DATA = 'UPDATE_OEM_DATA';
export const CLEAR_OEM_DATA = 'CLEAR_OEM_DATA';
export const DEL_PROD = 'DEL_PROD';
export const UPDATE_DEL_PROD = 'UPDATE_DEL_PROD';
export const CLEAR_DEL_PROD = 'CLEAR_DEL_PROD';
export const DELETE_AWS_FOLDER = 'DELETE_AWS_FOLDER';
export const UPDATE_DELETE_AWS_FOLDER = 'UPDATE_DELETE_AWS_FOLDER';
export const CLEAR_DELETE_AWS_FOLDER = 'CLEAR_DELETE_AWS_FOLDER';
