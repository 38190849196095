import { useDispatch, useSelector } from 'react-redux';
// import Select, { MultiValue } from 'react-select';
import Select from 'react-select';
import { useLocation } from 'react-router-dom';
import { RootState } from 'typesafe-actions';
import {
  Row, Col, Input, InputGroup, Button, Form, Label, Modal, ModalBody,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Card,
  CardImg,
} from 'reactstrap';
import '../login.scss';
import React, { useState } from 'react';
import { faBolt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleXmark } from '@fortawesome/free-regular-svg-icons';
import {
  APIHeader, DiscomDistributionOptions, DiscomTransmissionOptions, ListStateOptions,
} from '../../../../utils/constants';
import { LoaderData } from '../../../../utils/loader';
import { errorAlert, successAlert } from '../../../../utils/alert';
import { DiscomUserType, RegisterInput } from '../../../../services/register/register.types';
import {
  clearRegisterPostReq, registerReq,
} from '../../../../store/powerMart/actions';

export const RegisterUser: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const path = location.pathname.split('/');
  const [showSuccessAlert, setSuccessShowAlert] = useState(false);
  const [showErrorsAlert, setErrorShowAlert] = useState(false);
  const [alertMsg, setAlertMsg] = useState('');
  const [invalidField, setInvalidField] = useState(false);
  const [registerLoader, setRegisterLoader] = useState(false);
  const [isValidEmail, setIsValidEmail] = React.useState(false);
  const [isValidPhone, setIsValidPhone] = React.useState(false);
  const [modalConfirm, setModalConfirm] = useState(false);
  const [stateData, setStateData] = useState('');
  const registerResponse = useSelector((state: RootState) => state.powerMart.registerUser);
  const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,})+$/;
  const phoneRegex = /^\d{10}$/;
  const [activeTab, setActiveTab] = React.useState('distribution');
  const [distributionOptions, setDistributionOptions] = useState<DiscomUserType[]>([]);
  const [registerData, setRegisterData] = useState<RegisterInput>({
    nme: '',
    ph: '',
    eId: '',
    st: '',
    discom: [],
    div: '',
    usrTp: 'SBDUSR',
    subDiv: '',
    isAgree: false,
  });

  const handleTermUse = () => {
    setModalConfirm(!modalConfirm);
  };
  React.useEffect(() => {
    if (path[2] === 'user') {
      setRegisterData({ ...registerData, usrTp: 'SBDUSR' });
    } else if (path[2] === 'admin') {
      setRegisterData({ ...registerData, usrTp: 'SBDADM' });
    } else if (path[2] === 'sadmin') {
      setRegisterData({ ...registerData, usrTp: 'SADM' });
    }
  }, [path[2]]);

  React.useEffect(() => {
    if (registerResponse.error && registerResponse.message !== '') {
      dispatch(clearRegisterPostReq());
      setRegisterLoader(false);
      setErrorShowAlert(true);
      setAlertMsg(registerResponse.message);
    }
  }, [registerResponse.message, registerResponse.error]);

  React.useEffect(() => {
    if (!registerResponse.error && registerResponse.data !== '') {
      dispatch(clearRegisterPostReq());
      setRegisterLoader(false);
      setInvalidField(false);
      setIsValidEmail(false);
      setIsValidPhone(false);
      setRegisterData({
        nme: '',
        ph: '',
        eId: '',
        st: '',
        discom: [],
        div: '',
        usrTp: 'SBDUSR',
        subDiv: '',
        isAgree: false,
      });
      if (registerResponse.data === 'Mail sent successfully') {
        setAlertMsg('Congratulations! Your registration is complete. To access the powerMart platform, kindly get in touch with your administration.');
      }
      setSuccessShowAlert(true);
    }
  }, [registerResponse.data, registerResponse.error]);

  const handleFieldChangeRegister = (e: any) => {
    const fieldName = e.target.name;
    setRegisterData({ ...registerData, [fieldName]: e.target.value });
  };

  const handleTermChange = (e: any) => {
    setRegisterData({ ...registerData, isAgree: e.target.checked });
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const validEmail = emailRegex.test(registerData.eId);
    const validPhone = phoneRegex.test(registerData.ph);
    if (validEmail && validPhone) {
      setRegisterLoader(true);
      Promise.resolve(
        dispatch(registerReq({
          inputBody: registerData,
          requestType: APIHeader.REQ_ADD_USER,
        })),
      );
    } else if (!validEmail) {
      setIsValidEmail(true);
    } else if (!validPhone) {
      setIsValidPhone(true);
    }
  };

  const stateoptions = ListStateOptions.map((state) => ({
    label: state.label,
    value: state.value,
  }));

  const selectedStateOptions = stateoptions && stateoptions.find(
    (option) => option.value === stateData,
  );

  const discomDistributionOptions = DiscomDistributionOptions.map((dist) => ({
    label: dist.label,
    value: dist.value,
    st: dist.st,
  }));

  const filteredDistributionOptions = discomDistributionOptions.filter((option) => selectedStateOptions && selectedStateOptions.value === option.st);

  const discomTransmissionOptions = DiscomTransmissionOptions.map((trans) => ({
    label: trans.label,
    value: trans.value,
    st: trans.st,
  }));

  const filteredTransmissionOptions = discomTransmissionOptions.filter((option) => selectedStateOptions && selectedStateOptions.value === option.st);

  const handleSelectState = (e: any) => {
    setStateData(e.value);
    setRegisterData({ ...registerData, st: e.value });
  };

  const handleSelectDistribution = (e: any, discomType: string) => {
    const selectedValue = e.value;
    const selectedLabel = e.label;

    if (!distributionOptions.includes(selectedLabel)) {
      // setDistributionOptions((prevOptions) => [...prevOptions, selectedLabel]);

      if (!registerData.discom.some((discom) => discom.discomUtil === selectedValue)) {
        const updatedDISCOM: DiscomUserType[] = [
          ...registerData.discom,
          { discomTp: discomType, discomUtil: selectedValue, st: stateData },
        ];
        setRegisterData({ ...registerData, discom: updatedDISCOM });

        const updatedLabel: DiscomUserType[] = [
          ...distributionOptions,
          { discomTp: selectedValue, discomUtil: selectedLabel, st: stateData },
        ];
        setDistributionOptions(updatedLabel);
      }
    }
  };

  const handleRemoveDistribution = (dist: string) => {
    // Remove the selected label from distributionOptions
    setDistributionOptions((prevOptions) => prevOptions.filter((option) => option.discomTp !== dist));

    // Find the corresponding item in discom based on dist value
    const discomToRemove = registerData.discom.find(
      (discom) => discom.discomUtil === dist,
    );

    if (discomToRemove) {
      setRegisterData((prevData) => ({
        ...prevData,
        discom: prevData.discom.filter(
          (discom) => discom.discomUtil !== discomToRemove.discomUtil,
        ),
      }));
    }
  };

  const handleDiscomTp = (discom: string) => {
    setActiveTab(discom);
  };

  return (
    <Row className="mx-1 align-center">
      {registerLoader ? <LoaderData /> : null}
      {showSuccessAlert ? (
        successAlert(true, alertMsg, showSuccessAlert, setSuccessShowAlert)
      ) : null}
      {invalidField ? (
        errorAlert(false, 'All Fields Are Mandatory !!!', invalidField, setInvalidField)
      ) : null}
      {isValidEmail ? (
        errorAlert(false, 'Please enter valid Email Id !!!', isValidEmail, setIsValidEmail)
      ) : null}
      {isValidPhone ? (
        errorAlert(false, 'Please enter valid Phone Number !!!', isValidPhone, setIsValidPhone)
      ) : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="align-center mx-3 my-2">
        <span className="login-header">
          Sign up
        </span>
      </div>
      <Col lg="12">
        <Form onSubmit={handleSubmit}>
          <Row className="pt-2 mx-1">
            <Col lg="12">
              <Input
                placeholder="Full name"
                type="text"
                required
                name="nme"
                value={registerData.nme}
                disabled={registerLoader}
                onChange={handleFieldChangeRegister}
                className="powermart-input"
              />
            </Col>
            <Col lg="12" className="pt-4">
              <InputGroup>
                <Input
                  placeholder="Phone Number"
                  type="number"
                  required
                  name="ph"
                  value={registerData.ph}
                  disabled={registerLoader}
                  onChange={handleFieldChangeRegister}
                  className="powermart-input"
                />
              </InputGroup>
              <div className="align-start pt-1 mx-1">
                <h6 className="text-lite-grey">
                  10 digit phone number (exclude +91)
                </h6>
              </div>
            </Col>
            <Col lg="12" className="pt-2">
              <Input
                placeholder="Email Id"
                type="text"
                required
                name="eId"
                value={registerData.eId}
                disabled={registerLoader}
                onChange={handleFieldChangeRegister}
                className="powermart-input"
              />
              <div className="align-start pt-1 mx-1">
                <h6 className="text-lite-grey">
                  All the communications will be sent to your email id
                </h6>
              </div>
            </Col>
            <Col lg="12" className="pt-3">
              <Select
                value={stateData === '' ? null : selectedStateOptions}
                options={stateoptions}
                onChange={handleSelectState}
                menuPortalTarget={document.body}
                styles={{
                  menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                  menu: (base) => ({
                    ...base,
                  // position: 'relative',
                  }),
                  control: (base: any) => ({
                    ...base,
                    '&:hover': { borderColor: '#05cbddff' },
                    border: '1px solid #BFBFBF',
                    minHeight: '45px',
                    borderRadius: '5px',
                    padding: 1,
                    textAlign: 'left',
                    fontSize: '16px',
                    fontWeight: 400,
                    color: '#383838',
                    boxShadow: 'none',
                    backgroundColor: '#fff',
                    '&:focus': {
                      borderColor: '#E1EDF8',
                      boxShadow: 'none',
                      color: '#575656',
                      background: '#fff',
                    },
                  }),
                  option: (provided: any, state: { isSelected: any; }) => ({
                    ...provided,
                    color: state.isSelected ? '#383838' : '#212121',
                    padding: 15,
                    textAlign: 'left',
                    background: state.isSelected ? '#FAFCFB' : '#fff',
                    '&:hover': {
                      background: '#FAFCFB',
                    },
                  }),
                }}
              // isLoading={loaderState}
                noOptionsMessage={() => 'No State Found'}
                placeholder="Select State"
              />
              <div className="align-start pt-1 mx-1">
                <h6 className="text-lite-grey">
                  Select State to register from
                </h6>
              </div>
            </Col>
            <Col lg="12" className="pt-3">
              <div className="discom-signup-bg">
                <h6 className="text-lite-grey mx-2 my-2">Choose Multiple Utility Category<span className="mandate-star">*</span></h6>
                <div>
                  <div className="align-center1">
                    <Nav tabs className="metadata-sadmin-profile-tab align-center">
                      <NavItem className="cursor-pointer">
                        <NavLink className="signup-discom-tabs" active={activeTab === 'distribution'} onClick={() => handleDiscomTp('distribution')}>
                          <Card className="key-highlights-cards cursor-pointer">
                            <div className="align-center my-1">
                              <CardImg alt="prodLogo" className="text-signup-discom-icon" src="/images/category/distribution.png" />
                            </div>
                            <div className="align-center padding-zero">
                              <span className="text-signup-discom-header">Distribution</span>
                            </div>
                          </Card>
                        </NavLink>
                      </NavItem>
                      <NavItem className="cursor-pointer">
                        <NavLink className="signup-discom-tabs" active={activeTab === 'transmission'} onClick={() => handleDiscomTp('transmission')}>
                          <Card className="key-highlights-cards cursor-pointer">
                            <div className="align-center my-1">
                              <CardImg alt="prodLogo" className="text-signup-discom-icon" src="/images/category/transmission.png" />
                            </div>
                            <div className="align-center padding-zero">
                              <span className="text-signup-discom-header">Transmission</span>
                            </div>
                          </Card>
                        </NavLink>
                      </NavItem>
                      <NavItem className="cursor-pointer">
                        <NavLink className="signup-discom-tabs" active={activeTab === 'thermal'} onClick={() => handleDiscomTp('thermal')}>
                          <Card className="key-highlights-cards cursor-pointer">
                            <div className="align-center my-1">
                              <CardImg alt="prodLogo" className="text-signup-discom-icon" src="/images/category/thermal.png" />
                            </div>
                            <div className="align-center padding-zero">
                              <span className="text-signup-discom-header">Thermal</span>
                            </div>
                          </Card>
                        </NavLink>
                      </NavItem>
                      <NavItem className="cursor-pointer" disabled>
                        <NavLink className="signup-discom-tabs" active={activeTab === 'renewable'} onClick={() => handleDiscomTp('renewable')}>
                          <Card className="key-highlights-cards cursor-pointer">
                            <div className="align-center my-1">
                              <CardImg alt="prodLogo" className="text-signup-discom-icon" src="/images/category/renewable.png" />
                            </div>
                            <div className="align-center padding-zero">
                              <span className="text-signup-discom-header">Renewable</span>
                            </div>
                          </Card>
                        </NavLink>
                      </NavItem>
                      <NavItem className="cursor-pointer">
                        <NavLink className="signup-discom-tabs" active={activeTab === 'hydro'} onClick={() => handleDiscomTp('hydro')}>
                          <Card className="key-highlights-cards cursor-pointer">
                            <div className="align-center my-1">
                              <CardImg alt="prodLogo" className="text-signup-discom-icon" src="/images/category/hydro.png" />
                            </div>
                            <div className="align-center padding-zero">
                              <span className="text-signup-discom-header">Hydro</span>
                            </div>
                          </Card>
                        </NavLink>
                      </NavItem>
                    </Nav>
                    <div className="text-center-aligned">
                      <span className="text-pre-lite-grey">(Select your category from below)</span>
                    </div>
                  </div>
                </div>
                <div>
                  <TabContent activeTab={activeTab} className="my-3 mx-2">
                    {activeTab === 'distribution' && (
                      <TabPane tabId="distribution">
                        <div className="pt-3">
                          <Select
                          // value={distData === '' ? null : discomDistributionOptions}
                            options={filteredDistributionOptions}
                            onChange={(e) => handleSelectDistribution(e, 'DIST')}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({
                                ...base,
                              // position: 'relative',
                              }),
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#05cbddff' },
                                border: '1px solid #BFBFBF',
                                minHeight: '45px',
                                borderRadius: '5px',
                                padding: 1,
                                textAlign: 'left',
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#383838',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                          // isLoading={loaderState}
                            noOptionsMessage={() => 'No Distribution Found'}
                            placeholder="Select Distribution"
                          />
                        </div>
                      </TabPane>
                    )}
                    {activeTab === 'transmission' && (
                      <TabPane tabId="transmission">
                        <div className="pt-3">
                          <Select
                          // value={distData === '' ? null : discomDistributionOptions}
                            options={filteredTransmissionOptions}
                            onChange={(e) => handleSelectDistribution(e, 'TRANS')}
                            menuPortalTarget={document.body}
                            styles={{
                              menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                              menu: (base) => ({
                                ...base,
                              // position: 'relative',
                              }),
                              control: (base: any) => ({
                                ...base,
                                '&:hover': { borderColor: '#05cbddff' },
                                border: '1px solid #BFBFBF',
                                minHeight: '45px',
                                borderRadius: '5px',
                                padding: 1,
                                textAlign: 'left',
                                fontSize: '16px',
                                fontWeight: 400,
                                color: '#383838',
                                boxShadow: 'none',
                                backgroundColor: '#fff',
                                '&:focus': {
                                  borderColor: '#E1EDF8',
                                  boxShadow: 'none',
                                  color: '#575656',
                                  background: '#fff',
                                },
                              }),
                              option: (provided: any, state: { isSelected: any; }) => ({
                                ...provided,
                                color: state.isSelected ? '#383838' : '#212121',
                                padding: 15,
                                textAlign: 'left',
                                background: state.isSelected ? '#FAFCFB' : '#fff',
                                '&:hover': {
                                  background: '#FAFCFB',
                                },
                              }),
                            }}
                          // isLoading={loaderState}
                            noOptionsMessage={() => 'No Transmission Found'}
                            placeholder="Select Transmission"
                          />
                        </div>
                      </TabPane>
                    )}
                    {activeTab === 'thermal' && (
                      <TabPane tabId="thermal">
                        <div className="pt-3">
                          <span className="mandate-star">coming soon</span>
                        </div>
                      </TabPane>
                    )}
                    {activeTab === 'hydro' && (
                      <TabPane tabId="hydro">
                        <div className="pt-3">
                          <span className="mandate-star">coming soon</span>
                        </div>
                      </TabPane>
                    )}
                    {activeTab === 'renewable' && (
                      <TabPane tabId="renewable">
                        <div className="pt-3">
                          <span className="mandate-star">coming soon</span>
                        </div>
                      </TabPane>
                    )}
                  </TabContent>
                  {distributionOptions.length > 0
                    ? <h6 className="text-lite-grey mx-2 my-2">Selected Discom Type<span className="mandate-star">*</span></h6>
                    : null }
                  {distributionOptions && distributionOptions.length > 0
                    ? distributionOptions.map((dist, index) => (
                      <div key={`dist-${index.toString()}`} className="ml my-1 align-start">
                        <div className="mr">
                          <FontAwesomeIcon icon={faBolt} className="prod-tech-spec-icon" />
                        </div>
                        <div>
                          <span className="discom-liting-text">{dist.discomUtil}</span>
                          <Button className="button-icon padding-zero ml mb" onClick={() => handleRemoveDistribution(dist.discomTp)}>
                            <FontAwesomeIcon icon={faCircleXmark} className="wrong-pass-key" />
                          </Button>
                        </div>
                      </div>
                    )) : null }
                </div>
              </div>
              {/* <h6 className="text-lite-grey mx-2 my-1">Discom Type<span className="mandate-star">*</span></h6>
             */}
            </Col>
            <Col lg="12" className="pt-4">
              <Input
                placeholder="Enter Division Name"
                type="text"
                required
                name="div"
                value={registerData.div}
                disabled={registerLoader}
                onChange={handleFieldChangeRegister}
                className="powermart-input"
              />
              {/* <Select
              value={divData === '' ? null : selectedDivOptions}
              options={divoptions}
              onChange={handleSelectDivision}
              menuPortalTarget={document.body}
              styles={{
                menuPortal: (base) => ({ ...base, zIndex: 9999 }),
                menu: (base) => ({
                  ...base,
                  // position: 'relative',
                }),
                control: (base: any) => ({
                  ...base,
                  '&:hover': { borderColor: '#05cbddff' },
                  border: '1px solid #BFBFBF',
                  minHeight: '45px',
                  borderRadius: '5px',
                  padding: 1,
                  textAlign: 'left',
                  fontSize: '16px',
                  fontWeight: 400,
                  color: '#383838',
                  boxShadow: 'none',
                  backgroundColor: '#fff',
                  '&:focus': {
                    borderColor: '#E1EDF8',
                    boxShadow: 'none',
                    color: '#575656',
                    background: '#fff',
                  },
                }),
                option: (provided: any, state: { isSelected: any; }) => ({
                  ...provided,
                  color: state.isSelected ? '#383838' : '#212121',
                  padding: 15,
                  textAlign: 'left',
                  background: state.isSelected ? '#FAFCFB' : '#fff',
                  '&:hover': {
                    background: '#FAFCFB',
                  },
                }),
              }}
              isLoading={loaderDiv}
              noOptionsMessage={() => 'No Division Found'}
              placeholder="Select Division"
            /> */}
            </Col>
            <Col lg="12" className="pt-4">
              <Input
                placeholder="Enter Sub Station"
                type="text"
                required
                name="subDiv"
                value={registerData.subDiv}
                disabled={registerLoader}
                onChange={handleFieldChangeRegister}
                className="powermart-input"
              />
            </Col>
            <Col lg="12" className="pt-3">
              <div className="align-start pt-1">
                <Label check>
                  <Input
                    type="checkbox"
                    disabled={registerLoader}
                    onChange={handleTermChange}
                    className="powermart-check-box ml-1 pt-1 cursor-pointer"
                  />{' '}
                  <span className="text-lite-grey">I have read the <Button className="powermart-button-link" onClick={handleTermUse}>terms &amp; use</Button> and agree.</span>
                </Label>
              </div>
            </Col>
          </Row>
          <Row className="pt-4">
            <Col lg="12">
              <div className="align-center">
                <Button
                  className="powermart-button-solid mx-1"
                  disabled={!registerData.isAgree || registerData.discom.length === 0}
                >
                  Register
                </Button>
              </div>
            </Col>
          </Row>
          <div className="pt-4" />
        </Form>
      </Col>
      <Modal isOpen={modalConfirm} className="width-100" size="lg">
        <ModalBody>
          <div className="width-100">
            <span>Member Terms and Conditions</span>
          </div>
          <div className="pt-3">
            <span className="text-lite-grey">These Member Terms and Conditions (&quot;Terms&quot;) govern your participation as a member in our data registration program. By joining and participating in our program, you agree to abide by the following terms and conditions:</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">1. Accurate Data Collection:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">1.1. You are responsible for accurately and truthfully collecting and recording data as per the government-issued identification provided by the individuals you register.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">1.2. Any data you collect must be in compliance with local, state, and national laws and regulations.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">2. Non-Misuse of Data:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">2.1. You agree not to misuse or exploit any data collected during the registration process for personal gain, illegal activities, or unauthorized purposes.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">2.2. Data collected must be used solely for the purpose of registration and in accordance with our data handling policies.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">3. Identity Verification:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">3.1. You must not use fake or fraudulent identification for yourself during the registration process.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">3.2. Any misrepresentation of your identity is strictly prohibited.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">4. Data Integrity:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">4.1. Once data is recorded and submitted, you must not alter or manipulate it in any way, unless explicitly instructed to do so by authorized personnel.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">4.2. Any unauthorized modification of data is a breach of these terms.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">5. Confidentiality:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">5.1. You must maintain the confidentiality and security of all data collected and not disclose it to any unauthorized parties.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">5.2. Data confidentiality is critical, and violations of this policy may result in legal action.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">6. Compliance with Policies:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">6.1. You agree to comply with all policies, guidelines, and instructions provided to you by our organization.</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">6.2. Failure to comply with policies may lead to termination of your membership.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">7. Termination:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">7.1. We reserve the right to terminate your membership if you violate these terms and conditions.</span>
          </div>
          <div className="pt-2">
            <span className="text-lite-grey">8. Jurisdiction for Legal Matters:</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">8.1. In the event of any legal disputes or concerns arising from your participation in this program, both parties agree that the exclusive jurisdiction for resolution of such matters shall be the courts located in Pune, Maharashtra India..</span>
          </div>
          <div className="pt-1 ml-1">
            <span className="text-lite-grey">8.2. This provision applies to all legal matters and disputes, including but not limited to those related to the interpretation, enforcement, or breach of these terms and conditions.</span>
          </div>
          <div className="pt-3">
            <span className="text-lite-grey">By joining our data registration program, you acknowledge your understanding and acceptance of these terms and conditions. If you have any questions or concerns, please contact us before proceeding.</span>
          </div>
          <div className="pt-1">
            <span className="text-lite-grey">These terms and conditions are subject to change, and any updates will be communicated to you.</span>
          </div>
          <div className="align-center my-3">
            <Button className="powermart-button-solid mx-1" onClick={handleTermUse}>
              I Agree
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Row>
  );
};
export default RegisterUser;
