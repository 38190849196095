import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  Row, Col, Card, CardBody, Offcanvas, OffcanvasHeader, OffcanvasBody, Button, Input, Spinner, NavbarText,
  CardHeader,
  // FormGroup,
  // Label,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import {
  faCircleInfo,
  faCube,
  faFilePdf,
  faMagnifyingGlass,
  faPlusCircle,
} from '@fortawesome/free-solid-svg-icons';
// import '../../super-admin/metadata/metadata.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ProductsResult } from '../../../../services/products/list-products/get-all-product.types';
import { LoaderData } from '../../../../utils/loader';
import {
  clearCreateAddKartReq, clearKartCountReq, clearKartListReq, clearProdDescReq, createAddKartReq, getKartCountReq, getProductsListReq,
  prodDescReq,
} from '../../../../store/powerMart/actions';
import { errorAlert } from '../../../../utils/alert';
import { APIHeader, ApiLIMIT } from '../../../../utils/constants';
import { PostAddKartInput } from '../../../../services/products/add-kart/post-addkart.types';
import ImageGallery from './thumbnail-image';
import {
  getDiscomSessionStorage, getDiscomUtilSessionStorage, getTokenFromSessionStorage, getUserProfileSessionStorage,
} from '../../../../utils/service/localstorage-service';
import { ProductDescResult } from '../../../../services/products/prod-desc/product-desc.types';
import ShowMoreText from '../../../../utils/showmore-text';
import Tooltip from '../../../../utils/Tooltip/Tooltip';
// import { CorpResult } from '../../../../services/meta-data/list-corp/list-corp.types';

export const ProductPrivateList: React.FC = () => {
  const dispatch = useDispatch();
  const history = useNavigate();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const discomType = getDiscomSessionStorage();
  const discomUtil = getDiscomUtilSessionStorage();
  const [userTp, setUserTp] = React.useState('');
  const [userId, setUserId] = React.useState('');
  const [discomTp, setDiscomTp] = React.useState('');
  const [discomIdent, setDiscomIdent] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [alertKartMsg, setAlertKartMsg] = React.useState('');
  const [loaderAddKart, setLoaderAddKart] = React.useState(false);
  // const [taxInfoOpen, setTaxInfoOpen] = React.useState<Record<string, boolean>>({});
  const [prodDetailsOpen, setProdDetailsOpen] = React.useState<Record<string, boolean>>({});
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [showSuccKartAlert, setShowSuccKartAlert] = React.useState(false);
  const [showFailedKartAlert, setShowFailedKartAlert] = React.useState(false);
  const [loaderProduct, setLoaderProduct] = React.useState(false);
  const [loaderIndex, setLoaderIndex] = React.useState<number | null>(null);
  const [loaderProdDesc, setLoaderProdDesc] = React.useState(false);
  // const [loaderDescIndex, setLoaderDescIndex] = React.useState<number | null>(null);
  const addKartResponse = useSelector((state: RootState) => state.powerMart.createNewKart);
  const listProducts = useSelector((state: RootState) => state.powerMart.getProductList);
  const prodDescRes = useSelector((state: RootState) => state.powerMart.prodDesc);
  const [prodDescTp, setProdDescTp] = React.useState('');
  const [evalKeyText, setEvalKeyText] = React.useState('');
  const [evalTpText, setEvalTpText] = React.useState('');
  // const [radioSelected, setRadioSelected] = React.useState('');
  const [searchText, setSearchText] = React.useState('');
  const [loadingMore, setLoadingMore] = React.useState(false);
  // const corpResData = useSelector((state: RootState) => state.powerMart.getCorpMetaList);
  // const [corpListData, setCorpListData] = React.useState<CorpResult[]>([]);
  const [selectedProdIndex, setSelectedProdIndex] = React.useState<number | null>(null);
  const [productTechSpec, setProductTechSpec] = React.useState<ProductDescResult>({
    data: {
      prodDesc: [],
      techSpec: [],
    },
    isError: false,
    message: '',
  });
  const [productDesc, setProductDesc] = React.useState<ProductDescResult>({
    data: {
      prodDesc: [],
      techSpec: [],
    },
    isError: false,
    message: '',
  });
  const [productsData, setProductsData] = React.useState<ProductsResult>({
    tCnt: 0,
    fCnt: 0,
    prodData: [],
    lastEvalKey: {
      ProdTp: '',
      ProdId: '',
    },
  });
  const [showProdDesc, setShowProdDesc] = React.useState(Array(productsData.prodData.length).fill(false));
  const [kartTxnCnt, setKartTxnCnt] = React.useState<Record<number, number>>({});
  // const [kartTxnInputs, setKartTxnInputs] = React.useState<KartInfo[]>(
  //   Array(productsData.prodData.length).fill({
  //     cnt: -1,
  //     pSts: '',
  //     ProdTp: '',
  //     ProdId: '',
  //     prodCst: '',
  //     prodGst: '',
  //   }),
  // );
  const [kartInput, setKartInput] = React.useState<PostAddKartInput>({
    tagIn: '',
    discomId: '',
    UsrTp: '',
    UsrId: '',
    mSts: '',
    ProdId: '',
    cnt: 0,
  });

  const handleHideClick = (index: number) => {
    setShowProdDesc((prevState) => {
      const updatedState = [...prevState];
      updatedState[index] = false;
      return updatedState;
    });
    setSelectedProdIndex(null);
  };

  // const handleShowAllClick = (prodTp: string, prodId: string, index: number) => {
  //   setLoaderDescIndex(index);
  //   setLoaderProdDesc(true);
  //   setProdDescTp('TECH_SPEC');
  //   dispatch(prodDescReq({
  //     requestType: APIHeader.REQ_GET_PRODUCT_DESC,
  //     inSts: 'TECH_SPEC',
  //     ProdTp: prodTp,
  //     ProdId: prodId,
  //   }));
  //   setSelectedProdIndex(index);
  // };

  const toggleProdDetails = (target: string, prodTp: string, prodId: string) => {
    setProdDescTp('PROD_DESC');
    setLoaderProdDesc(true);
    dispatch(prodDescReq({
      requestType: APIHeader.REQ_GET_PRODUCT_DESC,
      inSts: 'PROD_DESC',
      ProdTp: prodTp,
      ProdId: prodId,
    }));
    setProdDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };

  React.useEffect(() => {
    if (prodDescRes.isError && prodDescRes.message !== '') {
      // setLoaderProdDesc(false);
      setLoaderProdDesc(false);
      setAlertMsg(prodDescRes.message);
      setErrorShowAlert(true);
    }
  }, [prodDescRes.isError, prodDescRes.message]);

  React.useEffect(() => {
    if (!prodDescRes.isError && prodDescRes.message === 'executed') {
      // setLoaderProdDesc(false);
      setLoaderProdDesc(false);
      if (prodDescTp === 'PROD_DESC') {
        setProductDesc(prodDescRes);
      } else if (prodDescTp === 'TECH_SPEC' && selectedProdIndex !== null) {
        setProductTechSpec(prodDescRes);
        setShowProdDesc((prevState) => {
          const updatedState = [...prevState];
          updatedState[selectedProdIndex] = true;
          return updatedState;
        });
      }
      dispatch(clearProdDescReq());
    }
  }, [prodDescRes.isError, prodDescRes.message, prodDescRes.data, selectedProdIndex, prodDescTp, clearProdDescReq]);

  const toggleDescProdDetails = (target: string) => {
    setProdDetailsOpen((prevState) => ({
      ...prevState,
      [target]: !prevState[target],
    }));
  };
  React.useEffect(() => {
    if (listProducts.data.lastEvalKey !== null) {
      setEvalKeyText(listProducts.data.lastEvalKey.ProdId);
      setEvalTpText(listProducts.data.lastEvalKey.ProdTp);
    }
  }, [listProducts.data.lastEvalKey]);

  React.useEffect(() => {
    if (discomTp !== '') {
      setLoaderProduct(true);
      setProductsData({
        tCnt: 0,
        fCnt: 0,
        prodData: [],
        lastEvalKey: {
          ProdTp: '',
          ProdId: '',
        },
      });
      dispatch(getProductsListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PRV,
        prodTp: discomTp,
        isPublic: false,
        token: tokenData,
        limit: ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        isSearch: 'NO',
      }));
    }
  }, [discomTp]);

  const fetchMoreData = () => {
    if (evalKeyText !== '' && evalTpText !== '') {
      setLoaderProduct(true);
      dispatch(
        getProductsListReq({
          userType: userTp,
          requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PRV,
          isPublic: false,
          token: tokenData,
          prodTp: discomTp,
          limit: ApiLIMIT.LIMIT,
          evalKey: evalKeyText,
          evalTp: evalTpText,
          isFilter: 'NO',
          isSearch: 'NO',
        }),
      );
    }
  };

  // const handleFilterData = (sellerId: string) => {
  //   setRadioSelected(sellerId);
  //   setLoaderProduct(true);
  //   dispatch(
  //     getProductsListReq({
  //       userType: userTp,
  //       requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PRV,
  //       isPublic: false,
  //       token: tokenData,
  //       prodTp: discomTp,
  //       limit: ApiLIMIT.LIMIT,
  //       evalKey: '',
  //       evalTp: '',
  //       isFilter: 'YES',
  //       isSearch: 'NO',
  //       filterBy: sellerId,
  //     }),
  //   );
  // };

  const handleSearchChange = (e:any) => {
    setSearchText(e.target.value);
  };

  const handleSearchProduct = () => {
    setProductsData({
      tCnt: 0,
      fCnt: 0,
      prodData: [],
      lastEvalKey: {
        ProdTp: '',
        ProdId: '',
      },
    });
    setLoaderProduct(true);
    dispatch(
      getProductsListReq({
        userType: userTp,
        requestType: APIHeader.REQ_GET_ALL_PRODUCTS_PRV,
        isPublic: false,
        token: tokenData,
        prodTp: discomTp,
        limit: ApiLIMIT.LIMIT,
        evalKey: '',
        evalTp: '',
        isFilter: 'NO',
        searchBy: searchText,
        isSearch: 'YES',
      }),
    );
  };

  React.useEffect(() => {
    if (listProducts.isError && listProducts.message !== '') {
      setLoaderProduct(false);
      setAlertMsg(listProducts.message);
      setErrorShowAlert(true);
    }
  }, [listProducts.isError, listProducts.message, discomTp]);

  React.useEffect(() => {
    if (!listProducts.isError && listProducts.message === 'executed') {
      setLoaderProduct(false);
      // if (radioSelected !== '') {
      //   setProductsData(listProducts.data);
      //   setLoadingMore(false);
      // } else
      if (searchText !== '') {
        setProductsData(listProducts.data);
        setLoadingMore(false);
      } else if (listProducts.data.lastEvalKey !== null) {
        const newData = listProducts.data.prodData.filter((item) => !productsData.prodData.some((existingItem) => existingItem.ProdId === item.ProdId));
        setProductsData((prevData) => ({
          ...prevData,
          tCnt: listProducts.data.tCnt,
          fCnt: listProducts.data.fCnt,
          prodData: [...prevData.prodData, ...newData],
          lastEvalKey: listProducts.data.lastEvalKey,
        }));
        setEvalKeyText(listProducts.data.lastEvalKey.ProdId);
        setEvalTpText(listProducts.data.lastEvalKey.ProdTp);
        setLoadingMore(true);
      } else {
        setProductsData((prevData) => ({
          ...prevData,
          tCnt: listProducts.data.tCnt,
          fCnt: listProducts.data.fCnt,
          prodData: [...prevData.prodData, ...listProducts.data.prodData],
        }));
        setLoadingMore(false);
      }
      // dispatch(clearProductsListReq());
    }
  }, [listProducts.isError, listProducts.message, listProducts.data.prodData, listProducts.data.lastEvalKey]);

  // React.useEffect(() => {
  //   if (!listProducts.isError && listProducts.message === 'executed') {
  //     setLoaderProduct(false);
  //     setProductsData(listProducts.data);
  //   }
  // }, [listProducts.isError, listProducts.message, listProducts.data]);

  React.useEffect(() => {
    if (discomType) {
      setDiscomTp(discomType);
    }
  }, [discomType]);

  React.useEffect(() => {
    if (discomUtil) {
      setDiscomIdent(discomUtil);
      // setKartInput({
      //   ...kartInput,
      //   discomId: discomUtil,
      // });
    }
  }, [discomUtil]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
      setUserId(userInitTp && JSON.parse(userInitTp).profileData.UserCd);
      setKartInput({
        ...kartInput,
        // divId: userInitTp && JSON.parse(userInitTp).profileData.divDet && JSON.parse(userInitTp).profileData.divDet.div,
        // st: userInitTp && JSON.parse(userInitTp).profileData.divDet && JSON.parse(userInitTp).profileData.divDet.st,
        UsrTp: userInitTp && JSON.parse(userInitTp).profileData.UserTp,
        UsrId: userInitTp && JSON.parse(userInitTp).profileData.UserCd,
        mSts: 'KART',
      });
    }
  }, [
    // userInitTp && JSON.parse(userInitTp).profileData.divDet.div,
    // userInitTp && JSON.parse(userInitTp).profileData.divDet.divst,
    userInitTp && JSON.parse(userInitTp).profileData.UserTp,
    userInitTp && JSON.parse(userInitTp).profileData.UserCd,
  ]);

  const handleQuantityChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    prodId: string,
    sellerId: string,
    index: number,
  ) => {
    setAlertKartMsg('');
    const { value } = e.target;
    const cntValue = value === '' ? 0 : parseInt(value);
    if (cntValue > 0) {
      setKartTxnCnt((prevState) => ({
        ...prevState,
        [index]: cntValue,
      }));
    } else {
      setKartTxnCnt((prevState) => ({
        ...prevState,
        [index]: 0,
      }));
    }

    // Check if any other index's quantity is filled, if yes, reset it to 0 and enable Add to Cart
    Object.entries(kartTxnCnt).forEach(([key, val]) => {
      if (parseInt(key) !== index && val > 0) {
        setKartTxnCnt((prevState) => ({
          ...prevState,
          [key]: 0,
        }));
      }
    });

    setKartInput((prevKartInput) => ({
      ...prevKartInput,
      cnt: Number(value),
      ProdId: prodId,
      tagIn: sellerId,
    }));
  };

  // const toggleTaxInfo = (target: string) => {
  //   setTaxInfoOpen((prevState) => ({
  //     ...prevState,
  //     [target]: !prevState[target],
  //   }));
  // };

  const handleAddtoKart = (index: number) => {
    setLoaderIndex(index);
    setLoaderAddKart(true);
    const updatedKartInput = {
      ...kartInput,
      discomId: discomIdent,
    };
    Promise.resolve(updatedKartInput).then((resolvedInput) => {
      dispatch(createAddKartReq({
        userType: userTp,
        requestType: APIHeader.REQ_ADD_PROD_TXN_BY_USER,
        inputBody: resolvedInput,
        token: tokenData,
      }));
    });
  };

  React.useEffect(() => {
    if (addKartResponse.error && addKartResponse.message !== '') {
      setLoaderAddKart(false);
      setShowFailedKartAlert(true);
      setAlertKartMsg(addKartResponse.message);
      dispatch(clearCreateAddKartReq());
    }
  }, [addKartResponse.error, addKartResponse.message]);

  React.useEffect(() => {
    if (!addKartResponse.error && addKartResponse.message !== '') {
      Promise.resolve(dispatch(clearKartCountReq())).then(() => {
        // setProdStatusSessionStorage('KART');
        dispatch(getKartCountReq({
          userType: userTp,
          token: tokenData,
          requestType: APIHeader.REQ_GET_KART_COUNT,
          UserTp: userTp,
          UserCd: userId,
          status: 'KART',
          discTp: discomIdent,
          // discomId: discomIdent,
        }));
        setAlertKartMsg(addKartResponse.message);
        setLoaderAddKart(false);
        setShowSuccKartAlert(true);
        dispatch(clearCreateAddKartReq());
      }).then(() => { dispatch(clearKartListReq()); });
    }
  }, [addKartResponse.error, addKartResponse.message, userTp, tokenData, userId, discomIdent]);

  const handleUserManualDownload = ([downloadUrl]: string[], prodId: string) => {
    if (downloadUrl) {
      const anchor = document.createElement('a');
      anchor.href = downloadUrl;
      anchor.download = `${prodId}.pdf`;
      anchor.target = '_blank';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    }
  };

  // React.useEffect(() => {
  //   dispatch(getCorpListReq({
  //     requestType: APIHeader.REQ_ALL_CORP_META,
  //   }));
  // }, []);

  // React.useEffect(() => {
  //   if (!corpResData.isError) {
  //     setCorpListData(corpResData.corpData);
  //   }
  // }, [corpResData.corpData]);

  return (
    <Row className="mx-1">
      { loaderProduct ? <LoaderData /> : null}
      { loaderProdDesc ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <Col lg={{ size: 3, order: 1 }} xs={{ order: 1 }}>
        <div className="pt-3">
          {/* <div className="my-3 footer-visible-N mx-5">
            <Button className="powermart-button-fullwidth" onClick={() => { history('/puser/add-my-products'); }}>
              ADD CUSTOM PRODUCT
              <FontAwesomeIcon icon={faPlusCircle} className="mx-2" />
            </Button>
          </div> */}
          <div className="my-3 mx-1 align-center">
            <Button className="powermart-button-fullwidth" onClick={() => { history('/puser/add-my-products'); }}>
              ADD CUSTOM PRODUCT
              <FontAwesomeIcon icon={faPlusCircle} className="mx-2" />
            </Button>
          </div>
          <hr className="hr-horz" />
          <Card className="key-highlights-cards">
            <CardHeader className="input-search-layer">
              <div className="my-2 align-center">
                <div className="input-wrapper">
                  <Input
                    type="text"
                    placeholder="Search by Product Name"
                    value={searchText}
                    onChange={handleSearchChange}
                  />
                  <Button type="button" onClick={handleSearchProduct}>
                    <FontAwesomeIcon icon={faMagnifyingGlass} />
                  </Button>
                </div>
              </div>
            </CardHeader>
            {/* <CardBody className="price-range-visible">
              <div className="pt-3">
                <span className="listing-price-text mb-1">Filter by Seller</span>
                <div className="pt-3">
                  {corpListData && corpListData.length > 0
                    ? corpListData.map((corpDet, corpIndex) => (
                      <div className="pt-1" key={`corpDet-${corpIndex.toString()}`}>
                        <FormGroup>
                          <Label check className="seller-filter-text">
                            <Input
                              type="radio"
                              role="switch"
                              name={corpDet.MetaId}
                              onClick={() => handleFilterData(corpDet.MetaId)}
                              checked={radioSelected === corpDet.MetaId}
                              className="powermart-check-box mr-1 cursor-pointer"
                            />
                            {corpDet.nme}
                          </Label>
                        </FormGroup>
                      </div>
                    )) : <div className="pt-4 align-center"><span className="form-collapse-card-header">No Sellers Found</span></div>}
                </div>
                <div className="pt-1" />
              </div>
            </CardBody>
            <CardFooter className="border-up-listing price-range-visible">
              <div className="my-2">
                <Button className="powermart-button-fullwidth" onClick={() => { history('/puser/add-my-products'); }}>
                  ADD CUSTOM PRODUCT
                  <FontAwesomeIcon icon={faPlusCircle} className="mx-2" />
                </Button>
              </div>
            </CardFooter> */}
          </Card>
        </div>
      </Col>
      <Col lg={{ size: 9, order: 2 }} xs={{ order: 2 }}>
        <span className="text-lite-grey mx-2">Product Count: {productsData && productsData.prodData.length} / {productsData.tCnt}</span>
        <InfiniteScroll
          dataLength={productsData.prodData.length}
          next={fetchMoreData}
          hasMore={loadingMore}
          loader={loaderProduct}
        >
          <Row className="pt-1">
            {productsData && productsData.prodData.length > 0
              ? productsData.prodData.map((prodDet, index) => (
                <Col lg={{ size: 12, order: 2 }} xs={{ order: 2 }} key={`pending-${index.toString()}`} className="my-1">
                  <Card className="card-borderless my-1">
                    <CardHeader className="padding-zero form-collapse-card-header">
                      <div className="align-center">
                        <NavbarText onClick={() => toggleProdDetails(`prodDetails-${index}`, prodDet.ProdTp, prodDet.ProdId)}>
                          <span className="product-name-header-link">{prodDet.nme}</span>
                        </NavbarText>
                        <Tooltip
                          content="User Manual"
                          position="bottom"
                          customClassName={`userMaual-${index}`}
                        >
                          <Button id={`userMaual-${index}`} className="button-icon" onClick={() => handleUserManualDownload(prodDet.usrManual, prodDet.ProdId)}>
                            <FontAwesomeIcon icon={faFilePdf} className="button-icon-small-pdf" />
                          </Button>
                        </Tooltip>
                      </div>
                    </CardHeader>
                    <CardBody className="padding-zero my-1">
                      <Row className="mx-1">
                        <Col lg="4" xs="12">
                          <div className="align-center">
                            <span className="meta-waranty-lite">{prodDet.wrnty} months warranty</span>
                          </div>
                          <div className="mx-1">
                            <ImageGallery imageGal={prodDet.prodUrl} isDesc={false} />
                          </div>
                          {/* <div className="align-center pt-1 mr-1">
                      <CardImg alt="logo" className="product-image" src={prodDet.prodUrl} />
                    </div> */}
                          {/* <div className="align-center pt-2 my-2">
                      <Button className="powermart-button-red" onClick={() => toggleProdDetails(`prodDetails-${index}`)}>
                        Product Description
                      </Button>
                    </div> */}
                        </Col>
                        <Col lg="4" xs="12">
                          <div className="my-2 meta-margin-mobile">
                            <div className="pt-2" key={`prodDet-${index.toString()}`}>
                              {showProdDesc[index] && (
                              <div>
                                {productTechSpec.data.techSpec.map((prodSpecData, pSepcIndx) => (
                                  <div key={`pSpec-${pSepcIndx.toString()}`} className="my-2 align-start">
                                    <div className="mr">
                                      <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                    </div>
                                    <div>
                                      <span className="prod-tech-spec-text"><ShowMoreText text={prodSpecData} wordLimit={10} /></span>
                                    </div>
                                  </div>
                                ))}
                                <div className="align-end">
                                  <Button className="powermart-button-link" onClick={() => handleHideClick(index)}>
                                    hide...
                                  </Button>
                                </div>
                              </div>
                              )}
                              {!showProdDesc[index] && (
                              <div>
                                {prodDet.techSpec.map((techSpecData, tSepcIndx) => (
                                  <div key={`tSpec-${tSepcIndx.toString()}`} className="my-2 align-start">
                                    <div className="mr">
                                      <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                    </div>
                                    <div>
                                      <span className="prod-tech-spec-text"><ShowMoreText text={techSpecData} wordLimit={10} /></span>
                                    </div>
                                  </div>
                                ))}
                                {/* <div className="align-end">
                                  <Button className="powermart-button-link" onClick={() => handleShowAllClick(prodDet.ProdTp, prodDet.ProdId, index)}>
                                    show all {loaderProdDesc && index === loaderDescIndex ? <><Spinner type="grow" className="spin-grow-loader" /><Spinner type="grow" className="spin-grow-loader" /><Spinner type="grow" className="spin-grow-loader" /></> : null}
                                  </Button>
                                </div> */}
                              </div>
                              )}
                            </div>
                            {/* <div className="pt-2">
                        {prodDet.techSpec.map((techSpecData, tSepcIndx) => (
                          <div key={`tSpec-${tSepcIndx.toString()}`} className={`my-2 align-start ${hideAfterIndex !== null && tSepcIndx > hideAfterIndex ? 'd-none' : ''}`}>
                            <div className="mr">
                              <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                            </div>
                            <div>
                              <span className="prod-tech-spec-text">{techSpecData}</span>
                            </div>
                          </div>
                        ))}
                        <div className="align-end">
                          {hideAfterIndex === null && (
                          <Button className="powermart-button-link" onClick={() => handleHideClick(3)}>hide...</Button>
                          )}
                          {hideAfterIndex !== null && (
                          <Button className="powermart-button-link" onClick={() => handleShowAllClick(prodDet.ProdTp, prodDet.ProdId)}>show all...</Button>
                          )}
                        </div>
                      </div> */}
                          </div>
                        </Col>
                        <Col lg="4" xs="12">
                          <div className="meta-margin-mobile">
                            <Row className="pt-1">
                              <Col xs="12">
                                <div className="align-center pt-2">
                                  <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                                  <Tooltip
                                    content={(
                                      <div>
                                        <div className="align-center">
                                          <span className="td-text-highlight">Cost Breakup</span>
                                        </div>
                                        <div className="pt-2">
                                          Cost: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.costVal))}
                                        </div>
                                        <div className="pt-1">
                                          GST: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.gstVal))}
                                        </div>
                                      </div>
                                      )}
                                    position="bottom"
                                    customClassName={`taxInfo-${index}`}
                                  >
                                    <Button className="button-icon" id={`taxInfo-${index}`}>
                                      <FontAwesomeIcon className="text-lite-grey" icon={faCircleInfo} />
                                    </Button>
                                  </Tooltip>
                                </div>
                                <div className="align-center">
                                  <h6 className="text-lite-grey">(inclusive of taxes)
                                  </h6>
                                </div>
                              </Col>
                              <Col xs="12">
                                <Row className="align-center my-1">
                                  <Col lg="6">
                                    <div className="my-3">
                                      <h6 className="text-lite-grey">Quantity</h6>
                                      <Input
                                        placeholder="Quantity"
                                        type="number"
                                        required
                                        name="cnt"
                                        min="0"
                                        value={kartTxnCnt[index] || ''}
                                        onChange={(e) => handleQuantityChange(
                                          e,
                                          prodDet.ProdId,
                                          prodDet.tagIn,
                                          index,
                                        )}
                                        className="powermart-input"
                                      />
                                    </div>
                                  </Col>
                                </Row>
                              </Col>
                              <Col xs="12">
                                <div className="align-center pt-3 mx-3">
                                  <Button
                                    disabled={loaderAddKart || kartInput.ProdId !== prodDet.ProdId || kartInput.cnt <= 0}
                                    className="powermart-button-fullwidth mx-1"
                                    onClick={() => handleAddtoKart(index)}
                                  >
                                    <div className="align-center">
                                      ADD ITEMS TO CART
                                      {loaderAddKart && index === loaderIndex ? <Spinner className="alert-loader mx-2" /> : null}
                                    </div>
                                  </Button>
                                </div>
                                <div className="align-center pt-1">
                                  {showFailedKartAlert && kartInput.ProdId === prodDet.ProdId
                                    ? <span className="wrong-pass-key">{alertKartMsg}</span> : null}
                                  {showSuccKartAlert && kartInput.ProdId === prodDet.ProdId
                                    ? <span className="correct-pass-key">{alertKartMsg}</span> : null}
                                </div>
                                {/* <div className="align-center pt-1">
                            <h6 className="text-lite-grey">({!kartTxnInputs[index]?.cnt} items selected)</h6>
                          </div> */}
                              </Col>
                            </Row>
                            {/* <div className="pt-3">
                        <div className="align-center">
                          <FontAwesomeIcon icon={faIndianRupeeSign} className="meta-price-icon mx-2" />
                          <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                        </div>
                        <div className="align-center">
                          <span className="meta-price-lite">Cost: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.cost))}</span>
                          <FontAwesomeIcon icon={faIndianRupeeSign} className="text-lite-grey mx-1" />
                        </div>
                        <div className="align-center">
                          <span className="meta-price-lite">GST: {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.gst))}</span>
                          <FontAwesomeIcon icon={faIndianRupeeSign} className="text-lite-grey mx-1" />
                        </div>
                      </div> */}
                          </div>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                  <Offcanvas
                    direction="end"
                    isOpen={prodDetailsOpen[`prodDetails-${index}`]}
                    toggle={() => toggleDescProdDetails(`prodDetails-${index}`)}
                  >
                    <OffcanvasHeader toggle={() => toggleDescProdDetails(`prodDetails-${index}`)} className="margin-0 mx-1" />
                    <div className="align-start border-bottom-box">
                      <span className="product-name-details-header my-1">{prodDet.nme}</span>
                    </div>
                    <OffcanvasBody>
                      <Row>
                        <Col xs="12">
                          <div className="pt-2">
                            <div className="align-center">
                              <span className="meta-waranty-lite">Warranty {prodDet.wrnty} months</span>
                            </div>
                          </div>
                          <ImageGallery imageGal={prodDet.prodUrl} isDesc />
                          {/* <div className="align-center">
                      <CardImg alt="logo" className="product-image border-image1" src={prodDet.prodUrl} />
                    </div> */}
                        </Col>
                        <Col xs="12">
                          <div className="my-2 meta-margin-mobile">
                            <div className="pt-4">
                              <div className="align-center">
                                <span className="meta-price-main">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.tCost))}</span>
                              </div>
                              <div className="align-center meta-price-lite">
                                (
                                <span className="meta-price-lite">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.costVal))}</span>
                                <span className="mx-2">+</span>
                                <span className="meta-price-lite">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(prodDet.gstVal))}</span>
                                )
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="my-4 meta-margin-mobile">
                            <div className="align-start pt-3">
                              <span className="product-name-header">Technical Specification</span>
                            </div>
                            <div className="pt-3">
                              {productDesc.data.techSpec.map((techSpecData, tSepcIndx) => (
                                <div key={`prodDesc-${tSepcIndx.toString()}`} className="my-1 align-start">
                                  <div className="mr">
                                    <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                  </div>
                                  <div>
                                    <span className="prod-tech-spec-text">{techSpecData}</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Col>
                        <Col xs="12">
                          <div className="my-2 meta-margin-mobile">
                            <div className="align-start pt-3">
                              <span className="product-name-header">Product Description</span>
                            </div>
                            <div className="pt-1">
                              {productDesc.data.prodDesc.map((descData, descIndx) => (
                                <div key={`prodDesc-${descIndx.toString()}`} className="my-3 align-start">
                                  <div className="mr">
                                    <FontAwesomeIcon icon={faCube} className="prod-tech-spec-icon" />
                                  </div>
                                  <div>
                                    <span className="prod-tech-spec-text">{ descData }</span>
                                  </div>
                                </div>
                              ))}
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </OffcanvasBody>
                  </Offcanvas>
                </Col>
              )) : <div className="pt-4 align-center"><span className="form-collapse-card-header">No Product Found</span></div>}
          </Row>
        </InfiniteScroll>
      </Col>
    </Row>
  );
};
export default ProductPrivateList;
