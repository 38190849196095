import { GetAllOrdersResponse, GetAllOrdersResults } from './get-all-orders.service.types';

export const GetAllOrdersTransform: (response: GetAllOrdersResponse) => GetAllOrdersResults = (getAllOrdersResults) => {
  const { data, is_error, message } = getAllOrdersResults;
  const result: GetAllOrdersResults = {
    data: [{
      fCnt: 0,
      ordId: '',
      usrOrg: '',
      usrInfo: {
        addr: '',
        div: '',
        eId: '',
        nme: '',
        ph: '',
        st: '',
        subDiv: '',
      },
      mSts: '',
      txnId: '',
      txnDt: '',
      ordDt: '',
      sumCost: '',
      sumGst: '',
      sumTotal: '',
      prodTxnData: [],
    }],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.data = data ?? {};
    result.message = 'executed';
  }
  return result;
};
