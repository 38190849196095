import React from 'react';
import '../cart.scss';
import { KartReviewProps } from '../cart.type';
import TxnCheckout from '../checkout-comp/checkout';
import { CartApprovedPrivateList } from './kart-approved';

export const KartCheckOutPrivateComponent: React.FC<KartReviewProps> = ({ productsData }) => (
  <div className="mx-2 pt-1">
    {productsData.prodTxnData.length > 0
      ? (
        <div>
          <CartApprovedPrivateList productsData={productsData} />
          <div className="mx-2 pt-5">
            <TxnCheckout productsData={productsData} />
          </div>
          <div className="pt-5" />
        </div>
      )
      : (
        <div className="pt-5 my-5 align-center">
          <span className="form-collapse-card-header">No Pending Transaction. Add New</span>
        </div>
      )}
  </div>
);
export default KartCheckOutPrivateComponent;
