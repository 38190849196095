import React from 'react';
import { Row, Col } from 'reactstrap';
import { ProductPrivateList } from '../products/product-list';

export const PUserDashboard: React.FC = () => (
  <div className="label-margin-to-nav">
    <div className="mb-1 pt-2">
      <span className="powermart-main-label">
        Quick View - PowerMart All Product
      </span>
    </div>
    <Row className="pt-4">
      <Col xs="12">
        <ProductPrivateList />
      </Col>
    </Row>
    <div className="mb-3" />
  </div>
);
