import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import {
  Routes, Route,
  NavLink,
} from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  faListUl, faRightFromBracket, faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './sadmin.scss';
import {
  Row, Col, NavItem,
  Button,
  Nav,
} from 'reactstrap';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { CXUnauthorized } from '../../public/unauthorized/unauthorized.component';
import ModalPasskeyUpdate from '../../../components/exclusive/passkey-reset/modal-reset-passkey.component';
import { SuperAdminDashboard } from '../../../components/exclusive/super-admin/dashboard/sadmin-dashboard.component';
import SAdminSideDrawer from './side-drawer.component';
import UserStatusComponent from '../../../components/exclusive/super-admin/user-management/user.component';
import MetaDataComponent from '../../../components/exclusive/super-admin/metadata/metadata.component';
import { clearKeysFromSessionStorage, getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../utils/service/localstorage-service';
import { MyProfileComponent } from '../../../components/exclusive/my-profile/profile';
import Popover from '../../../utils/Popover/Popover';
import Modal from '../../../utils/Modal/Modal';

export const ExclusiveRoutes: React.FC = () => (
  <Routes>
    <Route path="dashboard" element={<SuperAdminDashboard />} />
    <Route path="user-status" element={<UserStatusComponent />} />
    <Route path="metadata" element={<MetaDataComponent />} />
    <Route path="my-profile" element={<MyProfileComponent />} />
  </Routes>
);

export const SuperAdminComponent: React.FC = () => {
  const history = useNavigate();
  const [click, setClick] = React.useState(true);
  const tokenData = getTokenFromSessionStorage();
  const userProfile = getUserProfileSessionStorage();
  const [emailId, setEmailId] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [resetPassKey, setResetPassKey] = useState(true);
  const [isClickClose, setIsClickClose] = useState(false);
  const [isResetPassKey, setIsResetPasskey] = useState(false);
  const [openUserNotification, setOpenUserNotification] = useState(false);
  const passKeyModal = () => setResetPassKey(!resetPassKey);
  const userInfoRef = React.useRef<HTMLImageElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const [filePreview, setFilePreview] = useState('');
  // const [imageURL, setImageUrl] = useState('/images/register-login.png');

  const handleClick = () => {
    setClick(!click);
  };

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.isPKReset) {
      setIsResetPasskey(userProfile && JSON.parse(userProfile).profileData.isPKReset);
    }
  }, [isResetPassKey, userProfile && JSON.parse(userProfile).profileData.isPKReset]);

  // React.useEffect(() => {
  //   if (tokenData !== '') {
  //     const decodedToken: any = jwt_decode(tokenData);
  //     if (decodedToken !== undefined || decodedToken !== null) {
  //       if (decodedToken.exp * 1000 < new Date().getTime()) {
  //         history('/login/sadmin#logn');
  //       }
  //     }
  //   }
  // }, [tokenData, history]);

  React.useEffect(() => {
    let shouldRedirect = false;
    if (tokenData !== '') {
      const decodedToken: any = jwt_decode(tokenData);
      if (decodedToken !== undefined || decodedToken !== null) {
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          shouldRedirect = true;
          history('/login/sadmin#logn');
        }
      }
    }
    if (shouldRedirect) {
      window.location.reload();
    }
  }, [tokenData, history]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.eId === '') {
      setEmailId('');
    } else {
      setEmailId(userProfile && JSON.parse(userProfile).profileData.eId);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.eId]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.ph === '') {
      setPhone('');
    } else {
      setPhone(userProfile && JSON.parse(userProfile).profileData.ph);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.ph]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.nme === '') {
      setName('');
    } else {
      setName(userProfile && JSON.parse(userProfile).profileData.nme);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.nme]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.avatar) {
      setFilePreview(userProfile && JSON.parse(userProfile).profileData.avatar);
    }
  }, [
    userProfile && JSON.parse(userProfile).profileData.avatar,
  ]);

  const handleUserNotification = () => {
    setOpenUserNotification(!openUserNotification);
  };

  // React.useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (userInfoRef.current && !userInfoRef.current.contains(event.target as Node)) {
  //       setOpenUserNotification(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);
  // React.useEffect(() => {
  //   const handleClickOutside = (event: MouseEvent) => {
  //     if (
  //       userInfoRef.current
  //       && !userInfoRef.current.contains(event.target as Node)
  //       && popoverRef.current
  //       && !popoverRef.current.contains(event.target as Node)
  //     ) {
  //       setOpenUserNotification(false);
  //     }
  //   };

  //   document.addEventListener('mousedown', handleClickOutside);
  //   return () => {
  //     document.removeEventListener('mousedown', handleClickOutside);
  //   };
  // }, []);

  return (
    <div>
      {tokenData !== '' ? (
        <div>
          {userProfile && JSON.parse(userProfile).profileData.isPKReset ? (
            <Modal
              show={resetPassKey || false}
              onClose={passKeyModal}
              disCancel={false}
              targetId="passwordResetAdmin"
              title="Update Password"
              size="MD"
            >
              <ModalPasskeyUpdate setIsResetPasskey={setIsResetPasskey} userType="SADM" />
            </Modal>

          )
            : null}
          <Row className="navbar-sadmin">
            <Col lg="5" xs="8">
              <div className="align-start mx-1">
                <div className="bg-icon-nav-dash pt-3">
                  <NavLink to="dashboard">
                    <img
                      className="logo-bg icon-powermart-logo cursor-pointer"
                      src="/powerMart_white.png"
                      alt="powerMart"
                      id="powerImage"
                    />
                  </NavLink>
                </div>
                <span className="vert-line-dash">|</span>
                <NavLink
                  className="navbar-sadmin-icon"
                  onClick={() => { handleClick(); }}
                  to="#"
                >
                  {click ? (
                    <FontAwesomeIcon
                      icon={faListUl}
                      className={`navbar-sadmin-open-btn ${!click ? 'fade-out' : 'fade-in'}`}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      className={`navbar-sadmin-close-btn ${!click ? 'fade-in' : 'fade-out'}`}
                    />
                  )}
                </NavLink>
              </div>
            </Col>
            <Col lg="7" xs="4">
              <div className="align-end">
                <div className="menu-image-avatar">
                  <div className="align-center">
                    <Popover
                      header={(
                        <div className="align-center mx-2">
                          <span className="sadmin-user-name-text">{name}</span>
                        </div>
)}
                      content={(
                        <div ref={popoverRef} className="align-center">
                          <ul className="menu-list pt-3">
                            <NavItem className="menu-list">
                              <div className="align-center pt-2">
                                <span className="text-lite-grey">{emailId}</span>
                              </div>
                              <div className="align-center">
                                <span className="text-lite-grey">+91 {phone}</span>
                              </div>
                            </NavItem>
                            <NavItem className="menu-list">
                              <hr className="text-lite-grey" />
                              <div className="align-center margin-zero">
                                <Button className="button-icon" onClick={() => { history('my-profile'); setIsClickClose(true); }}>
                                  <FontAwesomeIcon className="seller-grey-icon" icon={faUserCircle} />
                                  <span className="text-link-user mx-2">My Profile</span>
                                </Button>
                              </div>
                            </NavItem>
                            <NavItem className="menu-list pt-1">
                              <hr className="text-lite-grey" />
                              <div className="align-center my-2">
                                <NavLink to="/login/sadmin#logn" onClick={() => { clearKeysFromSessionStorage(); window.location.href = '/login/sadmin#logn'; }} className="menu-link-notification">
                                  <FontAwesomeIcon className="sadmin-grey-icon" icon={faRightFromBracket} />
                                  <span className="text-link-user mx-2">Logout</span>
                                </NavLink>
                              </div>
                            </NavItem>
                          </ul>
                        </div>
                        )}
                      position="bottom"
                      customClassName="custom-popover"
                      isClickClose={isClickClose}
                      setIsClickClose={setIsClickClose}
                    >
                      <Nav className="button-icon   padding-zero" onClick={handleUserNotification}>
                        <img
                          alt="..."
                          className="powermart-user-icon rounded-image cursor-pointer"
                          src={filePreview !== '' ? filePreview : '/images/register-login.png'}
                          id="userInfo"
                          ref={userInfoRef}
                        />
                      </Nav>
                    </Popover>
                  </div>
                  <div className="align-center my-1">
                    <span className="user-info-text">Super Admin</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <SAdminSideDrawer menuCollapse={click} setClick={setClick} />
          <Row>
            <div className="route-margin">
              <ExclusiveRoutes />
            </div>
          </Row>
        </div>
      )
        : (
          <CXUnauthorized />
        )}
    </div>
  );
};

export default SuperAdminComponent;
