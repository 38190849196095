import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  CardImg,
  Col,
  Row,
} from 'reactstrap';
import { RootState } from 'typesafe-actions';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';
import { LoaderData } from '../../../../utils/loader';
import {
  clearGetAWSFileUrlReq,
  getAWSFileUrlReq,
  myOrdersReq,
} from '../../../../store/powerMart/actions';
import { errorAlert } from '../../../../utils/alert';
import { APIHeader } from '../../../../utils/constants';
import { getDiscomUtilSessionStorage, getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../../utils/service/localstorage-service';
import './orders.scss';
import { getConfig } from '../../../../utils/config/config';
import { MyOrdersResult } from '../../../../services/products/list-my-orders/get-all-myorders.types';
import Accordion from '../../../../utils/Accordion/Accordion';
import Modal from '../../../../utils/Modal/Modal';

const { AWS_PRODUCT_BUCKET } = getConfig();

export const OrderHistoryComponent: React.FC = () => {
  const dispatch = useDispatch();
  const tokenData = getTokenFromSessionStorage();
  const userInitTp = getUserProfileSessionStorage();
  const discomUtil = getDiscomUtilSessionStorage();
  const [discomUtility, setDiscomUtil] = React.useState('');
  const [alertMsg, setAlertMsg] = React.useState('');
  const [userTp, setUserTp] = React.useState('');
  const [showErrorsAlert, setErrorShowAlert] = React.useState(false);
  const [loaderOrders, setLoaderOrders] = React.useState(false);
  const [helpOrders, setHelpOrders] = React.useState(false);
  // const [myOrderData, setMyOrderData] = React.useState<string>('myOrder-0');
  const getAWSFileUrlRes = useSelector((state: RootState) => state.powerMart.getAWSFileUrl);
  const listMyOrder = useSelector((state: RootState) => state.powerMart.getMyOrdersList);
  const [myOrders, setMyOrders] = React.useState<MyOrdersResult[]>([
    {
      addr: '',
      fCnt: 0,
      ordId: '',
      ordDt: '',
      prodTxnData: [{
        ActId: '',
        ActTp: '',
        gst: '',
        prodCnt: '',
        prodCost: '',
        prodInfo: {
          cost: '',
          gst: '',
          imgUrl: '',
          nme: '',
        },
        mSts: '',
        sSts: '',
        tCost: '',
        rsn: '',
        shipDt: '',
        courNme: '',
        trckNo: '',
        trckUrl: '',
      }],
      sumCost: '',
      sumGst: '',
      sumTotal: '',
    },
  ]);

  React.useEffect(() => {
    if (discomUtil) {
      setDiscomUtil(discomUtil);
    }
  }, [discomUtil]);

  React.useEffect(() => {
    if (userInitTp && JSON.parse(userInitTp).profileData.UserTp) {
      setUserTp(userInitTp && JSON.parse(userInitTp).profileData.UserTp);
    }
  }, [userInitTp]);

  React.useEffect(() => {
    if (userTp !== '' && discomUtil !== '') {
      setLoaderOrders(true);
      dispatch(myOrdersReq({
        userType: userTp,
        token: tokenData,
        requestType: APIHeader.REQ_GET_ALL_USER_ORDERS,
        ordSts: 'WARANTY',
        discomId: discomUtility,
      }));
    }
  }, [userTp, discomUtil]);

  React.useEffect(() => {
    if (listMyOrder.isError && listMyOrder.message !== '') {
      setLoaderOrders(false);
      setAlertMsg(listMyOrder.message);
      setErrorShowAlert(true);
    }
  }, [listMyOrder.isError, listMyOrder.message]);

  React.useEffect(() => {
    if (!listMyOrder.isError && listMyOrder.message === 'executed') {
      setMyOrders(listMyOrder.data);
      setLoaderOrders(false);
    }
  }, [listMyOrder.isError, listMyOrder.message, discomUtil, listMyOrder.data]);

  // const handleAccordionToggle = (myData: string) => {
  //   setMyOrderData(myData);
  // };
  const handleDownloadPOWarranty = (orderId: string, orderTp: string, activityId: string) => {
    dispatch(getAWSFileUrlReq({
      bucketNme: AWS_PRODUCT_BUCKET,
      folderName: `p-orders/${orderId}`,
      fileNme: `${orderTp}-${orderId}_${activityId}_1.pdf`,
      requestType: '',
    }));
  };

  React.useEffect(() => {
    if (getAWSFileUrlRes.fileUrl !== '') {
      const anchor = document.createElement('a');
      anchor.href = getAWSFileUrlRes.fileUrl;
      anchor.download = 'downloaded-file.pdf';
      anchor.target = '_blank';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
      dispatch(clearGetAWSFileUrlReq());
    }
  }, [getAWSFileUrlRes.fileUrl]);

  return (
    <div className="pt-5">
      { loaderOrders ? <LoaderData /> : null}
      {showErrorsAlert ? (
        errorAlert(false, alertMsg, showErrorsAlert, setErrorShowAlert)
      ) : null}
      <div className="pt-5 align-center">
        <span className="my-order-label">
          MY PAST ORDERS
        </span>
      </div>
      <Row className="align-center">
        {myOrders && myOrders.length > 0
          ? myOrders.map((orderDet, index) => (
            <Col lg="10" xs="12" key={`order-det-${index.toString()}`}>
              <Accordion
                title={`Order# ${orderDet.ordId}`}
                defaultOpen={index === 0}
                targetId={`myHistoryOrder-${index}`}
              >
                <Row className="align-center">
                  <Col lg="12">
                    <Row>
                      <Col lg="5" xs="12">
                        <div className="align-start">
                          <span className="text-lite-grey mr-2">Order Date: {orderDet.ordDt}</span>
                        </div>
                      </Col>
                      {/* <Col lg="7" xs="12">
                            <div className="align-end">
                              <span className="text-lite-grey">Delivered on: {orderDet.shipDt}</span>
                            </div>
                          </Col> */}
                    </Row>
                  </Col>
                  <Col lg="12" className="pt-2">
                    <div className="my-order-items-bg">
                      <Row className="pt-2">
                        {orderDet.prodTxnData && orderDet.prodTxnData.length > 0
                          ? orderDet.prodTxnData.map((itmDet, itmIndex) => (
                            <Col xs="12" key={`item-det-${itmIndex.toString()}`} className="mb">
                              <Row className="mr-1">
                                <Col xs="12">
                                  <div className="align-end mx-2">
                                    <Button className="powermart-button-link mb" onClick={() => handleDownloadPOWarranty(orderDet.ordId, 'warranty', itmDet.ActId)}>
                                      Warranty Card
                                    </Button>
                                    <span className="mx-3 mb-1 text-lite-grey">|</span>
                                    <Button onClick={() => setHelpOrders(true)} className="powermart-button-link"> <FontAwesomeIcon icon={faHeadset} className="nav-mobile-icon1 mx-1" />help</Button>
                                  </div>
                                  <Modal
                                    show={helpOrders || false}
                                    onClose={() => setHelpOrders(false)}
                                    disCancel={false}
                                    targetId="orderHelp"
                                    title="Need Help ?"
                                    size="XL"
                                  >
                                    <div className="my-4 mx-3">
                                      <div className="ml">
                                        <span className="about-sub-title">Email Support</span><br />
                                        <span className="about-text">For detailed inquiries or support requests, you can contact us via email. Our team is ready to assist you with any questions or issues you may have.</span>
                                        <div className="pt-2 ml">
                                          <FontAwesomeIcon icon={faEnvelope} className="text-lite-grey mx-2" />
                                          <span className="text-lite-grey">support@powermart.com</span>
                                        </div>
                                      </div>
                                      <div className="pt-3 ml">
                                        <span className="about-sub-title">Phone Support</span><br />
                                        <span className="about-text">If you prefer to speak with a support representative directly, give us a call. Our phone support is available during business hours to provide you with immediate assistance.</span>
                                        <div className="pt-3 ml">
                                          <FontAwesomeIcon icon={faWhatsapp} className="correct-pass-key mx-2" />
                                          <span className="text-lite-grey">+91 8618679984</span>
                                        </div>
                                      </div>
                                    </div>
                                  </Modal>
                                </Col>
                              </Row>
                              <Row className="mr-1">
                                <Col lg="2" xs="4">
                                  <CardImg alt="prodLogo" className="order-prod-image order-margin-top" src={itmDet.prodInfo.imgUrl} />
                                </Col>
                                <Col lg="5" xs="8">
                                  <div className="align-start my-4">
                                    <span className="product-name-header-link">{itmDet.prodInfo.nme}</span>
                                  </div>
                                </Col>
                                <Col lg="2" xs="3">
                                  <div className="align-center my-4">
                                    <span className="order-cost-text">{itmDet.prodCnt} x</span>
                                  </div>
                                </Col>
                                <Col lg="3" xs="9">
                                  <div className="align-end my-4">
                                    <span className="order-cost-text">
                                      {new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(itmDet.prodCost))}
                                    </span>
                                  </div>
                                </Col>
                              </Row>
                              <Row className="align-center">
                                <Col xs="11">
                                  <hr className="hr-horz" />
                                </Col>
                              </Row>
                            </Col>
                          )) : (
                            <div className="pt-4 align-center">
                              <span className="form-collapse-card-header">No Product Found in Item List</span>
                            </div>
                          )}
                      </Row>
                      <Row className="mr-1">
                        <Col xs="12" lg="12">
                          <div className="align-end pt-1">
                            <span className="text-bold-grey mr-2">Product Total</span>
                            <span className="order-cost-text">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumCost))}</span>
                          </div>
                          <div className="align-end pt-3">
                            <span className="text-bold-grey mr-2">Total Tax</span>
                            <span className="order-cost-text">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumCost))}</span>
                          </div>
                        </Col>
                        <Col lg="12">
                          <hr className="hr-horz ml-3 my-3" />
                        </Col>
                        <Col xs="12" lg="12">
                          <div className="align-end">
                            <span className="text-bold-grey mr-2">Sum Total</span>
                            <span className="notification-text">{new Intl.NumberFormat('en-IN', { style: 'currency', currency: 'INR', minimumFractionDigits: 2 }).format(Number(orderDet.sumTotal))}</span>
                          </div>
                        </Col>
                      </Row>
                      <div className="pt-4" />
                    </div>
                  </Col>
                </Row>
              </Accordion>
            </Col>
          )) : (
            <div className="pt-4 align-center">
              <span className="form-collapse-card-header">No Orders Found. Continue Shopping</span>
            </div>
          )}
      </Row>
      <div className="pt-5" />
    </div>
  );
};
export default OrderHistoryComponent;
