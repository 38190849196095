import '../styles/scss/index.scss';

export const LoaderData: React.FC = () => (
  <div className="loader-container">
    <img src="/images/spinner.png" alt="Loading" className="spinner-img loader-image-size" />
    <span className="mx-2">Please wait....</span>
  </div>
);

export default LoaderData;
