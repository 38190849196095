import { ListCorpResponse, ListCorpResults } from './list-corp.service.types';

export const ListCorpTransform: (response: ListCorpResponse) => ListCorpResults = (listCorpResults) => {
  const { data, is_error, message } = listCorpResults;
  const result: ListCorpResults = {
    tCnt: 0,
    fCnt: 0,
    corpData: [],
    isError: false,
    message: '',
  };
  if (is_error) {
    result.isError = is_error;
    result.message = message;
  } else {
    result.tCnt = data.tCnt;
    result.fCnt = data.fCnt;
    result.corpData = data.corpData;
    result.message = 'executed';
  }
  return result;
};
