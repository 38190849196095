import React, { useState } from 'react';
import jwt_decode from 'jwt-decode';
import {
  Routes, Route,
  NavLink,
} from 'react-router-dom';
import { useNavigate } from 'react-router';
import {
  faRightFromBracket,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './seller.scss';
import {
  Row, Col, NavItem,
  Button,
  Nav,
} from 'reactstrap';
import { faUserCircle } from '@fortawesome/free-regular-svg-icons';
import { CXUnauthorized } from '../../public/unauthorized/unauthorized.component';
import ModalPasskeyUpdate from '../../../components/exclusive/passkey-reset/modal-reset-passkey.component';
import { clearKeysFromSessionStorage, getTokenFromSessionStorage, getUserProfileSessionStorage } from '../../../utils/service/localstorage-service';
import SellerDashboardComponent from '../../../components/exclusive/seller/seller.component';
import { MyProfileComponent } from '../../../components/exclusive/my-profile/profile';
import Popover from '../../../utils/Popover/Popover';
import Modal from '../../../utils/Modal/Modal';

export const ExclusiveRoutes: React.FC = () => (
  <Routes>
    <Route path="dashboard" element={<SellerDashboardComponent />} />
    <Route path="my-profile" element={<MyProfileComponent />} />
  </Routes>
);

export const SellerComponent: React.FC = () => {
  const history = useNavigate();
  const tokenData = getTokenFromSessionStorage();
  const userProfile = getUserProfileSessionStorage();
  // const [orgLogo, setOrgLogo] = React.useState('');
  const [orgNme, setOrgNme] = React.useState('');
  const [emailId, setEmailId] = useState('');
  const [phone, setPhone] = useState('');
  const [name, setName] = useState('');
  const [resetPassKey, setResetPassKey] = useState(true);
  const [isResetPassKey, setIsResetPasskey] = useState(false);
  const [isClickClose, setIsClickClose] = useState(false);
  const [openUserNotification, setOpenUserNotification] = useState(false);
  const passKeyModal = () => setResetPassKey(!resetPassKey);
  const userInfoRef = React.useRef<HTMLImageElement>(null);
  const popoverRef = React.useRef<HTMLDivElement>(null);
  const [filePreview, setFilePreview] = useState('');
  // const [imageURL, setImageUrl] = useState('/images/register-login.png');

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.isPKReset) {
      setIsResetPasskey(userProfile && JSON.parse(userProfile).profileData.isPKReset);
    }
  }, [isResetPassKey, userProfile && JSON.parse(userProfile).profileData.isPKReset]);

  // React.useEffect(() => {
  //   if (tokenData !== '') {
  //     const decodedToken: any = jwt_decode(tokenData);
  //     if (decodedToken !== undefined || decodedToken !== null) {
  //       if (decodedToken.exp * 1000 < new Date().getTime()) {
  //         history('/seller-login/seller#logn');
  //       }
  //     }
  //   }
  // }, [tokenData, history]);

  React.useEffect(() => {
    let shouldRedirect = false;
    if (tokenData !== '') {
      const decodedToken: any = jwt_decode(tokenData);
      if (decodedToken !== undefined || decodedToken !== null) {
        if (decodedToken.exp * 1000 < new Date().getTime()) {
          shouldRedirect = true;
          history('/seller-login/seller#logn');
        }
      }
    }
    if (shouldRedirect) {
      window.location.reload();
    }
  }, [tokenData, history]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.eId === '') {
      setEmailId('');
    } else {
      setEmailId(userProfile && JSON.parse(userProfile).profileData.eId);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.eId]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.ph === '') {
      setPhone('');
    } else {
      setPhone(userProfile && JSON.parse(userProfile).profileData.ph);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.ph]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.nme === '') {
      setName('');
    } else {
      setName(userProfile && JSON.parse(userProfile).profileData.nme);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.nme]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.avatar) {
      setFilePreview(userProfile && JSON.parse(userProfile).profileData.avatar);
    }
  }, [
    userProfile && JSON.parse(userProfile).profileData.avatar,
  ]);

  React.useEffect(() => {
    if (userProfile && JSON.parse(userProfile).profileData.corpData.nme !== '' && JSON.parse(userProfile).profileData.corpData.logo !== '') {
      // setOrgLogo(userProfile && JSON.parse(userProfile).profileData.corpData.logo);
      setOrgNme(userProfile && JSON.parse(userProfile).profileData.corpData.nme);
    }
  }, [userProfile && JSON.parse(userProfile).profileData.corpData.nme && JSON.parse(userProfile).profileData.corpData.logo]);

  const handleUserNotification = () => {
    setOpenUserNotification(!openUserNotification);
  };

  const handleToHome = () => {
    history('dashboard');
  };

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        userInfoRef.current
        && !userInfoRef.current.contains(event.target as Node)
        && popoverRef.current
        && !popoverRef.current.contains(event.target as Node)
      ) {
        setOpenUserNotification(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div>
      {tokenData !== '' ? (
        <div>
          {userProfile && JSON.parse(userProfile).profileData.isPKReset ? (
            <Modal
              show={resetPassKey || false}
              onClose={passKeyModal}
              disCancel={false}
              targetId="passwordResetAdmin"
              title="Update Password"
              size="MD"
            >
              <ModalPasskeyUpdate setIsResetPasskey={setIsResetPasskey} userType="SELLER" />
            </Modal>
          )
            : null}
          <Row className="navbar-seller">
            <Col lg="5" xs="8">
              <div className="align-start mx-1 pt-2">
                <div className="bg-icon-nav-dash">
                  <Button className="button-icon" onClick={handleToHome}>
                    <img
                      src="/powerMart_white.png"
                      alt="powerMart"
                      className="logo-bg icon-powermart-logo"
                    />
                  </Button>
                </div>
              </div>
            </Col>
            <Col lg="7" xs="4">
              <div className="align-end">
                <div className="menu-image-avatar">
                  <div className="align-center">
                    <Popover
                      header={(
                        <div className="align-center mx-2">
                          <span className="seller-user-name-text">{name}</span>
                        </div>
)}
                      content={(
                        <div ref={popoverRef}>
                          <ul className="menu-list pt-3">
                            <NavItem className="menu-list">
                              <div className="align-center pt-2">
                                <span className="text-lite-grey">{emailId}</span>
                              </div>
                              <div className="align-center">
                                <span className="text-lite-grey">+91 {phone}</span>
                              </div>
                              <div className="align-center pt-3">
                                <hr className="text-lite-grey my-2" />
                                <span className="text-lite-grey">{orgNme}</span>
                              </div>
                            </NavItem>
                            <NavItem className="menu-list">
                              <hr className="text-lite-grey" />
                              <div className="align-center margin-zero">
                                <Button className="button-icon" onClick={() => { history('my-profile'); setIsClickClose(true); }}>
                                  <FontAwesomeIcon className="seller-grey-icon" icon={faUserCircle} />
                                  <span className="text-link-user mx-2">My Profile</span>
                                </Button>
                              </div>
                            </NavItem>
                            <NavItem className="menu-list pt-1">
                              <hr className="text-lite-grey" />
                              <div className="align-center my-2">
                                <NavLink to="/seller-login/seller#logn" onClick={() => { clearKeysFromSessionStorage(); window.location.href = '/seller-login/seller#logn'; }} className="menu-link-notification">
                                  <FontAwesomeIcon className="seller-grey-icon" icon={faRightFromBracket} />
                                  <span className="text-link-user mx-2">Logout</span>
                                </NavLink>
                              </div>
                            </NavItem>
                          </ul>
                        </div>
                        )}
                      position="bottom"
                      customClassName="custom-popover"
                      isClickClose={isClickClose}
                      setIsClickClose={setIsClickClose}
                    >
                      <Nav className="button-icon   padding-zero" onClick={handleUserNotification}>
                        <img
                          alt="..."
                          className="powermart-user-icon rounded-image cursor-pointer"
                          src={filePreview !== '' ? filePreview : '/images/business.png'}
                          id="userInfo"
                          ref={userInfoRef}
                        />
                      </Nav>
                    </Popover>
                  </div>
                  <div className="align-center mb">
                    <span className="user-info-text">Seller</span>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <div className="route-margin">
              <ExclusiveRoutes />
            </div>
          </Row>
        </div>
      )
        : (
          <CXUnauthorized />
        )}
    </div>
  );
};

export default SellerComponent;
