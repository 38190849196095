import React, { useState } from 'react';
import { Button, Col, Row } from 'reactstrap';
import './order.scss';
import { SellerOrderDetails } from './order-details';

interface Step {
  label: string;
  content: any;
}

const steps: Step[] = [
  { label: 'Order Initiated', content: <SellerOrderDetails orderSts="RBUY" orderNme="Order Initiated" /> },
  { label: 'Payment Pending', content: <SellerOrderDetails orderSts="PEND" orderNme="Payment Pending" /> },
  { label: 'Payment Completed', content: <SellerOrderDetails orderSts="PCOMP" orderNme="Payment Completed" /> },
  { label: 'Package Status', content: <SellerOrderDetails orderSts="TACK" orderNme="Package Status" /> },
  { label: 'Shipment Progress', content: <SellerOrderDetails orderSts="SHPP" orderNme="Shipment Progress" /> },
  { label: 'Shipment Completed', content: <SellerOrderDetails orderSts="SHPC" orderNme="Shipment Completed" /> },
  { label: 'In Warranty', content: <SellerOrderDetails orderSts="WRNT" orderNme="In Warranty" /> },
];

const OrderVerticalStepper: React.FC = () => {
  const [activeStep, setActiveStep] = useState(0);

  const handleStepClick = (index: number) => {
    setActiveStep(index);
  };

  //   const handleNext = () => {
  //     setActiveStep((prevActiveStep) => Math.min(prevActiveStep + 1, steps.length - 1));
  //   };

  //   const handleBack = () => {
  //     setActiveStep((prevActiveStep) => Math.max(prevActiveStep - 1, 0));
  //   };

  //   const handleReset = () => {
  //     setActiveStep(0);
  //   };

  return (
    <Row className="pt-4">
      <Col xs="12" lg="3">
        <div className="pt-4 mx-1">
          <div className="stepper-buttons pt-3 mx-2">
            {steps.map((step, index) => (
              <Button
                key={`steps-${index.toString()}`}
                className={`step-power ${index === activeStep ? 'active' : 'powermart-button-solid mr-2'}`}
                onClick={() => handleStepClick(index)}
              >
                {step.label}
              </Button>
            ))}
          </div>
        </div>
      </Col>
      <Col xs="12" lg="9">
        <div className="step-content">
          {steps[activeStep].content}
        </div>
      </Col>
    </Row>
    // <div className="stepper-container ml-1 pt-4">
    //   <div className="stepper-buttons pt-3">
    //     {steps.map((step, index) => (
    //       <Button
    //         key={`steps-${index.toString()}`}
    //         className={`step ${index === activeStep ? 'active' : 'powermart-button-solid mr-2'}`}
    //         onClick={() => handleStepClick(index)}
    //       >
    //         {step.label}
    //       </Button>
    //     ))}
    //   </div>
    //   <div className="stepper-content">
    //     <div className="step-content">
    //       {steps[activeStep].content}
    //     </div>
    //     {/* <div className="navigation-buttons">
    //       <Button onClick={handleBack} disabled={activeStep === 0}>Back</Button>
    //       <Button onClick={handleNext} disabled={activeStep === steps.length - 1}>
    //         {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
    //       </Button>
    //       {activeStep === steps.length - 1 && (
    //         <Button onClick={handleReset}>Reset</Button>
    //       )}
    //     </div> */}
    //   </div>
    // </div>
  );
};

export default OrderVerticalStepper;
