import React from 'react';
import './powerMart.scss';
import { SellerLogin } from '../../components/public/login/sellers/seller-login';
import { SellerNavbar } from '../../components/public/navbar/seller.navbar';

export const SellerLoginPowerMartComponent: React.FC = () => (
  <div>
    <SellerNavbar />
    <SellerLogin />
  </div>
);

export default SellerLoginPowerMartComponent;
